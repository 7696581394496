import {
  GET_STATISTICS_INIT,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_ERROR,
  SELECT_STATISTICS_CHART_SECTION,
  SET_STATISTICS_NAME_LIST,
  SET_STATISTICS_FILTER_TYPE,
  CLEAR_STATISTICS_FILTER,
  SET_STATISTICS_FILTER,
  SET_SUB_SECTION,
  SET_STATISTICS_LOADING,
} from 'store/constants/statistics'

let today_date = new Date()
today_date.setDate(today_date.getDate() + 1)
let year_before_date = new Date()
year_before_date.setFullYear(year_before_date.getFullYear() - 1)
today_date = today_date.toISOString().split('T')[0]
year_before_date = year_before_date.toISOString().split('T')[0]

const initialFilter = {
  with_current_period_items: true,
  with_previous_period_items: true,
  license_is_free_trial: null,
  created_at_from: year_before_date,
  created_at_to: today_date,
  search_input: '',
}
const initialState = {
  selectedChartSection: 1,
  selected_sub_section: 0,
  loading: false,
  filter_type: 'users',
  filter_by: initialFilter,
  group_by: [],
  data: null,
}

const statisticsReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_STATISTICS_INIT:
      return {
        ...state,
        loading: true,
      }
    case GET_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
      }
    case GET_STATISTICS_ERROR:
      return {
        ...state,
        loading: false,
      }
    case SELECT_STATISTICS_CHART_SECTION:
      return {
        ...state,
        selectedChartSection: payload.value,
      }

    case SET_STATISTICS_NAME_LIST:
      return {
        ...state,
        [`${state.filter_type}_filter`]: {
          ...state[`${state.filter_type}_filter`],
          name_list: payload.name_list,
        },
      }
    case SET_STATISTICS_FILTER_TYPE:
      return {
        ...state,
        filter_type: payload.filter_type,
      }
    case CLEAR_STATISTICS_FILTER:
      return {
        ...state,
        filter_by: initialFilter,
      }
    case SET_STATISTICS_FILTER:
      return {
        ...state,
        filter_by: { ...state.filter_by, [payload.key]: payload.value },
      }
    case SET_SUB_SECTION:
      return {
        ...state,
        selected_sub_section: payload.id,
      }
    case SET_STATISTICS_LOADING:
      return {
        ...state,
        loading: payload.value,
      }
    default:
      return state
  }
}

export default statisticsReducer
