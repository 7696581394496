import { MS, SpectralLabel, PseudoSelect } from '../index.style'
import CustomSelect from 'components/CustomSelect'
import { useTranslation } from 'react-i18next'
import { ION_MODE_OPTIONS, ADDUCT_TYPE_OPTIONS, ROUNDINGS } from '../config'
import { connect, useDispatch } from 'react-redux'
import {
  clearSpectraData,
  setAdductType,
  setIonMode,
  setRoundingMZ,
  setRoundingRI,
} from 'store/actions/spectra'
import { Caption } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'

const MSBlock = ({ spectra }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const {
    adduct_type: adductType,
    roundingMZ,
    roundingRI,
    loading,
    ion_mode: ionMode,
    data,
  } = spectra

  const clearData = () => {
    if (!Object.keys(data).length) return
    dispatch(clearSpectraData())
  }

  const handleSelectIonMode = (option) => {
    clearData()
    dispatch(setIonMode(option))
    dispatch(setAdductType(ADDUCT_TYPE_OPTIONS[option.value][0]))
  }

  const handleRoundingRI = (option) => {
    clearData()
    dispatch(setRoundingRI(option))
  }

  const handleRoundingMZ = (option) => {
    clearData()
    dispatch(setRoundingMZ(option))
  }

  return (
    <MS>
      <div>
        <SpectralLabel>
          <Caption
            fontWeight={theme.fontWeight.medium}
            color={theme.colors.text.secondary}
          >
            {t('spectra.labels.spectral_type')}
          </Caption>
        </SpectralLabel>
        <PseudoSelect>ESI</PseudoSelect>
      </div>
      <div>
        <SpectralLabel>
          <Caption
            fontWeight={theme.fontWeight.medium}
            color={theme.colors.text.secondary}
          >
            {t('spectra.labels.ion_mode')}
          </Caption>
        </SpectralLabel>
        <CustomSelect
          options={ION_MODE_OPTIONS}
          selectedValue={ionMode}
          disabled={loading}
          onChange={handleSelectIonMode}
          withTranslation={false}
        />
      </div>
      <div>
        <SpectralLabel>
          <Caption
            fontWeight={theme.fontWeight.medium}
            color={theme.colors.text.secondary}
          >
            {t('spectra.labels.adduct_type')}
          </Caption>
        </SpectralLabel>
        <PseudoSelect>{adductType?.value}</PseudoSelect>
      </div>
      <div>
        <SpectralLabel>
          <Caption
            fontWeight={theme.fontWeight.medium}
            color={theme.colors.text.secondary}
          >
            {t('spectra.labels.roundingRI')}
          </Caption>
        </SpectralLabel>
        <CustomSelect
          options={ROUNDINGS}
          selectedValue={roundingRI}
          disabled={loading}
          onChange={handleRoundingRI}
          withTranslation={false}
        />
      </div>
      <div>
        <SpectralLabel>
          <Caption
            fontWeight={theme.fontWeight.medium}
            color={theme.colors.text.secondary}
          >
            {t('spectra.labels.roundingMZ')}
          </Caption>
        </SpectralLabel>
        <CustomSelect
          options={ROUNDINGS}
          selectedValue={roundingMZ}
          disabled={loading}
          onChange={handleRoundingMZ}
          withTranslation={false}
        />
      </div>
    </MS>
  )
}

const mapStateToProps = (state) => {
  return {
    spectra: state.spectra,
  }
}

export default connect(mapStateToProps)(MSBlock)
