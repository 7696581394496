const MAX_FILE_SIZE = 1024 * 1024 * 100

export { MAX_FILE_SIZE }

export const SMILES_SIZE = {
  width: 324,
  height: 180,
}

export const delimiterOptionsConfig = {
  title: 'baskets.delimiter',
  options: [
    {
      name: 'baskets.comma',
      value: ',',
    },
    {
      name: 'baskets.semicolon',
      value: ';',
    },
    // {
    //   name: 'baskets.tab',
    //   value: '\\t',
    // },
    {
      name: 'baskets.bar',
      value: '|',
    },
    {
      name: 'baskets.space',
      value: ' ',
    },
    {
      name: 'baskets.other',
      value: 'other',
    },
  ],
}
