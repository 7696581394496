import styled from 'styled-components'

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 13.75rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  overflow: hidden;
  padding: 1rem;
  gap: 1.5rem;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.default};
    cursor: pointer;
  }
`
export const CardHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: start;
  align-items: center;
  height: 1.5rem;
`
export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.25rem;
`
export const CardBodyText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 4;
  -moz-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardFooterDateBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.text.secondary};
`
export const CardFooterDateItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
export const FeedbackType = styled.div`
  display: flex;
  border-radius: 0.375rem;
  border: ${({ theme, withBorder, borderColor }) =>
    withBorder &&
    `1.2px solid ${
      borderColor ? borderColor : theme.colors.backgrounds.secondary
    }`};
  color: ${({ theme, color }) =>
    color ? color : theme.colors.text.accentPrimary};
  background-color: ${({ theme, background }) =>
    background ? background : theme.colors.backgrounds.accentTertiary};
  padding: 5px 0.5rem;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
`
export const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
export const CardInnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  overflow: hidden;
  gap: 1.5rem;
`
export const CardInnerHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: start;
  align-items: start;
`
export const CardInnerTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: start;
  align-items: center;
  width: 100%;
`
export const CardInnerDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
`
export const PDFAttach = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  gap: 0.75rem;
`
export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
`
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 19rem;
  border-radius: 0.75rem;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.75rem;
  }
  &:hover {
    cursor: pointer;
  }
`
export const ImageShowButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  box-shadow: ${({ theme }) => theme.shadow.default};

  svg {
    color: ${({ theme }) => theme.colors.icons.primary};
  }
  &:hover {
    cursor: pointer;
  }
`
export const FullImageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  overflow: hidden;
  gap: 2rem;
`
export const FullImage = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.icons.tertiary};
  border-radius: 0.75rem;
  flex-direction: column;
  flex-grow: 1;

  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 0.75rem;
  }
`

export const AttachImagesBlock = styled.div`
  gap: 0.5rem;
  display: flex;
  position: relative;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  border-radius: 0.75rem;
`
export const ImagesContainer = styled.div`
  gap: 0.25rem;
  display: flex;
  position: relative;
  flex-direction: column;
`

export const AttachImage = styled.img`
  width: 10.25rem;
  height: 7rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.icons.tertiary};

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.backgrounds.accent};
  }
`
export const EmailWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  div[type='icon_secondary'] > svg {
    min-width: 1.25rem;
  }
`
