import styled, { keyframes, css } from 'styled-components'

import background from 'assets/background/background.png'

const glow = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

const anim = css`
  ${glow} 0.3s forwards;
`
export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  z-index: 100;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(4px);
  }
`

export const Container = styled.form`
  gap: ${({ isLicenseWarning }) => (isLicenseWarning ? '1.75rem' : '2rem')};
  display: flex;
  padding: 2rem;
  width: 50%;
  height: 37.5rem;
  white-space: pre-line;
  flex-direction: column;
  border-radius: 1.25rem;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};

  & > img {
    height: 1.75rem;
  }
`

export const LoginWithPromo = styled.div`
  display: inline-flex;
  height: 37.5rem;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  animation: ${anim};
  overflow: auto;
  max-width: 59.375rem;
  width: 90vw;
`

export const LicenseWarning = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  span {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }
`
