import { t } from 'i18next'

export const getTitle = (length, text) => {
  if (!length) return { start_text: '', finish_text: '' }

  if (text.includes('already exists'))
    return length > 1
      ? {
          start_text: t('notification.molecules_already_exist_in_basket'),
          finish_text: '',
        }
      : {
          start_text: t('notification.molecule_already_exists_in_basket'),
          finish_text: '',
        }
  else if (text.includes('is not valid'))
    return length > 1
      ? {
          start_text: t('notification.structures'),
          finish_text: t('notification.are_not_valid'),
        }
      : {
          start_text: t('notification.structure'),
          finish_text: t('notification.is_not_valid'),
        }
  else if (text.includes('Network error'))
    return {
      start_text:
        length > 1
          ? t('notification.we_could_not_add_molecules')
          : t('notification.we_could_not_add_molecule'),
      finish_text: t('notification.wait_or_message'),
    }
}
