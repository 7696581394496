import {
  TOGGLE_MOLECULE_DIALOG,
  TOGGlE_ADD_MOLECULE_DIALOG,
} from 'store/constants/retrosynthesis'

export const toggleAddMoleculeDialog = (value) => {
  return {
    type: TOGGlE_ADD_MOLECULE_DIALOG,
    value,
  }
}

export const toggleMoleculeDialog = (value) => {
  return {
    type: TOGGLE_MOLECULE_DIALOG,
    value,
  }
}
