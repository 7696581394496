import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Content } from './index.style'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'

const AddEditDialog = ({
  handleCloseAddDialog,
  closeWithSave,
  editMode,
  name,
  desc,
  usedNames,
  withPortal,
}) => {
  const [valueName, setName] = useState(name)
  const [valueDesc, setDesc] = useState(desc)
  const { t } = useTranslation()

  const save = () => {
    closeWithSave(valueName, valueDesc)
  }

  const saveByEnter = (e) => {
    if (e.key === 'Enter' && valueName) {
      save()
    }
  }
  const nameError = useMemo(() => {
    return usedNames.some((name) => name === valueName)
  }, [usedNames, valueName])

  return (
    <CustomDialog
      width={'372px'}
      onClose={handleCloseAddDialog}
      withPortal={withPortal}
    >
      <CustomDialogHeader>
        {t(
          `baskets.add_create_dialog.${
            editMode ? 'dataset_editing' : 'dataset_creation'
          }`
        )}
      </CustomDialogHeader>
      <Content>
        <CustomInput
          onChange={setName}
          value={valueName}
          label={t('baskets.add_create_dialog.name')}
          placeholder={t('baskets.add_create_dialog.name_of_your_set')}
          withClearButton={true}
          onKeyUp={saveByEnter}
          autoFocus={true}
          error={nameError ? 'baskets.name_taken' : ''}
        />
        <CustomInput
          onChange={setDesc}
          value={valueDesc}
          label={t('baskets.add_create_dialog.description')}
          placeholder={t('baskets.add_create_dialog.description_of_your_set')}
          withClearButton={true}
          onKeyUp={saveByEnter}
        />
      </Content>
      <CustomButton
        width={'100%'}
        type="accent"
        disabled={!valueName || nameError}
        onClick={!valueName ? () => {} : save}
      >
        {t(`baskets.add_create_dialog.${editMode ? 'save' : 'create'}`)}
      </CustomButton>
    </CustomDialog>
  )
}

export default AddEditDialog
