import mailIcon from '../../assets/mail.svg'
import phoneIcon from '../../assets/phone.svg'
import vkIcon from '../../assets/logo_vk.svg'
import telegramIcon from '../../assets/logo_telegram.svg'
import youtubeIcon from '../../assets/logo_you_tube.svg'

export const contactsConfig = [
  {
    icon: phoneIcon,
    content: '+79250357695',
    alt: 'phone',
  },
  {
    icon: mailIcon,
    content: 'admin@syntelly.com',
    alt: 'e-mail',
  },
]

export const socialConfig = [
  { icon: vkIcon, link: 'https://vk.com/syntelly', alt: 'Vk' },
  {
    icon: telegramIcon,
    link: 'https://t.me/syntellyplatform',
    alt: 'Telegram',
  },
  {
    icon: youtubeIcon,
    link: 'https://www.youtube.com/@syntelly',
    alt: 'YouTube',
  },
]
