import styled from 'styled-components'

export const AdminCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`

export const CardsHeader = styled.div`
  display: flex;
  width: calc(100% - 6rem);
  padding: 1.5rem 1rem;
`

export const HeaderItem = styled.div`
  width: ${({ width }) => width};
  padding: 0 0.375rem;
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};
  align-items: center;
`

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.5rem;
`

export const UsersSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 3.75rem;
`
