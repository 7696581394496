import styled, { css } from 'styled-components'

const fontStyles = css`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.144px;
`

const calcStyles = css`
  flex-direction: column;
`

const defaultStyles = css`
  align-items: center;
  justify-content: center;
`

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding-right: 0.5rem;
  svg {
    min-width: 1.5rem;
  }
`

export const Content = styled.div`
  color: #616e7c;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.144px;
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;

  ${({ isCalculation }) => (isCalculation ? calcStyles : defaultStyles)}
`

export const CalculationHeader = styled.div`
  color: #1f2933;
  font-weight: 500;
  ${fontStyles}
`
