import React, { useState, useEffect } from 'react'
import { fetch, store, destroy, update } from '../../services/rest'
import { Container } from './index.style'
import BillingServicesList from '../BillingServicesList'

const BillingAdminView = () => {
  const [list, setList] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null)

  useEffect(() => {
    handleLoadServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNetworking = async (fn, reload) => {
    let result
    try {
      setLoading(true)
      result = await fn()
    } catch (e) {
      setError(e)
    } finally {
      if (reload) {
        handleLoadServices()
      }
    }
    setLoading(false)
    return result
  }

  const handleLoadServices = async () => {
    const response = await handleNetworking(
      () => fetch('/billing/services'),
      false
    )
    if (response && response.data) setList(response.data)
  }

  const handleAddService = ({ name, service_name, price, description }) => {
    handleNetworking(
      () =>
        store('/billing/services', { name, service_name, price, description }),
      true
    )
  }

  const handleDeleteService = ({ id }) => {
    handleNetworking(() => destroy(`/billing/services/${id}`), true)
  }

  const handleUpdateService = ({
    id,
    name,
    service_name,
    price,
    description,
  }) => {
    handleNetworking(
      () =>
        update(`/billing/services/${id}`, {
          name,
          service_name,
          price,
          description,
        }),
      true
    )
  }

  return (
    <Container>
      <BillingServicesList
        list={list}
        handleDelete={handleDeleteService}
        handleAdd={handleAddService}
        handleUpdate={handleUpdateService}
      />
    </Container>
  )
}

export default BillingAdminView
