import styled from 'styled-components'
import CustomButton from 'components/common/customButton'
import { scrollbarStyles } from 'components/common/scrollbar/scrollbar.style'
import CustomInput from 'components/common/customInput'

export const TableWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 8px;
  margin: 0 0.5rem;
  display: grid;

  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: #f5f7f9;
  ${scrollbarStyles}
`

export const Row = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : '8px')};
`
export const TotalRow = styled(Row)`
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
`

export const TotalRowWrapper = styled.div`
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
`

export const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  padding: 0.125rem;
  width: 1.25rem;
  svg {
    color: #cbd2d9;
  }
  cursor: pointer;
  :hover {
    svg {
      color: #42c1bf;
    }
  }
`
export const StyledTable = styled.table`
  border-spacing: 0 0.5rem;
  width: 100%;

  svg {
    width: 16px;
    height: 16px;
  }

  .no-padding {
    padding: 0px;
    padding-left: 4px;
    padding-right: 4px;
  }

  td {
    position: relative;
    height: 40px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* max-width: ${({ isSidebarCollapsed }) =>
      isSidebarCollapsed ? '100px' : '60px'} !important; */

    & > span {
      color: #6e809d;

      & > span {
        color: #2d3745;
      }
    }

    @media print {
      white-space: wrap;
      word-break: break-all;
    }
    a {
      color: #2e90fa;
      text-decoration: none;
    }
  }

  td:first-child {
    text-align: start;
    @media print {
      width: 10%;
    }
  }

  @media (max-width: 824px) {
    #close > svg {
      ${({ isSidebarCollapsed }) =>
        !isSidebarCollapsed &&
        `width: 14px;
				height: 14px;
				margin-left: -6px;`};
    }
  }

  td:nth-child(2) {
    text-align: start;
  }

  td:nth-child(5),
  td:nth-child(6) {
    text-align: center;
  }

  td:nth-child(3),
  td:nth-child(4) {
    @media print {
      width: 14%;
    }
  }

  thead {
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;

    tr {
      background: #ffffff;

      td {
        color: #6e809d;
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        padding-left: 0.75rem;
      }

      td:first-child {
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
      }

      td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      div {
        display: inline-flex;
        vertical-align: middle;
        flex-direction: row;
        svg {
          color: #cbd2d9;
          cursor: pointer;
          margin-left: 4px;
          margin-bottom: 2px;
          &:hover {
            color: #42c1bf;
          }
          display: ${({ isTableEdit }) => isTableEdit && 'none'};
          @media print {
            display: none;
          }
        }
      }
    }
  }

  tbody {
    tr {
      height: 2.5rem;

      td {
        height: 2.5rem;
        padding-left: 0.75rem;
      }
    }
    tr:first-child {
      background: #ffffff;
      color: #2d3745;

      td:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 6px;
      }

      td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
`
export const TableFooter = styled.div`
  display: flex;
  padding: 1.25rem;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #f5f7f9;
  border-radius: 0px 0px 0.5rem 0.5rem;
  margin: 0.125rem 0.5rem 0.5rem 0.5rem;

  svg {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 16px;
  }
  @media print {
    margin-top: 10px;
    justify-content: flex-end;
  }
`
export const ActionButtons = styled(Row)`
  gap: 1rem;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    justify-content: center;
  }
`
export const StyledButton = styled(CustomButton)`
  gap: 0.25rem;
  margin-left: ${({ isRightPosition }) => isRightPosition && 'auto'};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0')};
`

export const TdNumbers = styled.div`
  display: flex;
  column-gap: 0.125rem;
  justify-content: center;

  & > div {
  }
`

export const TdOutput = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  @media print {
    white-space: nowrap;
  }

  & > div {
    width: unset;
  }

  ${CustomInput} {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0 !important;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`
export const IconContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  & > svg {
    cursor: pointer;
    min-width: 1rem;
    color: ${({ theme }) => theme.colors.statuses.error};
  }
`
