import { NMR, SpectralLabel, Tabs, Tab } from '../index.style'
import { NMR_METHODS } from '../config'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { resetSpectra, setNmrMethod } from 'store/actions/spectra'
import CustomTab from 'components/common/customTab'
import { useTheme } from 'styled-components'
import { Caption, TextButtonMedium } from 'components/common/text/index.style'

const NMRBlock = ({ nmrMethod, loading }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const dispatch = useDispatch()

  const handleChooseMethod = (method) => {
    if (loading) return
    dispatch(resetSpectra())
    dispatch(setNmrMethod(method))
  }

  return (
    <NMR>
      <SpectralLabel>
        <Caption
          color={theme.colors.text.secondary}
          fontWeight={theme.fontWeight.medium}
        >
          {t('spectra.labels.method')}
        </Caption>
      </SpectralLabel>
      <Tabs>
        {NMR_METHODS.map((method, index) => {
          return (
            <CustomTab
              key={`${method.value}-${index}`}
              onClick={() => handleChooseMethod(method)}
              isActive={nmrMethod.value === method.value}
              type="grey"
              width="2.25rem"
              height="2.25rem"
              disabled={loading}
            >
              <TextButtonMedium color="inherit">
                {method.label}
              </TextButtonMedium>
            </CustomTab>
          )
        })}
      </Tabs>
    </NMR>
  )
}

const mapStateToProps = (state) => {
  return {
    nmrMethod: state.spectra.nmr.method,
    data: state.spectra.nmr.data,
    loading: state.spectra.nmr.loading,
  }
}

export default connect(mapStateToProps)(NMRBlock)
