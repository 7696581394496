import { bisector } from 'd3-array'
import { localPoint } from '@visx/event'

export const getX = (d) => d.x
export const getY = (d) => d.y
export const bisect = bisector((d) => d.x).left

export const numZeroesAfterPoint = (x) => {
  if (x % 1 === 0) {
    return 0
  } else {
    return -1 - Math.floor(Math.log10(x % 1))
  }
}

export const getAverage = (arr) => {
  return Array.isArray(arr) ? arr.reduce((p, c) => p + c, 0) / arr.length : arr
}

export const getScrollScale = (e, zoom) => {
  const delta = e.deltaY

  if (Math.abs(delta) > 10) {
    zoom.scale({
      scaleX: delta > 0 ? 1.1 : 0.9,
      scaleY: 1,
      point: localPoint(e),
    })
  } else if (Math.abs(delta) > 100) {
    zoom.scale({
      scaleX: delta > 0 ? 1.15 : 0.85,
      scaleY: 1,
      point: localPoint(e),
    })
  } else {
    zoom.scale({
      scaleX: delta > 0 ? 1.03 : 0.97,
      scaleY: 1,
      point: localPoint(e),
    })
  }
}

export const downloadSvgAsImageById = (elementId) => {
  // Step 1: Get the SVG element by its ID
  const svgElement = document.getElementById(elementId)

  // Step 2: Convert the SVG element to an XML string
  const svgData = new XMLSerializer().serializeToString(svgElement)

  // Step 3: Create a Blob from the SVG data
  const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
  const url = URL.createObjectURL(svgBlob)

  // Step 4: Create an Image object
  const img = new Image()
  img.onload = () => {
    // Step 5: Create a canvas element
    const canvas = document.createElement('canvas')
    canvas.width = svgElement.clientWidth // Set canvas dimensions to match the SVG
    canvas.height = svgElement.clientHeight

    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // Step 6: Draw the image onto the canvas
    ctx.drawImage(img, 0, 0)

    // Step 7: Convert the canvas to a data URL
    const pngFile = canvas.toDataURL('image/png')

    // Step 8: Create a download link and trigger the download
    const downloadLink = document.createElement('a')
    downloadLink.href = pngFile
    downloadLink.download = 'spectra-prediction-image.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(url)
  }

  // Step 9: Set the source of the Image object to the SVG Blob URL
  img.src = url
}
