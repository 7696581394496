import styled from 'styled-components'

export const ListBlock = styled.div`
  display: grid;
  column-gap: 12px;
  row-gap: 20px;
  grid-template-columns: 1fr;
  padding: 0 1.5rem;
  align-content: center;
  align-items: center;
`

export const WrapBlock = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 0.75rem;
  width: 100%;
`
export const InputWithButton = styled.div`
  gap: 0.5rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 10rem;
`
