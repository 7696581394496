import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import {
  FilterCondition,
  PlusIcon,
  FilterLitConditionWrapper,
  ConditionHeader,
  Condition,
  ConditionWrapper,
  StyledTrashButton,
} from './index.style'
import { useTranslation } from 'react-i18next'
import {
  Caption,
  Subheadline,
  TextButtonMedium,
  TextMedium,
} from 'components/common/text/index.style'
import {
  setLitFilterConfig,
  setLitFilterDocType,
  setOpenLitFilter,
} from 'store/actions/literature'
import { connect, useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { memo } from 'react'
import { setSearchText } from 'store/actions/search'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import { confirm } from 'store/actions/confirm'
import { setLeftFilterLitDocType } from 'store/actions/filter'
import { CONFLICT_OPTIONS } from '../../../Search/LiteratureFilter/constants'
const FilterLitConditions = memo(
  ({ rightFilterConfig, leftFilterConfig, docType, ctxMenu }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const isCtxMenuActive = ctxMenu?.menu === 'literatureFilterMenu'

    const handleAddNewCondition = (option) => {
      const handleAddName = () => {
        dispatch(
          confirm(
            t('confirmation.add_condition'),
            () => {
              dispatch(setLitFilterConfig({ name: '' }))
              dispatch(setLitFilterDocType(option))
              dispatch(setSearchText(''))
              handleOpenFilter()
            },
            t('filter.literature.name_warning')
          )
        )
      }
      switch (option) {
        case 'doi':
          handleAddName()

          break
        case 'patent_number':
          handleAddName()

          break
        default:
          dispatch(
            setLitFilterConfig({
              ...rightFilterConfig,
              [option]: {
                operator: 'must',
                exact: false,
                values: [''],
              },
            })
          )
          handleOpenFilter()
          break
      }
    }

    const handleOpenFilter = () => {
      dispatch(setOpenLitFilter(true))
    }
    const handleOpenContextMenu = (e) => {
      if (Object.keys(rightFilterConfig).includes('name')) return
      e.stopPropagation()
      dispatch(
        handleShowContextMenu({
          e,
          menu: 'literatureFilterMenu',
          item: {
            handler: handleAddNewCondition,
            config: {
              documentTypes: leftFilterConfig?.document_type || [],
              rightConfig: Object.keys(rightFilterConfig),
            },
          },
        })
      )
    }

    const handleDeleteRow = (currentElement) => {
      const configCopy = structuredClone(rightFilterConfig)
      delete configCopy[currentElement]

      if (
        !Object.keys(configCopy).length ||
        !Object.keys(configCopy).find(
          (el) =>
            CONFLICT_OPTIONS.patent.includes(el) ||
            CONFLICT_OPTIONS.article.includes(el)
        )
      ) {
        dispatch(setLeftFilterLitDocType(['article', 'patent']))
      }
      dispatch(setLitFilterConfig(configCopy))
    }

    const getTranslate = (text) => {
      if (text === 'name') return t(`filter.literature.${docType}`)
      if (text === 'company') return t(`filter.literature.owner`)
      return t(`filter.literature.${text}`)
    }

    return (
      <FilterLitConditionWrapper>
        <CustomButton
          style={{ marginBottom: '0.25rem' }}
          type="secondary"
          gap="0.25rem"
          onClick={handleOpenContextMenu}
          disabled={Object.keys(rightFilterConfig).includes('name')}
          active={isCtxMenuActive}
        >
          <PlusIcon iconType="add" size="1rem" />
          <Subheadline color="inherit">
            {t('filter.literature.addCondition')}
          </Subheadline>
        </CustomButton>
        {Object.entries(rightFilterConfig)?.map((el, i) => {
          if (
            (el?.[0] !== 'name' &&
              el[1]?.values?.length === 1 &&
              el[1]?.values[0] === '') ||
            (el?.[0] === 'name' && !el?.[1])
          )
            return null
          return (
            <FilterCondition key={`left-filter-condition-${el?.[0]}-${i}`}>
              <ConditionHeader>
                <TextMedium
                  fontWeight={theme.fontWeight.medium}
                  color={theme.colors.text.primary}
                >
                  {getTranslate(el[0])}
                </TextMedium>
                <StyledTrashButton onClick={() => handleDeleteRow(el?.[0])}>
                  <Icon iconType="deleted" size="1rem" />
                </StyledTrashButton>
              </ConditionHeader>
              {el?.[0] !== 'name' &&
                el?.[1]?.values?.map((condition, i) => (
                  <ConditionWrapper key={`${condition}-${i}`}>
                    {i !== 0 && (
                      <Caption
                        color={theme.colors.text.secondary}
                        fontWeight={theme.fontWeight.medium}
                      >
                        {t(`filter.literature.${el?.[1]?.operator}_lowercase`)}
                      </Caption>
                    )}
                    <Condition>
                      <Caption
                        style={{ maxHeight: '100%' }}
                        color={theme.colors.text.primary}
                        fontWeight={theme.fontWeight.medium}
                        ellipsis={true}
                      >
                        {condition}
                      </Caption>
                    </Condition>
                  </ConditionWrapper>
                ))}
              {el?.[0] === 'name' && (
                <ConditionWrapper key={`${el?.[0]}-${i}`}>
                  <Condition>
                    <Caption
                      style={{ maxHeight: '100%' }}
                      color={theme.colors.text.primary}
                      fontWeight={theme.fontWeight.medium}
                    >
                      {el?.[1]}
                    </Caption>
                  </Condition>
                </ConditionWrapper>
              )}
              <CustomButton
                type="text"
                gap="0.25rem"
                onClick={handleOpenFilter}
              >
                <TextButtonMedium color="inherit">
                  {t('admin.edit')}
                </TextButtonMedium>
                <Icon iconType="pen" size="1rem" />
              </CustomButton>
            </FilterCondition>
          )
        })}
      </FilterLitConditionWrapper>
    )
  },
  arePropsEqual
)

const mapStateToProps = (state) => {
  return {
    rightFilterConfig: state.literature.filter.config,
    leftFilterConfig: state.filter.config,
    isFilterOpen: state.literature.filter.open,
    docType: state.literature.filter.docType,
    ctxMenu: state.contextMenu.contextMenuState,
  }
}

function arePropsEqual(oldProps, newProps) {
  const oldConfig = oldProps.isFilterOpen
  const newConfig = newProps.isFilterOpen
  if ((newConfig && oldConfig) || (newConfig && !oldConfig)) return true
  return false
}

FilterLitConditions.displayName = 'FilterLitConditions'

export default connect(mapStateToProps)(FilterLitConditions)
