import styled from 'styled-components'

export const NotFoundBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  align-items: start;
  justify-content: center;
`

export const NotFoundButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;

  & > svg {
    color: rgba(45, 55, 69, 0.7);
  }

  &:hover {
    font-weight: 600;
    & > svg {
      color: rgba(45, 55, 69, 1);
      transition: color 0.3s;
    }
  }
`
