import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  height: ${({ height }) => height ?? '0rem'};
  max-height: 3rem;
  flex-grow: 1;
  overflow: hidden;
  transition: height 0.3s ease;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: start;
`

export const CardHeader = styled.div``

export const CardData = styled.div`
  display: flex;
  gap: 0.125rem;
`

export const CardDataItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: ${({ bg }) => bg && bg};
  color: ${({ color }) => color && color};
  color: ${({ color }) => color && color};
  border-radius: ${({ radius }) => radius && radius};
  padding: 0.25rem 0.375rem;
  ${({ rotate }) =>
    rotate &&
    `svg {
    transform: rotate(${rotate}deg);
  }`}
`
export const Separator = styled.div`
  width: 1.5rem;
  position: relative;
  height: 3rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    background-color: ${({ theme }) => theme.colors.backgrounds.separator};
  }
`
