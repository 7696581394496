import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { update } from 'services/rest'
import { useAuth } from 'utils/auth/auth'
import { useTheme } from 'styled-components'
import { resetMe, loadMe } from 'store/actions/auth'
import CustomInput from 'components/common/customInput'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import CustomToggle from 'components/common/customToggle'
import CustomButton from 'components/common/customButton'
import { validatePasswordTotal } from 'utils/common/common'
import { CustomDialog } from 'components/common/customDialog'
import { addNotification } from 'store/actions/notifications'
import { settingsShowTipsToggle } from 'store/actions/settings'
import { TextButtonMedium } from 'components/common/text/index.style'

import {
  Footer,
  Content,
  ExtBlock,
  DialogButtons,
  ToggleWithLabel,
  TitleWithDescription,
  PasswordChangeButtons,
} from './index.style'
import { hasChanges } from './utils'
import ChangedInfoDialog from './ChangedInfoDialog'

const AccountInfo = ({
  push,
  loadMe,
  resetMe,
  userdata,
  showTips,
  handleClose,
  addNotification,
  settingsShowTipsToggle,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [showPasswordChange, setShowPasswordChange] = useState(false)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const [showTipsTemp, setShowTipsTemp] = useState(showTips)
  const [isChangedInfoDialogOpen, setIsChangedInfoDialogOpen] = useState(false)

  const initialUpdatedUserDataState = {
    status: null,
    message: null,
  }

  const initialUpdatedUserData = {
    name: userdata.name,
    position: userdata.position,
    company: userdata.company,
  }

  const [updateUserdataState, setUpdateUserdataState] = useState(
    initialUpdatedUserDataState
  )

  const [updatedUserdata, setUpdatedUserdata] = useState(initialUpdatedUserData)

  useEffect(() => {
    if (updateUserdataState.status) {
      const id = uuidv4()
      const notify = {
        id,
        name: `account.${updateUserdataState.message}`,
        notification_type: updateUserdataState.status,
        autoRemove: true,
        timeout: 5000,
      }
      addNotification(notify)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserdataState.status])

  const isNoChanges =
    !hasChanges(initialUpdatedUserData, updatedUserdata) &&
    showTips === showTipsTemp

  const { name, position, company } = updatedUserdata

  const updateUserdataHandler = async () => {
    settingsShowTipsToggle(showTipsTemp)
    setUpdateUserdataState(initialUpdatedUserDataState)
    try {
      await update('/profile', { ...updatedUserdata })
      loadMe()
      setUpdateUserdataState({
        ...updateUserdataState,
        status: 'success',
        message: 'messageSuccess',
      })
    } catch (e) {
      setUpdateUserdataState({
        ...updateUserdataState,
        status: 'error',
        message: 'messageFailed',
      })
    }
  }

  const handleResetData = () => {
    setUpdateUserdataState(initialUpdatedUserDataState)
    setUpdatedUserdata(initialUpdatedUserData)
    setShowTipsTemp(showTips)
  }

  const { logout } = useAuth()
  const logoImage = useGetLogoImage()

  const handleChangeUserdata = (id, value) => {
    switch (id) {
      case 'name':
        setUpdatedUserdata({
          ...updatedUserdata,
          name: value,
        })
        break
      case 'position':
        setUpdatedUserdata({
          ...updatedUserdata,
          position: value,
        })
        break
      case 'company':
        setUpdatedUserdata({
          ...updatedUserdata,
          company: value,
        })
        break
      default:
        break
    }
  }

  const handleLogout = async () => {
    handleClose()
    await logout()
    push('/')
    resetMe()
  }

  const handleChangePassword = async () => {
    try {
      await update('/profile/password', { password })
      setIsChangedInfoDialogOpen(true)
      handleClosePassword()
    } catch (e) {
      console.log(e)
    }
  }

  const handleClosePassword = () => {
    setPassword('')
    setPassword2('')
    setShowPasswordChange(false)
  }

  const [password2Error, setPassword2Error] = useState('')
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(false)

  const isPasswordValidationError = validatePasswordTotal(
    password,
    userdata?.email
  )

  const isChangePasswordDisabled =
    !password ||
    !password2 ||
    isPasswordValidationError ||
    password2Error ||
    !isPasswordsMatch

  useEffect(() => {
    if (!password2 && password2Error) setPassword2Error('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password2])

  const handleBlur = () => {
    if (password && password2) {
      if (password !== password2) {
        setPassword2Error('account.password2Error')
        setIsPasswordsMatch(false)
      } else {
        if (!isPasswordsMatch) setIsPasswordsMatch(true)
        setPassword2Error('')
      }
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
  }

  return (
    <>
      <CustomDialog
        width="624px"
        onClose={isChangedInfoDialogOpen ? () => {} : handleClose}
      >
        <img
          src={logoImage}
          alt="syntelly"
          height="70px"
          style={{ margin: '0 auto' }}
        />
        <Content>
          <CustomInput
            label={t('account.email')}
            value={userdata.email}
            disabled
          />
          <CustomInput
            label={t('account.name')}
            value={name}
            onChange={(value) => handleChangeUserdata('name', value)}
            withClearButton
          />

          <CustomInput
            label={t('account.position')}
            value={position}
            onChange={(value) => handleChangeUserdata('position', value)}
            withClearButton
          />
          <CustomInput
            label={t('account.company')}
            value={company}
            onChange={(value) => handleChangeUserdata('company', value)}
            withClearButton
          />
          {showPasswordChange && (
            <>
              <CustomInput
                id="pass1"
                type="password"
                placeholder={t('account.password')}
                label={t('account.password')}
                value={password}
                onChange={(value) => {
                  setIsPasswordsMatch(false)
                  setPassword(value)
                }}
                warning={isPasswordValidationError}
                isValidated={!!password && !isPasswordValidationError}
                withClearButton
                withPasswordTooltip={isPasswordValidationError}
                email={userdata?.email}
              />
              <CustomInput
                id="pass2"
                type="password"
                placeholder={t('account.retypePassword')}
                label={t('account.retypePassword')}
                value={password2}
                onChange={(value) => {
                  setIsPasswordsMatch(false)
                  setPassword2(value)
                }}
                warning={password2Error}
                isValidated={!!password2 && !password2Error && isPasswordsMatch}
                withClearButton
                onBlur={handleBlur}
                onKeyUp={handleKeyUp}
              />
            </>
          )}
        </Content>
        <Footer>
          {!showPasswordChange && (
            <ToggleWithLabel>
              <TitleWithDescription>
                <TextButtonMedium>{t('account.tips')}</TextButtonMedium>
                <TextButtonMedium
                  color={theme.colors.text.secondary}
                  fontWeight={theme.fontWeight.light}
                >
                  {t('account.about_each_module')}
                </TextButtonMedium>
              </TitleWithDescription>
              <CustomToggle
                size="large"
                type="grey"
                value={showTipsTemp}
                onClick={() => setShowTipsTemp((prev) => !prev)}
              />
            </ToggleWithLabel>
          )}

          <ExtBlock>
            {' '}
            <CustomButton type="secondary" onClick={handleLogout}>
              {t('account.logout')}
            </CustomButton>
            {showPasswordChange ? (
              <PasswordChangeButtons>
                <CustomButton type="accent" onClick={handleClosePassword}>
                  {t('account.dontChange')}
                </CustomButton>
                <CustomButton
                  onClick={handleChangePassword}
                  type="secondary"
                  disabled={isChangePasswordDisabled}
                >
                  {t('account.change')}
                </CustomButton>
              </PasswordChangeButtons>
            ) : (
              <CustomButton
                gap="4px"
                type="text"
                onClick={() => setShowPasswordChange(true)}
              >
                <Icon iconType="edit" size="1rem" />
                {t('account.changePassword')}
              </CustomButton>
            )}
          </ExtBlock>
        </Footer>

        <DialogButtons>
          <CustomButton
            type="secondary"
            width="100%"
            onClick={handleResetData}
            disabled={isNoChanges}
          >
            {t('account.resetInfo')}
          </CustomButton>
          <CustomButton
            type="accent"
            width="100%"
            onClick={updateUserdataHandler}
            disabled={showPasswordChange || isNoChanges}
          >
            {t('account.saveInfo')}
          </CustomButton>
        </DialogButtons>
      </CustomDialog>{' '}
      {isChangedInfoDialogOpen && (
        <ChangedInfoDialog onClose={() => setIsChangedInfoDialogOpen(false)} />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userdata: state.auth.userdata,
    showTips: state.settings.showTips,
  }
}

const mapDispatchToProps = {
  push,
  resetMe,
  loadMe,
  addNotification,
  settingsShowTipsToggle,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo)
