import { t } from 'i18next'

export const getTitle = (count) => {
  const lastDigit = count % 10
  const lastTwoDigits = count % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14)
    return t('baskets.compounds_many')
  if (lastDigit === 1) return t('baskets.compound')
  if (lastDigit >= 2 && lastDigit <= 4) return t('baskets.compounds_few')
  return t('baskets.compounds_many')
}
