import { GETME_INIT, GETME_SUCCESS, GETME_FAIL } from '../constants/auth';
import { put, takeEvery } from 'redux-saga/effects';
import { fetch } from '../../services/rest';

function* getme() {
  try {
    const { data } = yield fetch('/profile');
    yield put({
      type: GETME_SUCCESS,
      userdata: data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GETME_FAIL,
    });
  }
}

function* loadmeWatcher() {
  yield takeEvery(GETME_INIT, getme);
}

const watchers = [loadmeWatcher()];
export default watchers;
