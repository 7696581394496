import { useParams } from 'react-router-dom'

import Molecule from 'components/Molecule/molecule'

import { Container } from './index.style'

export const MoleculePage = ({ smiles, onGoBackToMolEditor }) => {
  const { id } = useParams()

  return (
    <Container>
      <Molecule {...{ id, smiles, onGoBackToMolEditor }} />
    </Container>
  )
}
