import { TextLarge } from 'components/common/text/index.style'
import styled from 'styled-components'

export const Container = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`

export const ToggleWithResizer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderWithDescription = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
export const Description = styled(TextLarge)`
  max-width: 45.25rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`
