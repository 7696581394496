import { useEffect, useRef, useState } from 'react'
import { getDocument } from 'pdfjs-dist'
import * as pdfjsLib from 'pdfjs-dist/webpack.mjs'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import { PDF_JS_WORKER_SRC } from '../config/config'
import { connect } from 'react-redux'
import { addNotification } from 'store/actions/notifications'

pdfjsLib.GlobalWorkerOptions.workerSrc = PDF_JS_WORKER_SRC

const PdfLoader = ({ children, onLoad, file_url: url, addNotification }) => {
  const [isError, setIsError] = useState(false)
  const [pdfDocument, setPdfDocument] = useState(null)
  const docRef = useRef(null)
  const { t } = useTranslation()

  const load = async () => {
    setPdfDocument(null)
    getDocument({
      url,
    })
      .promise.then((document) => {
        onLoad(document.numPages)
        setPdfDocument(document)
        docRef.current = document
      })
      .catch(() => {
        setIsError(true)

        const id = uuidv4()
        const notify = {
          id,
          name: 'notifications.loading_error',
          notification_type: 'error',
          text: 'pdf2smiles.viewer.failed_to_open_document',
        }
        addNotification(notify)
      })
  }

  useEffect(() => {
    url && load()
    return () => {
      docRef.current?.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isError || !pdfDocument || !children || typeof children !== 'function'
        ? null
        : children(pdfDocument)}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    file_url: state.pdf2Smiles.file_url,
  }
}

const mapDispatchToProps = { addNotification }

export default connect(mapStateToProps, mapDispatchToProps)(PdfLoader)
