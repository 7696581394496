import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'

import TitleWithDescription from '../TitleWithDescription'

const SuccessBlock = ({ onGoToLogin, title, description, buttonText }) => {
  return (
    <>
      <Icon iconType="success2" size="3.75rem" />
      <TitleWithDescription {...{ title, description }} />
      <CustomButton
        type="accent"
        width="100%"
        onClick={onGoToLogin}
        data-test="success-recovery-login-btn"
      >
        {buttonText}
      </CustomButton>
    </>
  )
}
export default SuccessBlock
