import { memo } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { addMolecule } from 'store/actions/crud'
import { selectBasket } from 'store/actions/basket'
import { openFeedbackForm } from 'store/actions/feedback'
import { removeNotification } from 'store/actions/notifications'
import {
  GoToBasketBtn,
  NotificationBtns,
} from 'components/Notifications/index.style'

import {
  NotificationHeader,
  NotificationWarningBody,
  NotificationWarningContainer,
} from './index.style'
import NotificationText from '../NotificationText'
import CustomButton from '../../../common/customButton'

const NotificationWarning = memo(
  ({
    push,
    item,
    addMolecule,
    selectBasket,
    openFeedbackForm,
    removeNotification,
  }) => {
    const { text, notification_type, withActions, translationNameParams } = item
    const { t } = useTranslation()

    const handleGoToBasket = () => {
      selectBasket(null)
      push(`/molecules/${item.params.target}`)
      removeNotification(item.id)
    }

    const firstButton = {
      text: t('notifications.error.write_about_an_error'),
      type: 'secondary',
      onClick: openFeedbackForm,
    }

    const handleRepeatAddMolecules = () => {
      removeNotification(item.id)
      const {
        basket,
        smiles,
        shouldGoToBasket,
        label,
        t,
        isTargetEqualsSource,
        isSelected,
      } = item.params
      addMolecule(
        basket,
        smiles,
        shouldGoToBasket,
        label,
        t,
        isTargetEqualsSource,
        isSelected
      )
    }

    const secondButton =
      item?.params?.type === 'createMolecule'
        ? {
            text: t('notifications.error.add_again'),
            type: 'secondary',
            onClick: handleRepeatAddMolecules,
          }
        : {
            text: t('notifications.error.repeat'),
            type: 'secondary',
            onClick: () => console.log('repeat'),
          }

    const buttonsConfig = [firstButton, secondButton]

    return (
      <NotificationWarningContainer type={notification_type}>
        <NotificationWarningBody>
          <NotificationHeader>
            <Icon iconType={notification_type} />
            <span>{t(item.name, translationNameParams)}</span>
          </NotificationHeader>
          {!!text && <NotificationText {...{ item, t }} />}
        </NotificationWarningBody>
        {notification_type === 'error' && withActions && (
          <NotificationBtns>
            {buttonsConfig.map(({ text, type, onClick }, i) => (
              <CustomButton type={type} onClick={onClick} width={'50%'} key={i}>
                {text}
              </CustomButton>
            ))}
          </NotificationBtns>
        )}
        {(notification_type === 'success' || notification_type === 'warning') &&
          withActions &&
          item?.params?.target && (
            <NotificationBtns>
              <GoToBasketBtn
                type="secondary"
                width="100%"
                onClick={handleGoToBasket}
              >
                {t('notifications.go_to_set')}
              </GoToBasketBtn>
            </NotificationBtns>
          )}
      </NotificationWarningContainer>
    )
  }
)

NotificationWarning.displayName = 'NotificationWarning'

const mapDispatchToProps = {
  push,
  addMolecule,
  selectBasket,
  openFeedbackForm,
  removeNotification,
}

export default connect(null, mapDispatchToProps)(NotificationWarning)
