import { put, takeEvery } from 'redux-saga/effects'

import {
  GET_EN_IUPAC_INIT,
  GET_RU_IUPAC_INIT,
  GET_EN_IUPAC_ERROR,
  GET_RU_IUPAC_ERROR,
  GET_RU_IUPAC_SUCCESS,
  GET_EN_IUPAC_SUCCESS,
} from 'store/constants/smilesIupac'
import { fetch } from 'services/rest'

function* converSmilesToRuIupac({ smiles, lang }) {
  try {
    const { data } = yield fetch(
      `/smiles2iupac_article?smiles=${smiles}&lang=${lang}`
    )
    if (data.status === 'error') {
      yield put({
        type: GET_RU_IUPAC_ERROR,
        data: data.error,
      })
    }
    if (data.status === 'ok') {
      yield put({
        type: GET_RU_IUPAC_SUCCESS,
        data: data.data,
      })
    }
  } catch (error) {
    yield put({
      type: GET_RU_IUPAC_ERROR,
      data: error?.message,
    })
  }
}

function* converSmilesToEnIupac({ smiles }) {
  try {
    const { data } = yield fetch(`/smiles2iupac_article?smiles=${smiles}`)
    if (data.status === 'error') {
      yield put({
        type: GET_EN_IUPAC_ERROR,
        data: data.error,
      })
    }
    if (data.status === 'ok') {
      yield put({
        type: GET_EN_IUPAC_SUCCESS,
        data: data.data,
      })
    }
  } catch (error) {
    yield put({
      type: GET_EN_IUPAC_ERROR,
      data: error?.message,
    })
  }
}

export function* converSmilesToRuIupacWatcher() {
  yield takeEvery(GET_RU_IUPAC_INIT, converSmilesToRuIupac)
}

export function* converSmilesToEnIupacWatcher() {
  yield takeEvery(GET_EN_IUPAC_INIT, converSmilesToEnIupac)
}
