import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSpring, animated } from 'react-spring'
import { useEffect, useMemo, useState } from 'react'
import Icon from 'components/Icon'
import { INITIAL_OUTPUT } from 'pages/SynthCost'
import CustomInput from 'components/common/customInput'
import { Label } from 'components/common/text/index.style'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import {
  Row,
  Close,
  TdOutput,
  TdNumbers,
  TableFooter,
  StyledTable,
  TableWrapper,
  ActionButtons,
  IconContainer,
  StyledButton,
  TotalRow,
  TotalRowWrapper,
} from './index.style'
import { isLink } from '../../utils'

import {
  getOutputValue,
  getUpdatedTable,
  calculateDefaultValues,
} from './utils'
import { useTheme } from 'styled-components'

const Numbers = ({
  n,
  initial,
  allowAnimate,
  setAllowAnimate,
  downloadAllInPdfClicked,
}) => {
  const { number } = useSpring({
    from: { number: initial },
    to: { number: n?.toString() },
    number: n?.toString(),
    delay: 200,
    config: { mass: 1, tension: 180, friction: 26 },
  })

  useEffect(() => {
    return () => {
      if (allowAnimate === false) {
        setAllowAnimate && setAllowAnimate(true)
      }
    }
  }, [setAllowAnimate, allowAnimate])

  return downloadAllInPdfClicked || !allowAnimate ? (
    <div>{Number(n).toFixed(2)}</div>
  ) : (
    <animated.div>
      {number?.to((n) => {
        return Number(n).toFixed(2)
      })}
    </animated.div>
  )
}

const SynthCostTable = ({
  onDownload,
  savedSchemes,
  selectedScheme,
  setSavedSchemes,
  isSidebarCollapsed,
  downloadAllInPdfClicked,
  allowAnimate,
  setAllowAnimate,
  defaultValues,
  setDefault,
}) => {
  const defaultObj = {
    output: INITIAL_OUTPUT,
    stages: [
      {
        name: '',
        price: '',
        weight: '',
        vendor: '',
      },
    ],
  }

  const dispatch = useDispatch()
  const theme = useTheme()
  const [lastTableSchemes, setLastTableSchemes] = useState(savedSchemes)
  const [currentTableSchemes, setCurrentTableSchemes] = useState(savedSchemes)

  useEffect(() => {
    setLastTableSchemes(savedSchemes)
    setCurrentTableSchemes(savedSchemes)
  }, [savedSchemes])

  const [isTableEdit, setIsTableEdit] = useState(false)

  const [changes, setChanges] = useState(new Set())

  const undo = () => {
    const sortedArr = Array.from(changes).sort(
      (a, b) => a.timeStamp - b.timeStamp
    )

    const filteredArr = sortedArr.filter((change) => !change.isCanceled)
    const lastChange = filteredArr[filteredArr.length - 1]
    const newArr = sortedArr.map((change) =>
      change.timeStamp === lastChange.timeStamp
        ? { ...change, isCanceled: true }
        : change
    )
    setChanges(new Set(newArr))
    setCurrentTableSchemes(
      filteredArr.length > 1
        ? filteredArr[filteredArr.length - 2].schemes
        : lastTableSchemes
    )
  }

  const redo = () => {
    const sortedArr = Array.from(changes).sort(
      (a, b) => a.timeStamp - b.timeStamp
    )
    const filteredArr = sortedArr.filter((change) => change.isCanceled)
    const firstChange = filteredArr[0]

    const newArr = sortedArr.map((change) =>
      change.timeStamp === firstChange.timeStamp
        ? { ...change, isCanceled: false }
        : change
    )

    setCurrentTableSchemes(firstChange.schemes)
    setChanges(new Set(newArr))
  }

  const handleChangeResults = (stageInd, reagentInd, type, value) => {
    let newSchemes = currentTableSchemes
    if (type === 'output') {
      newSchemes = currentTableSchemes.map((s, ind) =>
        selectedScheme === ind
          ? [
              ...s.map((stage, i) =>
                i === stageInd
                  ? {
                      ...stage,
                      output: value,
                    }
                  : stage
              ),
            ]
          : s
      )
    } else {
      newSchemes = currentTableSchemes.map((s, ind) =>
        selectedScheme === ind
          ? [
              ...s.map((stage, i) =>
                i === stageInd
                  ? {
                      ...stage,
                      stages: stage.stages.map((reagent, i) =>
                        i === reagentInd
                          ? { ...reagent, [type]: value }
                          : reagent
                      ),
                    }
                  : stage
              ),
            ]
          : s
      )
    }
    setCurrentTableSchemes(newSchemes)
    setChanges(updateChanges(newSchemes))
  }

  const isUndoDisabled = !Array.from(changes).filter(
    (change) => !change.isCanceled
  ).length

  const isRedoDisabled = !Array.from(changes).some(
    (change) => change.isCanceled
  )

  const handleAddStage = () => {
    const newSchemes = currentTableSchemes.map((s, ind) => {
      return selectedScheme === ind ? [...s, { ...defaultObj }] : s
    })
    setCurrentTableSchemes(newSchemes)
    setChanges(updateChanges(newSchemes))
  }

  const handleAddReagent = (stageInd) => {
    const { output, ...rest } = defaultObj
    const newSchemes = currentTableSchemes.map((s, ind) =>
      selectedScheme === ind
        ? s.map((stage, i) =>
            i === stageInd
              ? { ...stage, stages: [...stage.stages, { ...rest.stages[0] }] }
              : stage
          )
        : s
    )
    setCurrentTableSchemes(newSchemes)
    setChanges(updateChanges(newSchemes))
  }

  const handleDeleteStage = (stageInd) => {
    const newSchemes = currentTableSchemes.map((s, ind) =>
      selectedScheme === ind ? s.filter((_, i) => i !== stageInd) : s
    )
    setCurrentTableSchemes(newSchemes)
    setChanges(updateChanges(newSchemes))
  }

  const handleDeleteReagent = (stageInd, reagentInd) => {
    const newSchemes = currentTableSchemes.map((s, ind) =>
      selectedScheme === ind
        ? [
            ...s.map((stage, i) =>
              i === stageInd
                ? {
                    ...stage,
                    stages: stage.stages.filter((_, i) => i !== reagentInd),
                  }
                : stage
            ),
          ]
        : s
    )

    setCurrentTableSchemes(newSchemes)
    setChanges(updateChanges(newSchemes))
  }

  const updateChanges = (newSchemes) => {
    const changesArr = Array.from(changes)
      .sort((a, b) => a.timeStamp - b.timeStamp)
      .filter((change) => !change.isCanceled)
    const newChanges = new Set(changesArr)
    if (changesArr.length === 200) {
      newChanges.delete(changesArr[0])
    }
    newChanges.add({
      timeStamp: new Date().getTime(),
      schemes: newSchemes,
      isCanceled: false,
    })
    return newChanges
  }

  const handleSaveChanges = () => {
    const updated = currentTableSchemes.map((scheme) =>
      scheme.map((stage) => {
        return {
          output: stage.output,
          stages: stage.stages.map((reagent) => ({
            ...reagent,
            price: Number(reagent.price),
            weight: Number(reagent.weight),
          })),
        }
      })
    )
    setChanges(new Set())
    setSavedSchemes(updated)
    const updatedDefault = calculateDefaultValues(updated, selectedScheme)
    setDefault(updatedDefault)
    setIsTableEdit(false)
  }

  const handleResetChanges = () => {
    setChanges(new Set())
    setCurrentTableSchemes(lastTableSchemes)
  }

  let reagentNumber = 0

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') handleSaveChanges()
  }

  const handleOutputKeyUp = (e, stageInd) => {
    if (e.key === 'Enter') handleBlur(true, stageInd)
  }

  const { t } = useTranslation()

  const isSomeOutputEmpty = currentTableSchemes?.[selectedScheme]?.some(
    (el) => !el?.output
  )

  const handleBlur = (needCalc, stageInd) => {
    const currentOutput = currentTableSchemes[selectedScheme][stageInd]?.output
    if (!currentOutput || isSomeOutputEmpty) return
    if (needCalc) {
      const updated = getUpdatedTable(
        currentTableSchemes,
        selectedScheme,
        defaultValues,
        currentTableSchemes[selectedScheme][stageInd].output,
        stageInd
      )
      setSavedSchemes(updated)
      setChanges(new Set())
    } else setSavedSchemes(currentTableSchemes)
  }

  const handleContextMenu = (e) => {
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'synthCostMenu',
        item: { handler: onDownload, selectedScheme },
      })
    )
  }

  const getTotalPrice = useMemo(() => {
    return currentTableSchemes[selectedScheme]
      ?.reduce((a, b) => a?.concat(b?.stages), [])
      ?.reduce((acc, curr) => Number(curr.price) + acc, 0)
      ?.toFixed(2)
  }, [currentTableSchemes, selectedScheme])

  return (
    <>
      <TableWrapper>
        <StyledTable
          isTableEdit={isTableEdit}
          isSidebarCollapsed={isSidebarCollapsed}
        >
          <thead width="100%">
            <tr width="100%">
              <td width="5%">№</td>
              <td width="25%">{t('synthCost.compound_name')}</td>
              <td width="15%">
                {t('synthCost.price')}

                <div data-html2canvas-ignore="true" data-tooltip-id="price">
                  <Icon iconType="description" size="1rem" />
                </div>
                <ReactTooltip
                  id="price"
                  place="top-start"
                  className="c-tooltip c-tooltip-base c-tooltip-synthcost"
                  classNameArrow="c-tooltip-arrow"
                  positionStrategy={'fixed'}
                  offset={15}
                >
                  {t('synthCost.estimated_substance_price')}
                </ReactTooltip>
              </td>
              <td width="15%">
                {t('synthCost.amount')}
                <div data-html2canvas-ignore="true" data-tooltip-id="amount">
                  <Icon iconType="description" size="1rem" />
                </div>
                <ReactTooltip
                  id="amount"
                  place="top-start"
                  className="c-tooltip c-tooltip-base c-tooltip-synthcost"
                  classNameArrow="c-tooltip-arrow"
                  positionStrategy={'fixed'}
                  offset={15}
                >
                  {t('synthCost.required_amount')}
                </ReactTooltip>
              </td>
              <td width="25%">{t('synthCost.source')}</td>
              <td width="15%">ID</td>
            </tr>
          </thead>

          {currentTableSchemes[selectedScheme]?.map((reagents, stageInd) => {
            return (
              <tbody key={stageInd}>
                <tr>
                  <td
                    width="8%"
                    style={{
                      textAlign: 'start',
                    }}
                  >
                    <Label color={theme.colors.text.primary}>
                      {t('synthCost.stage')} {stageInd + 1}
                    </Label>
                  </td>
                  <td colSpan="1">
                    {isTableEdit ? null : (
                      <TdOutput>
                        <Label color={theme.colors.text.secondary}>
                          {t('synthCost.output')}
                        </Label>
                        <CustomInput
                          value={reagents.output}
                          onChange={(value) =>
                            handleChangeResults(
                              stageInd,
                              null,
                              'output',
                              getOutputValue(value)
                            )
                          }
                          onBlur={() => handleBlur(true, stageInd)}
                          withClearButton
                          onKeyUp={(e) => handleOutputKeyUp(e, stageInd)}
                          withAfterLabel={true}
                          pseudoAfterText="%"
                          placeholder="1 - 100"
                          afterLabelPadding="0.5rem"
                          size="small"
                          withWhiteBackground={false}
                          width="5rem"
                        />

                        {!Number(reagents.output) && (
                          <IconContainer data-tooltip-id="empty-output-tooltip">
                            <Icon iconType="description" size="1rem" />
                            <ReactTooltip
                              id="empty-output-tooltip"
                              className="c-tooltip c-tooltip-base c-tooltip-synthcost"
                              classNameArrow="c-tooltip-arrow"
                              place="top-start"
                              positionStrategy={'fixed'}
                              offset={15}
                            >
                              {t('synthCost.add_value')}
                            </ReactTooltip>
                          </IconContainer>
                        )}
                      </TdOutput>
                    )}
                  </td>
                  {isTableEdit ? (
                    <>
                      <td />
                      <td colSpan="2">
                        <StyledButton
                          isRightPosition
                          onClick={() => handleAddReagent(stageInd)}
                          type="text"
                        >
                          <Icon iconType="add" size="1rem" />
                          {t('synthCost.add_row')}
                        </StyledButton>
                      </td>
                      <td colSpan="4">
                        <StyledButton
                          onClick={() => handleDeleteStage(stageInd)}
                          type="text"
                          isRightPosition
                          marginRight="0.75rem"
                        >
                          <Icon iconType="deleted" size="1rem" />
                          {t('synthCost.delete_stage')}
                        </StyledButton>
                      </td>
                    </>
                  ) : (
                    <td colSpan="4" />
                  )}
                </tr>

                {reagents.stages.map((reagent, reagentInd) => {
                  reagentNumber++
                  return isTableEdit ? (
                    <tr key={`${stageInd}-${reagentInd}`}>
                      <td width="5%">
                        <TdOutput>
                          <Icon iconType="drag" size="1rem" />
                          {reagentNumber}
                        </TdOutput>
                      </td>
                      <td width="25%">
                        <CustomInput
                          value={reagent?.smiles || ''}
                          withClearButton={true}
                          placeholder={t('synthCost.enter_inchi_or_smiles')}
                          onChange={(value) =>
                            handleChangeResults(
                              stageInd,
                              reagentInd,
                              'smiles',
                              value
                            )
                          }
                          onKeyUp={handleKeyUp}
                          withWhiteBackground
                        />
                      </td>

                      <td width="15%">
                        <CustomInput
                          value={reagent.price}
                          withClearButton={true}
                          placeholder={t('synthCost.price')}
                          onChange={(value) =>
                            handleChangeResults(
                              stageInd,
                              reagentInd,
                              'price',
                              value.replace(/[^\d.]|(\.(?=.*\.))/g, '')
                            )
                          }
                          onKeyUp={handleKeyUp}
                          pseudoAfterText="$"
                          withWhiteBackground
                        />
                      </td>

                      <td width="15%">
                        <CustomInput
                          value={reagent.weight}
                          withClearButton={true}
                          placeholder={t('synthCost.amount')}
                          onChange={(value) => {
                            handleChangeResults(
                              stageInd,
                              reagentInd,
                              'weight',
                              value.replace(/[^\d.]|(\.(?=.*\.))/g, '')
                            )
                          }}
                          onKeyUp={handleKeyUp}
                          pseudoAfterText={` ${t('synthCost.g')}`}
                          withWhiteBackground
                        />
                      </td>
                      <td width="25%">
                        <CustomInput
                          value={reagent?.vendor || ''}
                          withClearButton={true}
                          placeholder={t('synthCost.source')}
                          onChange={(value) =>
                            handleChangeResults(
                              stageInd,
                              reagentInd,
                              'vendor',
                              value
                            )
                          }
                          onKeyUp={handleKeyUp}
                          withWhiteBackground
                        />
                      </td>
                      <td width="15%">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <CustomInput
                            value={reagent.id}
                            withClearButton={true}
                            placeholder="ID"
                            onChange={(value) =>
                              handleChangeResults(
                                stageInd,
                                reagentInd,
                                'id',
                                value
                              )
                            }
                            onKeyUp={handleKeyUp}
                            withWhiteBackground
                          />
                          <Close
                            id="close"
                            onClick={() =>
                              handleDeleteReagent(stageInd, reagentInd)
                            }
                          >
                            <Icon iconType="close" size="1rem" />
                          </Close>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr key={`${stageInd}-${reagentInd}`}>
                      <td>{reagentNumber}</td>
                      <td>{reagent?.smiles || ''}</td>
                      <td>
                        <TdNumbers>
                          <Numbers
                            initial={'0.00'}
                            n={reagent?.price ? reagent.price : '0.00'}
                            downloadAllInPdfClicked={downloadAllInPdfClicked}
                            allowAnimate={allowAnimate}
                            setAllowAnimate={setAllowAnimate}
                          />
                          {'$'}
                        </TdNumbers>
                      </td>
                      <td>
                        <TdNumbers>
                          <Numbers
                            initial={'0.00'}
                            n={reagent?.weight ? reagent.weight : '0.00'}
                            downloadAllInPdfClicked={downloadAllInPdfClicked}
                            allowAnimate={allowAnimate}
                            setAllowAnimate={setAllowAnimate}
                          />
                          {t('synthCost.g')}
                        </TdNumbers>
                      </td>
                      <td>
                        {isLink(reagent?.vendor) ? (
                          <a
                            href={reagent.vendor}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {reagent.vendor}
                          </a>
                        ) : (
                          <Label
                            color={
                              reagent?.vendor
                                ? theme.colors.text.primary
                                : theme.colors.text.tertiary
                            }
                          >
                            {reagent?.vendor || t('synthCost.no_info')}
                          </Label>
                        )}
                      </td>
                      <td>
                        <Label
                          color={
                            reagent?.id
                              ? theme.colors.text.primary
                              : theme.colors.text.tertiary
                          }
                        >
                          {reagent?.id || t('synthCost.no_info')}
                        </Label>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            )
          })}
        </StyledTable>
        <TotalRow gap="0.75rem">
          <Label fontWeight={400}>
            {t('synthCost.total_cost')}
            {':'}
          </Label>
          <Label fontWeight={500} color="#42C1BF">
            {getTotalPrice}
            {'$'}
          </Label>
        </TotalRow>
      </TableWrapper>

      <TableFooter
        className={downloadAllInPdfClicked ? 'hidden-for-print' : ''}
      >
        {isTableEdit ? (
          <ActionButtons className="hidden-for-print">
            <StyledButton type="text" onClick={handleAddStage}>
              <Icon iconType="add" size="1rem" />
              {t('synthCost.add_stage')}
            </StyledButton>
            <StyledButton type="text" disabled={isUndoDisabled} onClick={undo}>
              <Icon iconType="back" size="1rem" />
              {t('synthCost.cancel')}
            </StyledButton>
            <StyledButton type="text" disabled={isRedoDisabled} onClick={redo}>
              <Icon iconType="forward" size="1rem" />
              {t('synthCost.return')}
            </StyledButton>
            <StyledButton type="text" onClick={handleSaveChanges}>
              <Icon iconType="save" size="1rem" />
              {t('synthCost.save_and_close')}
            </StyledButton>
            <StyledButton
              type="text"
              onClick={handleResetChanges}
              disabled={!Array.from(changes).length}
            >
              <Icon iconType="refresh" size="1rem" />
              {t('synthCost.reset')}
              {''} {t('synthCost.changes')}
            </StyledButton>
          </ActionButtons>
        ) : (
          <Row
            gap="20px"
            className="hidden-for-print"
            data-html2canvas-ignore="true"
          >
            <StyledButton
              type="text"
              disabled={isSomeOutputEmpty}
              onClick={() => setIsTableEdit(true)}
            >
              <Icon iconType="edit" size="1rem" />
              {t('synthCost.edit_table')}
            </StyledButton>
            <StyledButton type="text" onClick={handleContextMenu}>
              <Icon iconType="upload" size="1rem" />
              {`${t('synthCost.download_scheme')} ${selectedScheme + 1}`}
            </StyledButton>
          </Row>
        )}
      </TableFooter>
    </>
  )
}
export default SynthCostTable
