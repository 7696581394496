import styled from 'styled-components'

export const IndicatorContainer = styled.div`
  gap: 0.25rem;
  display: flex;
`
export const IndicatorItem = styled.div`
  height: 0.25rem;
  overflow: hidden;
  border-radius: 0.75rem;
  width: ${({ ind, active }) => (ind === active ? '2.75rem' : '0.75rem')};
  background: ${({ theme, ind, active }) =>
    ind < active
      ? theme.colors.backgrounds.accent
      : theme.colors.backgrounds.quaternary};
  transition: all 0.1s ease;
  & > span {
    display: block;
    height: 100%;
    background: ${({ theme }) => theme.colors.backgrounds.accent};
    position: relative;
    border-radius: 0.75rem;
  }
`
