import PhoneInput from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'

import 'react-phone-input-2/lib/style.css'

import Icon from 'components/Icon'
import CustomInput from 'components/common/customInput'
import { CustomInputClearButton } from 'components/common/customInput/RightButtons/index.style'

import { InputWithTitle } from './index.style'

const PhoneNumber = ({ phone, onChange, phoneError, isInvalidPhone }) => {
  const { t } = useTranslation()

  return (
    <InputWithTitle>
      <CustomInput
        error={phoneError}
        id="phone"
        label={`${t('registration.phone')} *`}
        warning={isInvalidPhone ? 'registration.invalid_phone' : ''}
        isValidated={!!phone && !isInvalidPhone && !phoneError}
        errorTranslationParams={{ phone }}
      />
      <PhoneInput
        international
        country="ru"
        phone={phone}
        value={phone}
        onChange={(value) => onChange(value, 'phone')}
        disableDropdown={true}
        placeholder="+7 (___) ___-__-__"
      />
      <CustomInputClearButton
        visible={phone}
        btnSize={1.75}
        btnBorderRadius="0.5rem"
        onClick={() => onChange('', 'phone')}
      >
        <Icon iconType="close" size="1rem" />
      </CustomInputClearButton>
    </InputWithTitle>
  )
}

export default PhoneNumber
