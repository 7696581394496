import { useTranslation } from 'react-i18next'
import { validate as isValidUUID } from 'uuid'
import { useLocation } from 'react-router-dom'

import CustomInput from 'components/common/customInput'
import CustomToggle from 'components/common/customToggle'
import { TEST_PERIOD } from 'components/RegistrationPageNew/config'

import { ToggleContainer } from './index.style'

const LicenseKeyBlock = ({
  isLicense,
  licenseKey,
  onChange,
  licenseError,
  isInvalidLicenseKey,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const inviteToken = new URLSearchParams(search)?.get('customer_invite_token')
  const disabledEdit = !!inviteToken && isValidUUID(inviteToken)

  return (
    <>
      <ToggleContainer>
        <CustomToggle
          label={t('registration.product_key')}
          withTooltip
          type="grey"
          tooltipText={t('registration.if_no_key', {
            count: TEST_PERIOD,
          })}
          value={isLicense}
          onClick={(e) => {
            e.preventDefault()
            onChange(!isLicense, 'is_license')
          }}
        />
      </ToggleContainer>
      {isLicense && (
        <CustomInput
          error={licenseError}
          label={`${t('registration.license_key')} *`}
          placeholder={t('registration.license_key_placeholder')}
          value={licenseKey}
          withClearButton={!disabledEdit}
          onChange={(value) => onChange(value, 'license_key')}
          disabled={disabledEdit}
          warning={
            isInvalidLicenseKey ? 'registration.invalid_license_key' : ''
          }
        />
      )}
    </>
  )
}
export default LicenseKeyBlock
