export const OPEN_FEEDBACK_FORM = 'OPEN_FEEDBACK_FORM'
export const CLOSE_FEEDBACK_FORM = 'CLOSE_FEEDBACK_FORM'

export const OPEN_FEEDBACK_CARD = 'OPEN_FEEDBACK_CARD'
export const CLOSE_FEEDBACK_CARD = 'CLOSE_FEEDBACK_CARD'

export const SET_FEEDBACK_PAGINATION_CONFIG = 'SET_FEEDBACK_PAGINATION_CONFIG'

export const GET_FEEDBACK_INIT = 'GET_FEEDBACK_INIT'
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS'
export const GET_FEEDBACK_ERROR = 'GET_FEEDBACK_ERROR'

export const SET_FEEDBACK_FILTER = 'SET_FEEDBACK_FILTER'
export const CLEAR_FEEDBACK_FILTER = 'CLEAR_FEEDBACK_FILTER'
export const APPLY_FEEDBACK_FILTER = 'APPLY_FEEDBACK_FILTER'

export const SHOW_FEEDBACK_BTN = 'SHOW_FEEDBACK_BTN'
export const HIDE_FEEDBACK_BTN = 'HIDE_FEEDBACK_BTN'
