import {
  SYNTHCOST_RESET_TASK,
  SYNTHCOST_SAVE_SETTINGS,
  SYNTHCOST_GET_TASK_INIT,
  SYNTHCOST_RESET_SETTINGS,
  SYNTHCOST_RESET_PREDICTION,
  SYNTHCOST_RESET_ERROR,
} from 'store/constants/synthcost'

export const predict = () => {
  return {
    type: SYNTHCOST_GET_TASK_INIT,
  }
}

export const resetPrediction = () => {
  return {
    type: SYNTHCOST_RESET_PREDICTION,
  }
}

export const saveSettings = (data) => {
  return {
    type: SYNTHCOST_SAVE_SETTINGS,
    data,
  }
}

export const resetSettings = () => {
  return {
    type: SYNTHCOST_RESET_SETTINGS,
  }
}

export const resetSynthCostTask = () => {
  return {
    type: SYNTHCOST_RESET_TASK,
  }
}

export const resetSynthCostError = () => {
  return {
    type: SYNTHCOST_RESET_ERROR,
  }
}
