import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  gap: 1rem;
  padding-right: 1.25rem;
  overflow: hidden;
`

export const Selectors = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  max-height: 3.5rem;
`
export const ChartWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify && justify};
  position: relative;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  min-height: 20rem;
  height: 100%;
  padding: 1rem;
  flex-grow: 1;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
`
export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`
export const ChartLegend = styled.div`
  display: flex;
  width: 100%;
  gap: 0.75rem;
  flex-wrap: wrap;
`

export const ChartHeader = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  height: 1.5rem;
`

export const PeriodComparison = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease-in-out;
`

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.contextMenu};
  box-shadow: ${({ theme }) => theme.shadow.default};
`

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
`
export const ColorIndicator = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ color }) => (color ? color : '#3495FF')};
  border-radius: 50%;
`
export const TooltipKey = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
