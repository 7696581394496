import React, { memo } from 'react'

const LoaderRestyling = memo(() => {
  return (
    <div className={`loader`}>
      <div className="logo-left" />
      <div className="logo-right" />
      <div className="logo-bottom" />
    </div>
  )
})

LoaderRestyling.displayName = 'LoaderRestyling'
export default LoaderRestyling
