import styled, { css } from 'styled-components'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '8px')};
`

export const Form = styled(Column)`
  flex: 1;
  width: 100%;
`

export const InputContainer = styled.div`
  flex: 1;
  gap: ${({ gap }) => (gap ? gap : '0.5rem')};
  display: flex;
  position: relative;
  flex-direction: column;
`
export const InputLabel = styled.div`
  gap: 4px;
  display: flex;
  color: #616e7c;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  align-items: center;
  &:hover {
    & > svg {
      color: #42c1bf !important;
    }
  }

  svg {
    color: #cbd2d9 !important;
  }
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : '8px')};
`

export const Buttons = styled(Row)`
  svg {
    width: 16px;
    height: 16px;
  }
`
export const Inputs = styled(Row)`
  width: 70%;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  ${({ isSidebarCollapsed }) =>
    isSidebarCollapsed &&
    css`
      @media (max-width: 964px) {
        width: 100%;
        grid-template-columns: 1fr;
      }
    `}
`

export const Error = styled.div`
  top: 60px;
  position: absolute;
`

export const ProgressBar = styled.div`
  place-items: center;
  height: 12px;
  background-color: #f5f7f9;
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  align-items: flex-start;

  & > span {
    display: block;
    height: 100%;
    background-image: linear-gradient(
      270deg,
      #42c1bf 0%,
      rgba(66, 193, 191, 0) 95%
    );
    position: relative;
    border-radius: 12px;
    transition: all 0.5s ease;
    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
  }
`
