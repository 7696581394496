import styled from 'styled-components'

export const SourceList = styled.div`
  gap: 8px;
  column-gap: 8px;
  width: 624px;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Source = styled.div`
  gap: 4px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  padding: 10px 12px;
  background-color: #f3f5f8;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.statuses.link};
  }
  svg {
    color: inherit;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  div {
    max-width: 568px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
