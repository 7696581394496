export const applicabilityTypes = [
  {
    number: null,
    description: 'moleculeslist.not_applicable',
  },
  {
    number: 0.2,
    description: 'moleculeslist.low_reliability',
  },
  {
    number: 0.5,
    description: 'moleculeslist.middle_reliability',
  },
  {
    number: 1,
    description: 'moleculeslist.high_reliability',
  },
]
