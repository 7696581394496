import styled from 'styled-components'

import Icon from 'components/Icon'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
`

export const ReactBlock = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 1rem;
  column-gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`

export const Label = styled.div`
  font-size: ${({ theme }) => theme.text[12]};
  line-height: ${({ theme }) => theme.text[16]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const ReactionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
export const ReactionOperatorIcon = styled(Icon)`
  align-self: center;
  color: ${({ theme, color }) => (color ? color : theme.colors.icons.tertiary)};
`

export const Score = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
`
export const ScoreTag = styled.div`
  display: flex;
  gap: 0.25rem;
  cursor: pointer;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  & > svg {
    color: ${({ theme }) => theme.colors.icons.whiteSecondary};
  }
  background: ${({ background }) => background};
`

export const ArrowBlock = styled.div`
  display: grid;
  grid-template-rows: 1fr 30px 1fr;

  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 1rem;
  display: flex;
  flex-wrap: wrap;

  column-gap: 0.5rem;
`

export const AgentsBlock = styled.div`
  display: grid;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
`

export const AgentElement = styled.div`
  position: relative;
`

export const AgentText = styled.div`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    & ~ * {
      opacity: 1;
    }
  }
`

export const AgentHiddenBlock = styled.div`
  opacity: 0;
  position: absolute;
  width: 150px;
  height: 100px;
  padding: 12px;
  border-radius: 5px;
  z-index: 10;
  right: -150px;
  top: -50px;
`

export const YieldBlock = styled.div`
  position: absolute;
  font-size: 12px;
  text-transform: uppercase;
  top: 85%;
  left: 0;
  width: 80px;
`
