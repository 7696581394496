import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import {
  setStorage,
  readStorage,
  removeFromStorage,
} from 'utils/storage/storage'
import Icon from 'components/Icon'
import Promo from 'components/Promo'
import { fetch, store } from 'services/rest'
import SidebarButton from 'components/SidebarButton'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import { addNotification } from 'store/actions/notifications'
import { Caption, Label } from 'components/common/text/index.style'

import {
  Wrapper,
  LogInText,
  LoginBlock,
  LogInButton,
  LoginWithPromo,
  LogInWithCopyRight,
} from './index.style'
import EmailBlock from './components/EmailBlock'
import SuccessBlock from './components/SuccessBlock'
import PasswordsBlock from './components/PasswordsBlock'
import TitleWithDescription from './components/TitleWithDescription'

const RecoveryPasswordPage = ({ path, push, match, addNotification }) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [status, setStatus] = useState('email')
  const [loading, setLoading] = useState(false)

  const { t, i18n } = useTranslation()

  const theme = useTheme()

  const logoImage = useGetLogoImage()

  const recoveryEmail = readStorage('email')

  const isResetPasswordDisabled = !email || emailError || loading

  useEffect(() => {
    if (match?.params?.id) {
      setStatus('password')
    }
  }, [match])

  const handleSendMail = async () => {
    if (isResetPasswordDisabled) return

    try {
      setLoading(true)
      await fetch(`/profile/recovery?mail=${encodeURIComponent(email)}`)
      setStatus('mailok')
      setLoading(false)
      setStorage('email', email)
    } catch (error) {
      setLoading(false)
      const err =
        error?.response?.data !== null &&
        typeof error?.response?.data === 'object'
          ? error?.response?.data.detail
          : error?.response?.data
      const isUserNotFound =
        err === 'User not found' || error?.response?.status === 400
      setLoading(false)
      if (isUserNotFound) setEmailError('recovery.user_not_found')

      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: isUserNotFound ? 'recovery.user_not_found' : err,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: isUserNotFound,
      }
      addNotification(notify)
    }
  }

  const handleChangePassword = async (password) => {
    try {
      setLoading(true)

      const token = match.params.id
      await store('/profile/recovery', { token, password })
      setStatus('changeok')
      removeFromStorage('email')
    } catch (error) {
      setLoading(false)

      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: error?.response?.data || 'recovery.something_went_wrong',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: !error?.response?.data,
      }
      addNotification(notify)
    }
  }

  const handleClickLogin = () => {
    if (path?.includes('recovery')) {
      push('/login')
    }
  }

  return (
    <Wrapper>
      <LoginWithPromo>
        <LoginBlock onClick={(e) => e.stopPropagation()} method="dialog">
          <img src={logoImage} alt="syntelly" />

          {status === 'email' && (
            <EmailBlock
              onSendMail={handleSendMail}
              isResetPasswordDisabled={isResetPasswordDisabled}
              {...{ email, loading, setEmail, emailError, setEmailError }}
            />
          )}
          {status === 'password' && (
            <PasswordsBlock
              loading={loading}
              email={recoveryEmail}
              onChangePassword={handleChangePassword}
            />
          )}
          {status === 'mailok' && (
            <TitleWithDescription
              title={t('recovery.check_your_email')}
              description={t('recovery.link_sended')}
            />
          )}
          {status === 'changeok' && (
            <SuccessBlock
              title={t('recovery.password_changed')}
              description={t('recovery.search_predict_explore')}
              buttonText={t('recovery.login')}
              onGoToLogin={handleClickLogin}
            />
          )}

          <LogInWithCopyRight>
            {status !== 'changeok' && (
              <LogInText>
                <Label
                  style={{ margin: 0 }}
                  color={theme.colors.text.secondary}
                >
                  {' '}
                  {t('registration.alreadyRegistered')}
                </Label>
                <LogInButton
                  type="text"
                  onClick={handleClickLogin}
                  gap="0.25rem"
                  data-test="recovery-login-btn"
                >
                  {t('account.signIn')}
                  <Icon iconType="arrowRight" size="1rem" />
                </LogInButton>
              </LogInText>
            )}
            <SidebarButton />

            <Caption
              fontWeight={theme.fontWeight.medium}
              color={theme.colors.text.tertiary}
            >
              {t('account.copyRight').toUpperCase()}
              {new Date().getFullYear()}
            </Caption>
          </LogInWithCopyRight>
        </LoginBlock>
        <Promo />
      </LoginWithPromo>
    </Wrapper>
  )
}

const mapStateToProps = (state) => ({
  path: state.router.location.pathname,
})

const mapDispatchToProps = {
  push,
  addNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoveryPasswordPage)
