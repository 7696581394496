import {
  REACTION_SET_MODE,
  REACTION_CALC_INIT,
  REACTION_MOLECULE_ADD,
  REACTION_MOLECULE_EDIT,
  REACTION_RETRO_CALC_INIT,
  REACTION_MOLECULE_REMOVE,
  REACTION_SYNTH_CALC_INIT,
  REACTION_CALCULATION_RESET,
  REACTION_MOLECULE_REMOVE_ALL,
} from '../constants/reactions'

export const addMoleculeToReaction = (smiles) => {
  return {
    type: REACTION_MOLECULE_ADD,
    smiles,
  }
}

export const removeMoleculeFromReaction = (id) => {
  return {
    type: REACTION_MOLECULE_REMOVE,
    id,
  }
}

export const removeAllMoleculesFromReaction = () => {
  return {
    type: REACTION_MOLECULE_REMOVE_ALL,
  }
}

export const resetCalculations = () => {
  return {
    type: REACTION_CALCULATION_RESET,
  }
}

export const editMoleculeInReaction = (id, smiles) => {
  return {
    type: REACTION_MOLECULE_EDIT,
    id,
    smiles,
  }
}

export const calcReaction = (mode, data, model, naug, beam) => {
  return {
    type: REACTION_CALC_INIT,
    mode,
    data,
    model,
    naug,
    beam,
  }
}

export const calcSynth = (data, model, naug, beam) => {
  return {
    type: REACTION_SYNTH_CALC_INIT,
    data,
    model,
    naug,
    beam,
  }
}

export const calcRetro = (smiles) => {
  return {
    type: REACTION_RETRO_CALC_INIT,
    smiles,
  }
}

export const setMode = (mode) => {
  return {
    type: REACTION_SET_MODE,
    mode,
  }
}
