import styled from 'styled-components'

import { HeaderItem } from '../customers/index.style'

export const ApplicationsWrapper = styled.div`
  ${HeaderItem} > div {
    white-space: pre-line !important;
  }
`

export const UsersBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin: ${({ marginBottom }) => `0 0.75rem ${marginBottom} 0rem`};
`
