import styled from 'styled-components'

import { Label } from 'components/common/text/index.style.js'

export const ToggleWithLabel = styled.div`
  gap: 0.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-wrap: nowrap;
`
export const Container = styled(ToggleWithLabel)`
  gap: 0.25rem;
  &:hover > svg {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    transition: color 0.3s ease;
  }
`

export const ToggleBtnGroup = styled.div`
  display: flex;
  cursor: pointer;
  width: ${({ size }) => (size === 'small' ? '2.25rem' : '2.75rem')};
  height: ${({ size }) => (size === 'small' ? '1.25rem' : '1.5rem')};
  padding: 0.125rem;
  border-radius: 0.75rem;
  align-items: center;
  transition: all 0.3s;

  background: ${({ theme, type, active }) =>
    active
      ? theme.colors.backgrounds.accent
      : type === 'white'
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.secondary};
`

export const ToggleBtn = styled.button`
  cursor: pointer;
  width: ${({ size }) => (size === 'small' ? '1rem' : '1.25rem')};
  height: ${({ size }) => (size === 'small' ? '1rem' : '1.25rem')};
  border: none;
  outline: none;
  border-radius: 0.625rem;
  box-shadow: ${({ boxShadow }) => boxShadow};
  background: ${({ active, type, theme }) => {
    if (type === 'white')
      return active
        ? theme.colors.backgrounds.primary
        : theme.colors.backgrounds.secondary
    return theme.colors.backgrounds.primary
  }};
  transition: all 0.3s;
  transform: ${({ active }) => (active ? 'translateX(100%)' : 'none')};
`

export const ToggleLabel = styled(Label)`
  white-space: nowrap;
  svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
  }
  color: ${({ theme, active }) =>
    active ? theme.colors.text.primary : theme.colors.text.secondary};
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
    transition: color 0.3s ease;
  }
`
