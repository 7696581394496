export const reactionEncode = (left, right) => {
  const leftSide = left.join('.');
  const rightSide = right.join('.');
  return `${leftSide}>${rightSide}`;
};

export const reactionDecode = reaction => {
  const [leftSide, rightSide] = reaction.split('>');
  return {
    left: leftSide.split('.'),
    right: rightSide.split('.'),
  };
};
