import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import { copyToClipboard } from 'utils/common/common'
import { setBlurNonApplicable } from 'store/actions/molecule'
import { Subheadline } from 'components/common/text/index.style'
import { addSimpleNotification } from 'store/actions/notifications'

import {
  getCategoryKey,
  CATEGORIES_WITH_DESC,
  COPIED_PROPERTY_NAMES,
  getDataByCategoryName,
  getCustomHeadersDescription,
} from 'config/molecule/molecule'
import ApplicabilityIndicator from 'components/applicability-indicator'

import {
  PropertyRow,
  PropertyValue,
  PropertySource,
  KeyValueProperty,
  DescriptedPropertyKey,
} from './index.style'

import BlurableValue from './BlurableValue'
import ValueWithTooltip from './ValueWithTooltip'
import { POSSIBLE_ERR_VALUES_BY_GROUP } from './config/config'
import { getSourceByCategoryName, needHideProperty } from './utils/utils'

const Property = memo(
  ({
    name,
    data,
    sources,
    category_id,
    isGeneratedMol,
    category_title,
    blurNonApplicable,
    applicabilityData,
    isApplicabilityShow,
  }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const value = getDataByCategoryName(name, data, isGeneratedMol)
    const source = getSourceByCategoryName(name, sources)

    if (needHideProperty(name, value)) return null

    const hideCopyIconOnError =
      Object.prototype.hasOwnProperty.call(
        POSSIBLE_ERR_VALUES_BY_GROUP,
        name
      ) && POSSIBLE_ERR_VALUES_BY_GROUP[name]?.some((val) => val === value)

    const isBlurable =
      isApplicabilityShow &&
      !!applicabilityData?.[category_title] &&
      typeof applicabilityData?.[category_title] === 'string' &&
      source?.type !== 'exp'

    const handleCopy = (e, name, value) => {
      e.stopPropagation()
      copyToClipboard(value)
      let notifyName = ''
      if (name === 'smiles') notifyName = 'notification.smiles_copied'
      if (name === 'iupac') notifyName = 'notification.iupac_copy'
      if (name === 'syntelly_id') notifyName = 'notification.baseid_copy'
      if (name === 'inchi') notifyName = 'notification.inchi_copy'
      if (name === 'inchiKey') notifyName = 'notification.inchi_key_copy'
      if (name === 'cas_number') notifyName = 'notification.cas_copy'
      dispatch(addSimpleNotification(notifyName))
    }

    return (
      <PropertyRow>
        <KeyValueProperty className="molecule-key-value-property">
          {CATEGORIES_WITH_DESC.includes(name) ? (
            <div>
              <DescriptedPropertyKey
                data-tooltip-id={`${category_id.toString()}-${name}`}
                className={'property-name molecule-descripted-key'}
              >
                <Subheadline
                  color={theme.colors.text.secondary}
                  fontWeight={400}
                >
                  {t(getCategoryKey(name))}
                </Subheadline>
                <Icon iconType={'description'} />
              </DescriptedPropertyKey>

              <ReactTooltip
                id={`${category_id.toString()}-${name}`}
                clickable={true}
                delayHide={100}
                className="c-tooltip c-tooltip-base c-tooltip-z-max"
                classNameArrow="c-tooltip-arrow"
                place={'bottom'}
                positionStrategy={'fixed'}
              >
                {getCustomHeadersDescription(name)}
              </ReactTooltip>
            </div>
          ) : (
            <Subheadline
              color={theme.colors.text.secondary}
              fontSize="0.75rem"
              fontWeight={400}
              className={'property-name'}
            >
              {t(getCategoryKey(name))}
            </Subheadline>
          )}
          <PropertyValue
            name={name}
            className={`property-value`}
            onClick={() => {
              isBlurable &&
                blurNonApplicable &&
                dispatch(setBlurNonApplicable(false))
            }}
          >
            {value ? (
              isBlurable && blurNonApplicable ? (
                <BlurableValue
                  {...{
                    name,
                    value,
                    isBlurable,
                    blurNonApplicable,
                  }}
                />
              ) : (
                <ValueWithTooltip
                  onCopy={handleCopy}
                  {...{ name, value, isGeneratedMol }}
                />
              )
            ) : (
              <Icon iconType="loader" className="loader-icon" size="1rem" />
            )}
            {COPIED_PROPERTY_NAMES.includes(name) && !hideCopyIconOnError && (
              <Icon
                iconType="copy"
                onClick={(e) => handleCopy(e, name, value)}
                size="1rem"
              />
            )}
            {source && (
              <>
                <PropertySource
                  data-tooltip-id={`${category_id.toString()}-${name}-ds`}
                  withTooltip={!!source?.source}
                >
                  {source.type}
                </PropertySource>
                <ReactTooltip
                  id={`${category_id.toString()}-${name}-ds`}
                  className="c-tooltip c-tooltip-base c-tooltip-z-max"
                  classNameArrow="c-tooltip-arrow"
                  place={'bottom'}
                  hidden={!source?.source}
                  offset={8}
                  positionStrategy={'fixed'}
                >
                  <Subheadline color={'#FFFFFF'}>{source.source}</Subheadline>
                </ReactTooltip>
              </>
            )}{' '}
            {isApplicabilityShow &&
              !!applicabilityData?.[category_title] &&
              source?.type !== 'exp' && (
                <ApplicabilityIndicator
                  number={
                    typeof applicabilityData?.[category_title] === 'string'
                      ? null
                      : applicabilityData?.[category_title]
                  }
                />
              )}
          </PropertyValue>
        </KeyValueProperty>
      </PropertyRow>
    )
  }
)

Property.displayName = 'Property'

export default Property
