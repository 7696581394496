import {
  SET_REACTIONS_DIALOG_CONFIG,
  SET_OPEN_REACTIONS_DIALOG,
  SET_REACTIONS_COMPOUND_ID,
} from 'store/constants/searchReactions'

export const setOpenReactionsDialog = (isOpen) => {
  return {
    type: SET_OPEN_REACTIONS_DIALOG,
    isOpen,
  }
}

export const setReactionsDialogConfig = (config) => {
  return {
    type: SET_REACTIONS_DIALOG_CONFIG,
    config,
  }
}

export const setReactionsCompoundId = (compound_id) => {
  return {
    type: SET_REACTIONS_COMPOUND_ID,
    compound_id,
  }
}
