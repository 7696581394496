import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem 0.75rem 0.75rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ mode }) => (mode === 'forward' ? '1.25rem' : '0.75rem')};
`
