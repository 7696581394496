import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import { Label } from 'components/common/text/index.style'

import { CasWrapper, Container, LabelWithTooltip } from './index.style'

const CasNumbers = ({ values, onCopy, isGeneratedMol }) => {
  const { t } = useTranslation()
  return (
    <Container>
      {values.length > 0 ? (
        values.map((value, i, arr) => (
          <CasWrapper key={value}>
            <Label as="span">{value}</Label>
            <Icon
              iconType="copy"
              size="1rem"
              onClick={(e) => onCopy(e, value)}
            />
            {i !== arr.length - 1 && <Label as="span">,</Label>}
          </CasWrapper>
        ))
      ) : (
        <>
          <LabelWithTooltip
            isGeneratedMol={isGeneratedMol}
            data-tooltip-id="generated-mol-cas-number"
          >
            <Label>{t('molviewer.no_cas')}</Label>
            {isGeneratedMol && <Icon iconType="info" size="1rem" />}
          </LabelWithTooltip>{' '}
          {isGeneratedMol && (
            <ReactTooltip
              id="generated-mol-cas-number"
              className="c-tooltip c-tooltip-base c-tooltip-molviewer c-tooltip-id"
              offset={10}
              place="bottom-end"
              classNameArrow="c-tooltip-arrow"
              positionStrategy={'fixed'}
            >
              {t('molecule_viewer.molecule_generated_description')}
            </ReactTooltip>
          )}
        </>
      )}
    </Container>
  )
}

export default CasNumbers
