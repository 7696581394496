import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EnLogo } from '../../assets/syntelly_logo_en.svg'
import { ReactComponent as RuLogo } from '../../assets/syntelly_logo_ru.svg'
import { ReactComponent as Icon } from '../../assets/inDev.svg'

import {
  Container,
  BannerWrapper,
  BannerText,
  Title,
  Text,
} from './index.style'

const MobileInDev = () => {
  const { t } = useTranslation()

  return (
    <Container>
      {i18n.language === 'en' ? <EnLogo /> : <RuLogo />}
      <BannerWrapper>
        <Icon />
        <BannerText>
          <Title>{t('devBanner.hello')}</Title>
          <Text>{t('devBanner.mobileInDev')}</Text>
        </BannerText>
      </BannerWrapper>
    </Container>
  )
}

export default MobileInDev
