import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  border-radius: 12px;
  background-color: #ffffff;
  overflow: hidden;
  height: 36px;
`

export const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: #f5f7f7;
`

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 26px;
  cursor: pointer;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`

export const NewButton = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 12px 19px;
  background: ${({ selected }) =>
    selected ? 'rgba(196, 196, 196, 0.2)' : 'white'};
  font-size: 18px;
  line-height: 21px;

  color: #000000;

  &:hover {
    background: rgba(196, 196, 196, 0.2);
  }
`

export const TextBlock = styled.div`
  font-size: 14px;
  padding: 0 22px 0 22px;
  display: grid;
  gap: 12px;
  margin-top: 30px;
  font-weight: 600;
`

export const WarningText = styled.div`
  color: #f44336;
`
