import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'

import {
  HEADERS_EXCEPTIONS,
  getCustomHeadersDescription,
} from 'config/molecule/molecule'

import {
  KeyValueProperty,
  ColoredSubheadline,
  DescriptedPropertyKey,
} from './index.style'

const HeadersProperty = ({ text, type, color, value, units }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <KeyValueProperty className="molecule-key-value-property">
      {HEADERS_EXCEPTIONS.descriptions.includes(text) ? (
        <div>
          <DescriptedPropertyKey
            data-tooltip-id={text}
            className={'property-name molecule-descripted-key'}
            status={type === 'header' ? color : undefined}
          >
            <ColoredSubheadline
              color={theme.colors.text.secondary}
              fontWeight={400}
              status={type === 'header' ? color : undefined}
            >
              {t(`molviewer.${text}`)}
            </ColoredSubheadline>
            <Icon iconType={'description'} />
          </DescriptedPropertyKey>
          <ReactTooltip
            id={text}
            clickable={true}
            delayHide={100}
            className="c-tooltip c-tooltip-base c-tooltip-z-max"
            classNameArrow="c-tooltip-arrow"
            place={'bottom'}
            positionStrategy={'fixed'}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {getCustomHeadersDescription(text)}
          </ReactTooltip>
        </div>
      ) : (
        <ColoredSubheadline
          color={'#616E7C'}
          status={type === 'header' ? color : undefined}
        >
          {t(`molviewer.${text}`)}
        </ColoredSubheadline>
      )}
      {value !== undefined && (
        <ColoredSubheadline color={'#1F2933'} status={color}>
          {value === 'not included' ? t(`molviewer.not_included`) : value}
          {units &&
            !HEADERS_EXCEPTIONS.units.includes(units) &&
            ` ${t(`molviewer.${units}`)}`}
        </ColoredSubheadline>
      )}
    </KeyValueProperty>
  )
}

export default HeadersProperty
