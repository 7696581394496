export const generateNodesAndEdges = (tree, x, y, nodeWidth, nodeHeight) => {
  const getHorizontalNodes = () => {
    let res = []
    tree.forEach((current) => {
      const otherChildren = tree.filter(
        (el) => el.parent === current.parent && el.id !== current.id
      )
      if (
        current.parent === null ||
        !otherChildren.some((el) => res.includes(el.id))
      )
        res.push(current.id)
    })
    return res
  }

  const nodes = []
  const edges = []
  let currentX = x

  const horizontalNodesCount = getHorizontalNodes().length
  const totalWidth = horizontalNodesCount * nodeWidth

  const calcOffsetX = (x - totalWidth) / (horizontalNodesCount - 1)
  const minOffsetX = x < 600 ? 200 : 240

  const offsetX = calcOffsetX < minOffsetX ? minOffsetX : calcOffsetX
  const offsetY = 200

  const setNodePosition = (parentId, level, parentY = 0) => {
    const parent = tree.find((item) => item.id === parentId)
    const children = tree.filter((item) => item.parent === parentId)

    if (parent.parent === null) {
      nodes.push({
        id: parentId.toString(),
        data: {
          smiles: tree.find((node) => node.id === parentId).smiles,
          width: nodeWidth,
          height: nodeHeight,
          moleculeId: tree.find((node) => node.id === parentId).structure_id,
        },
        position: { x: currentX, y: 0 },
        sourcePosition: 'left',
        targetPosition: 'right',
        type: 'custom',
        style: { borderRadius: 12 },
      })
      currentX -= offsetX
    }
    const x = currentX - level * offsetX
    const nodesWithSameX = nodes.filter((el) => el.position.x === x)

    children.forEach((child, index, arr) => {
      let y = parentY

      if (!nodesWithSameX.length) {
        if (arr.length > 1) {
          if (index === 0) y = y + offsetY
          if (index === 2) y = y - offsetY
          if (index > 2) y = y + (index - 1) * offsetY
        }
      } else {
        if (nodesWithSameX.some((el) => el.position.y >= parentY)) {
          y = parentY - offsetY * index
        }
      }

      nodes.push({
        id: child.id.toString(),
        data: {
          smiles: child.smiles,
          width: nodeWidth,
          height: nodeHeight,
          moleculeId: child.structure_id,
        },
        position: { x, y },
        sourcePosition: 'left',
        targetPosition: 'right',
        type: 'custom',
        style: { borderRadius: 12 },
      })

      edges.push({
        id: `${parent.id}-${child.id}`,
        source: parent.id.toString(),
        target: child.id.toString(),
        type: 'smoothstep',
      })

      if (tree.some((el) => el.parent === child.id)) {
        setNodePosition(child.id, level + 1, y)
      }
    })
  }

  tree
    .filter((item) => item.parent === null)
    .forEach((root) => {
      setNodePosition(root.id, 0)
    })
  return { nodes, edges }
}

export const downloadImage = (dataUrl) => {
  const a = document.createElement('a')
  a.setAttribute('download', 'reactflow.png')
  a.setAttribute('href', dataUrl)
  a.click()
}
