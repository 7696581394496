export const SET_OPEN_FILTER = 'SET_OPEN_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
export const SET_FILTER_CONFIG = 'SET_FILTER_CONFIG'
export const SET_FILTER_BUTTONS_DISABLED = 'SET_FILTER_BUTTONS_DISABLED'
export const SET_FILTER_BUTTONS_ACTIVE = 'SET_FILTER_BUTTONS_ACTIVE'
export const GET_MARKUSH_MOLECULE_WEIGHT = 'GET_MARKUSH_MOLECULE_WEIGHT'
export const SET_MARKUSH_MOLECULE_WEIGHT = 'SET_MARKUSH_MOLECULE_WEIGHT'
export const SET_MARKUSH_MOLECULE_MAX_WEIGHT = 'SET_MARKUSH_MOLECULE_MAX_WEIGHT'
export const SET_MARKUSH_INPUTS_DISABLED = 'SET_MARKUSH_INPUTS_DISABLED'
export const RESET_MARKUSH_MOLECULE_WEIGHT = 'RESET_MARKUSH_MOLECULE_WEIGHT'
export const SET_LIT_DOC_TYPE = 'SET_LIT_DOC_TYPE'
