import styled from 'styled-components'

export const Links = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const Link = styled.div`
  display: flex;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  cursor: default;
  color: #2d3745;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  transition: all 0.3s;
  align-items: center;

  & > svg {
    color: #2d3745;
    transition: all 0.3s;
  }

  &:hover {
    color: ${({ disabled }) => (disabled ? '#2D3745' : '#42C1BF')};

    & svg {
      color: ${({ disabled }) => (disabled ? '#2D3745' : '#42C1BF')};
    }
  }
`
