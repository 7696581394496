import styled from 'styled-components'

export const ListContainer = styled.div`
  background-color: #f5f7f7;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding: 1rem 0;
`

export const ListBlock = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  row-gap: 12px;
  align-content: center;
  align-items: center;
  font-size: 13px;
  width: 100%;
  background: #ffffff;
  height: 100%;
  padding: 1rem;
  border-radius: 12px;
`

export const ListBlockItem = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 15% 10% 15% 60%;
  & > div {
    word-break: break-word;
  }
`

export const ReactionItem = styled.div`
  white-space: pre-line;
`
