import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomInput from 'components/common/customInput'
import { checkIfNumbers, checkSymbCount } from 'components/Filter/utils/utils'

import {
  DoubleInputContainer,
  FilterDoubleInputTitle,
  FilterDoubleInputWrapper,
} from './index.style'
import { HideBtn } from '../../index.style'

const FilterDoubleInput = memo(({ setState, state, config, searchType }) => {
  const {
    values,
    title,
    numberOnly,
    key,
    validate,
    nonIntegral = false,
    searchKey,
  } = config
  const { t } = useTranslation()
  const [isFilerOpened, setFilterOpened] = useState(true)
  const handleOpen = () => setFilterOpened(!isFilerOpened)
  const isPublicationDateFilter = key === 'published_date'

  //const [inputValues, setInputValues] = useState({ 0: '', 1: '' })

  const handleChange = (value, idx) => {
    if (Number(value) === 0 && key === 'molecular_weight') value = ''
    if (
      (numberOnly && !checkIfNumbers(value, nonIntegral)) ||
      (validate === 'year' && value.length > 4) ||
      (idx === 0 &&
        state[key]?.[1] &&
        Number(value) > Number(state[key]?.[1])) ||
      (key === 'molecular_weight' && !checkSymbCount(value))
    )
      return

    setState({ [key]: { ...state[key], [idx]: value } })
  }

  return (
    <FilterDoubleInputWrapper opened={isFilerOpened}>
      <FilterDoubleInputTitle onClick={handleOpen}>
        {t(title)}
        <HideBtn opened={isFilerOpened}>
          <Icon iconType="arrowDown" size="1rem" />
        </HideBtn>
      </FilterDoubleInputTitle>
      <DoubleInputContainer
        opened={isFilerOpened}
        isMolecularWeight={key === 'molecular_weight'}
      >
        <CustomInput
          label={values?.[0]?.label ? t(values?.[0]?.label) : ''}
          value={state[key]?.[0] ?? ''}
          onChange={(value) => handleChange(value, 0)}
          key="double-input-filter-0"
          width="6.875rem"
          isDisabled={
            searchType === 'literature'
              ? false
              : state.match_type !== searchKey ||
                (key === 'molecular_weight' && state.molecular_weight_disabled)
          }
          // withClearButton={isPublicationDateFilter}
          withPseudoLabel={true}
          placeholder={t(`${values[0].placeholder}`)}
          pseudoBeforeText={t(`${values[0].placeholder}`)}
        />

        <CustomInput
          label={values?.[1]?.label ? t(values?.[1]?.label) : ''}
          value={state[key]?.[1] ?? ''}
          onChange={(e) => handleChange(e, 1)}
          key="double-input-filter-1"
          isDisabled={
            searchType === 'literature'
              ? false
              : state.match_type !== searchKey ||
                (key === 'molecular_weight' && state.molecular_weight_disabled)
          }
          // withClearButton={isPublicationDateFilter}
          width="6.875rem"
          withPseudoLabel={true}
          placeholder={t(`${values[1].placeholder}`)}
          pseudoBeforeText={t(`${values[1].placeholder}`)}
        />
      </DoubleInputContainer>
    </FilterDoubleInputWrapper>
  )
})

FilterDoubleInput.displayName = 'FilterDoubleInput'
export default FilterDoubleInput
