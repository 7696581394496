import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  max-height: calc(100% - 44px);
  & > svg {
    color: ${({ theme }) => theme.colors.statuses.success};
  }
`

export const Info = styled.div`
  color: #1f2933;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.168px;
`

export const Log = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 16px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  margin-right: 8px;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
`

export const LogDate = styled.div`
  color: #616e7c;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.144px;
  white-space: nowrap;
`

export const LogTitle = styled.div`
  color: #1f2933;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.168px;
  word-break: break-word;
`

export const LogMessage = styled.div`
  color: #616e7c;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.144px;
  word-break: break-word;
`

export const LogData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`
