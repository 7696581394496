import styled from 'styled-components'

export const Container = styled.div`
  gap: ${({ gap }) => gap || '1rem'};
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const FieldWithHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`
