import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Icon from 'components/Icon'
import { fetch } from 'services/rest'
import LoadingOverlay from 'components/LoadingOverlay'
import { setLitPageOpen } from 'store/actions/literature'
import CustomScrollbar from 'components/CustomScrollbar'
import CustomButton from 'components/common/customButton'
import { TABS_CONFIG } from 'components/LiteratureCard/config'

import LeftPanel from './leftPanel'
import RightPanel from './rightPanel'
import AuthorsDialog from './authorsDialog'
import { Container, Wrapper } from './index.style'

const LiteraturePage = () => {
  const [loading, setLoading] = useState(true)
  const [docData, setDocData] = useState({})
  const [withCarousel, setWithCarousel] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [showFullText, setShowFullText] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { open: withBackBtn, config } = useSelector(
    (state) => state.literature.litPage
  )

  const { type_id: stateTypeId, data, litSearchSmiles } = config
  const { type_id: paramsTypeId } = useParams()

  const type_id = stateTypeId || paramsTypeId

  useEffect(() => {
    const getDocData = async () => {
      setLoading(true)
      try {
        const params = type_id.split('-')
        const type = params[0]
        const { data: fetchData } = await fetch(
          type === 'patent'
            ? `/patents/${params[2]}?language=${params[1]}`
            : `/articles/${params[2]}`
        )
        setDocData(fetchData)
        setLoading(false)
        TABS_CONFIG.forEach((el) => {
          if (fetchData?.[el.numberPointer]?.length > 0) setWithCarousel(true)
        })
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    }
    getDocData()

    return () => {
      withBackBtn && dispatch(setLitPageOpen(false, {}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type_id])

  const goBack = () => dispatch(setLitPageOpen(false, {}))

  if (loading) return <LoadingOverlay />

  return (
    <Wrapper>
      {withBackBtn && (
        <CustomButton type="text_accent" onClick={goBack} gap="0.25rem">
          <Icon iconType="arrowLeft" size="1rem" />
          {t('literatureCard.back_to_results')}
        </CustomButton>
      )}
      <Container id="literature-page-container">
        <CustomScrollbar
          className="margin-4-bottom margin--4-right"
          disableTrackYWidthCompensation={true}
        >
          <LeftPanel
            docData={docData}
            withCarousel={withCarousel}
            showFullText={showFullText}
            setShowFullText={setShowFullText}
            litSearchSmiles={litSearchSmiles}
            data={data}
            linkParams={stateTypeId}
          />
        </CustomScrollbar>
        <RightPanel docData={docData} setDialogOpen={setDialogOpen} />
      </Container>
      {dialogOpen && (
        <AuthorsDialog setDialogOpen={setDialogOpen} docData={docData} />
      )}
    </Wrapper>
  )
}

export default LiteraturePage
