import { useTranslation } from 'react-i18next'
import Icon from 'components/Icon'
import { isLink } from 'pages/SynthCost/utils'
import { CustomDialog } from 'components/common/customDialog'
import {
  Subheadline,
  TextButtonMedium,
  TitleTertiary,
} from 'components/common/text/index.style'
import { Source, SourceList } from './index.style'
import CustomScrollbar from 'components/CustomScrollbar'

const SourceDialog = ({ sources, onClose, onCopy }) => {
  const { t } = useTranslation()
  return (
    <CustomDialog gap="20px" padding="24px" onClose={onClose} maxHeight="344px">
      <TitleTertiary fontWeight={900} color="#2D3745">
        {t('synthCost.reaction_scheme_source')}
      </TitleTertiary>
      <CustomScrollbar style={{ width: '624px' }}>
        <SourceList>
          {sources.map((el) => (
            <Source key={el}>
              <TextButtonMedium color="inherit">
                {isLink(el) ? (
                  <a href={el} target="_blank" rel="noopener noreferrer">
                    {el}
                  </a>
                ) : (
                  <span>{el}</span>
                )}
              </TextButtonMedium>
              <Icon iconType="link2" onClick={() => onCopy(el)} />
            </Source>
          ))}
        </SourceList>
      </CustomScrollbar>
    </CustomDialog>
  )
}

export default SourceDialog
