import styled from 'styled-components'

export const UsersWrapper = styled.div``

export const UsersBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin: ${({ marginBottom }) => `0 0.75rem ${marginBottom} 0rem`};
`
