import { Fragment } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { Container, Element } from './index.style'
import '../../components/common/tooltip/style.css'

const MoleculesListTopToolbarRestyled = ({
  data,
  onDelete,
  isPublic,
  onCalcTLight,
  handleShowAddDialog,
}) => {
  const len = data?.length ?? 0
  const { t } = useTranslation()

  const buttonsConfig = [
    {
      visible: !isPublic,
      onClick: handleShowAddDialog,
      type: 'add',
    },
    {
      visible: !isPublic,
      onClick: onCalcTLight,
      type: 'calculate',
      disabled: len === 0,
    },
    {
      visible: !isPublic,
      onClick: onDelete,
      type: 'deleted',
      disabled: len === 0,
      text: 'delete',
    },
  ]

  const getText = (text, type) => text ?? type

  return (
    <>
      <Container>
        {buttonsConfig
          .filter(({ visible }) => visible)
          .map(({ type, onClick, disabled, text }) => (
            <Fragment key={type}>
              <Element
                disabled={disabled}
                onClick={disabled ? undefined : onClick}
                data-tooltip-id={`toolbar-${type}`}
                id={`toolbar-molecules-${type}`}
              >
                <Icon iconType={type} size="1.25rem" />
              </Element>
              <ReactTooltip
                id={`toolbar-${type}`}
                className="c-tooltip c-tooltip-base c-tooltip-baskets"
                classNameArrow="c-tooltip-arrow"
                place={'bottom'}
                offset={14}
              >
                <div>{t(`moleculeslist.topblock.${getText(text, type)}`)}</div>
              </ReactTooltip>
            </Fragment>
          ))}
      </Container>
    </>
  )
}

export default MoleculesListTopToolbarRestyled
