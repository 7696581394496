import styled from 'styled-components'
import {
  FilterDoubleInputWrapper,
  FilterDoubleInputTitle,
  DoubleInputContainer,
} from '../FilterDoubleInput/index.style'

export const FilterSingleInputWrapper = styled(FilterDoubleInputWrapper)``

export const FilterSingleInputTitle = styled(FilterDoubleInputTitle)``

export const SingleInputContainer = styled(DoubleInputContainer)``
