import { Caption, TextMedium } from 'components/common/text/index.style'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  max-height: 9.875rem;
  min-height: 9.875rem;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const NewButton = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 0.625rem 0;
  background: transparent;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.text.accentPrimary : theme.colors.text.primary};
  display: flex;
  justify-content: space-between;
  user-select: none;
  column-gap: 0.5rem;

  & ${TextMedium} {
    max-width: 85%;
    color: ${({ selected, theme }) =>
      selected ? theme.colors.text.accentPrimary : theme.colors.text.primary};
  }

  &:last-child {
    margin-bottom: 0.25rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    & ${TextMedium} {
      color: ${({ theme }) => theme.colors.text.accentPrimary};
    }
  }

  & > svg {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    margin-right: 0.5rem;
  }
`

export const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  z-index: 9999;
`

export const ModalDialog = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  padding: 1.5rem 1.5rem 1.25rem;
  box-shadow: ${({ theme }) => theme.shadow.default};
  margin: auto;
  width: calc(100% - 4rem);
  max-width: 23.25rem;
  display: flex;
  max-height: calc(100% - 4rem);
  flex-direction: column;
  overflow-y: auto;
  row-gap: 1.25rem;
  border-radius: 1.25rem;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalBtns = styled.div`
  padding-top: 0.25rem;
  display: flex;
  justify-content: space-between;
  column-gap: 0.75rem;
`

export const SelectedList = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.25rem;
`

export const TagText = styled(Caption)`
  max-width: 7.625rem;
`

export const ListContainer = styled.div`
  &:after {
    content: '';
    width: 85%;
    height: 1rem;
    position: absolute;
    bottom: 5rem;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }

  ${Caption} {
    margin-bottom: 0.5rem;
  }
`

export const DatasetTypesSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const DatasetTypes = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
