import { Caption } from 'components/common/text/index.style'

import { Column } from '../index.style'
import HistoryCard from '../HistoryCard'
import { CardsList } from './index.style'
import { getSearchSmiles, getSearchText } from './utils'

const HistoryList = ({ history, onRepeatSearch }) => {
  return (
    <Column>
      {history.map((el, i) => (
        <Column key={i}>
          <Caption fontWeight="500" color="#616E7C">
            {el.date}
          </Caption>
          <CardsList>
            {el.values.map((el) => {
              return (
                <HistoryCard
                  key={el.id}
                  id={el.id}
                  time={el.time}
                  type={el.query.type}
                  smiles={getSearchSmiles(el.query)}
                  method={el.query?.params?.method}
                  onRepeatSearch={(showPastResult) => {
                    onRepeatSearch(el, showPastResult)
                  }}
                  searchText={getSearchText(el.query)}
                  // compoundTypes={el.query?.params?.compound_types}
                />
              )
            })}
          </CardsList>
        </Column>
      ))}
    </Column>
  )
}
export default HistoryList
