import styled from 'styled-components'

import { Headline } from 'components/common/text/index.style'

export const AliasLabel = styled(Headline)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`
