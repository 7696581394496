import styled from 'styled-components'

export const Checkboxes = styled.div`
  gap: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CheckboxWithTitle = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
