export const CUSTOM_FILTER_CONFIG = [
  { title: 'dataset_table.filter', type: 'filter' },
  {
    title: 'dataset_table.paint',
    type: 'painting',
  },
]

export const textFiltersConfig = [
  {
    type: 'contains',
    filter: '',
  },
  {
    type: 'doesNotContain',
    filter: '',
  },
  {
    type: 'equals',
    filter: '',
  },
  {
    type: 'doesNotEqual',
    filter: '',
  },
  {
    type: 'startsWith',
    filter: '',
  },

  {
    type: 'endsWith',
    filter: '',
  },
  {
    type: 'blank',
    filter: '',
  },
  {
    type: 'notBlank',
    filter: '',
  },
]

export const numberFiltersConfig = [
  {
    type: 'equals',
    filter: '',
  },
  {
    type: 'contains',
    filter: '',
  },
  {
    type: 'doesNotEqual',
    filter: '',
  },
  {
    type: 'greaterThan',
    filter: '',
  },
  {
    type: 'greaterThanOrEqual',
    filter: '',
  },
  {
    type: 'lessThan',
    filter: '',
  },

  {
    type: 'lessThanOrEqual',
    filter: '',
  },
  {
    type: 'inRange',
    filter: '',
  },
  {
    type: 'blank',
    filter: '',
  },
  {
    type: 'notBlank',
    filter: '',
  },
]

export const initFilterState = [
  {
    type: '',
    filter: '',
    filter2: '',
  },

  {
    type: '',
    filter: '',
    filter2: '',
  },
]

export const textCells = [
  'SMILES_standardized',
  'PAINS',
  'NR-ER-LBD',
  'CYP',
  'Eye irritation',
  'SR-ARE',
  'SR-MMP',
  'Developmental toxicity',
  'Acute Toxicity Swallowed',
  'Carcinogenicity',
  'Human pharmacological',
  'Human pharmacological half-life',
  'NR-PPAR-gamma',
  'Ames test',
  'Hepatotoxicity',
  'DILI',
  'Eye corrosion',
  'lipinski',
  'Selective Target-Organ',
  'Cardiotoxicity',
  'HumanVDss',
  'NR-ARD-LBD',
  'SR-HSE',
  'oprea',
  'Acute Aquatic Toxicity',
  'BBBP',
  'veber',
  'Reproductive toxicity',
  'NR-ER',
  'NR-Aromatase',
  'ghose',
  'SR-p53',
  'NR-AhR',
  'SR-ATAD5',
  'NR-AR',
]
