import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import CustomInput from 'components/common/customInput'
import { storeV2 } from '../../services/rest'
import { VariantBlock } from 'components/main-search-bar/molecules/variant-block'
import { useDebounce } from 'hooks/useDebounce'
import {
  getEaxFilterKey,
  getRequestParams,
  onSelectPropsConfig,
} from './helpers'

const InputHelper = React.forwardRef(
  ({ onAdd, onBlur, onFocus, showOptions, url, placeholder }, ref) => {
    const [options, setOptions] = useState([])
    const [value, setValue] = useState('')
    const [noLoadOptions, setNoLoadOptions] = useState(true)
    const { t } = useTranslation()

    const debouncedInputValue = useDebounce(value, 300)
    const selectedValueRef = useRef('')

    const isStatistics = url === 'user/get' || url === 'customer/get'

    useImperativeHandle(ref, () => {
      return {
        clearInput: () => {
          setValue('')
          setOptions([])
        },
      }
    })

    useEffect(() => {
      if (value === selectedValueRef.current) return
      if (value) {
        !noLoadOptions && requestSearch({ text: value, isQuick: true })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedInputValue])

    useEffect(() => {
      if (!url) return
      setValue('')
      setOptions([])
    }, [url])

    const requestSearch = async ({ text, isQuick = false }) => {
      const key = getEaxFilterKey({ url })
      const { data } = await storeV2(url, {
        params: getRequestParams({ url, text, isQuick }),
      })

      const search_data =
        url === 'customer/get' ? data?.items : data?.result?.items || []

      if (Array.isArray(search_data)) {
        const eax = search_data.filter((e) => e[key])
        setOptions(eax)
      }
    }

    const handleChange = (msg, noLoadOptions) => {
      setValue(msg)
      setNoLoadOptions(noLoadOptions)
      if (!msg) {
        !!options.length && setOptions([])
      }
    }

    const onSelect = (data) => {
      const config = onSelectPropsConfig[url] || onSelectPropsConfig.default
      const { handleChangeProps, onAddProps } = config
      handleChange(data[handleChangeProps], true)
      onAdd(data[onAddProps])
      if (isStatistics) selectedValueRef.current = data[onAddProps]

      setOptions([])
    }

    return (
      <div
        style={{
          position: 'relative',
          ...(isStatistics ? { width: '100%' } : {}),
        }}
      >
        <CustomInput
          onChange={handleChange}
          placeholder={placeholder ?? t('moleculeslist.enter_synonym')}
          withClearButton
          showSearchIcon
          {...{ value, onBlur, onFocus }}
          focused={showOptions || value}
        />
        {!!options.length && showOptions && (
          <VariantBlock
            data={options}
            withMaxHeight={true}
            handleSelect={onSelect}
            withSelectStyles
            url={url}
          />
        )}
      </div>
    )
  }
)

InputHelper.displayName = 'InputHelper'
export default InputHelper
