import styled, { css } from 'styled-components'

export const TripleToggleContainer = styled.div`
  height: 2.25rem;
  width: ${({ isDoubleSwitcher }) => (isDoubleSwitcher ? '68px' : '100px')};
  border-radius: 0.75rem;
  padding: 2px;
  display: inline-block;
  vertical-align: middle;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  position: ${({ position }) => position};
  input[type='radio'] {
    display: none;
  }
`

export const Switch = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 10px;
  padding: 6px;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  left: ${({ switchPosition }) =>
    switchPosition === 'left'
      ? '2px'
      : switchPosition === 'center'
      ? '34px'
      : '66px'};
  animation-name: ${({ animation }) => animation};
  animation-duration: 0.5s;
  position: absolute;
  transition: ease-in;
  box-shadow: 0px 1px 4px 0px rgba(45, 55, 69, 0.16);
`

export const StyledLabel = styled.label`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 0.75rem;
  left: ${({ left }) => left};
  color: ${({ theme, switchPosition, position }) =>
    switchPosition === position
      ? theme.colors.icons.accentPrimary
      : theme.colors.icons.tertiary};
  ${({ theme, disabled }) =>
    !disabled &&
    css`
      &:hover {
        & svg {
          transition: color 0.3s;
          color: ${theme.colors.icons.accentPrimary};
        }
      }
    `};
`
