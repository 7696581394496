import { Tooltip as ReactTooltip } from 'react-tooltip'
import { memo, useEffect, useRef, useState } from 'react'

import { AliasLabel } from './index.style'

const Alias = memo(({ num, alias }) => {
  const ref = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    if (ref?.current?.scrollWidth > ref?.current?.clientWidth)
      setShowTooltip(true)
    else setShowTooltip(false)
  }, [ref])

  return (
    <>
      <AliasLabel ref={ref} data-tooltip-id={`${num}-alias`}>
        {alias}
      </AliasLabel>
      {showTooltip && (
        <ReactTooltip
          id={`${num}-alias`}
          className="c-tooltip c-tooltip-base c-tooltip-molecule-card-note"
          classNameArrow="c-tooltip-arrow"
          place={'bottom-start'}
          offset={14}
        >
          {alias}
        </ReactTooltip>
      )}
    </>
  )
})

Alias.displayName = 'Alias'

export default Alias
