import styled from 'styled-components'

export const FilterSelectPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterSelectPointsTitle = styled.span`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  color: ${({theme})=> theme.colors.text.secondary};
  cursor: pointer;

  & > span {
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
  }
`

export const PointsContainer = styled.div`
  height: ${({ opened }) => (opened ? '2.5rem' : '0px')};
  overflow: ${({ opened }) => (opened ? 'unset' : 'hidden')};
  min-height: ${({ opened }) => (opened ? '2.5rem' : '0px')};
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  transition: all 0.3s;
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
`

export const Point = styled.div`
  padding: 0.625rem 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#616E7C')};
  background: ${({ selected }) => (selected ? '#42C1BF' : '#f5f7f7')};
  transition: color 0.3s;
  width: 2.25rem;
  height: 2.25rem;

  &:hover {
    color: ${({ selected }) => (selected ? '#FFFFFF' : '#42c1bf')};
  }
`
