import styled from 'styled-components'

export const SchemeContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  justify-content: center;
  .react-flow__handle.connectionindicator {
    background-color: transparent;
  }

  .react-flow__panel {
    display: none;
  }
`
