import styled from 'styled-components'

export const Row = styled.div`
  & .info {
    white-space: nowrap;
  }
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
  gap: ${({ gap }) => (gap ? gap : '8px')};
  svg {
    color: #ced6e3;
  }
  .info {
    &:hover {
      svg {
        color: #42c1bf;
      }
    }
  }
`

export const Column = styled.div`
  display: flex;
  position: relative;
  gap: ${({ gap }) => (gap ? gap : '8px')};
`

export const ApplicabilityBlock = styled.div`
  display: flex;
  flex-direction: column;
  top: ${({ asHotTip }) => !asHotTip && '1.75rem'};
  right: ${({ asHotTip }) => !asHotTip && '0rem'};
  gap: 1rem;
  width: 423px;
  padding: ${({ asHotTip }) => !asHotTip && '1rem'};
  border-radius: ${({ asHotTip }) => !asHotTip && '0.75rem'};
  position: ${({ asHotTip }) => !asHotTip && 'absolute'};
  z-index: 5;
  background-color: ${({ theme, asHotTip }) =>
    !asHotTip && theme.colors.backgrounds.primary};
  box-shadow: ${({ theme, asHotTip }) => !asHotTip && theme.shadow.default};
`
