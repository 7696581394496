import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from 'services/rest'
import { setSelected } from 'store/actions/basket'
import { loadBaskets, loadLastChange } from 'store/actions/crud'
import { useAuth } from 'utils/auth/auth'

export const useUndo = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth()
  const basketType = useSelector((store) => store.settings.basketType)

  const handleRefresh = useCallback(() => {
    dispatch(loadBaskets({ basketType }))
    if (isAuthenticated) dispatch(loadLastChange())
  }, [dispatch, isAuthenticated, basketType])

  const handleUndo = async () => {
    try {
      await store(`/undo-last-change`)
      dispatch(setSelected([]))
      handleRefresh()
    } catch (e) {
      console.log(e)
    }
  }

  return { handleUndo }
}
