import React from 'react'
import { Infrared } from '../index.style'
import { SpectralLabel } from '../index.style'
import CustomSelect from 'components/CustomSelect'
import { INFRARED_SHOOTING } from '../config'
import { connect, useDispatch } from 'react-redux'
import { resetSpectra, setInfraredShootingMethod } from 'store/actions/spectra'
import { useTranslation } from 'react-i18next'
import { Caption } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'

const InfraredBlock = ({ shootingMethod, infraredData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useTheme()
  const { data, loading } = infraredData

  const handleSetMethod = (option) => {
    data?.length && dispatch(resetSpectra())
    dispatch(setInfraredShootingMethod(option))
  }

  return (
    <Infrared>
      <div>
        <SpectralLabel>
          <Caption
            color={theme.colors.text.secondary}
            fontWeight={theme.fontWeight.medium}
          >
            {' '}
            {t('spectra.labels.shooting_method')}
          </Caption>{' '}
        </SpectralLabel>
        <CustomSelect
          disabled={loading}
          options={INFRARED_SHOOTING}
          selectedValue={shootingMethod}
          onChange={(option) => handleSetMethod(option)}
          withTranslation={false}
        />
      </div>
    </Infrared>
  )
}

const mapStateToProps = (state) => {
  return {
    shootingMethod: state.spectra.infrared.shootingMethod,
    infraredData: state.spectra.infrared,
  }
}

export default connect(mapStateToProps)(InfraredBlock)
