import { storeV2 } from 'services/rest'

export const getLicenseData = async (id) => {
  try {
    const res = await storeV2('license/get', {
      params: {
        filter: { ids: [id] },
      },
    })

    if (res?.data?.items?.[0]) {
      return res.data.items[0]
    }
  } catch (e) {
    console.log(e)
    return {}
  }
}

export const SECTIONS_CONFIG = [
  {
    name: 'login_details',
    details: 'user_account.email_and_password',
  },
  {
    name: 'personal_data',
    details: 'user_account.last_name_first_name_company_and_position',
  },
  {
    name: 'product_key',
    details: 'user_account.full_access_to_the_platform',
  },
  {
    name: 'notification_settings',
    details: 'user_account.newsletters_emails_and_tips',
  },
]

export const NAMES_CONFIG = [
  {
    value: 'name',
    placeholder: 'enter_name',
  },
  {
    value: 'surname',
    placeholder: 'enter_surname',
  },
]

export const JOB_CONFIG = [
  {
    value: 'company',
    placeholder: 'enter_company',
  },
  {
    value: 'position',
    placeholder: 'enter_position',
  },
]
