export const SMILES_SIZE = {
  width: 184,
  height: 214,
}

export const CANVAS_ARR = ['product', 'reagent']

export const INIT_SETTINGS = {
  product: '',
  reagent: '',
  weight: '',
  stage_start: '',
  stage_end: '',
}

export const REQUIRED_SETTINGS = ['product', 'weight']

export const getIsPredictBtnDisabled = (settings, loading, isDataLoaded) => {
  if (!settings || loading || isDataLoaded) return true

  const pairs = Object.entries(settings)
  if (
    !pairs
      .filter((el) => REQUIRED_SETTINGS.includes(el[0]))
      .every((el) => String(el[1]).trim())
  )
    return true

  if (Number(settings.stage_start) > Number(settings.stage_end)) return true

  if (
    (settings.stage_start === '' || settings.stage_end === '') &&
    settings.stage_start !== settings.stage_end
  )
    return true

  return false
}

export const DEFAULT_STAGE_START = 1
export const DEFAULT_STAGE_END = 6
