import styled from 'styled-components'

export const NavigationButtons = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  display: none;
  position: absolute;
  justify-content: space-between;
  & > div {
    background: ${({ theme }) => theme.colors.backgrounds.primary};
  }
`

export const Wrapper = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  padding: 0.75rem;
  border-radius: 0rem 1.25rem 1.25rem 0rem;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  &:hover {
    ${NavigationButtons} {
      display: flex;
    }
  }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
`
export const ImageContainerWithNavigation = styled.div`
  width: 100%;
  height: 24.5rem;
  display: flex;
  position: relative;
  align-items: center;
`
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
  padding: 2rem 1rem;
  overflow: hidden;

  & > img {
    width: 100%;
    height: auto;
    padding: 2rem;

    transition: ${({ transition }) => transition && 'transform 0.5s ease'};
    transform: ${({ transformValue }) => transformValue};
  }
`

export const Info = styled.div`
  gap: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const TitleWithDescription = styled.div`
  gap: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`
