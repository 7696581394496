import styled from 'styled-components'

import { Caption } from 'components/common/text/index.style'

export const InfoRow = styled.div`
  width: 100%;
  gap: 0.75rem;
  display: flex;
  align-items: center;
`

export const InfoSection = styled.div`
  display: flex;
  gap: 0.25rem;
  max-width: 50%;
  align-items: center;

  & svg {
    min-width: 1rem;

    color: ${({ theme }) => theme.colors.icons.secondary};
  }

  & svg:last-child {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.icons.tertiary};
    &:hover {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
    transition: all 0.3s ease;
  }

  ${Caption} {
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  .react-tel-input {
    display: none;
  }
`
