import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo } from 'react'
import { useTheme } from 'styled-components'

import { Wrapper, SettingsItem, SettingsDetail } from './index.style'
import { Label } from 'components/common/text/index.style'
import CustomToggle from 'components/common/customToggle'
import { settingsShowTipsToggle } from 'store/actions/settings'

const NotificationSettingsSection = memo(
  ({ showTips, settingsShowTipsToggle }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const config = [
      {
        name: 'user_account.notifications.show_tips',
        details: 'user_account.notifications.about_each_module',
        onClick: () => settingsShowTipsToggle(!showTips),
      },
    ]

    return (
      <Wrapper>
        {config.map(({ name, details, onClick }) => (
          <SettingsItem key={name}>
            <SettingsDetail>
              <Label>{t(name)}</Label>
              <Label
                color={theme.colors.text.secondary}
                fontWeight={theme.fontWeight.light}
              >
                {t(details)}
              </Label>
            </SettingsDetail>
            <CustomToggle
              size="large"
              type="grey"
              value={showTips}
              onClick={onClick}
            />
          </SettingsItem>
        ))}
      </Wrapper>
    )
  }
)

NotificationSettingsSection.displayName = 'NotificationSettingsSection'

const mapStateToProps = (state) => {
  return {
    showTips: state.settings.showTips,
  }
}

const mapDispatchToProps = { settingsShowTipsToggle }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSettingsSection)
