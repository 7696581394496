import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'

import { Container } from './index.style'

const ActionButtons = ({ buttonsConfig, isDownloadMenuShow, margin, note }) => {
  return (
    <Container margin={margin} className="molecule-actions-buttons">
      {buttonsConfig?.map(
        ({ icon, text, disabled, onClick, id, hidden }, i) => {
          return (
            !hidden && (
              <>
                <CustomButton
                  type="text"
                  key={i}
                  onClick={(e) => {
                    if (id !== 'download-structure') {
                      e.stopPropagation()
                      onClick(e)
                    }
                  }}
                  onMouseOver={(e) => {
                    if (id === 'download-structure') {
                      e.stopPropagation()
                      onClick(e)
                    }
                  }}
                  gap="4px"
                  disabled={disabled}
                  active={icon === 'upload' && isDownloadMenuShow}
                  data-tooltip-id={id}
                >
                  <Icon iconType={icon} size="1rem" />
                  {text}
                </CustomButton>

                <ReactTooltip
                  id="open-note"
                  className="c-tooltip c-tooltip-base c-tooltip-molecule-card-note"
                  classNameArrow="c-tooltip-arrow"
                  place={'bottom-start'}
                  offset={14}
                >
                  {note}
                </ReactTooltip>
              </>
            )
          )
        }
      )}
    </Container>
  )
}

export default ActionButtons
