import {
  HeaderWrapper,
  ImgWrapper,
  HeaderItems,
  HeaderTitle,
  CompanyNumber,
  HeaderInfo,
  InfoItem,
  ButtonsBlock,
  SkeletonWrapper,
  NumberWrapper,
  Tags,
} from './index.style'
import moleculesPng from '../../../assets/background/molecules.png'
import CustomButton from 'components/common/customButton'
import { useTranslation } from 'react-i18next'
import Icon from 'components/Icon'
import {
  Caption,
  Label,
  TitlePrimary,
} from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SkeletonComponent from 'components/common/skeleton'
import CustomTag from 'components/common/customTag'
import { addSimpleNotification } from 'store/actions/notifications'
import { copyToClipboard } from 'utils/common/common'
import { getLicenseDate } from '../utils'
import { licenseToggleAccess } from 'store/actions/adminPanel'
import { useState } from 'react'
import EditCompanyDialog from '../editCompanyDialog'
import { getPlacesLeftTitle } from './utils/utils'
import { getIsLicenseActive } from 'utils/licenses/getLicenseNotificationText'

const CompanyHeader = ({ currentCustomer, role }) => {
  const [editOpen, setEditOpen] = useState(false)
  const {
    item: {
      license_id,
      invite_link,
      invite_token,
      customer_name,
      customer_admin,
      customer_domain,
      license_is_active,
      license_started_at,
      license_expired_at,
      license_users_count,
      license_is_free_trial,
      license_max_users_count,
    },
    loading,
  } = currentCustomer

  const isLicenseActive = getIsLicenseActive(currentCustomer.item)

  const licensesLeftCount =
    license_max_users_count - license_users_count >= 0
      ? license_max_users_count - license_users_count
      : 0

  const isCustomerAdmin = role === 'customer_admin'

  const dispatch = useDispatch()

  const theme = useTheme()
  const { t } = useTranslation()

  const handleCopy = () => {
    copyToClipboard(invite_token)
    dispatch(addSimpleNotification('notification.product_key_copied'))
  }

  const handleCopyInviteLink = () => {
    invite_link && copyToClipboard(invite_link)
    dispatch(addSimpleNotification('notification.invite_link_copied'))
  }

  const handleToggleAccess = () => {
    dispatch(
      licenseToggleAccess({
        isCurrentCustomer: true,
        id: license_id,
        is_active: !license_is_active,
      })
    )
  }

  if (loading) {
    return (
      <SkeletonWrapper marginTop={isCustomerAdmin ? '1.5rem' : '0.75rem'}>
        <SkeletonComponent width="100%" height="15.125rem" variant="grey" />
      </SkeletonWrapper>
    )
  }

  return (
    <HeaderWrapper marginTop={isCustomerAdmin ? '1.5rem' : '0.75rem'}>
      <ImgWrapper>
        <img
          src={moleculesPng}
          alt="molecules-background"
          loading="lazy"
          height="100%"
          style={{ opacity: 0.5 }}
        />
      </ImgWrapper>
      <HeaderItems>
        <HeaderTitle>
          <Tags>
            <CustomTag
              width="fit-content"
              type={
                !isLicenseActive
                  ? 'score_minus'
                  : license_is_free_trial
                  ? 'accent_secondary'
                  : 'accent'
              }
            >
              <Caption color="inherit">
                {t(
                  `admin.${
                    !isLicenseActive
                      ? 'not_active'
                      : license_is_free_trial
                      ? 'test_period'
                      : 'license'
                  }`
                )}
              </Caption>
            </CustomTag>
            {license_max_users_count !== null && (
              <CustomTag
                width="fit-content"
                type={licensesLeftCount ? 'accent_secondary' : 'score_minus'}
                withBorder={false}
              >
                <Caption color="inherit">
                  {`${licensesLeftCount} ${getPlacesLeftTitle(
                    licensesLeftCount
                  )}`}
                </Caption>
              </CustomTag>
            )}
          </Tags>
          <TitlePrimary ellipsis>{customer_name}</TitlePrimary>
          <CompanyNumber>
            <Label fontWeigth={500} color={theme.colors.text.primary}>
              {t('registration.product_key')}
            </Label>
            <NumberWrapper>
              {invite_token ? (
                <>
                  <Label color="inherit">{invite_token}</Label>
                  <CustomButton
                    type="icon_secondary"
                    bgColor={theme.colors.backgrounds.primary}
                    defaultPadding={'0rem'}
                    height="1rem"
                    width="1rem"
                  >
                    <Icon
                      iconType="copy"
                      size={'1rem'}
                      dataTest="company-card-copy-btn"
                      onClick={handleCopy}
                    />
                  </CustomButton>
                </>
              ) : (
                <Label color={theme.colors.text.primary}>—</Label>
              )}
            </NumberWrapper>
          </CompanyNumber>
        </HeaderTitle>
        <HeaderInfo>
          <InfoItem>
            <Label name="ellipsis">
              {customer_admin ? customer_admin : '—'}
            </Label>
            <Caption ellipsis color={theme.colors.text.secondary}>
              {t('admin.admin')}
            </Caption>
          </InfoItem>
          <InfoItem>
            <Label name="ellipsis">
              {customer_domain ? customer_domain : '—'}
            </Label>
            <Caption color={theme.colors.text.secondary}>
              {t('admin.domain')}
            </Caption>
          </InfoItem>
          <InfoItem>
            <Label>
              {license_max_users_count !== null ? license_max_users_count : '—'}
            </Label>
            <Caption color={theme.colors.text.secondary}>
              {t('admin.numberInLicense')}
            </Caption>
          </InfoItem>
          <InfoItem>
            <Label>{license_users_count ? license_users_count : '—'}</Label>
            <Caption color={theme.colors.text.secondary}>
              {t('admin.registered')}
            </Caption>
          </InfoItem>
          <InfoItem>
            <Label color="inherit">
              {getLicenseDate(license_started_at, license_expired_at)}
            </Label>
            <Caption color={theme.colors.text.secondary}>
              {t('admin.periodOfValidity')}
            </Caption>
          </InfoItem>
        </HeaderInfo>
        <ButtonsBlock>
          {!isCustomerAdmin && (
            <CustomButton
              type="secondary"
              gap="4px"
              onClick={() => setEditOpen(true)}
            >
              <Icon iconType="pen" size="1rem" />
              {t('admin.edit')}
            </CustomButton>
          )}
          {!isCustomerAdmin && (
            <CustomButton type="secondary" gap="4px" disabled={true}>
              <Icon iconType="add" size="1rem" />
              {t('admin.addUser')}
            </CustomButton>
          )}
          <CustomButton
            type="secondary"
            gap="4px"
            onClick={handleCopyInviteLink}
            disabled={!invite_link}
          >
            <Icon iconType="share" size="1rem" />
            {t('admin.inviteLink')}
          </CustomButton>
          {!isCustomerAdmin && (
            <CustomButton
              type="secondary"
              gap="4px"
              onClick={handleToggleAccess}
            >
              <Icon
                iconType={license_is_active ? 'eyeClose' : 'eyeOpen'}
                size="1rem"
              />
              {t(
                `admin.${license_is_active ? 'limit_access' : 'allow_access'}`
              )}
            </CustomButton>
          )}
        </ButtonsBlock>
      </HeaderItems>
      {editOpen && (
        <EditCompanyDialog
          onClose={() => {
            setEditOpen(false)
          }}
          company={currentCustomer.item}
          setEditOpen={setEditOpen}
        />
      )}
    </HeaderWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    currentCustomer: state.adminPanel.currentCustomer,
  }
}

export default connect(mapStateToProps)(CompanyHeader)
