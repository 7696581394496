import {
  GET_STATISTICS_INIT,
  SELECT_STATISTICS_CHART_SECTION,
  SET_STATISTICS_NAME_LIST,
  SET_STATISTICS_FILTER_TYPE,
  CLEAR_STATISTICS_FILTER,
  SET_STATISTICS_FILTER,
  SET_SUB_SECTION,
  SET_STATISTICS_LOADING,
} from 'store/constants/statistics'

export const getStatistics = () => {
  return {
    type: GET_STATISTICS_INIT,
  }
}

export const selectChartSection = ({ value }) => {
  return {
    type: SELECT_STATISTICS_CHART_SECTION,
    value,
  }
}
export const setStatisticsNameList = (name_list) => {
  return {
    type: SET_STATISTICS_NAME_LIST,
    name_list,
  }
}

export const handleFilterType = (filter_type) => {
  return {
    type: SET_STATISTICS_FILTER_TYPE,
    filter_type,
  }
}

export const clearStatisticsFilter = () => {
  return {
    type: CLEAR_STATISTICS_FILTER,
  }
}

export const setStatisticsFilter = ({ key, value }) => {
  return {
    type: SET_STATISTICS_FILTER,
    key,
    value,
  }
}

export const setStatisticsSubSection = (id) => {
  return {
    type: SET_SUB_SECTION,
    id,
  }
}
export const setStatisticsLoading = ({ value }) => {
  return {
    type: SET_STATISTICS_LOADING,
    value,
  }
}
