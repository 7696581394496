import { TextBody } from 'components/common/text/index.style'
import styled from 'styled-components'

export const TextNumber = styled(TextBody)`
  color: #42c1bf;

  & a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;

  & div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`

export const TabsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;

  & div {
    display: flex;
    gap: 8px;
  }
`

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
