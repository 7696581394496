import { Chart } from 'react-google-charts'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import { fetch } from '../../services/rest'
import { ListBlock, UsersContainer } from './index.style'
import {
  formatUtcDigitDate,
  formatUtcShortDate,
} from 'utils/dateTime/formatUtcDate'

const AdminUsersView = () => {
  const [list, setList] = useState([])
  const [stat, setStat] = useState([])
  const [usersCount, setUsersCount] = useState(null)

  const { t } = useTranslation()

  // eslint-disable-next-line no-unused-vars
  const loadData = async (offset = 0, limit = 100) => {
    try {
      const { data } = await fetch(`/admin/users`)
      setList(data)
    } catch (e) {
      console.log(e)
    }
  }

  const loadStatistic = async () => {
    try {
      const { data } = await fetch(`/profile/statistic`)

      const formed = data.map(({ day, users }) => [new Date(day), +users])
      setStat([['date', 'users'], ...formed])
    } catch (e) {
      console.log(e)
    }
  }

  const loadUsersCount = async () => {
    try {
      const { data } = await fetch(`/admin/users-count`)
      setUsersCount(data.count)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadStatistic()
    loadData()
    loadUsersCount()
  }, [])

  return (
    <UsersContainer>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {stat.length > 0 && (
          <Chart
            width={'500px'}
            height={'200px'}
            chartType="LineChart"
            loader={<div>{t('admin.loading')}</div>}
            data={stat}
            options={{
              hAxis: {
                format: 'dd.MM.yyyy',
              },
              legend: 'none',
              vAxis: {
                title: t('admin.newUsers'),
                minValue: 0,
              },
            }}
          />
        )}
        {usersCount && (
          <div style={{ fontWeight: '800', margin: '0 1.5rem' }}>
            {t('admin.usersCount')} {usersCount}
          </div>
        )}
      </div>
      <ListBlock>
        <div style={{ fontWeight: '800' }}>{t('admin.name')}</div>
        <div style={{ fontWeight: '800' }}>{t('admin.email')}</div>
        <div style={{ fontWeight: '800' }}>{t('admin.company')}</div>
        <div style={{ fontWeight: '800' }}>{t('admin.position')}</div>
        <div style={{ fontWeight: '800' }}>{t('admin.baskets')}</div>
        <div style={{ fontWeight: '800' }}>{t('admin.molecules')}</div>
        <div style={{ fontWeight: '800' }}>{t('admin.pdfs')}</div>
        <div style={{ fontWeight: '800' }}>{t('admin.regDate')}</div>
        <div style={{ fontWeight: '800' }}>{t('admin.lastEnter')}</div>
        {list.map(
          ({
            id,
            name,
            email,
            company,
            position,
            date_register,
            date_last_enter,
            is_activated,
            baskets,
            molecules,
            pdfs,
          }) => (
            <React.Fragment key={id}>
              <div style={{ color: is_activated ? 'green' : 'red' }}>
                {name}
              </div>
              <div>{email}</div>
              <div>{company}</div>
              <div>{position}</div>
              <div>{baskets}</div>
              <div>{molecules}</div>
              <div>{pdfs}</div>
              <div>{formatUtcShortDate(new Date(date_register))}</div>
              <div>{formatUtcDigitDate(new Date(date_last_enter))}</div>
            </React.Fragment>
          )
        )}
      </ListBlock>
    </UsersContainer>
  )
}

export default AdminUsersView
