import { useTranslation } from 'react-i18next'
import { SmartCaptcha } from '@yandex/smart-captcha'

import { SITE_KEY } from './config/config'
import { CaptchaContainer } from './index.style'
import InfoWithProgress from '../InfoWithProgress'

const SmartCaptchaComp = ({ onCompleteCaptcha, scale = 0.8 }) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <InfoWithProgress
        showProgress={false}
        title={t('registration.confirm_not_a_robot')}
        description={t('registration.providing_quality_services')}
        additionalDescription={t('registration.protection')}
      />
      <CaptchaContainer scale={scale}>
        <SmartCaptcha
          language={i18n.language}
          sitekey={SITE_KEY}
          onSuccess={onCompleteCaptcha}
        />
      </CaptchaContainer>
    </>
  )
}

export default SmartCaptchaComp
