import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'

import SkeletonComponent from 'components/common/skeleton'

import CompanyCard from '../companyCard'
import { AdminCardsWrapper, Cards } from './index.style'

const Customers = ({ items, getItemsLoading, getItemsError, pagination }) => {
  return (
    <AdminCardsWrapper
      marginBottom={pagination.pagesAmount > 1 ? '4.25rem' : '0.75rem'}
    >
      <Cards>
        {getItemsLoading &&
          Array.from({ length: 12 }).map((_, i) => (
            <SkeletonComponent
              height="9rem"
              width="100%"
              variant="dark"
              key={`skeleton-index-${i}`}
            />
          ))}
        {!!items.length &&
          !getItemsLoading &&
          !getItemsError &&
          items.map((company) => (
            <CompanyCard key={uuidv4()} company={company} />
          ))}
      </Cards>
    </AdminCardsWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    items: state.adminPanel.customers.items,
    getItemsLoading: state.adminPanel.customers.loading,
    getItemsError: state.adminPanel.customers.error,
    pagination: state.adminPanel.customers.pagination,
  }
}

export default connect(mapStateToProps)(Customers)
