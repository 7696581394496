import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Buttons, Fields, Names, Wrapper } from './index.style'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import { update } from 'services/rest'
import { JOB_CONFIG, NAMES_CONFIG } from '../config/config'
import { loadMe } from 'store/actions/auth'
import { addNotification } from 'store/actions/notifications'

const PersonalDataSection = memo(
  ({ userdata, openDialog, loadMe, addNotification }) => {
    const { t } = useTranslation()

    const { name, surname, position, company } = userdata
    const [data, setData] = useState({
      name,
      surname,
      position,
      company,
    })
    const [isDataChanged, setIsDataChanged] = useState(false)

    const confirm = async () => {
      try {
        await update('/profile', data)
        loadMe()
        openDialog({
          type: 'success',
          title: 'user_account.dialog.personal_data_successfully_changed',
          btnText: 'user_account.dialog.ok',
        })
        setIsDataChanged(false)
      } catch (e) {
        const id = uuidv4()
        const notify = {
          id,
          name: 'user_account.personal_data_section.failed_to_save_changes',
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
        }
        addNotification(notify)
      }
    }

    const cancelChanges = () => {
      setIsDataChanged(false)
      setData({
        name,
        surname,
        position,
        company,
      })
    }

    const isConfirmDisabled = !(
      data &&
      data?.name &&
      data.surname &&
      data?.company &&
      data?.position
    )

    const saveChangesBtnsConfig = [
      {
        icon: 'tick',
        onClick: confirm,
        text: 'user_account.personal_data_section.confirm',
        disabled: isConfirmDisabled,
        type: 'accent',
      },
      {
        icon: 'back',
        onClick: cancelChanges,
        text: 'user_account.personal_data_section.cancel_changes',
        type: 'secondary',
      },
    ]

    return (
      <Wrapper>
        <Fields>
          <Names>
            {NAMES_CONFIG.map(({ value, placeholder }) => (
              <CustomInput
                key={value}
                label={t(`user_account.personal_data_section.${value}`)}
                value={data?.[value] ?? ''}
                onChange={(v) => {
                  setData({
                    ...data,
                    [value]: v,
                  })
                  setIsDataChanged(true)
                }}
                placeholder={t(
                  `user_account.personal_data_section.${placeholder}`
                )}
                withClearButton
                warning={!!data?.[value]?.lenght}
              />
            ))}
          </Names>
          {JOB_CONFIG.map(({ value, placeholder }) => (
            <CustomInput
              key={value}
              label={t(`user_account.personal_data_section.${value}`)}
              value={data?.[value] ?? ''}
              onChange={(v) => {
                setData({
                  ...data,
                  [value]: v,
                })
                setIsDataChanged(true)
              }}
              placeholder={t(
                `user_account.personal_data_section.${placeholder}`
              )}
              withClearButton
              warning={!!data?.[value]?.lenght}
            />
          ))}
        </Fields>
        {isDataChanged && (
          <Buttons>
            {saveChangesBtnsConfig.map(
              ({ icon, onClick, text, disabled, type }) => (
                <CustomButton
                  key={icon}
                  type={disabled ? 'secondary' : type}
                  gap={'0.25rem'}
                  onClick={onClick}
                  disabled={disabled}
                >
                  <Icon iconType={icon} size={'1rem'} />
                  <span>{t(text)}</span>
                </CustomButton>
              )
            )}
          </Buttons>
        )}
      </Wrapper>
    )
  }
)

PersonalDataSection.displayName = 'PersonalDataSection'

const mapStateToProps = (state) => {
  return {
    userdata: state.auth.userdata,
  }
}

const mapDispatchToProps = { loadMe, addNotification }

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataSection)
