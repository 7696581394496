export const NO_DATA_VALUE = -0.01
export const STATISTICS_SELECT_OPTIONS = [
  { id: 1, value: 'visits', label: 'admin_statistics.visits_amount' },
  { id: 2, value: 'search', label: 'admin_statistics.search_queries_amount' },
  {
    id: 3,
    value: 'section_comparison',
    label: 'admin_statistics.calculation_amount',
  },
  {
    id: 4,
    value: 'global_comparison',
    label: 'admin_statistics.module_queries_amount',
  },
]

export const STATISTICS_FILTER_TYPES = [
  {
    id: 1,
    name: 'admin_statistics.users',
    value: 'users',
  },
  {
    id: 2,
    name: 'admin_statistics.customers',
    value: 'customers',
  },
]

export const NAME_LIST_BY_TYPE = {
  1: ['auth'],
  2: [
    'advanced_text_search',
    'bingo_search',
    'full_text_search',
    'molecules_search',
    'reaction_compound_get',
  ],
  3: [
    ['synmap'], //синмап
    //[расчет прогнозируемых свойств],
    ['calc_basket'], //расчет датасета
    ['apply_transformer', 'reactions_tree'], // прогнозирование реакций
    ['ir_spectr', 'nmr-hosegen', 'predict_cfmid'], // спектры
    ['price_retro'], //стоимость синтеза
    ['pdf2smiles_create_document_post'], //pdf2smiles
    ['iupacConverter'], //smiles2iupac
    ['plugins_statistic'], //статистика по плагинам
  ],
  4: [
    'advanced_text_search',
    'bingo_search',
    'full_text_search',
    'molecules_search',
    'reaction_compound_get',
    'calc_basket',
    'synmap',
    'apply_transformer',
    'reactions_tree',
    'ir_spectr',
    'nmr-hosegen',
    'predict_cfmid',
    'price_retro',
    'pdf2smiles_create_document_post',
    'iupacConverter',
    'plugins_statistic',
  ],
}

export const COLORS_CONFIG = {
  0: '#3495FF',
  1: '#98E8FA',
  2: '#FF84A9',
  3: '#B8FF71',
  4: '#12B76A',
  5: '#F79009',
  6: '#613CB0',
  7: '#CFCFCF',
  8: '#74564A',
}

export const user_statuses = [
  {
    id: 1,
    label: 'admin_statistics.full_license',
    value: false,
  },
  { id: 2, label: 'admin_statistics.trial_license', value: true },
]

export const subSectionsConfig = [
  { id: 0, label: 'admin_statistics.labels.synmap' },
  { id: 1, label: 'admin_statistics.labels.datasets' },
  { id: 2, label: 'admin_statistics.labels.prediction' },
  { id: 3, label: 'admin_statistics.labels.spectra' },
  { id: 4, label: 'admin_statistics.labels.synth_cost' },
  { id: 5, label: 'admin_statistics.labels.pdf2smiles' },
  { id: 6, label: 'admin_statistics.labels.smiles2iupac' },
  { id: 7, label: 'admin_statistics.labels.plugins_statistic' },
]
