import styled from 'styled-components'

export const Container = styled.div`
  gap: 1.75rem;
  display: flex;
  flex-direction: column;
`

export const TitleWithDescription = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  & > p {
    white-space: pre-line;
  }
`
export const Descriptions = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`
