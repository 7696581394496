export const FREEREACTIONS_MAX = 7
export const NAUG = 0
export const BEAM = 5
export const MODEL_PARAM = 'compile_small_direct'

export const REACTION_SWITCH = [
  {
    title: 'reactions_prediction.forward',
    type: 'forward',
  },
  {
    title: 'reactions_prediction.reverse',
    type: 'reverse',
  },
]