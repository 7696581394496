import {
  GET_SURVEYS_INIT,
  GET_USER_ANSWERS_INIT,
  RESET_USER_ANSWERS,
} from 'store/constants/survey'

export const getSurvey = (onRegistration, offset, limit) => {
  return {
    type: GET_SURVEYS_INIT,
    onRegistration,
    offset,
    limit,
  }
}

export const getUsersAnswers = ({ user_id, survey_id }) => {
  return {
    type: GET_USER_ANSWERS_INIT,
    user_id,
    survey_id,
  }
}

export const resetUsersAnswers = () => {
  return {
    type: RESET_USER_ANSWERS,
  }
}
