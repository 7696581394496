import styled from 'styled-components'

export const FiltersContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  border-radius: 0.75rem;
  padding: 1rem;
  gap: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadow.below};
  margin-top: 1.5rem;
`
export const LeftBlock = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.75rem;
`
export const RightBlock = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const FiltersRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.75rem;
`
export const InputWithLabel = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 50%;
`
