export const searchTypes = [
  {
    name: 'search.structure',
    value: 'structure',
  },
  {
    name: 'search.reaction',
    value: 'reaction',
  },
  {
    name: 'search.literature',
    value: 'literature',
  },
]

const searchSmileSize = {
  width: 60,
  height: 32,
}

const datasetSmileSize = {
  width: 52,
  height: 24,
}

const searchSmileSizeLarge = {
  width: 164,
  height: 164,
}

const datasetSmileSizeLarge = {
  width: 148,
  height: 148,
}

export const smileSizeRight = {
  width: 238,
  height: 238,
}

export const getSmileSize = (isStandartSearchBar) =>
  isStandartSearchBar ? searchSmileSize : datasetSmileSize

export const getSmileSizeLarge = (isStandartSearchBar) =>
  isStandartSearchBar ? searchSmileSizeLarge : datasetSmileSizeLarge

export const isNumeric = (value) => {
  return /^\d+$/.test(value)
}
