import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { openFeedbackForm } from 'store/actions/feedback'
import InformationBlock from 'components/LoginPage/InformationBlock'
import { themeOptions } from 'components/Feedback/FeedbackForm/config'
import { Caption, TextMedium } from 'components/common/text/index.style'

import { Footer } from './index.style'
import { getInfoConfig } from './utils/utils'

const TrialPeriodInfo = ({ licenseError }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { title, description, description2, iconType } = getInfoConfig(
    licenseError.error_data
  )

  const handleOpenFeedbackForm = () =>
    dispatch(openFeedbackForm(themeOptions[1]))

  return (
    <>
      <InformationBlock {...{ iconType, title, description, description2 }} />
      <Footer>
        <TextMedium
          fontWeight={theme.fontWeight.light}
          color={theme.colors.text.secondary}
        >
          {t('account.if_error')}
        </TextMedium>
        <CustomButton
          type="text"
          gap="0.25rem"
          onClick={handleOpenFeedbackForm}
        >
          <Icon iconType="telegram" size="1rem" />
          {t('account.write')}
        </CustomButton>
        <Caption
          fontWeight={theme.fontWeight.medium}
          color={theme.colors.text.tertiary}
        >
          {t('account.copyRight').toUpperCase()}
          {new Date().getFullYear()}
        </Caption>
      </Footer>
    </>
  )
}

export default TrialPeriodInfo
