import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Content } from './index.style'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'

const EditDialog = ({ close, closeWithSave, name }) => {
  const [valueName, setName] = useState(name)
  const { t } = useTranslation()

  const save = () => {
    closeWithSave(valueName)
  }

  const saveByEnter = (e) => {
    if (e.key === 'Enter' && valueName) {
      save()
    }
  }

  return (
    <CustomDialog width={'372px'} onClose={close}>
      <CustomDialogHeader>
        {t('pdf2smiles.dashboard.edit_dialog.document_editing')}
      </CustomDialogHeader>
      <Content>
        <CustomInput
          onChange={setName}
          value={valueName}
          label={t('pdf2smiles.dashboard.edit_dialog.name')}
          placeholder={t(
            'pdf2smiles.dashboard.edit_dialog.name_of_your_document'
          )}
          withClearButton={true}
          onKeyUp={saveByEnter}
          autoFocus={true}
        />
      </Content>
      <CustomButton
        width={'100%'}
        type="accent"
        disabled={!valueName}
        onClick={!valueName ? () => {} : save}
      >
        {t('pdf2smiles.dashboard.edit_dialog.save')}
      </CustomButton>
    </CustomDialog>
  )
}

export default EditDialog
