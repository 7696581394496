import styled, { css } from 'styled-components'

export const CustomTabElement = styled.div`
  display: flex;
  width: ${({ width }) => {
    if (width) return width
    return 'auto'
  }};
  height: ${({ size, height }) => {
    if (height) return height
    if (size === 'standard') return '2.25rem'
    return '1.75rem'
  }};
  padding: ${({ size }) => {
    if (size === 'standard') return '0.625rem 0.75rem'
    if (size === 'small') return '0.375rem 0.625rem'
    return '0.375rem' //xs
  }};
  justify-content: center;
  align-items: center;
  border-radius: ${({ size }) => {
    if (size === 'standard') return '0.75rem'
    return '0.5rem'
  }};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease;
  gap: 0.25rem;

  font-size: ${({ theme }) => theme.text[12]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 16px;

  color: ${({ disabled, isActive, type, theme }) => {
    if (disabled) return theme.colors.text.tertiary
    if (type === 'search' || type === 'white-no-bg')
      return isActive
        ? theme.colors.text.accentPrimary
        : theme.colors.text.secondary
    if (type === 'white')
      return isActive ? theme.colors.text.white : theme.colors.text.secondary
    if (type === 'grey')
      return isActive ? theme.colors.text.white : theme.colors.text.secondary
    return isActive ? '#FFFFFF' : '#616E7C'
  }};

  background-color: ${({ type, isActive, theme }) => {
    if (type === 'search')
      return isActive
        ? theme.colors.backgrounds.accentTertiary
        : theme.colors.buttons.white
    if (type === 'white')
      return isActive
        ? theme.colors.buttons.accent
        : theme.colors.buttons.primary
    if (type === 'white-no-bg') return theme.colors.buttons.white
    if (type === 'grey')
      return isActive
        ? theme.colors.buttons.accent
        : theme.colors.buttons.secondary
    if (isActive) return '#42c1bf'
    return theme.colors.buttons.secondary
  }};

  &:hover {
    ${({ disabled, isActive }) => {
      if (disabled || isActive) return

      return css`
        color: #42c1bf;
      `
    }}
  }

  &:active {
    ${({ disabled, type, theme }) => {
      if (disabled || type === 'white-no-bg') return
      if (type === 'search')
        return css`
          background-color: ${theme.colors.backgrounds.accentTertiary};
          color: ${theme.colors.text.accentPrimary};
        `

      return css`
        background-color: ${theme.colors.buttons.accent};
        color: ${theme.colors.text.white};
      `
    }}
  }
`
