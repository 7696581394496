export const SORT_TYPE_CONFIG = [
  {
    label: 'search.sortTypes.relevance',
    value: 'relevance',
  },
  {
    label: 'search.sortTypes.structures',
    value: 'structures',
  },
  // {
  //   label: 'search.sortTypes.registrationNumber',
  //   value: 'registration number',
  // },
  {
    label: 'search.sortTypes.date',
    value: 'date',
  },
]

export const litListViewOptions = {
  left: {
    title: 'left',
    value: 'left',
    icon: 'singleBlocks',
  },
  center: {
    title: 'center',
    value: 'center',
    icon: 'largeBlocks',
  },
}

export const valueSelector = {
  left: 'list',
  center: 'brick',
}
