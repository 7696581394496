import styled, { css } from 'styled-components'
import CustomButton from 'components/common/customButton'

export const FloatingFilterWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
`

export const FilterBtn = styled(CustomButton)`
  width: 2.25rem !important;
  svg {
    min-width: 1.25rem;
  }
  color: ${({ theme, isFilterActive }) =>
    isFilterActive
      ? theme.colors.icons.accentPrimary
      : theme.colors.icons.tertiary};

  ${({ theme, isFilterActive }) =>
    isFilterActive &&
    css`
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        z-index: 2;
        background-color: ${theme.colors.backgrounds.accent};
      }
    `}
`
