import { t } from 'i18next'
import { getIsLicenseActive } from '../../../../utils/licenses/getLicenseNotificationText'


export const getName = (name, surname) => {
  if (name && surname) {
    return name.concat(' ', surname)
  }
  if (!name && surname) return surname
  if (name && !surname) return name
  return t('admin.no_name')
}

export const getApplicationStatus = (isApproved) => {
  if (isApproved) return 'admin.application_approved'
  else if (isApproved === false) return 'admin.application_rejected'
  return 'admin.application'
}

export const getUserStatus = (card) => {
  const {
    is_activated,
    license_is_active,
    license_request_id,
    license_request_is_handled,
    license_is_free_trial,
  } = card

  if (!is_activated || !getIsLicenseActive(card)) return 'admin.not_active'

  if (license_is_active)
    return license_is_free_trial ? 'admin.test_period' : 'admin.license'

  if (license_request_id) {
    // trial period application under consideration
    if (license_request_is_handled === null)
      return 'admin.user_application_under_consideration'
    // trial period application is rejected
    if (license_request_is_handled === false)
      return 'admin.user_application_rejected'
  }

  return 'admin.user_no_license'
}
