import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0.75rem')};
`
export const NumberWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

export const ImgWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const HeaderItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 1.25rem;
  width: 100%;
`

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 80%;
  z-index: 3;
`

export const Tags = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const CompanyNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.text.accentPrimary};
  cursor: pointer;
`
export const HeaderInfo = styled.div`
  display: flex;
  gap: 1.25rem;
`

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 20%;
  min-width: 10%;
  overflow: hidden;
  z-index: 3;
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  z-index: 3;
`

export const SkeletonWrapper = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0.75rem')};
`
