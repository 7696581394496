import styled, { css } from 'styled-components'
import { SMILES_SIZE } from './config/config'

export const BestSmilesViewContainer = styled.div`
  max-width: ${SMILES_SIZE}px;
  max-height: ${SMILES_SIZE}px;
  width: 100%;
  height: 100%;
  border: ${({ theme }) => `1.2px solid ${theme.colors.backgrounds.separator}`};
  border-radius: 0.5rem;
  canvas {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
  }
`

export const Data = styled.div`
  width: 100%;
  height: 100%;
  gap: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const Content = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`
export const Properties = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  min-width: 10rem;
`
export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.75rem;
  padding-left: 0;
  overflow: auto;
  ${({ isLeftPanelOpen }) =>
    isLeftPanelOpen &&
    css`
      ${Content} {
        @media (max-width: 1000px) {
          width: 100%;
        }
      }
      ${Data} {
        @media (max-width: 1000px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    `}
`
