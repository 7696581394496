import { useTranslation } from 'react-i18next'
import {
  FiltersInnerContainer,
  FiltersOuterContainer,
  FiltersRow,
  InputWithLabel,
  LeftBlock,
  RightBlock,
} from './index.style'
import './index.css'
import { useDispatch, useSelector } from 'react-redux'
import { memo, useRef, useState } from 'react'
import { Caption, Label } from 'components/common/text/index.style'
import CustomSelect from 'components/CustomSelect'
import {
  InputWrapper,
  ButtonsBlock,
} from 'pages/AdminPanelPage/editCompanyDialog/index.style'
import CustomInput from 'components/common/customInput'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'
import RangeCalendar from 'pages/AdminPanelPage/rangeCalendar'
import { useTheme } from 'styled-components'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import {
  SearchType,
  SearchTypes,
} from 'components/main-search-bar/style/index.style'
import { STATISTICS_FILTER_TYPES, user_statuses } from '../config'
import CustomTab from 'components/common/customTab'
import {
  clearStatisticsFilter,
  getStatistics,
  handleFilterType,
  setStatisticsFilter,
} from 'store/actions/statistics'
import InputHelper from 'components/InputHelper'
import { getEndOfPeriod, getStartOfPeriod } from 'store/sagas/utils/utils'

const StatisticsFilters = memo(({ filter_type }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const licence_status = useSelector(
    (state) => state.statistics.filter_by.license_is_free_trial
  )

  const selected_modules = useSelector((state) => state.feedback.filter.modules)
  const date = useSelector((state) => state.feedback.filter.date)
  const created_at_from = useSelector(
    (state) => state.statistics.filter_by.created_at_from
  )

  const created_at_to = useSelector(
    (state) => state.statistics.filter_by.created_at_to
  )
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const inputHelperRef = useRef(null)
  const isUserFilter = filter_type === 'users'

  /** METHODS - START */
  const handlePointerIn = () => setShowOptions(true)
  const handlePointerOut = () => setShowOptions(false)

  const handleToggleCalendar = () => {
    setCalendarOpen((prev) => !prev)
  }
  const handleFilter = ({ key, value }) => {
    dispatch(setStatisticsFilter({ key, value }))
  }

  const setCalendarValues = (values) => {
    handleFilter({
      key: 'created_at_from',
      value: getStartOfPeriod(values?.[0], 'day').split('T')[0],
    })
    handleFilter({
      key: 'created_at_to',
      value: getEndOfPeriod(values?.[1], 'day').split('T')[0],
    })
  }
  /** METHODS - END */

  return (
    <FiltersOuterContainer>
      <SearchTypes>
        {STATISTICS_FILTER_TYPES?.map(({ id, name, value }) => {
          return (
            <SearchType key={id}>
              <CustomTab
                isActive={filter_type === value}
                onClick={() => {
                  dispatch(clearStatisticsFilter())
                  dispatch(handleFilterType(value))
                  dispatch(getStatistics())
                }}
                type={'white-no-bg'}
                size={'xs'}
                width="8rem"
              >
                {t(name)}
              </CustomTab>
            </SearchType>
          )
        })}
      </SearchTypes>
      <FiltersInnerContainer>
        <LeftBlock>
          <FiltersRow>
            <InputWithLabel>
              <Caption color={theme.colors.text.secondary}>
                {t('admin_statistics.status')}
              </Caption>
              <CustomSelect
                emptyLabel={t('common.not_selected')}
                selectedValue={{
                  value: user_statuses.find(
                    (el) => el?.value === licence_status
                  )?.id,
                  label: user_statuses.find(
                    (el) => el?.value === licence_status
                  )?.label,
                }}
                onChange={(option) => {
                  handleFilter({
                    key: 'license_is_free_trial',
                    value: option?.value,
                  })
                }}
                options={user_statuses}
                withTranslation={true}
                width="100%"
              />
            </InputWithLabel>

            <ButtonsBlock>
              <InputWrapper onClick={handleToggleCalendar}>
                <CustomInput
                  label={t('admin_statistics.period')}
                  pseudoBeforeText={t('filter.from_rus')}
                  placeholder={t('filter.from_rus')}
                  gap="0.375rem"
                  className="statics-calendar-input"
                  value={
                    created_at_from
                      ? formatUtcDigitDateByFormat(
                          new Date(created_at_from),
                          'dd.MM.yyyy'
                        )
                      : ''
                  }
                  readOnly
                />
              </InputWrapper>
              <InputWrapper onClick={handleToggleCalendar}>
                <CustomInput
                  label={' '}
                  className="statics-calendar-input"
                  pseudoBeforeText={t('filter.to_rus')}
                  gap="0.375rem"
                  width="100%"
                  value={
                    created_at_to
                      ? formatUtcDigitDateByFormat(
                          new Date(created_at_to),
                          'dd.MM.yyyy'
                        )
                      : ''
                  }
                  placeholder={t('filter.to_rus')}
                  readOnly
                />
              </InputWrapper>
            </ButtonsBlock>
          </FiltersRow>
          <FiltersRow className="email-sort-row">
            <InputHelper
              ref={inputHelperRef}
              onAdd={(id) => {
                handleFilter({
                  key: isUserFilter ? 'user_id_list' : 'customer_id_list',
                  value: [id],
                })
              }}
              showOptions={showOptions}
              onFocus={handlePointerIn}
              onBlur={handlePointerOut}
              url={isUserFilter ? 'user/get' : 'customer/get'}
              placeholder={t(
                `admin_statistics.${
                  isUserFilter ? 'enter_user_email' : 'enter_customer_name'
                }`
              )}
            />
          </FiltersRow>
        </LeftBlock>
        <RightBlock justify="end">
          <FiltersRow>
            <CustomButton
              onClick={() => {
                dispatch(clearStatisticsFilter())
                dispatch(getStatistics())
                inputHelperRef.current?.clearInput &&
                  inputHelperRef.current.clearInput()
              }}
              type="secondary"
              width="7rem"
              minWidth="5rem"
              gap="0.25rem"
              defaultColor="red"
            >
              <Label color={theme.colors.text.primary} ellipsis={true}>
                {t('spectra.buttons.reset')}
              </Label>
              <Icon
                iconType="deleted"
                size="1rem"
                style={{ minWidth: '1rem', color: theme.colors.text.primary }}
              />
            </CustomButton>
            <CustomButton
              onClick={() => {
                dispatch(getStatistics())
              }}
              type="accent"
              width="10rem"
              minWidth="5rem"
              gap="0.25rem"
            >
              <Label color={theme.colors.text.white} ellipsis={true}>
                {t('admin.filter.set_filter')}
              </Label>
              <Icon
                iconType="arrowRight"
                size="1rem"
                style={{ minWidth: '1rem' }}
              />
            </CustomButton>
          </FiltersRow>
        </RightBlock>

        {calendarOpen && (
          <RangeCalendar
            values={date}
            setValues={setCalendarValues}
            onClose={() => {
              setCalendarOpen(false)
            }}
            title="admin_statistics.select_period"
            align="center"
          />
        )}
      </FiltersInnerContainer>
    </FiltersOuterContainer>
  )
})
StatisticsFilters.displayName = 'StatisticsFilters'
export default StatisticsFilters
