import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Fragment, memo, useCallback } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import { Caption } from 'components/common/text/index.style'

import MoleculeViewer from '../MoleculeViewer'
import { reactionDecode } from '../../utils/reactions/reactions'
import {
  Label,
  Container,
  ReactBlock,
  ArrowBlock,
  ReactionBlock,
  ReactionOperatorIcon,
  AgentsBlock,
  AgentElement,
  AgentHiddenBlock,
  AgentText,
  YieldBlock,
  Score,
  ScoreTag,
} from './index.style'
import { getScore, getScoreBackground } from './utils/utils'
import ScoreHotTip from 'pages/ReactionListNew/components/Synthesis/ScoreHotTip'

const ReactionViewer = ({
  id,
  data,
  size,
  selected,
  handleOpen,
  handleSelect,
  isAddDialogOpen,
  woAdditionalBorder,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const score = getScore(data.score)
  const background = theme.colors.statuses[getScoreBackground(score)]

  const voidClick = useCallback(() => {
    console.log('nothing')
  }, [])

  const handleReactionClick = (e) => {
    if (e.ctrlKey || e.metaKey) {
      handleSelect(id)
    } else {
      handleOpen(id)
    }
  }

  const uniqueAgent = (arr) => {
    let result = []
    if (arr.length > 0) {
      let idsSet = new Set(arr.map((e) => e.structure_id))
      result = arr.filter(({ structure_id }) => {
        if (idsSet.has(structure_id)) {
          idsSet.delete(structure_id)
          return true
        }
        return false
      })
    }
    return result
  }

  const left = data.elements
    ? data.elements.filter((e) => e.role === 'reactant').map((e) => e.smiles)
    : data.encoded
    ? reactionDecode(data.encoded).left
    : []
  const right = data.elements
    ? data.elements.filter((e) => e.role === 'product').map((e) => e.smiles)
    : data.encoded
    ? reactionDecode(data.encoded).right
    : []
  let catalyst =
    (data.elements && data.elements.filter((e) => e.role === 'catalyst')) || []
  catalyst = uniqueAgent(catalyst)
  let solvent =
    (data.elements && data.elements.filter((e) => e.role === 'solvent')) || []
  solvent = uniqueAgent(solvent)

  const reaction = { left, right }

  return (
    <ReactionBlock
      woAdditionalBorder={woAdditionalBorder}
      noTopPadding
      selected={selected}
    >
      <Score>
        <Label>{t('reactions_prediction.score')}</Label>
        <ScoreTag
          data-tooltip-id="reactions-predictions-score"
          {...{ background }}
        >
          <Caption
            fontWeight={theme.fontWeight.medium}
            color={theme.colors.text.white}
          >
            {score}%
          </Caption>
          <Icon iconType="info" size="1rem" />
        </ScoreTag>
      </Score>
      <ReactTooltip
        id="reactions-predictions-score"
        className="c-tooltip c-tooltip-base c-tooltip-reactions-score"
        offset={10}
        classNameArrow="c-tooltip-arrow"
      >
        <ScoreHotTip />
      </ReactTooltip>

      <Container onClick={handleReactionClick}>
        <ReactBlock data-test="reaction-block">
          {reaction.left.map((mol, idx, arr) => {
            return (
              <Fragment key={`l-${idx}`}>
                <MoleculeViewer
                  size={size}
                  handleClick={() => handleOpen(id)}
                  onShowProps={() => handleOpen(id)}
                  smiles={mol}
                  type="gray-border"
                  noDelete
                  noEdit
                  noMenu
                  isAddDialogOpen={isAddDialogOpen}
                  structureClassName="rounded-12-mol-structure"
                  fixedCanvas={true}
                />
                {idx !== arr.length - 1 && (
                  <ReactionOperatorIcon iconType={'add'} size="1.25rem" />
                )}
              </Fragment>
            )
          })}

          <ReactionOperatorIcon iconType="reactionResultIcon" size="1.25rem" />

          {/* кажется, что в ArrowBlock мы используем только icon "equals".
        Если это так - то в будущем надо будет почистить */}
          {/* <ArrowBlock>
          <AgentsBlock>
            {catalyst.map((e, idx) => {
              return (
                <AgentElement key={idx}>
                  <AgentText>{e.name ? e.name : `Catalyst ${idx}`}</AgentText>
                  <AgentHiddenBlock>
                    <MoleculeViewer
                      size={size}
                      handleClick={voidClick}
                      smiles={e.smiles}
                      onShowProps={voidClick}
                      noDelete
                      type="solid-border"
                      noEdit
                    />
                  </AgentHiddenBlock>
                </AgentElement>
              )
            })}
          </AgentsBlock>

          

          <AgentsBlock>
            {solvent.map((e, idx) => {
              return (
                <AgentElement key={idx}>
                  <AgentText>{e.name ? e.name : `Solvent ${idx}`}</AgentText>
                  <AgentHiddenBlock>
                    <MoleculeViewer
                      size={size}
                      handleClick={voidClick}
                      smiles={e.smiles}
                      onShowProps={voidClick}
                      noDelete
                      noEdit
                      type="solid-border"
                    />
                  </AgentHiddenBlock>
                </AgentElement>
              )
            })}
            {data.temp && <div>{`Temp: ${data.temp}`}</div>}
            {data.pressure && <div>{`Pressure: ${data.pressure}`}</div>}
            {data.time && <div>{`Time: ${data.time}`}</div>}
          </AgentsBlock>
        </ArrowBlock> */}

          {reaction.right.map((mol, idx, arr) => {
            return (
              <Fragment key={`r-${idx}`}>
                <MoleculeViewer
                  size={size}
                  handleClick={() => handleOpen(id)}
                  smiles={mol}
                  onShowProps={() => handleOpen(id)}
                  noDelete
                  type="gray-border"
                  noEdit
                  noBaseId={true}
                  structureClassName="rounded-12-mol-structure"
                  fixedCanvas={true}
                />
                {idx !== arr.length - 1 && (
                  <ReactionOperatorIcon iconType="add" size="1.25rem" />
                )}
              </Fragment>
            )
          })}
          {/* {data.yield && (
            <YieldBlock>
              {t('reactions_prediction.yield')} {data.yield}
            </YieldBlock>
          )} */}
        </ReactBlock>
      </Container>
    </ReactionBlock>
  )
}

export default memo(ReactionViewer)
