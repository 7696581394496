import { v4 } from 'uuid'
import { useDispatch } from 'react-redux'

import { settingsMolsize } from 'store/actions/settings'

import { RangeInput, RangeContainer } from './index.style'

const Resizer = ({ viewSize }) => {
  const dispatch = useDispatch()

  const handleChangeSize = ({ target }) => {
    const value = +target.value
    dispatch(settingsMolsize(value))
    target.style.background = `linear-gradient(to right, #42c1bf ${
      (100 / 6) * value
    }%, #ffffff ${(100 / 6) * value}%)`
  }

  return (
    <RangeContainer>
      <RangeInput
        style={{
          background: `linear-gradient(to right, #42c1bf ${
            (100 / 6) * viewSize
          }%, #F5F7F7 ${(100 / 6) * viewSize}%)`,
        }}
        type="range"
        className="range"
        value={viewSize}
        min="0"
        max="6"
        step="0.1"
        id={v4()}
        onChange={handleChangeSize}
      />
    </RangeContainer>
  )
}

export default Resizer
