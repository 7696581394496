import styled from 'styled-components'

export const Container = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  gap: 1rem;
`
