import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import {
  getUsers,
  getCustomers,
  getApplications,
  openAddCompanyDialog,
  setAdminPanelFilterType,
} from 'store/actions/adminPanel'
import Icon from 'components/Icon'
import { readStorage } from 'utils/storage/storage'
import Pagination from 'pages/MoleculesList/Pagination'
import CustomButton from 'components/common/customButton'
import CustomSwitch from 'components/common/customSwitch'

import Users from '../users'
import Customers from '../customers'
import Applications from '../applications'
import { SWITCH_FILTER_TYPES } from '../config'
import AddCompanyDialog from '../addCompanyDialog'
import { ScrollTopBtn, SwitchWrapper } from '../index.style'
import { hideFeedbackBtn, showFeedbackBtn } from 'store/actions/feedback'

const UsersAndCompanies = ({
  handleScrollTop,
  isBottom,
  scrollTopEnable,
  filterType,
  addCompanyDialogOpen,
  notifications,
  tasks,
  usersTotal,
  customersTotal,
  applicationsTotal,
  usersFirstRequest,
  customersFirstRequest,
  applicationsFirstRequest,
  usersPagination,
  customersPagination,
  applicationsPagination,
  isFeedbackBtnshow,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { notificationsList, isHidden } = notifications

  const isNotificationsButtonShow =
    isHidden && notificationsList.length + tasks.length > 0
  const handleSwitch = (type) => {
    dispatch(setAdminPanelFilterType(type))
  }

  const [items, setItems] = useState(SWITCH_FILTER_TYPES)

  const { pagesAmount } = (() => {
    if (filterType === 'users') return usersPagination
    if (filterType === 'applications') return applicationsPagination
    return customersPagination
  })()

  // feedback btn should be hidden if isBottom and there is no pagination
  useEffect(() => {
    if (isBottom && pagesAmount < 2 && isFeedbackBtnshow)
      dispatch(hideFeedbackBtn())
    else if (!isFeedbackBtnshow) dispatch(showFeedbackBtn())

    return () => dispatch(showFeedbackBtn())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom, pagesAmount])

  useEffect(() => {
    const adminRole = readStorage('role')
    if (adminRole === 'syn_admin') {
      if (usersFirstRequest) dispatch(getUsers({ limit: 24, offset: 0 }))
      if (customersFirstRequest)
        dispatch(getCustomers({ limit: 24, offset: 0 }))

      if (applicationsFirstRequest)
        dispatch(
          getApplications({ limit: 24, offset: 0, isFirstRequest: true })
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersFirstRequest, customersFirstRequest, applicationsFirstRequest])

  useEffect(() => {
    setItems((prev) =>
      prev.map((el) => ({
        ...el,
        count:
          el.type === 'users'
            ? usersTotal
            : el.type === 'companies'
            ? customersTotal
            : applicationsTotal,
      }))
    )
  }, [usersTotal, customersTotal, applicationsTotal])

  return (
    <>
      <SwitchWrapper>
        <CustomSwitch
          items={items}
          keyTemplate="admin-panel-switch"
          active={filterType}
          btnWidth="fit-content"
          bg="white"
          onClick={handleSwitch}
          withCount={true}
        />
        {filterType === 'companies' && (
          <CustomButton
            type="primary"
            gap="0.25rem"
            nowrap={true}
            onClick={() => dispatch(openAddCompanyDialog())}
          >
            <Icon iconType="add" size={'16px'} />
            {t('admin.filter.add_company')}
          </CustomButton>
        )}
      </SwitchWrapper>

      {filterType === 'companies' && (
        <>
          <Customers />
        </>
      )}
      {filterType === 'users' && <Users />}
      {filterType === 'applications' && <Applications />}
      {isBottom && pagesAmount > 1 && (
        <Pagination
          pageType={
            filterType === 'users'
              ? 'adminAllUsers'
              : filterType === 'applications'
              ? 'adminAllApplications'
              : 'adminAllCustomers'
          }
        />
      )}
      {addCompanyDialogOpen && <AddCompanyDialog />}
      <ScrollTopBtn
        visible={scrollTopEnable}
        onClick={handleScrollTop}
        isNotificationsButtonShow={isNotificationsButtonShow}
      >
        <Icon iconType="arrowTop" size="1.25rem" />
      </ScrollTopBtn>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    section: state.adminPanel.section,
    filterType: state.adminPanel.filter.type,
    addCompanyDialogOpen: state.adminPanel.addCompanyDialogOpen,
    notifications: state.notifications,
    tasks: state.tasks,
    usersTotal: state.adminPanel.users.total,
    customersTotal: state.adminPanel.customers.total,
    applicationsTotal: state.adminPanel.applications.notHandledTotal,
    usersFirstRequest: state.adminPanel.users.firstRequest,
    customersFirstRequest: state.adminPanel.customers.firstRequest,
    applicationsFirstRequest: state.adminPanel.applications.firstRequest,
    usersPagination: state.adminPanel.users.pagination,
    applicationsPagination: state.adminPanel.applications.pagination,
    customersPagination: state.adminPanel.customers.pagination,
    isFeedbackBtnshow: state.feedback.show_btn,
  }
}

export default connect(mapStateToProps)(UsersAndCompanies)
