import styled, { css } from 'styled-components'
import CustomButton from 'components/common/customButton'

export const HistoryResultsActions = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  ${({ pastResult }) =>
    !pastResult &&
    css`
      display: none;
    `}
`

export const ResultsHeader = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
export const StyledButton = styled(CustomButton)`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.primary};
  & > svg {
    min-width: 1rem;
  }
`
