import PhoneInput from 'react-phone-input-2'
import { useTheme } from 'styled-components'
import { useEffect, useRef, useState } from 'react'

import { Caption, Label } from 'components/common/text/index.style'

const PhoneNumber = ({ value, withCaption = false }) => {
  const ref = useRef(null)
  const theme = useTheme()

  const [phone, setPhone] = useState(value)

  useEffect(() => {
    if (ref?.current) {
      setPhone(ref.current.value)
    }
  }, [ref])

  return (
    <>
      <PhoneInput
        value={value}
        inputProps={{
          ref,
        }}
      />
      {withCaption ? (
        <Caption
          ellipsis
          lineHeight={theme.text[14]}
          fontWeight={theme.fontWeight.medium}
        >
          {phone || value}
        </Caption>
      ) : (
        <Label color={theme.colors.text.secondary} name="ellipsis">
          {phone || value}
        </Label>
      )}
    </>
  )
}

export default PhoneNumber
