import { persistatedStore } from 'store/persist'

export const readStorage = (key) => {
  const value = localStorage.getItem(key)
  if (value) {
    try {
      return JSON.parse(value)
    } catch (e) {
      return
    }
  } else {
    return
  }
}

export const setStorage = (key, value) => {
  const json = JSON.stringify(value)
  if (!value) {
    removeFromStorage(key)
  } else {
    localStorage.setItem(key, json)
  }
}

export const removeFromStorage = (key) => {
  localStorage.removeItem(key)
}

export const getDataFromLocalForage = async (key) => {
  try {
    const data = await persistatedStore.getItem(key)
    return data
  } catch (error) {
    return
  }
}

export const setDataToLocalForage = async (key, value) => {
  try {
    await persistatedStore.setItem(key, value)
  } catch (error) {
    return
  }
}
