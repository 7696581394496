const sortToxy = (category) => {
  const animalArrays = {
    Mouse: [],
    qsar_mouse: [],
    Rat: [],
    qsar_rat: [],
    Rabbit: [],
    qsar_rabbit: [],
    Pig: [],
    qsar_guinea_pig: [],
    unknown: [],
  }

  for (let i = 1; i < category.length; i++) {
    const item = category[i]
    const animalType = Object.keys(animalArrays).find((key) =>
      item.name.startsWith(key)
    )

    animalArrays[animalType ? animalType : 'unknown'].push(item)
  }

  return [category[0], ...Object.values(animalArrays).flat()]
}

export const getSortedCategories = (categories) =>
  categories.map((c) =>
    c?.category_name === 'toxy'
      ? {
          ...c,
          inner: sortToxy(c.inner),
        }
      : c
  )
