import { connect } from 'react-redux'
import { usePopper } from 'react-popper'
import ReactDom from 'react-dom'
import { useState } from 'react'
import debounce from 'lodash.debounce'
import { ThemeProvider } from 'styled-components'

import { setCurrentHighlightId } from 'store/actions/pdf2smiles'
import { Box } from './index.style'
import SmilesViewer from '../SmilesViewer'
import '../../../components/common/popper/style.css'
import { scrollToSidebarHighlight } from '../config/config'
import { projectTheme } from 'styles/theme'

const AreaHighlight = ({
  highlight,
  currentHighlightId,
  setCurrentHighlightId,
}) => {
  const { top, left, width, height } = highlight.bbox

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrow, setArrow] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 16],
        },
      },
      { name: 'arrow', options: { element: arrow } },
    ],
  })

  const hideMenu = () => {
    setIsMenuOpen(false)
  }

  const hideMenuWithDelay = debounce(hideMenu, 200)

  const handleHighlightClick = () => {
    setCurrentHighlightId(highlight.id)
    scrollToSidebarHighlight(highlight.id)
  }

  const openMenu = (e) => {
    hideMenuWithDelay.cancel()
    setIsMenuOpen(true)
  }

  return (
    <>
      <Box
        top={top}
        left={left}
        width={width}
        height={height}
        isCurrent={highlight.id === currentHighlightId}
        className={`isCurrent-${highlight.id === currentHighlightId}`}
        ref={setReferenceElement}
        onClick={handleHighlightClick}
        onMouseOver={openMenu}
        onFocus={openMenu}
        onMouseOut={hideMenuWithDelay}
        onBlur={hideMenuWithDelay}
      />
      {isMenuOpen &&
        ReactDom.createPortal(
          <ThemeProvider theme={projectTheme}>
            <div
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className={'area-highlight-popover'}
            >
              <div
                ref={setArrow}
                className={'area-highlight-popover-arrow'}
                style={styles.arrow}
                {...attributes.popper}
              />
              <div
                className="area-highlight-popover-content"
                onMouseOver={openMenu}
                onFocus={openMenu}
                onMouseOut={hideMenuWithDelay}
                onBlur={hideMenuWithDelay}
              >
                <SmilesViewer
                  image={highlight.image_url}
                  smiles={highlight.structure.smiles[0]}
                  score={highlight.structure.score}
                  variant={'modal'}
                  id={highlight.id}
                />
              </div>
            </div>
          </ThemeProvider>,
          document.body
        )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    currentHighlightId: state.pdf2Smiles.currentHighlightId,
  }
}

const mapDispatchToProps = {
  setCurrentHighlightId,
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaHighlight)
