const CustomTooltip = (params) => {
  const { rowIndex, column } = params
  let tooltipText = ''

  let cell
  if (rowIndex !== undefined) {
    const cellSelector = `[row-index="${rowIndex}"]`
    const row = document.querySelector(cellSelector)
    cell = row?.querySelector(`[col-id="${column.colId}"]`)
    tooltipText = cell?.innerText || ''
  } else {
    const wrapper = document.querySelector(
      `.ag-header-cell[col-id="${params.column.colId}"]`
    )
    cell = wrapper?.querySelector('.ag-header-cell-text')
    tooltipText = cell?.innerText || ''
  }
  return cell?.scrollWidth - cell?.clientWidth ? (
    <div className="c-tooltip c-tooltip-base">{tooltipText}</div>
  ) : (
    <></>
  )
}

export default CustomTooltip
