import {
  OPEN_FEEDBACK_FORM,
  CLOSE_FEEDBACK_FORM,
  OPEN_FEEDBACK_CARD,
  CLOSE_FEEDBACK_CARD,
  SET_FEEDBACK_PAGINATION_CONFIG,
  GET_FEEDBACK_INIT,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_ERROR,
  SET_FEEDBACK_FILTER,
  CLEAR_FEEDBACK_FILTER,
  SHOW_FEEDBACK_BTN,
  HIDE_FEEDBACK_BTN,
} from 'store/constants/feedbackForm'

const initialFilter = {
  theme: 'all',
  modules: [],
  date: [null, null],
  sort: {
    type: 'created_at',
    direction: 'desc',
  },
  email: '',
}

const initialState = {
  show_btn: true,
  show: false,
  defaultTheme: null,
  show_feedback_card: false,
  feedback_card_id: null,
  feedback_selected_card: null,
  pagination: {
    pagesAmount: 0,
    total: 0,
    perPage: 24,
    activePage: 1,
  },
  loading: false,
  data: [],
  filter: initialFilter,
  offset: 0,
}

const feedbackReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_FEEDBACK_INIT:
      return {
        ...state,
        loading: true,
      }
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
      }
    case GET_FEEDBACK_ERROR:
      return {
        ...state,
        loading: false,
      }
    case OPEN_FEEDBACK_FORM:
      return {
        ...state,
        show: true,
        defaultTheme: payload.defaultTheme,
      }
    case CLOSE_FEEDBACK_FORM:
      return {
        ...state,
        show: false,
        defaultTheme: null,
      }
    case OPEN_FEEDBACK_CARD:
      return {
        ...state,
        show_feedback_card: true,
        feedback_selected_card: payload.data,
      }
    case CLOSE_FEEDBACK_CARD:
      return {
        ...state,
        show_feedback_card: false,
        feedback_selected_card: null,
      }
    case SET_FEEDBACK_PAGINATION_CONFIG:
      return { ...state, pagination: payload.config }
    case SET_FEEDBACK_FILTER:
      return {
        ...state,
        filter: { ...state.filter, [payload.key]: payload.value },
      }
    case CLEAR_FEEDBACK_FILTER:
      return {
        ...state,
        filter: initialFilter,
      }

    case SHOW_FEEDBACK_BTN:
      return {
        ...state,
        show_btn: true,
      }
    case HIDE_FEEDBACK_BTN:
      return {
        ...state,
        show_btn: false,
      }

    default:
      return state
  }
}

export default feedbackReducer
