import React, { useState, useEffect, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  NewButton,
  Wrapper,
  ModalHeader,
  ModalDialog,
  Modal,
  ModalBtns,
  SelectedList,
  ListContainer,
  TagText,
  ListWrapper,
  DatasetTypesSelector,
  DatasetTypes,
} from './index.style'
import { addBasket, loadBaskets } from '../../store/actions/crud'
import Icon from 'components/Icon'
import LoaderRestyling from 'components/LoaderRestyling'
import CustomScrollbar from 'components/CustomScrollbar'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import CustomTag from 'components/common/customTag'
import { useTheme } from 'styled-components'
import {
  Caption,
  TextMedium,
  TitleTertiary,
} from 'components/common/text/index.style'
import { CustomCloseIcon } from 'components/common/customCloseIcon'
import CustomTab from 'components/common/customTab'
import {
  BASKET_TYPES,
  NON_PUBLIC_BASKET_TYPES,
} from 'pages/Baskets/config/config'

const BasketsListModal = ({
  baskets,
  onAgree,
  onClose,
  withPublic,
  loadBaskets,
  withNew = true,
  actionText,
  selectedDatasets,
  currentBasketId,
  onlyOne,
  loading,
}) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState([])
  const [view, setView] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [datasetType, setDatasetType] = useState('private')
  const { t } = useTranslation()
  const theme = useTheme()

  const handleChange = (value) => setFilterValue(value)

  useEffect(() => {
    loadBaskets && loadBaskets({ basketType: datasetType, withSearch: false })
  }, [loadBaskets, datasetType])

  useEffect(() => {
    const processedSets = baskets.filter(
      (basket) => basket.status !== 'pending'
    )
    setView(processedSets)
  }, [baskets])

  const handleCreate = () => {
    dispatch(addBasket(filterValue, datasetType))
  }

  const handleAgree = () => {
    onAgree(selected, filterValue)
    onClose()
  }

  const handleSelect = (id, name) => () => {
    const isSelected = selected.find(({ id: itemId }) => itemId === id)
    if (isSelected) {
      const filtered = selected.filter((el) => el.id !== id)
      setSelected(filtered)
    } else if (onlyOne && selected.length > 0 && !isSelected)
      setSelected([{ id, name }])
    else setSelected([...selected, { id, name }])
  }

  const handleRemoveSelected = (id) => {
    const filtered = selected.filter((el) => el.id !== id)
    setSelected(filtered)
  }

  const filtered = useMemo(
    () =>
      (filterValue
        ? view.filter(({ name }) =>
            name.toLowerCase().includes(filterValue.toLowerCase())
          )
        : view
      ).filter((basket) => {
        return !(
          (
            selectedDatasets.includes(basket.id) ||
            basket.id.toString() === currentBasketId
          ) /*||
          (datasetType === 'thematic' && !basket.public) ||
          (datasetType === 'companies' && !basket.is_customer_basket) ||
          (datasetType === 'private' &&
            (basket.public || basket.is_customer_basket))*/
        )
      }) ?? [],
    [currentBasketId, filterValue, selectedDatasets, view]
  )

  // this is commented before https://jira.nanosemantics.ru/browse/SYN-2738 is ready
  const datasetTypes = withPublic ? BASKET_TYPES : NON_PUBLIC_BASKET_TYPES

  return (
    <Modal>
      <ModalDialog>
        <ModalHeader>
          <TitleTertiary fontWeight={theme.fontWeight.semibold}>
            {t('baskets.modal.choose_set_text')}
          </TitleTertiary>
          <CustomCloseIcon onClick={onClose} />
        </ModalHeader>
        <DatasetTypesSelector>
          <TextMedium fontWeight={theme.fontWeight.medium}>
            {t('baskets.modal.dataset_type')}
          </TextMedium>
          <DatasetTypes>
            {datasetTypes.map((dt) => (
              <CustomTab
                key={dt}
                onClick={() => setDatasetType(dt)}
                type={'grey'}
                size={'small'}
                isActive={datasetType === dt}
              >
                {t(`baskets.dataset_type_selectors.${dt}`)}
              </CustomTab>
            ))}
          </DatasetTypes>
        </DatasetTypesSelector>

        <CustomInput
          placeholder={t(
            withNew
              ? 'baskets.modal.placeholder'
              : 'baskets.modal.placeholder_filter'
          )}
          onChange={handleChange}
          value={filterValue}
          withClearButton={true}
        />
        {!onlyOne && !!selected.length && (
          <SelectedList>
            {selected.map(({ name, id }, key) => {
              return (
                <CustomTag key={key}>
                  <TagText
                    lineHeight={theme.text[14]}
                    fontWeight={theme.fontWeight.medium}
                    ellipsis
                  >
                    {name}
                  </TagText>
                  <CustomButton
                    type={'icon_secondary'}
                    width={'1rem'}
                    height={'1rem'}
                    defaultPadding={'0'}
                    onClick={() => handleRemoveSelected(id)}
                  >
                    <Icon iconType={'close'} size={'1rem'} />
                  </CustomButton>
                </CustomTag>
              )
            })}
          </SelectedList>
        )}
        <ListContainer>
          <Caption
            color={theme.colors.text.secondary}
            lineHeight={theme.text[14]}
            fontWeight={theme.fontWeight.medium}
          >
            {t('baskets.modal.choose_from_list')}
          </Caption>
          <Wrapper>
            <CustomScrollbar
              className="margin--8-right"
              disableTrackYWidthCompensation={true}
            >
              {!loading && (
                <ListWrapper>
                  {filtered.map((el) => {
                    const isSelected = selected.find(({ id }) => id === el.id)
                    return (
                      <NewButton
                        onClick={handleSelect(el.id, el.name)}
                        selected={isSelected}
                        key={el.id}
                        data-test="choose-baskets-list"
                      >
                        <TextMedium
                          fontWeight={theme.fontWeight.medium}
                          ellipsis
                        >
                          {el.name}
                        </TextMedium>
                        {isSelected && <Icon iconType="tick" size={'1rem'} />}
                      </NewButton>
                    )
                  })}
                </ListWrapper>
              )}
              {loading && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '220px',
                  }}
                >
                  <LoaderRestyling />
                </div>
              )}
            </CustomScrollbar>
          </Wrapper>
        </ListContainer>
        <ModalBtns>
          {withNew && (
            <CustomButton
              type={'secondary'}
              onClick={handleCreate}
              disabled={
                !filterValue || filtered.some((el) => el?.name === filterValue)
              }
              width={'100%'}
            >
              {t('baskets.modal.create_new_basket')}
            </CustomButton>
          )}
          <CustomButton
            type={selected.length ? 'accent' : 'secondary'}
            onClick={!selected.length ? () => {} : handleAgree}
            disabled={!selected.length}
            width={'100%'}
          >
            {actionText}
          </CustomButton>
        </ModalBtns>
      </ModalDialog>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    baskets: state.crud.baskets,
    selectedDatasets: state.mmap.selectedDatasets,
    loading: state.crud.loading,
  }
}

const mapDispatchToProps = {
  loadBaskets,
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketsListModal)
