import {
  ADD_NEW_COMPANY_FAIL,
  ADD_NEW_COMPANY_INIT,
  ADD_NEW_COMPANY_SUCCESS,
  CLOSE_ADD_COMPANY_DIALOG,
  CLOSE_APPLICATION_DIALOG,
  CURRENT_CUSTOMER_EDIT_SUCCESS,
  CURRENT_CUSTOMER_LICENSE_TOGGLE_SUCCESS,
  CURRENT_CUSTOMER_LICENSE_USER_TOGGLE_SUCCESS,
  CUSTOMER_EDIT_SUCCESS,
  GET_APPLICATIONS_FAIL,
  GET_APPLICATIONS_INIT,
  GET_APPLICATIONS_SUCCESS,
  GET_CURRENT_CUSTOMER_FAIL,
  GET_CURRENT_CUSTOMER_INIT,
  GET_CURRENT_CUSTOMER_SUCCESS,
  GET_CURRENT_CUSTOMER_USERS_FAIL,
  GET_CURRENT_CUSTOMER_USERS_INIT,
  GET_CURRENT_CUSTOMER_USERS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_INIT,
  GET_CUSTOMERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_INIT,
  GET_USERS_SUCCESS,
  LICENSE_TOGGLE_ACCESS_SUCCESS,
  LICENSE_USER_TOGGLE_ACCESS_SUCCESS,
  OPEN_ADD_COMPANY_DIALOG,
  OPEN_APPLICATION_DIALOG,
  REMOVE_USER_FROM_COMPANY_SUCCESS,
  SET_ADD_COMPANY_STAGE,
  SET_ADMIN_PANEL_FILTER_TYPE,
  SET_ADMIN_PANEL_SECTION,
  SET_APPLICATIONS_PAGINATION_CONFIG,
  SET_CURRENT_CUSTOMER_ID,
  SET_CURRENT_CUSTOMER_PAGINATION_CONFIG,
  SET_CUSTOMERS_PAGINATION_CONFIG,
  SET_FILTER_STATUS,
  SET_LICENSE_ACTIVATION_DATE,
  SET_LICENSE_EXPIRATION_DATE,
  SET_USERS_PAGINATION_CONFIG,
  UPDATE_APPLICATION_FAIL,
  UPDATE_APPLICATION_INIT,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_CURRENT_CUSTOMER_LICENSE_USERS,
} from 'store/constants/adminPanel'

const initialState = {
  section: 'users_and_companies',
  addCompanyDialogOpen: false,
  currentCustomerId: '',
  currentLicenseId: '',
  filter: {
    type: 'users',
    licenseActivationDate: { from: '', to: '' },
    licenseExpDate: { from: '', to: '' },
    status: 'all',
  },
  customers: {
    total: null,
    items: [],
    loading: false,
    error: false,
    firstRequest: true,
    pagination: {
      pagesAmount: 0,
      total: 0,
      perPage: 24,
      activePage: 1,
    },
  },
  currentCustomer: {
    item: {},
    loading: false,
    error: false,
  },
  currentCustomerUsers: {
    items: [],
    loading: false,
    error: false,
    pagination: {
      pagesAmount: 0,
      total: 0,
      perPage: 24,
      activePage: 1,
    },
  },
  users: {
    total: null,
    items: [],
    loading: false,
    error: false,
    firstRequest: true,
    pagination: {
      pagesAmount: 0,
      total: 0,
      perPage: 24,
      activePage: 1,
    },
  },
  applications: {
    total: null,
    notHandledTotal: null,
    items: [],
    loading: false,
    error: false,
    firstRequest: true,
    updating: false,
    openedApplicationDialog: null,
    pagination: {
      pagesAmount: 0,
      total: 0,
      perPage: 24,
      activePage: 1,
    },
  },
  addCompany: {
    stage: 'create',
    item: {},
    loading: false,
    error: false,
  },
}

const adminPanelReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_CUSTOMERS_INIT:
      return {
        ...state,
        customers: {
          ...state.customers,
          items: [],
          loading: true,
          error: false,
          firstRequest: false,
        },
      }
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: {
          ...state.customers,
          total: payload.total,
          items: payload.items,
          loading: false,
          error: false,
          firstRequest: false,
        },
      }
    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        customers: {
          ...state.customers,
          total: null,
          items: [],
          loading: false,
          error: true,
          firstRequest: false,
        },
      }
    case GET_CURRENT_CUSTOMER_INIT:
      return {
        ...state,
        currentCustomer: {
          item: {},
          loading: true,
          error: false,
        },
      }
    case GET_CURRENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        currentCustomer: {
          item: { ...payload?.items?.[0] },
          loading: false,
          error: false,
        },
      }

    case UPDATE_CURRENT_CUSTOMER_LICENSE_USERS:
      return {
        ...state,
        currentCustomer: {
          ...state.currentCustomer,
          item: {
            ...state.currentCustomer.item,
            license_users_count: payload.withLicense
              ? state.currentCustomer.item.license_users_count + 1
              : state.currentCustomer.item.license_users_count - 1,
          },
        },
      }
    case GET_CURRENT_CUSTOMER_FAIL:
      return {
        ...state,
        currentCustomer: {
          item: {},
          loading: false,
          error: true,
        },
      }
    case GET_USERS_INIT: {
      return {
        ...state,
        users: {
          ...state.users,
          items: [],
          loading: true,
          error: false,
          firstRequest: false,
        },
      }
    }

    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          total: payload.total,
          items: payload.items,
          loading: false,
          error: false,
          firstRequest: false,
        },
      }
    }
    case GET_USERS_FAIL: {
      return {
        ...state,
        users: {
          ...state.users,
          total: null,
          items: [],
          loading: false,
          error: true,
          firstRequest: false,
        },
      }
    }

    case GET_APPLICATIONS_INIT: {
      return {
        ...state,
        applications: {
          ...state.applications,
          items: [],
          loading: true,
          error: false,
          firstRequest: false,
        },
      }
    }

    case OPEN_APPLICATION_DIALOG: {
      return {
        ...state,
        applications: {
          ...state.applications,
          openedApplicationDialog: payload.id,
        },
      }
    }

    case CLOSE_APPLICATION_DIALOG: {
      return {
        ...state,
        applications: {
          ...state.applications,
          openedApplicationDialog: null,
        },
      }
    }

    case UPDATE_APPLICATION_INIT: {
      return {
        ...state,
        applications: {
          ...state.applications,
          updating: true,
        },
      }
    }

    case UPDATE_APPLICATION_SUCCESS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          items: state.applications.items.map((el) =>
            el.id === payload.id ? { ...el, is_handled: payload.value } : el
          ),
          updating: false,
          ...(payload.needUpdateTotal
            ? { notHandledTotal: state.applications.notHandledTotal - 1 }
            : {}),
        },
      }
    }

    case UPDATE_APPLICATION_FAIL: {
      return {
        ...state,
        applications: {
          ...state.applications,
          updating: false,
        },
      }
    }

    case GET_APPLICATIONS_SUCCESS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          total: payload.total,
          items: payload.items,
          loading: false,
          error: false,
          firstRequest: false,
          notHandledTotal: payload?.isFirstRequest
            ? payload.notHandledTotal
            : state.applications.notHandledTotal,
        },
      }
    }
    case GET_APPLICATIONS_FAIL: {
      return {
        ...state,
        applications: {
          ...state.applications,
          total: null,
          items: [],
          loading: false,
          error: true,
          firstRequest: false,
        },
      }
    }
    case GET_CURRENT_CUSTOMER_USERS_INIT: {
      return {
        ...state,
        currentCustomerUsers: {
          ...state.currentCustomerUsers,
          items: [],
          loading: true,
          error: false,
        },
      }
    }
    case GET_CURRENT_CUSTOMER_USERS_SUCCESS: {
      return {
        ...state,
        currentCustomerUsers: {
          ...state.currentCustomerUsers,
          items: payload.items,
          loading: false,
          error: false,
        },
      }
    }
    case GET_CURRENT_CUSTOMER_USERS_FAIL: {
      return {
        ...state,
        currentCustomerUsers: {
          ...state.currentCustomerUsers,
          items: [],
          loading: false,
          error: true,
        },
      }
    }
    case SET_CURRENT_CUSTOMER_PAGINATION_CONFIG: {
      return {
        ...state,
        currentCustomerUsers: {
          ...state.currentCustomerUsers,
          pagination: payload.config,
        },
      }
    }
    case SET_USERS_PAGINATION_CONFIG:
      return { ...state, users: { ...state.users, pagination: payload.config } }
    case SET_APPLICATIONS_PAGINATION_CONFIG:
      return {
        ...state,
        applications: { ...state.applications, pagination: payload.config },
      }
    case SET_CUSTOMERS_PAGINATION_CONFIG:
      return {
        ...state,
        customers: { ...state.customers, pagination: payload.config },
      }
    case SET_ADMIN_PANEL_SECTION:
      return {
        ...state,
        section: payload.section,
      }
    case SET_ADMIN_PANEL_FILTER_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          type: payload.filterType,
        },
      }
    case SET_LICENSE_ACTIVATION_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          licenseActivationDate: {
            ...state.filter.licenseActivationDate,
            [payload.inputType]: payload.date,
          },
        },
      }
    case SET_LICENSE_EXPIRATION_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          licenseExpDate: {
            ...state.filter.licenseExpDate,
            [payload.inputType]: payload.date,
          },
        },
      }
    case SET_FILTER_STATUS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: payload.status,
        },
      }
    case LICENSE_TOGGLE_ACCESS_SUCCESS:
      return {
        ...state,
        customers: {
          ...state.customers,
          items: state.customers.items.map((el) =>
            el.license_id === payload.items.id
              ? { ...el, license_is_active: payload.items.is_active }
              : el
          ),
        },
      }
    case CURRENT_CUSTOMER_LICENSE_TOGGLE_SUCCESS:
      return {
        ...state,
        currentCustomer: {
          ...state.currentCustomer,
          item: {
            ...state.currentCustomer.item,
            license_is_active: payload.items.is_active,
          },
        },
      }
    case LICENSE_USER_TOGGLE_ACCESS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          items: state.users.items.map((el) =>
            el.user_id === payload.items.user_id
              ? { ...el, license_id: payload.items.license_id }
              : el
          ),
        },
      }
    case REMOVE_USER_FROM_COMPANY_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          items: state.users.items.map((el) =>
            el.user_id === payload.items.user_id
              ? {
                  ...el,
                  customer_id: null,
                  license_id: null,
                  license_is_free_trial: null,
                  license_is_active: null,
                  license_expired_at: null,
                }
              : el
          ),
        },
      }
    case CURRENT_CUSTOMER_LICENSE_USER_TOGGLE_SUCCESS:
      return {
        ...state,
        currentCustomerUsers: {
          ...state.currentCustomerUsers,
          items: state.currentCustomerUsers.items.map((el) =>
            el.user_id === payload.items.user_id
              ? { ...el, license_id: payload.items.license_id }
              : el
          ),
        },
      }
    case CUSTOMER_EDIT_SUCCESS: {
      const { admin, name } = payload.items.customerResult
      const { max_users_count, started_at, expired_at, id } =
        payload.items.licenseResult
      return {
        ...state,
        customers: {
          ...state.customers,
          items: state.customers.items.map((el) =>
            el.license_id === id
              ? {
                  ...el,
                  customer_admin: admin,
                  customer_name: name,
                  license_max_users_count: max_users_count,
                  license_started_at: started_at,
                  license_expired_at: expired_at,
                }
              : el
          ),
        },
      }
    }
    case CURRENT_CUSTOMER_EDIT_SUCCESS: {
      const { admin, name } = payload.items.customerResult
      const { max_users_count, started_at, expired_at } =
        payload.items.licenseResult
      return {
        ...state,
        currentCustomer: {
          ...state.currentCustomer,
          item: {
            ...state.currentCustomer.item,
            customer_admin: admin,
            customer_name: name,
            license_max_users_count: max_users_count,
            license_started_at: started_at,
            license_expired_at: expired_at,
          },
        },
      }
    }
    case SET_CURRENT_CUSTOMER_ID: {
      return {
        ...state,
        currentCustomerId: payload.customerId,
        currentLicenseId: payload.licenseId,
      }
    }
    case OPEN_ADD_COMPANY_DIALOG: {
      return { ...state, addCompanyDialogOpen: true }
    }
    case CLOSE_ADD_COMPANY_DIALOG: {
      return {
        ...state,
        addCompanyDialogOpen: false,
      }
    }
    case SET_ADD_COMPANY_STAGE: {
      return {
        ...state,
        addCompany: { ...state.addCompany, stage: payload.stage },
      }
    }
    case ADD_NEW_COMPANY_INIT: {
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          loading: true,
          error: false,
          item: {},
        },
      }
    }
    case ADD_NEW_COMPANY_SUCCESS: {
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          loading: false,
          error: false,
          item: payload.item,
        },
      }
    }
    case ADD_NEW_COMPANY_FAIL:
      return {
        ...state,
        addCompany: {
          stage: 'create',
          loading: false,
          error: true,
          item: {},
        },
      }
    default:
      return state
  }
}

export default adminPanelReducer
