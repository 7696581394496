import { memo } from 'react'
import { SkeletonWrapper } from './index.style'

const SkeletonComponent = memo(
  ({ width, minWidth, height, variant = 'light', borderRadius }) => {
    return (
      <SkeletonWrapper
        {...{ width, height, minWidth, variant, borderRadius }}
      />
    )
  }
)

SkeletonComponent.displayName = 'SkeletonComponent'
export default SkeletonComponent
