import {
  TOGGLE_MOLECULE_DIALOG,
  TOGGlE_ADD_MOLECULE_DIALOG,
} from 'store/constants/retrosynthesis'

const initialState = {
  smilesToAdd: null,
  fastViewMoleculeId: null,
}

const retrosynthesisReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case TOGGlE_ADD_MOLECULE_DIALOG:
      return { ...state, smilesToAdd: payload.value }
    case TOGGLE_MOLECULE_DIALOG:
      return { ...state, fastViewMoleculeId: payload.value }

    default:
      return state
  }
}

export default retrosynthesisReducer
