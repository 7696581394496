import { connect } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import React, { memo, useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import {
  TitleTertiary,
  TextButtonMedium,
} from 'components/common/text/index.style'
import { toggleAddMoleculeDialog } from 'store/actions/retrosynthesis'
import RetrosynthTree from 'components/RetrosynthViewer/RetrosynthTree'

import {
  Tabs,
  Wrapper,
  TopBlock,
  RightPanel,
  TabsWithRightPanel,
} from './index.style'
import CustomTag from 'components/common/customTag'
import CustomTab from 'components/common/customTab'

const RetrosynthViewer = ({ calculation, toggleAddMoleculeDialog }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [selectedStage, setSelectedStage] = useState(0)
  const [isDownloadClicked, setIsDownloadClicked] = useState(false)
  const currentCalc = calculation[selectedStage]
  const tooltipText = currentCalc.is_solved
    ? t('reactions_prediction.retrosynth.is_solved')
    : t('reactions_prediction.retrosynth.is_not_solved')

  return (
    <Wrapper>
      <TopBlock>
        <TitleTertiary fontWeight={theme.fontWeight.semibold}>
          {t('reactions_prediction.retrosynth.schemas')}
        </TitleTertiary>
        <TabsWithRightPanel>
          <Tabs>
            {calculation.map((_, ind) => (
              <CustomTab
                key={ind}
                type="grey"
                width="1.75rem"
                height="1.75rem"
                size="small"
                isActive={selectedStage === ind}
                onClick={() => setSelectedStage(ind)}
                data-test={`retro-choose-schema-btn`}
              >
                {' '}
                <TextButtonMedium color="inherit">{ind + 1}</TextButtonMedium>
              </CustomTab>
            ))}
          </Tabs>
          <RightPanel>
            <CustomButton
              type="text"
              gap="0.25rem"
              onClick={() =>
                toggleAddMoleculeDialog(
                  currentCalc.retro_tree.map((el) => el.smiles)
                )
              }
            >
              {t('reactions_prediction.retrosynth.save_to_dataset')}
              <Icon iconType="save" size="1rem" />
            </CustomButton>
            <CustomButton
              type="text"
              gap="0.25rem"
              onClick={() => setIsDownloadClicked(true)}
            >
              {t('reactions_prediction.retrosynth.download')}
              <Icon iconType="upload" size="1rem" />
            </CustomButton>
            <CustomTag
              data-tooltip-id="availability_value_desc"
              withInteraction={true}
              id="retrosynth-solution-tag"
            >
              {currentCalc.is_solved
                ? t('reactions_prediction.retrosynth.solutionFound')
                : t('reactions_prediction.retrosynth.solutionNotFound')}
              <Icon iconType="description" size="1rem" />
              <ReactTooltip
                id="availability_value_desc"
                className="c-tooltip c-tooltip-base c-tooltip-molviewer"
                classNameArrow="c-tooltip-arrow"
                place="bottom"
              >
                {tooltipText}
              </ReactTooltip>
            </CustomTag>
            <CustomTag
              defaultColor={theme.colors.statuses.success}
              id="retrosynth-score-tag"
            >
              Score {currentCalc.score.toFixed(3)}
            </CustomTag>
          </RightPanel>
        </TabsWithRightPanel>
      </TopBlock>
      <RetrosynthTree
        tree={currentCalc.retro_tree}
        {...{
          selectedStage,
          isDownloadClicked,
          setIsDownloadClicked,
        }}
      />
    </Wrapper>
  )
}

const mapDispatchToProps = {
  toggleAddMoleculeDialog,
}

export default connect(null, mapDispatchToProps)(memo(RetrosynthViewer))
