import { memo, useEffect, useState } from 'react'

import ApplyResetBtns from '../ApplyResetBtns'

import ConditionsBlock from '../ConditionsBlock'
import { initFilterState } from '../config/config'
import { Container } from '../FilterContent/index.style'
import { checkObjDisablity, onChangeFilters } from '../utils/utils'

const PaintingContent = memo(
  ({ column, values, typeOptions, paintingModel, onChangePaintingModel }) => {
    const [paintingOperator, setPaintingOperator] = useState(null)
    const [paintingState, setPaintingState] = useState([...initFilterState])

    const appliedFilter = paintingModel?.[column.colId]?.model[0]

    const isApplyBtnDisabled = paintingOperator
      ? paintingState.some((el) => checkObjDisablity(el))
      : checkObjDisablity(paintingState[0])

    useEffect(() => {
      convertModelToFilterState()
    }, [paintingModel])

    const convertModelToFilterState = () => {
      let newFilterState = []
      if (!appliedFilter) newFilterState = initFilterState
      else if (appliedFilter?.conditions) {
        newFilterState = [...appliedFilter.conditions]
        setPaintingOperator(appliedFilter.operator)
      } else {
        newFilterState = [
          { ...appliedFilter },
          { type: '', filter: '', filter2: '' },
        ]
        setPaintingOperator(null)
      }
      setPaintingState(newFilterState)
    }

    const handleReset = () => {
      setPaintingState(initFilterState)
      setPaintingOperator(null)
      onChangePaintingModel('reset', column.colId)
    }

    const handleApply = () => {
      const notEmptyState = paintingState.filter((el) => el.type)
      if (!notEmptyState.length) handleReset()

      if (notEmptyState.length === 1 || !paintingOperator) {
        if (paintingOperator) setPaintingOperator(null)

        onChangePaintingModel(
          'apply',
          column.colId,
          notEmptyState,
          paintingOperator
        )
      } else if (notEmptyState.length === 2) {
        onChangePaintingModel(
          'apply',
          column.colId,
          paintingState,
          paintingOperator
        )
      }
    }

    return (
      <>
        <Container>
          <ConditionsBlock
            {...{ values, typeOptions }}
            operator={paintingOperator}
            filtersState={paintingState}
            onChangeFilter={(ind, type, value) =>
              onChangeFilters(ind, type, value, setPaintingState)
            }
            onChangeOperator={(value) => setPaintingOperator(value)}
          />
        </Container>
        <ApplyResetBtns
          onReset={handleReset}
          onApply={handleApply}
          isApplyBtnDisabled={isApplyBtnDisabled}
        />
      </>
    )
  }
)

PaintingContent.displayName = 'PaintingContent'

export default PaintingContent
