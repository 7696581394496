import {
  PDF2SMILES_DELETE_HIGHLIGHTS,
  PDF2SMILES_GET_DOCUMENT_INIT,
  PDF2SMILES_IS_DOCUMENT_HEADER_COLLAPSED,
  PDF2SMILES_SET_CURRENT_HIGHLIGHT_ID,
  PDF2SMILES_SET_CURRENT_PAGE,
  PDF2SMILES_SET_NEED_UPDATE_DOCUMENTS,
  PDF2SMILES_SET_SELECTED_HIGHLIGHTS,
  PDF2SMILES_SET_SELECTED_SMILES,
  PDF2SMILES_UPDATE_HIGHLIGHT_SMILES,
  PDF2SMILES_UPDATE_MARKUP,
  TOGGLE_SHOW_DIALOG,
} from 'store/constants/pdf2smiles'

export const setCurrentPage = (currentPage) => {
  return {
    type: PDF2SMILES_SET_CURRENT_PAGE,
    currentPage,
  }
}

export const setCurrentHighlightId = (currentHighlightId) => {
  return {
    type: PDF2SMILES_SET_CURRENT_HIGHLIGHT_ID,
    currentHighlightId,
  }
}

export const setIsDocumentHeaderCollapsed = (isDocumentHeaderCollapsed) => {
  return {
    type: PDF2SMILES_IS_DOCUMENT_HEADER_COLLAPSED,
    isDocumentHeaderCollapsed,
  }
}

export const updateMarkup = (markup) => {
  return {
    type: PDF2SMILES_UPDATE_MARKUP,
    markup,
  }
}

export const getPdf2SmilesDocument = (id) => {
  return {
    type: PDF2SMILES_GET_DOCUMENT_INIT,
    id,
  }
}

export const setNeedUpdateDocuments = (needUpdateDocuments) => {
  return {
    type: PDF2SMILES_SET_NEED_UPDATE_DOCUMENTS,
    needUpdateDocuments,
  }
}

export const setSelectedHighlights = (selectedHighlights) => {
  return {
    type: PDF2SMILES_SET_SELECTED_HIGHLIGHTS,
    selectedHighlights,
  }
}

export const deleteHighlights = (ids, document_id) => {
  return {
    type: PDF2SMILES_DELETE_HIGHLIGHTS,
    ids,
    document_id,
  }
}

export const editSmiles = (id, smiles, document_id) => {
  return {
    type: PDF2SMILES_UPDATE_HIGHLIGHT_SMILES,
    id,
    smiles,
    document_id,
  }
}
export const toggleShowDialog = (id) => {
  return {
    type: TOGGLE_SHOW_DIALOG,
    id,
  }
}

export const setSelectedSmiles = (selectedSmiles) => {
  return {
    type: PDF2SMILES_SET_SELECTED_SMILES,
    selectedSmiles,
  }
}
