export const GET_HISTORY_INIT = 'GET_HISTORY_INIT'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const GET_HISTORY_ERROR = 'GET_HISTORY_ERROR'

export const SHOW_PAST_RESULT = 'SHOW_PAST_RESULT'
export const RESET_PAST_RESULT = 'RESET_PAST_RESULT'

export const REPEAT_SEARCH = 'REPEAT_SEARCH'

export const SET_HISTORY_OPEN = 'SET_HISTORY_OPEN'
export const SET_HISTORY_FILTER = 'SET_HISTORY_FILTER'
