export const GET_STATISTICS_INIT = 'GET_STATISTICS_INIT'
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS'
export const GET_STATISTICS_ERROR = 'GET_STATISTICS_ERROR'

export const SELECT_STATISTICS_CHART_SECTION = 'SELECT_STATISTICS_CHART_SECTION'

export const SET_STATISTICS_NAME_LIST = 'SET_STATISTICS_NAME_LIST'

export const SET_STATISTICS_FILTER_TYPE = 'SET_STATISTICS_FILTER_TYPE'

export const CLEAR_STATISTICS_FILTER = 'CLEAR_STATISTICS_FILTER'
export const SET_STATISTICS_FILTER = 'SET_STATISTICS_FILTER'

export const SET_SUB_SECTION = 'SET_SUB_SECTION'

export const SET_STATISTICS_LOADING = 'SET_STATISTICS_LOADING'
