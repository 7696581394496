import { useTheme } from 'styled-components'

import Resizer from 'components/Resizer'
import CustomSwitch from 'components/common/customSwitch'
import { TitleSecondary } from 'components/common/text/index.style'
import { REACTION_SWITCH } from 'pages/ReactionListNew/config/config'

import {
  Container,
  Description,
  HeaderWithDescription,
  ToggleWithResizer,
} from './index.style'

const HeaderWithInstruments = ({ t, viewSize, mode, onClickToggleBtn }) => {
  const theme = useTheme()
  return (
    <Container>
      <HeaderWithDescription>
        <TitleSecondary>{t('reactions_prediction.label')}</TitleSecondary>
        <Description fontWeight={theme.fontWeight.medium} color="inherit">
          {t(`reactions_prediction.${mode}_description`)}
        </Description>
      </HeaderWithDescription>
      <ToggleWithResizer>
        <CustomSwitch
          items={REACTION_SWITCH}
          onClick={onClickToggleBtn}
          active={mode}
          btnWidth="fit-content"
          minBtnWidth="6.125rem"
          wrapperWidth="fit-content"
          bg="white"
        />
        {mode === 'forward' && <Resizer viewSize={viewSize} />}
      </ToggleWithResizer>
    </Container>
  )
}

export default HeaderWithInstruments
