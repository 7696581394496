import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Label,
  Caption,
  TextMedium,
  TitleTertiary,
} from 'components/common/text/index.style'
import Icon from 'components/Icon'
import SidebarButton from 'components/SidebarButton'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'

import {
  SignUpText,
  WelcomeBlock,
  RegisterButton,
  SignUpWithCopyRight,
  PasswordActionsBlock,
} from './index.style'
import ActivationError from './components/ActivationError'
import SuccessRegistration from './components/SuccessRegistration'

export const LoginBlock = ({
  activationError,
  isActivated,
  onRegisterClick,
  setIsActivated,
  isLoginDisabled,
  loading,
  onLogin,
  push,
  passwordError,
  onChange,
  checkEnter,
  emailError,
  data,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <>
      {!activationError && !isActivated && (
        <>
          <WelcomeBlock>
            <TitleTertiary
              fontWeight={theme.fontWeight.semibold}
              style={{ margin: 0 }}
            >
              {t('account.welcome')}
            </TitleTertiary>
            <TextMedium
              fontWeight={theme.fontWeight.light}
              color={theme.colors.text.secondary}
              style={{ margin: 0, display: 'block' }}
            >
              {t('account.enter_your_data')}
            </TextMedium>
          </WelcomeBlock>

          <CustomInput
            id="login"
            label={t('account.email')}
            placeholder={t('account.emailPlaceholder')}
            withClearButton
            value={data.login}
            onKeyUp={checkEnter}
            onChange={(value) => onChange(value, 'login')}
            type="email"
            autoComplete="email"
            error={emailError}
          />

          <>
            <CustomInput
              id="password"
              label={t('account.password')}
              placeholder={t('account.password_placeholder')}
              withClearButton
              value={data.password}
              onKeyUp={checkEnter}
              onChange={(value) => onChange(value, 'password')}
              type="password"
              autoComplete={'current-password'}
              error={passwordError}
            />
          </>

          <PasswordActionsBlock>
            {/* <CheckboxWithTitle
                  value={rememberData}
                  onChange={() => setRememberData((prev) => !prev)}
                  title={t('account.remember_data')}
                /> */}

            <CustomButton
              type="text"
              onClick={() => push('/recovery')}
              data-test="forgot-password-btn"
            >
              {t('account.forgotPassword')}
            </CustomButton>
          </PasswordActionsBlock>

          <CustomButton
            width="100%"
            type="accent"
            onClick={() => onLogin()}
            disabled={isLoginDisabled}
            data-test="login-btn"
          >
            {loading ? (
              <Icon size="1rem" iconType="loader" />
            ) : (
              t('account.signIn')
            )}
          </CustomButton>
        </>
      )}
      {/* аккаунт не активирован */}
      {!!activationError && <ActivationError />}

      {/* экран успешной регистрации */}
      {isActivated && !activationError && (
        <SuccessRegistration onLoginClick={() => setIsActivated(false)} />
      )}

      <SignUpWithCopyRight>
        {!isActivated && (
          <SignUpText>
            <Label style={{ margin: 0 }} color={theme.colors.text.secondary}>
              {' '}
              {t('account.hasNoAccount')}
            </Label>
            <RegisterButton
              type="text"
              onClick={onRegisterClick}
              gap="0.25rem"
              data-test="register-btn"
            >
              {t('account.signUp')}
              <Icon iconType="arrowRight" size="1rem" />
            </RegisterButton>
          </SignUpText>
        )}

        <SidebarButton />
        <Caption
          fontWeight={theme.fontWeight.medium}
          color={theme.colors.text.tertiary}
        >
          {t('account.copyRight').toUpperCase()}
          {new Date().getFullYear()}
        </Caption>
      </SignUpWithCopyRight>
    </>
  )
}

export default LoginBlock
