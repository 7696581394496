import React, { memo, useMemo, useState } from 'react'
import { AreaClosed, Bar, BarGroup, Circle, Line, LinePath } from '@visx/shape'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { scaleLinear, scaleBand, scaleOrdinal, scalePoint } from '@visx/scale'
import { curveMonotoneX } from '@visx/curve'
import { LinearGradient } from '@visx/gradient'
import { useTheme } from 'styled-components'
import { GridColumns, GridRows } from '@visx/grid'
import { ChartSvg } from 'pages/SpectraPrediction/index.style'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { COLORS_CONFIG, NO_DATA_VALUE } from '../config'
import { Group } from '@visx/group'
import { useSelector } from 'react-redux'
import { Label } from 'components/common/text/index.style'
import { ColorIndicator, TooltipItem, TooltipKey } from '../index.style'
import './index.css'
import { useTranslation } from 'react-i18next'

const LineChart = memo(({ data = [], showBar, width, height }) => {
  /** INIT - START */
  const theme = useTheme()
  const { t } = useTranslation()
  const [td, settd] = useState(null)
  const statistics_data_loading = useSelector(
    (state) => state.statistics.loading
  )
  const truncate_by = useSelector(
    (state) =>
      state.statistics.data?.result?.current_period?.created_at_truncate_by
  )

  const ICONS_SEC = theme.colors.icons.secondary
  const ICONS_TER = theme.colors.icons.tertiary
  const STROKE_COLOR = '#3495FF'
  const BACK_SEC = theme.colors.backgrounds.secondary

  const search_query_keys =
    Object.keys(data?.[0]).filter((i) => i !== 'date') ?? []
  const search_query_color_range = search_query_keys?.map(
    (_, index) => COLORS_CONFIG?.[index]
  )

  const margin = { top: 10, right: 50, bottom: 30, left: 61 }
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
  })
  const { tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip()

  const x = (d) => d.date || 'null'
  const yAccessor = (key) => (d) => d[key]
  const lineKeys = Object.keys(data[0]).filter((key) => key !== 'date')
  /** INIT - END */

  /** SCALES - START */
  const xScale = scalePoint({
    domain: data?.map(x),
    range: [0, innerWidth],
  })

  const yScale = scaleLinear({
    domain: [
      0,
      Math.max(...lineKeys.flatMap((key) => data.map(yAccessor(key)))),
    ],
    range: [innerHeight + margin.top, margin.top],
  })
  const xBarScale = scaleBand({
    domain: data.map(x),
    padding: 0.2,
  })

  const x1BarScale = scaleBand({
    domain: search_query_keys,
    padding: 0.1,
  })
  const yBarScale = scaleLinear({
    domain: [
      0,
      Math.max(
        ...data.map((d) =>
          Math.max(...search_query_keys.map((key) => Number(d[key])))
        )
      ),
    ],
    range: [innerHeight + margin.top, margin.top],
  })

  const colorScale = scaleOrdinal({
    domain: search_query_keys,
    range: search_query_color_range,
  })

  xBarScale.rangeRound([0, innerWidth])
  x1BarScale.rangeRound([0, xBarScale.bandwidth()])
  yBarScale.range([innerHeight, margin.top])
  /** SCALES - END */

  /** METHODS - START */
  const { pointerX, pointerY } = useMemo(() => {
    return {
      pointerX: showBar
        ? xBarScale(td?.date) + xBarScale.bandwidth() / 2 + margin.left
        : xScale(td?.date) + xScale.bandwidth() / 2 + margin.left,
      pointerY: td?.values?.map((el) => yScale(el?.value)),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [td])

  const handleBarMouseOver = (event, group) => {
    const { x0, bars } = group
    const tooltipXPosition = x0 + xBarScale.bandwidth() / 2 + margin.left
    const { x, y } = localPoint(event)
    const closestPoint = data.reduce(
      (closest, point) => {
        const { date } = point
        const mouseX = event.nativeEvent.offsetX - margin.left
        const pointX = xBarScale(date) + xBarScale.bandwidth() / 2

        const closestX = xBarScale(closest[0]?.date) + xBarScale.bandwidth() / 2
        return Math.abs(pointX - mouseX) < Math.abs(closestX - mouseX)
          ? [point]
          : closest
      },
      [data[0]]
    )

    if (closestPoint[0]?.date !== td?.date) {
      settd({
        date: closestPoint[0].date,
        values: lineKeys.map((key) => ({
          key,
          value: closestPoint[0][key],
        })),
      })
    }

    closestPoint?.[0]?.sum !== td && settd({ ...closestPoint?.[0], group })

    showTooltip({
      tooltipLeft: tooltipXPosition,
      tooltipTop: y,
      tooltipData: [
        bars[0]?.width,
        bars.map((bar) => bar.key),
        tooltipXPosition,
        bars[0]?.width,
      ],
    })
  }

  const handleMouseOver = ({ e: event, data }) => {
    const coords = localPoint(event.target.ownerSVGElement, event)
    const mouseX = event.nativeEvent.offsetX - margin.left
    const closestPoint = data.reduce(
      (closest, point) => {
        const pointX = xScale(point.date)
        const closestX = xScale(closest[0]?.date)
        return Math.abs(pointX - mouseX) < Math.abs(closestX - mouseX)
          ? [point]
          : closest
      },
      [data[0]]
    )

    if (closestPoint[0]?.date !== td?.date) {
      settd({
        date: closestPoint[0].date,
        values: lineKeys.map((key) => ({
          key,
          value: closestPoint[0][key],
        })),
      })
    }

    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: closestPoint[0],
    })
  }
  /** METHODS - END */

  return (
    <ChartSvg
      ref={containerRef}
      width={width}
      height={height}
      preserveAspectRatio="none"
    >
      {!showBar && (
        <rect
          x={margin.left}
          y={showBar ? 0 : margin.top}
          width={innerWidth}
          height={innerHeight}
          fill="none"
          stroke={BACK_SEC}
          strokeWidth={1}
        />
      )}
      <defs>
        <LinearGradient
          id="statistics-gradient"
          from={STROKE_COLOR}
          to={STROKE_COLOR}
          fromOpacity={0.3}
          toOpacity={0.1}
        />
      </defs>

      <GridRows
        scale={showBar ? yBarScale : yScale}
        left={margin.left}
        width={innerWidth}
        numTicks={data?.length}
        stroke={BACK_SEC}
      />

      {!showBar && (
        <GridColumns
          scale={xScale}
          top={height - margin.bottom}
          left={margin.left}
          height={-height + margin.top + margin.bottom}
          width={innerWidth}
          numTicks={data?.length}
          stroke={BACK_SEC}
          strokeWidth={1}
          strokeOpacity={1}
        />
      )}
      <AxisBottom
        hideAxisLine={true}
        hideTicks={true}
        numTicks={data?.length}
        scale={showBar ? xBarScale : xScale}
        top={showBar ? innerHeight : innerHeight + margin.top}
        left={margin.left}
        tickLabelProps={(el) => {
          return {
            fill: ICONS_SEC,
            fontSize: 11,
            textAnchor: 'middle',
            fontWeight: 400,
            fontFamily: 'Geologica Cursive',
            lineHeight: 14,
            dy: 2,
            angle: data?.length > 15 ? -20 : 0,
          }
        }}
      />
      <AxisLeft
        hideAxisLine={true}
        hideTicks={true}
        scale={showBar ? yBarScale : yScale}
        left={margin.left}
        tickFormat={(value) => value.toLocaleString('en-US').replace(/,/g, ' ')}
        tickLabelProps={() => ({
          fill: ICONS_SEC,
          fontSize: 11,
          textAnchor: 'middle',
          fontWeight: 400,
          fontFamily: 'Geologica Cursive',
          lineHeight: 14,
          angle: 0,
          dx: -25,
          dy: 0,
        })}
      />
      {!showBar && !statistics_data_loading && (
        <>
          {lineKeys?.length === 1 && (
            <AreaClosed
              data={data}
              x={(d) => xScale(d.date) + xScale.bandwidth() / 2 + margin.left}
              y={(d) => yScale(d[lineKeys[0]])}
              yScale={yScale}
              curve={curveMonotoneX}
              strokeWidth={0}
              stroke="url(#statistics-gradient)"
              fill="url(#statistics-gradient)"
            />
          )}
          {lineKeys?.map((key, index) => (
            <LinePath
              key={key}
              data={data}
              x={(d) => xScale(d.date) + xScale.bandwidth() / 2 + margin.left}
              y={(d) => yScale(yAccessor(key)(d))}
              stroke={COLORS_CONFIG[index]}
              strokeWidth={1.2}
              curve={curveMonotoneX}
            />
          ))}
        </>
      )}
      {!showBar && (
        <Bar
          x={margin.left}
          y={margin.top}
          width={innerWidth}
          height={innerHeight}
          fill="transparent"
          rx={14}
          onMouseMove={(e) => handleMouseOver({ e, data })}
          onMouseLeave={() => {
            settd(null)
            hideTooltip()
          }}
        />
      )}
      {tooltipOpen && (
        <TooltipInPortal
          key={Math.random()} // set this to random so it correctly updates with parent bounds
          top={tooltipTop}
          left={tooltipLeft}
          className="statistics-tooltip-wrapper"
        >
          {td?.date && <Label>{td?.date}</Label>}
          {!showBar ? (
            <>
              {td?.values &&
                td.values.map(({ key, value }, index) => (
                  <TooltipItem key={key}>
                    <TooltipKey>
                      <ColorIndicator color={COLORS_CONFIG[index]} />
                      <Label color={theme.colors.text.secondary}>
                        {t(`admin_statistics.tooltip.${key}`)}:
                      </Label>
                    </TooltipKey>
                    <Label color={theme.colors.text.primary}>
                      {value === NO_DATA_VALUE
                        ? t('admin_statistics.no_data')
                        : value}
                    </Label>
                  </TooltipItem>
                ))}
            </>
          ) : (
            <>
              {Array.isArray(td?.group?.bars) &&
                td.group.bars.map((bar, index) => (
                  <TooltipItem key={bar?.key}>
                    {bar?.value && (
                      <>
                        <TooltipKey>
                          <ColorIndicator color={COLORS_CONFIG[index]} />
                          <Label color={theme.colors.text.secondary}>
                            {t(`admin_statistics.${bar?.key}`)}:
                          </Label>
                        </TooltipKey>
                        <Label color={theme.colors.text.primary}>
                          {bar?.value === NO_DATA_VALUE
                            ? t('admin_statistics.no_data')
                            : bar?.value}
                        </Label>
                      </>
                    )}
                  </TooltipItem>
                ))}
            </>
          )}
        </TooltipInPortal>
      )}
      {pointerX && showBar && (
        <g>
          <Line
            from={{ x: pointerX, y: margin.top }}
            to={{ x: pointerX, y: innerHeight }}
            stroke={ICONS_TER}
            strokeWidth={2}
            strokeDasharray="3,3"
            pointerEvents="none"
          />
        </g>
      )}
      {td && pointerX && pointerY && !showBar && (
        <>
          <g>
            <Line
              from={{ x: pointerX, y: margin.top }}
              to={{ x: pointerX, y: innerHeight + margin.top }}
              stroke={ICONS_TER}
              strokeWidth={1}
              strokeDasharray="3,3"
              pointerEvents="none"
            />
          </g>
          {pointerY?.map((pointer, index) => {
            return (
              <g key={index}>
                <Line
                  from={{ x: margin.left, y: pointer }}
                  to={{ x: innerWidth + margin.left, y: pointer }}
                  strokeDasharray="3,3"
                  stroke={ICONS_TER}
                  strokeWidth={1}
                  pointerEvents="none"
                />
                <g>
                  <Circle
                    cx={pointerX}
                    cy={pointer}
                    r={4}
                    fill={COLORS_CONFIG[index] ?? '#3495FF'}
                    stroke={COLORS_CONFIG[index] ?? '#3495FF'}
                    strokeWidth={1}
                  />
                </g>
              </g>
            )
          })}
        </>
      )}

      {showBar && !statistics_data_loading && (
        <BarGroup
          data={data}
          keys={search_query_keys}
          height={innerHeight}
          width={innerWidth}
          x0={x}
          x0Scale={xBarScale}
          x1Scale={x1BarScale}
          yScale={yBarScale}
          color={colorScale}
        >
          {(groups) => {
            return groups?.map((group) => {
              const { index, bars, x0 } = group || {}
              return (
                <Group key={`bar-group-${index}-${x0}`} left={x0 + margin.left}>
                  {bars?.map((bar) => (
                    <rect
                      key={`bar-group-bar-${group?.index}-${bar?.index}-${bar?.key}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar?.width}
                      height={bar?.height}
                      fill={bar?.color}
                      rx={6}
                    />
                  ))}
                  <Bar
                    left={x0 + margin.left}
                    width={xBarScale.bandwidth()}
                    height={innerHeight}
                    fill="transparent"
                    onMouseOver={(e) => handleBarMouseOver(e, group)}
                    onMouseLeave={() => {
                      settd(null)
                      hideTooltip()
                    }}
                  />
                </Group>
              )
            })
          }}
        </BarGroup>
      )}
    </ChartSvg>
  )
})

LineChart.displayName = 'LineChart'

export default LineChart
