import { v4 as uuidv4 } from 'uuid'
import { useState, useEffect } from 'react'
import { push } from 'connected-react-router'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addMinutes, differenceInDays, parseISO } from 'date-fns'

import Promo from 'components/Promo'
import history from 'services/history'

import { useAuth } from 'utils/auth/auth'
import { fetch } from 'services/rest'
import LoadingOverlay from 'components/LoadingOverlay'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import { addNotification } from 'store/actions/notifications'
import { emailReg, validatePasswordLength } from 'utils/common/common'
import {
  clearUserTempCreds,
  setLicenseExpiredInfo,
  setUserTempCreds,
} from 'store/actions/auth'
import { TIPS_DELAY } from 'config/constants'
import { readStorage } from 'utils/storage/storage'

import {
  LICENSE_NOTIFY_DATE_KEY,
  LICENSE_DIFF_DAYS_NOTIFICATIONS,
} from './config'
import LoginBlock from './LoginBlock'
import {
  Wrapper,
  LoginWithPromo,
  Container,
  LicenseWarning,
} from './index.style'
import {
  Caption,
  TextMedium,
  TitleTertiary,
} from 'components/common/text/index.style'
import Icon from 'components/Icon'
import LicenseProblemsBlock from './LicenseProblemsBlock'

const LoginPage = ({ push, path, match, setLicenseExpiredInfo }) => {
  const logoImage = useGetLogoImage()
  const theme = useTheme()
  const dispatch = useDispatch()
  const tempUserCreds = useSelector((store) => store.auth?.userCreds)
  const { t } = useTranslation()

  const { isAuthenticated, login, loading: authLoading, userInfo } = useAuth()
  const [data, setData] = useState({
    login: tempUserCreds?.email ?? '',
    password: tempUserCreds?.password ?? '',
    showPassword: false,
  })
  const [activationError, setActivationError] = useState('')
  const [isActivated, setIsActivated] = useState(false)
  const [licenseError, setLicenseError] = useState(null)
  const [isLicenseWarning, setIsLicenseWarning] = useState(false)

  const [loading, setLoading] = useState(false)

  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')

  const isLoginDisabled =
    !data.login || !data.password || !!emailError || !!passwordError

  const handleActivate = async (token) => {
    try {
      await fetch(`/profile/activate?token=${token}`)
      setIsActivated(true)
    } catch (e) {
      if (e?.response?.data?.result?.error_message === 'Token is not valid') {
        const notify = {
          id: uuidv4(),
          name: 'notification.error',
          text: 'registration.invalid_token',
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
        }
        dispatch(addNotification(notify))

        setActivationError(
          e?.response?.data?.result?.error_message ||
            t('notification.wrong_activation')
        )
      }
    }
  }

  useEffect(() => {
    const token = match?.params?.id
    if (token) {
      handleActivate(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      const currentDate = new Date()
      const lastLicenseInfoDate = readStorage(LICENSE_NOTIFY_DATE_KEY)

      if (
        !lastLicenseInfoDate ||
        currentDate - new Date(lastLicenseInfoDate) > TIPS_DELAY
      ) {
        const { license_expired_at, license_is_free_trial } = userInfo || {}

        if (license_expired_at) {
          const utcDate = parseISO(license_expired_at)
          const timeZoneOffset = -new Date().getTimezoneOffset()
          const modifiedDate = addMinutes(utcDate, timeZoneOffset)
          const now = new Date()
          const diffDays = differenceInDays(modifiedDate, now)

          if (LICENSE_DIFF_DAYS_NOTIFICATIONS.includes(diffDays)) {
            setLicenseExpiredInfo({
              diffDays,
              isFreeTrial: license_is_free_trial,
            })
          }
        }
      }

      const haasPrevPath =
        history?.location?.state?.prevPath &&
        history?.location?.state?.prevPath !== '/'
      haasPrevPath ? push(history.location.state.prevPath) : push('/search')
    }
  }, [isAuthenticated, push])

  const handleChange = (value, id) => {
    id === 'login' && !!emailError && setEmailError('')
    id === 'password' && !!passwordError && setPasswordError('')
    setData({ ...data, [id]: value })
  }

  const handleLogin = async (isLoginAfterActivation, callback) => {
    if (isLoginDisabled) return

    if (!emailReg.test(data.login)) {
      setEmailError('account.emailError')
      return
    }

    if (!validatePasswordLength(data.password)) {
      setPasswordError('account.passwordPlaceholder')
      return
    }

    try {
      setLoading(true)
      await login(data.login, data.password)
      setLoading(false)
      dispatch(clearUserTempCreds())
    } catch (e) {
      setLoading(false)

      if (e.data.status === 400) {
        const id = uuidv4()
        const errorToShow =
          e.message === 'Wrong email or password'
            ? 'account.wrongEmailOrPassword'
            : e.message

        const notify = {
          id,
          name: 'notification.error',
          text: errorToShow,
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
          needTranslateText: errorToShow === 'account.wrongEmailOrPassword',
        }

        dispatch(addNotification(notify))
      } else if (e.data.status === 403) {
        callback && callback()
        const err = e?.data?.data?.result
        setLicenseError(err)
        if (
          err &&
          err?.error_message ===
            'License None is invalid, contact with customer admin' &&
          err?.error_data?.customer_id &&
          !err?.error_data?.license_is_free_trial &&
          !err?.error_data?.license_expired_at
        ) {
          setIsLicenseWarning(true)
        }
        if (isLoginAfterActivation) {
          const notify = {
            id: uuidv4(),
            name: 'notification.error',
            text: 'account.license_is_invalid',
            notification_type: 'error',
            autoRemove: true,
            timeout: 5000,
          }
          dispatch(addNotification(notify))
        }
      } else if (e?.message?.includes('400')) {
        setActivationError(e.message)
      }
    }
  }

  const checkEnter = (e) => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  const handleRegisterClick = () => {
    if (path === '/login') {
      if (!!data.login || !!data.password) {
        dispatch(
          setUserTempCreds({ email: data.login, password: data.password })
        )
      }
    }
    push('/register')
  }

  return (authLoading && !loading) || isAuthenticated ? (
    <LoadingOverlay />
  ) : (
    <Wrapper>
      <LoginWithPromo>
        <Container
          onClick={(e) => e.stopPropagation()}
          method="dialog"
          isLicenseWarning={isLicenseWarning}
        >
          <img src={logoImage} alt="syntelly" />
          {!licenseError && (
            <LoginBlock
              {...{
                push,
                data,
                loading,
                emailError,
                checkEnter,
                isActivated,
                passwordError,
                setIsActivated,
                activationError,
                isLoginDisabled,
              }}
              onLogin={handleLogin}
              onChange={handleChange}
              onRegisterClick={handleRegisterClick}
            />
          )}
          {licenseError && !isLicenseWarning && (
            <LicenseProblemsBlock
              onLogin={handleLogin}
              {...{ licenseError, setLicenseError, setIsLicenseWarning }}
            />
          )}
          {licenseError && isLicenseWarning && (
            <>
              <Icon iconType={'warning3'} size={'3.75rem'} />
              <LicenseWarning>
                <TitleTertiary fontWeight={theme.fontWeight.semibold}>
                  {t('account.you_do_not_have_valid_license')}
                </TitleTertiary>
                <TextMedium
                  color={theme.colors.text.secondary}
                  fontWeight={theme.fontWeight.light}
                >
                  {`${t('account.contact_customer_admin')} `}
                  <span>
                    {licenseError?.error_data?.customer_admin
                      ? licenseError.error_data.customer_admin
                      : 'admin@syntelly.ru'}
                  </span>
                </TextMedium>
                <TextMedium
                  color={theme.colors.text.secondary}
                  fontWeight={theme.fontWeight.light}
                >
                  {t('account.we_check_limits')}
                </TextMedium>
              </LicenseWarning>
              <Caption
                fontWeight={theme.fontWeight.medium}
                color={theme.colors.text.tertiary}
                style={{ marginTop: 'auto' }}
              >
                {t('account.copyRight').toUpperCase()}
                {new Date().getFullYear()}
              </Caption>
            </>
          )}
        </Container>
        <Promo />
      </LoginWithPromo>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    path: state.router.location.pathname,
  }
}

const mapDispatchToProps = {
  push,
  setLicenseExpiredInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
