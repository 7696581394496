import styled from 'styled-components'

const tablet = 768
const mobile = 375

export const Container = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  position: fixed;

  @media (max-width: ${tablet}px) {
    display: flex;
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 5rem;

    & > svg {
      width: 200px;
      height: 36px;
    }
  }

  @media (max-width: ${mobile}px) {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 1.5rem;
    padding-bottom: 3rem;

    & > svg {
      width: 176px;
      height: 32px;
    }
  }

  @media print {
    display: none;
  }
`

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex: 1;

  @media (max-width: ${tablet}px) {
    & > svg {
      width: 164px;
      height: 164px;
    }
  }

  @media (max-width: ${mobile}px) {
    & > svg {
      width: 124px;
      height: 124px;
    }
  }
`

export const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 455px;

  @media (max-width: ${mobile}px) {
    max-width: 343px;
  }
`

export const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.336px;
  color: #1f2933;
  text-align: center;

  @media (max-width: ${mobile}px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.24px;
  }
`

export const Text = styled.div`
  color: #616e7c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.168px;
  text-align: center;
`
