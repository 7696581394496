import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  & > span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #1f2933;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    width: 318px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`

export const Body = styled.div`
  display: flex;
  padding: 1rem;
  row-gap: 0.75rem;
  flex-direction: column;
`
export const Bottom = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.colors.backgrounds.contextMenuHover};
`
