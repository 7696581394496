import styled from 'styled-components'

export const NotificationAdmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  row-gap: 0.75rem;
`

export const NotificationHeader = styled.div`
  color: #1f2933;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  & > span {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const NotificationAdmitButtons = styled.div`
  display: flex;
  column-gap: 0.5rem;
`
