import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import { TitleTertiary } from 'components/common/text/index.style'

import { Content } from './index.style'

const ChangedInfoDialog = ({ onClose }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <CustomDialog width="25.5rem" showCloseIcon={false} onClose={onClose}>
      <Content>
        <Icon iconType="successLight" size="4rem" />
        <TitleTertiary fontWeight={theme.fontWeight.semibold}>
          {t('account.password_changed')}
        </TitleTertiary>
        <CustomButton width={'100%'} type="secondary" onClick={onClose}>
          {t('account.ok')}
        </CustomButton>
      </Content>
    </CustomDialog>
  )
}

export default ChangedInfoDialog
