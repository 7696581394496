import Icon from 'components/Icon'

import { CustomInputLabel, InputLabelContainer } from './index.style'

const CustomInputLabelBlock = ({
  id,
  type,
  label,
  error,
  onFocus,
  warning,
  isValidated,
  isValidating,
  showTooltip,
  needTooltip,
  setShowTooltip,
  withPasswordTooltip,
}) => {
  return (
    <InputLabelContainer
      data-tooltip-id={
        withPasswordTooltip
          ? 'password-req-tooltip'
          : error
          ? `${type}-${id}-error`
          : `${type}-${id}-warning`
      }
      onMouseEnter={() => needTooltip && !showTooltip && setShowTooltip(true)}
      onMouseLeave={() => showTooltip && setShowTooltip(false)}
    >
      <>
        <CustomInputLabel htmlFor={id} onFocus={onFocus}>
          {label}
        </CustomInputLabel>
        {isValidating && (
          <Icon
            size="1rem"
            iconType="loader"
            className="icon-login-validating"
          />
        )}
        {(!!error || !!warning) && (
          <>
            <Icon
              size="1rem"
              iconType={warning ? 'warning2' : 'description'}
              className={warning ? 'icon-login-warning' : 'icon-login-error'}
            />
          </>
        )}
        {isValidated && (
          <Icon size="1rem" iconType="tick" className="icon-login-validated" />
        )}
      </>
    </InputLabelContainer>
  )
}

export default CustomInputLabelBlock
