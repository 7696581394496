import styled from 'styled-components'

export const RightBlock = styled.div`
  background: #ffffff;
  border-radius: 12px;
  min-width: 324px;
  width: 324px;
  height: fit-content;

  @media (max-width: 1200px) {
    min-width: 200px;
    width: 200px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px;
  }

  & > div:not(:first-child) {
    border-top: 2px solid #f3f5f8;
  }
`
export const ColoredSpan = styled.span`
  color: ${({ color }) => (color ? color : '#2D3745')};
`

export const AuthorsBlock = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  min-height: 48px;
  height: 48px;
  overflow: hidden;
`
export const FixedBlock = styled.div`
  max-height: ${({ height }) => (height ? height : '136px')};
`
