import { PROMO_CONFIG } from 'components/Promo/config'
import { IndicatorContainer, IndicatorItem } from './index.style'

const Indicator = ({ active }) => {
  const arr = new Array(PROMO_CONFIG.length).fill('')
  return (
    <IndicatorContainer>
      {arr.map((_, ind) => (
        <IndicatorItem active={active} key={ind} ind={ind}>
          {ind === active && <span />}
        </IndicatorItem>
      ))}
    </IndicatorContainer>
  )
}
export default Indicator
