import styled from 'styled-components'

import Icon from 'components/Icon'

const getSize = ({ size }) => size
const getPosition = ({ size }) =>
  size === '20px' || size === '1.25rem' ? '1rem' : '0.75rem'

export const CloseIcon = styled(Icon)`
  color: ${({ color, theme }) => (color ? color : theme.colors.icons.tertiary)};
  position: absolute;
  right: ${getPosition};
  top: ${getPosition};
  cursor: pointer;
  width: ${getSize};
  height: ${getSize};
  z-index: 1;

  @media print {
    display: none;
  }
`
