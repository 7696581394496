import styled, { css } from 'styled-components'

import CustomButton from 'components/common/customButton'

export const UserCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    p[id^='user-label'] {
      color: ${({ theme }) => theme.colors.text.accentPrimary};
      transition: all 0.3s;
    }

    ${({ isApplication }) =>
      isApplication &&
      css`
        .questionnaire-data-label,
        .questionnaire-data-label + div {
          color: ${({ theme }) => theme.colors.text.accentPrimary};
          transition: all 0.3s;
        }
      `}

    box-shadow: 0px 0px 4px 0px #2d374505, 0px -4px 12px 0px #2d37450a,
      0px 4px 12px 0px #2d37450a;
  }
`

export const CardBlock = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  justify-content: ${({ row }) => (row ? 'start' : 'center')};
  align-items: ${({ row, alignItems }) =>
    alignItems ? alignItems : row ? 'center' : 'flex-start'};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  gap: ${({ gap }) => (gap ? gap : '0.75rem')};
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'initial')};

  &:hover {
    color: ${({ theme }) => theme.colors.text.accent};
  }
`

export const UserRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  width: 100%;

  & > svg {
    min-width: 1rem;
  }
  .react-tel-input {
    display: none;
  }
`

export const CardButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
  width: 4rem;
`

export const RestBlock = styled.div`
  display: flex;
  width: calc(100% - 6rem);
  padding: 1rem;
`

export const ToggleButton = styled(CustomButton)`
  background-color: ${({ theme }) => theme.colors.buttons.secondary};
  color: ${({ isGreen, isRemoveBtn, theme }) =>
    isGreen
      ? theme.colors.statuses.success
      : isRemoveBtn
      ? theme.colors.icons.tertiary
      : theme.colors.statuses.error};

  &:hover {
    color: ${({ isGreen, theme }) =>
      isGreen ? theme.colors.statuses.success : theme.colors.statuses.error};

    background-color: ${({ isGreen, isRemoveBtn, theme }) =>
      isGreen
        ? theme.colors.statuses.successBg
        : isRemoveBtn
        ? theme.colors.buttons.secondary
        : theme.colors.statuses.errorBg};
  }
`

export const CompanyOverflowBlock = styled.div`
  display: flex;
  max-width: 100%;
  gap: 0.25rem;
  transition: all 0.15s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text.accentPrimary};

    & > svg {
      color: ${({ theme }) => theme.colors.text.accentPrimary};
    }
  }
`

export const Info = styled.div`
  width: 100%;
  gap: ${({ gap }) => gap || '0.5rem'};
  display: flex;
  flex-direction: column;
`
