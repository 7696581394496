import { put } from 'redux-saga/effects'
import { UPDATE_MOLECULE_PROPS_ERROR } from 'store/constants/molecule'

export const handleCalculationsError = (name) => {
  switch (name) {
    case 'iupacConverter':
      return put({
        type: UPDATE_MOLECULE_PROPS_ERROR,
        data: {
          iupac: 'Unable to generate valid IUPAC name',
        },
        sources: {},
      })
    default:
      return
  }
}
