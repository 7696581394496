import {
  HANDLE_SHOW_CONTEXT_MENU,
  HIDE_CONTEXT_MENU,
  UPDATE_DISABLED_ACTIONS,
} from '../constants/contextMenu'

const initialState = { contextMenuState: {} }

const contextMenuReducer = (state = initialState, data) => {
  const { type, payload } = data
  switch (type) {
    case HANDLE_SHOW_CONTEXT_MENU:
      return { ...state, contextMenuState: payload }

    case HIDE_CONTEXT_MENU:
      return initialState

    case UPDATE_DISABLED_ACTIONS:
      return {
        ...state,
        contextMenuState: {
          ...state.contextMenuState,
          item: {
            ...state.contextMenuState.item,
            disabledActions: payload,
          },
        },
      }

    default:
      return state
  }
}

export default contextMenuReducer
