import { useMemo } from 'react'

import CustomSelect from 'components/CustomSelect'
import TripleToggleSwitch from 'components/common/customTripleSwitch'
import { LiteratureSwitchWrapper } from 'pages/MoleculesList/Panel/index.style'

import { Container } from './index.style'

const SortPanel = ({
  sortType,
  switcherID,
  sortOptions,
  showOnlySort,
  onChangeSorting,
  selectClassName,
}) => {
  const selected = useMemo(() => {
    return sortOptions.find(({ value }) => value === sortType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType])

  const DIRECTION_CONFIG = {
    left: {
      title: 'search.sortDirection.desc',
      value: 'desc',
      icon: 'desc',
    },
    center: {
      title: 'search.sortDirection.asc',
      value: 'asc',
      icon: 'asc',
      disabled:
        ((switcherID === 'litSearchDirection' ||
          switcherID === 'basketsDirection') &&
          sortType === 'relevance') ||
        showOnlySort,
    },
  }

  return (
    <Container>
      <CustomSelect
        selectedValue={selected}
        onChange={(option) => onChangeSorting('type', option.value)}
        options={sortOptions.filter((el) => !el?.hidden)}
        disabled={showOnlySort}
        withWhiteBackground
        className={selectClassName}
      />
      <LiteratureSwitchWrapper>
        <TripleToggleSwitch
          className="direction-switcher"
          switcherID={switcherID}
          disabled={showOnlySort}
          options={DIRECTION_CONFIG}
          onChange={(value) =>
            onChangeSorting('direction', DIRECTION_CONFIG[value].value)
          }
        />
      </LiteratureSwitchWrapper>
    </Container>
  )
}

export default SortPanel
