import { Subheadline } from 'components/common/text/index.style'
import styled, { css } from 'styled-components'

const hoverColorStyles = css`
  &:hover {
    color: #42c1bf;
    & > svg,
    div {
      color: #42c1bf;
    }
  }
`

export const KeyValueProperty = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;

  & .property-name {
    white-space: wrap;
    max-width: 13.75rem;
  }

  & .property-value {
    width: 100%;
    position: relative;
    width: fit-content;
    display: flex;
    // align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media print {
    break-inside: avoid;
    visibility: visible;
    & .property-name {
      white-space: nowrap !important;
    }
  }
`

export const DescriptedPropertyKey = styled.a`
  display: flex;
  gap: 4px;
  cursor: pointer;

  & > svg {
    color: #616e7c;
    transition: 0.2s;
    min-width: 1rem;

    ${({ status }) =>
      status &&
      css`
        color: ${({ status }) => {
          if (status === 'success') return '#34C759'
          if (status === 'warning') return '#FF3B30'
        }};
      `}
  }

  ${hoverColorStyles}

  @media print {
    & > svg {
      display: none;
    }
  }
`

export const ColoredSubheadline = styled(Subheadline)`
  font-weight: 500;

  ${({ color }) =>
    color &&
    css`
      color: ${({ color, status }) => {
        if (status === 'success') return '#34C759'
        if (status === 'warning') return '#FF3B30'
        return color
      }};
    `}
`
