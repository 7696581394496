import { memo, useState } from 'react'
import {
  Condition,
  ConditionHeader,
  ControlButtons,
  TrashIcon,
} from './index.style'
import { PlusIcon } from '../../index.style'
import { useTranslation } from 'react-i18next'
import { CONFLICT_OPTIONS, LANGUAGES_OPTIONS } from '../../constants'
import CustomButton from 'components/common/customButton'
import { connect, useDispatch } from 'react-redux'
import { setLitFilterConfig } from 'store/actions/literature'
import FilterRow from '../FilterRow'
import { Subheadline } from 'components/common/text/index.style'
import { setLeftFilterLitDocType } from 'store/actions/filter'

const ConditionBlock = memo(({ currentElement, config, docType }) => {
  const [hoveredButton, setHoveredButton] = useState('add')
  const disabledAddButton =
    currentElement === 'published_date' ||
    (currentElement === 'language' &&
      LANGUAGES_OPTIONS.length === config?.language?.values.length) ||
    (currentElement !== 'name' &&
      config[currentElement].values.length === 1 &&
      config[currentElement].values[0] === '')

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDeleteRow = (index) => {
    if (index === 0) {
      const configCopy = structuredClone(config)

      delete configCopy[currentElement]
      dispatch(setLitFilterConfig(configCopy))

      if (
        !Object.keys(configCopy).length ||
        !Object.keys(configCopy).find(
          (el) =>
            CONFLICT_OPTIONS.patent.includes(el) ||
            CONFLICT_OPTIONS.article.includes(el)
        )
      ) {
        dispatch(setLeftFilterLitDocType(['article', 'patent']))
      }
    } else {
      const currentTypeObject = config[currentElement]
      const newValues = config[currentElement].values
      dispatch(
        setLitFilterConfig({
          ...config,
          [currentElement]: {
            ...currentTypeObject,
            values: [
              ...newValues.slice(0, index),
              ...newValues.slice(index + 1),
            ],
          },
        })
      )
    }
  }

  const handleAddRow = () => {
    const currentTypeObject = config[currentElement]
    const newValues = config[currentElement].values
    if (
      currentElement !== 'name' &&
      newValues.length === 1 &&
      newValues[0] === ''
    )
      return

    dispatch(
      setLitFilterConfig({
        ...config,
        [currentElement]: {
          ...currentTypeObject,
          values: [...newValues, ''],
        },
      })
    )
  }

  return (
    <Condition>
      <ConditionHeader>
        <div>
          {currentElement === 'name'
            ? t(`filter.literature.${docType}`)
            : currentElement === 'company'
            ? t(`filter.literature.owner`)
            : t(`filter.literature.${currentElement}`)}
        </div>
        <ControlButtons>
          {currentElement !== 'name' &&
            currentElement !== 'text' &&
            currentElement !== 'claims' && (
              <CustomButton
                type="text"
                onMouseOver={() => setHoveredButton('add')}
                onClick={() => handleAddRow()}
                disabled={disabledAddButton}
              >
                <Subheadline color="inherit">
                  {hoveredButton === 'add' && t('filter.literature.add')}{' '}
                </Subheadline>
                <PlusIcon iconType="add" marginleft="4px" size="1rem" />
              </CustomButton>
            )}
          <CustomButton
            type="text"
            onMouseOver={() => setHoveredButton('delete')}
            onMouseLeave={() => setHoveredButton('add')}
            onClick={() => handleDeleteRow(0)}
          >
            <Subheadline color="inherit">
              {hoveredButton === 'delete' && t('filter.literature.delete')}{' '}
            </Subheadline>
            <TrashIcon iconType="deleted" size="1rem" marginleft="4px" />
          </CustomButton>
        </ControlButtons>
      </ConditionHeader>
      <FilterRow
        currentElement={currentElement}
        handleDeleteRow={handleDeleteRow}
      />
    </Condition>
  )
})

const mapStateToProps = (state) => {
  return {
    config: state.literature.filter.config,
    docType: state.literature.filter.docType,
  }
}
ConditionBlock.displayName = 'ConditionBlock'

export default connect(mapStateToProps)(ConditionBlock)
