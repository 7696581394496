import styled from 'styled-components'

export const HistoryPreviewWrapper = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const HistoryPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 24.5rem;
  height: 19rem;
  row-gap: 1.25rem;
  align-items: center;
`

export const IconWrapper = styled.div`
  display: flex;
  width: 10rem;
  & > svg {
    width: 10rem;
    color: #42c1bf;
  }
`

export const HistoryTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const HistoryPreviewTitle = styled.span`
  color: ${({ theme }) => theme.colors.icons.primary};
  margin-bottom: 0.25rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`

export const HistoryPreviewText = styled.span`
  color: ${({ theme }) => theme.colors.icons.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  max-width: 18rem;
`
