import { select, takeEvery } from 'redux-saga/effects'

import { SETTINGS_LANG_CHANGE } from 'store/constants/settings'
import { getUserData } from 'store/selectors'
import { updateLanguage } from 'utils/common/common'

function* setLanguage({ lang }) {
  const data = yield select(getUserData)
  if (!Object.getOwnPropertyNames(data)?.length) return
  yield updateLanguage(lang)
}

export function* changeLanguageWatcher() {
  yield takeEvery(SETTINGS_LANG_CHANGE, setLanguage)
}

const watchers = [changeLanguageWatcher()]
export default watchers
