export const getPagesRange = (start, end) => {
  let length = end - start + 1
  return Array.from({ length }, (_, idx) => idx + start)
}

export const calculateMolWidth = (viewSize, withData) => {
  const molWidth = withData
    ? 80 + 190 + viewSize * 50 + 10
    : 190 + viewSize * 50 + 10
  return { molWidth }
}

export const calculateMolWidthInPercents = (viewSize) => {
  if (viewSize === 1) return { molWidth: 20, molMaxWidth: 20.5 }
  if (viewSize === 3 || (viewSize > 1 && viewSize < 6))
    return { molWidth: 25, molMaxWidth: 26.75 }
  if (viewSize === 6) return { molWidth: 35, molMaxWidth: 36.125 }
  return { molWidth: 20, molMaxWidth: 20.5 }
}

export const parseCSVString = (csvString) => {
  const rows = csvString.trim().split('\n')
  const headers = rows[0].split(',').map((header) => header.trim())

  const parsedData = []

  const parseRow = (row) => {
    const values = []
    let value = ''
    let inQuotes = false

    for (const char of row) {
      if (char === '"') {
        inQuotes = !inQuotes
      } else if (char === ',' && !inQuotes) {
        values.push(value.trim())
        value = ''
      } else {
        value += char
      }
    }
    values.push(value.trim())

    return values
  }

  for (let i = 1; i < rows.length; i++) {
    const rowData = {}
    const values = parseRow(rows[i])

    for (let j = 0; j < headers.length; j++) {
      rowData[headers[j]] = values[j] || ''
    }

    parsedData.push(rowData)
  }

  return parsedData
}
