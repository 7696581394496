export const PDF2SMILES_SET_CURRENT_PAGE = 'PDF2SMILES_SET_CURRENT_PAGE'
export const PDF2SMILES_SET_CURRENT_HIGHLIGHT_ID =
  'PDF2SMILES_SET_CURRENT_HIGHLIGHT_ID'
export const PDF2SMILES_IS_DOCUMENT_HEADER_COLLAPSED =
  'PDF2SMILES_IS_DOCUMENT_HEADER_COLLAPSED'

export const PDF2SMILES_GET_DOCUMENT_INIT = 'PDF2SMILES_GET_DOCUMENT_INIT'
export const PDF2SMILES_GET_DOCUMENT_SUCCESS = 'PDF2SMILES_GET_DOCUMENT_SUCCESS'
export const PDF2SMILES_GET_DOCUMENT_ERROR = 'PDF2SMILES_GET_DOCUMENT_ERROR'
export const PDF2SMILES_UPDATE_HIGHLIGHT_SMILES =
  'PDF2SMILES_UPDATE_HIGHLIGHT_SMILES'
export const PDF2SMILES_UPDATE_MARKUP = 'PDF2SMILES_UPDATE_MARKUP'

export const PDF2SMILES_SET_NEED_UPDATE_DOCUMENTS =
  'PDF2SMILES_SET_NEED_UPDATE_DOCUMENTS'

export const PDF2SMILES_SET_SELECTED_HIGHLIGHTS =
  'PDF2SMILES_SET_SELECTED_HIGHLIGHTS'
export const PDF2SMILES_DELETE_HIGHLIGHTS = 'PDF2SMILES_DELETE_HIGHLIGHTS'
export const TOGGLE_SHOW_DIALOG = 'TOGGLE_SHOW_DIALOG'
export const PDF2SMILES_SET_SELECTED_SMILES = 'PDF2SMILES_SET_SELECTED_SMILES'
