import { format, zonedTimeToUtc } from 'date-fns-tz'

import { CURRENT_TIME_ZONE, NULL_TIME_ZONE } from './constants'
import { addMinutes, parseISO } from 'date-fns'

export const formatUtcDigitDateByFormat = (date, formatStr) => {
  const utcDate = zonedTimeToUtc(date, NULL_TIME_ZONE)

  return format(utcDate, formatStr, { timeZone: CURRENT_TIME_ZONE })
}

export const formatUtcDigitDate = (date) =>
  formatUtcDigitDateByFormat(date, 'dd.MM.yyyy HH:mm')

export const formatUtcShortDate = (date) =>
  formatUtcDigitDateByFormat(date, 'dd.MM.yyyy')

export const formatUtcDateWithTimeZone = (date) => {
  const utcDate = parseISO(date)
  const timeZoneOffset = -new Date().getTimezoneOffset()
  const modifiedDate = addMinutes(utcDate, timeZoneOffset)
  return modifiedDate
}
