import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Headline, TitleSecondary } from 'components/common/text/index.style'
import CustomSnackbar from 'components/HotTip/index'
import {
  Container,
  Documents,
  FileUploadInput,
  Header,
  P2SDropzoneWrapper,
} from './index.style'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import { addNotification } from 'store/actions/notifications'
import { store } from 'services/rest'
import LoadingOverlay from 'components/LoadingOverlay'
import Document from './Document'
import {
  DOCUMENTS_CONTAINER_ID,
  PDF2SMILES_GET_DOCS_URL,
  PDF2SMILES_UPDATE_DOCUMENT_URL,
  PDF2SMILES_UPLOAD_D0C_ONLY_ONE,
  PDF2SMILES_UPLOAD_D0C_SUCCESS,
  PDF2SMILES_UPLOAD_HEADERS,
  PDF2SMILES_UPLOAD_URL,
} from './config/config'
import {
  setNeedUpdateDocuments,
  toggleShowDialog,
} from 'store/actions/pdf2smiles'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useDropzone } from 'react-dropzone'
import EditDialog from './EditDialog'
import CustomScrollbar from 'components/CustomScrollbar'
import { useTheme } from 'styled-components'

const Pdf2Smiles = ({
  /*showPublicPdf, setShowPublicPdf,*/ addNotification,
  needUpdateDocuments,
  setNeedUpdateDocuments,
  tasks,
  dialogId,
  toggleShowDialog,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const fileInput = useRef(null)
  const downloadBtnHash = uuidv4()

  // const tabsConfig = [
  //   {
  //     text: 'thematic',
  //     onClick: () => setShowPublicPdf(true),
  //     isActive: showPublicPdf,
  //   },
  //   {
  //     text: 'private',
  //     onClick: () => setShowPublicPdf(false),
  //     isActive: !showPublicPdf,
  //   },
  // ]

  const handleUploadPdfClick = () => {
    fileInput.current.click()
  }
  const handleUploadFile = async (e, file) => {
    let notifyName = PDF2SMILES_UPLOAD_D0C_SUCCESS

    const formData = new FormData()
    formData.append('file', file)

    try {
      setLoading(true)
      await store(PDF2SMILES_UPLOAD_URL, formData, PDF2SMILES_UPLOAD_HEADERS)
    } catch (e) {
      notifyName =
        e?.response?.data?.result?.error_message ===
        PDF2SMILES_UPLOAD_D0C_ONLY_ONE
          ? 'notification.you_can_only_recognize_one_document_at_a_time'
          : 'notification.failed_to_upload_file'
    } finally {
      setLoading(false)

      const id = uuidv4()
      const notify = {
        id,
        name: `${notifyName}`,
        notification_type:
          notifyName === PDF2SMILES_UPLOAD_D0C_SUCCESS ? 'success' : 'error',
        autoRemove: true,
        timeout: 5000,
        translationNameParams: {
          name: file?.name,
        },
      }
      addNotification(notify)
    }
  }

  const handleInputChange = (e) => {
    const file = e.target.files[0]
    handleUploadFile(e, file)
    if (fileInput.current) {
      fileInput.current.value = null
    }
  }

  const handleDragAndDrop = (file) => {
    handleUploadFile(null, file)
    if (fileInput.current) {
      fileInput.current.value = null
    }
  }

  const loadData = useCallback(
    async (limit = 0, offset = 0) => {
      setLoading(true)
      try {
        const res = await store(PDF2SMILES_GET_DOCS_URL, {
          params: {
            limit,
            offset,
          },
        })
        setData(res.data.result)
      } catch (e) {
        const id = uuidv4()
        const notify = {
          id,
          name: 'notifications.loading_error',
          notification_type: 'warning',
          text: 'pdf2smiles.dashboard.failed_to_load_documents',
        }
        addNotification(notify)
      }
      setLoading(false)
    },
    [addNotification, t]
  )

  const isDocumentUploading = tasks.find(
    ({ plugin, progress, status }) =>
      plugin === 'pdf2smiles' && progress !== 100 && status !== 'ok'
  )

  useEffect(() => {
    if ((!needUpdateDocuments && isDocumentUploading) || loading) return
    loadData().then(setNeedUpdateDocuments(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needUpdateDocuments, setNeedUpdateDocuments, isDocumentUploading])

  const onDrop = (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0]
    uploadedFile.type === 'application/pdf' && handleDragAndDrop(uploadedFile)
  }
  const preventClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    return
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
    },
  })

  const updateName = async (filename) => {
    toggleShowDialog()

    try {
      await store(PDF2SMILES_UPDATE_DOCUMENT_URL, {
        params: {
          document_id: dialogId,
          filename,
        },
      })
      setNeedUpdateDocuments(true)
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        notification_type: 'error',
        text: 'pdf2smiles.viewer.failed_to_edit_filename',
        autoRemove: true,
        timeout: 5000,
      }
      addNotification(notify)
    }
  }

  return (
    <Container className="pdf2smiles-container">
      <Header>
        <TitleSecondary>{t('pdf2smiles.dashboard.pdf2smiles')}</TitleSecondary>
        <div>
          <FileUploadInput
            type="file"
            onChange={handleInputChange}
            ref={fileInput}
            accept=".pdf"
          />
          <CustomButton
            onClick={handleUploadPdfClick}
            type="accent"
            disabled={isDocumentUploading}
            data-tooltip-id={downloadBtnHash}
            gap={'0.25rem'}
          >
            <Icon iconType="import" size={'1rem'} />
            <span>{t('pdf2smiles.dashboard.upload_pdf')}</span>
          </CustomButton>
          <ReactTooltip
            id={downloadBtnHash}
            className="c-tooltip c-tooltip-base"
            classNameArrow="c-tooltip-arrow"
            place={'bottom'}
            hidden={!isDocumentUploading}
          >
            {t(
              'pdf2smiles.dashboard.you_can_only_recognize_one_document_at_a_time'
            )}
          </ReactTooltip>
        </div>
        {/* <Toolbar>
           <Tools>
            {tabsConfig.map(({ text, onClick, isActive }, i) => (
              <CustomTab
                size="small"
                onClick={onClick}
                type={'primary'}
                isActive={isActive}
                key={i}
              >
                {t(`pdf2smiles.dashboard.${text}`)}
              </CustomTab>
            ))}
          </Tools> 
        </Toolbar> */}
      </Header>
      <div
        {...getRootProps({ onClick: preventClick })}
        id="dropzone"
        className={`dropzone ${
          isDragActive && data?.items?.length ? 'dropzone-blur' : ''
        }`}
      >
        <input {...getInputProps()} className="dropzone-input" />
        {data?.items?.length ? (
          <CustomScrollbar>
            <Documents id={DOCUMENTS_CONTAINER_ID}>
              {data.items.map(
                ({ id, preview_url, filename, markup, pages_count }) => (
                  <Document
                    key={id}
                    image={preview_url}
                    id={id}
                    name={filename}
                    structuresCount={
                      markup?.filter(
                        ({ structure }) =>
                          Array.isArray(structure?.smiles) &&
                          structure.smiles.length > 0
                      )?.length
                    }
                    pagesCount={pages_count}
                  />
                )
              )}
            </Documents>
          </CustomScrollbar>
        ) : (
          !loading &&
          !isDocumentUploading && (
            <P2SDropzoneWrapper>
              {isDragActive ? (
                <Icon iconType="folderOpened" />
              ) : (
                <Icon iconType="folderClosed" />
              )}

              <Headline
                fontWeight={theme.fontWeight.semibold}
                color={theme.colors.text.accentPrimary}
              >
                {t('pdf2smiles.drag_pdf')}
              </Headline>
            </P2SDropzoneWrapper>
          )
        )}
      </div>
      {!loading && <CustomSnackbar />}
      {loading && <LoadingOverlay />}
      {!!dialogId && (
        <EditDialog
          name={data?.items?.find((el) => el?.id === dialogId)?.filename}
          close={() => toggleShowDialog()}
          closeWithSave={updateName}
        />
      )}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    needUpdateDocuments: state.pdf2Smiles.needUpdateDocuments,
    tasks: state.tasks,
    dialogId: state.pdf2Smiles.showDialog,
  }
}

const mapDispatchToProps = {
  //setShowPublicPdf,
  addNotification,
  setNeedUpdateDocuments,
  toggleShowDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(Pdf2Smiles))
