import { ReactComponent as Search } from '../../assets/search.svg'
import { ReactComponent as Close } from '../../assets/close.svg'
import { ReactComponent as ArrowRight } from '../../assets/arrow_right.svg'
import { ReactComponent as ArrowLeft } from '../../assets/arrow_left.svg'
import { ReactComponent as Edit } from '../../assets/edit.svg'
import { ReactComponent as Deleted } from '../../assets/deleted.svg'
import { ReactComponent as ThreeDots } from '../../assets/three-dots.svg'
import { ReactComponent as ArrowDown } from '../../assets/arrow_down.svg'
import { ReactComponent as ArrowTop } from '../../assets/arrow_top.svg'
import { ReactComponent as Warning } from '../../assets/warning.svg'
import { ReactComponent as Share } from '../../assets/share.svg'
import { ReactComponent as Description } from '../../assets/description.svg'
import { ReactComponent as Copy } from '../../assets/copy.svg'
import { ReactComponent as Link } from '../../assets/link.svg'
import { ReactComponent as Info } from '../../assets/info.svg'
import { ReactComponent as Tick } from '../../assets/tick.svg'
import { ReactComponent as Nut } from '../../assets/nut.svg'
import { ReactComponent as Error } from '../../assets/error.svg'
import { ReactComponent as Add } from '../../assets/plus.svg'
import { ReactComponent as Add16 } from '../../assets/plus16.svg'
import { ReactComponent as Calculate } from '../../assets/calculate.svg'
import { ReactComponent as Upload } from '../../assets/upload.svg'
import { ReactComponent as Download } from '../../assets/download.svg'
import { ReactComponent as Refresh } from '../../assets/refresh.svg'
import { ReactComponent as Success } from '../../assets/success.svg'
import { ReactComponent as BorderBox } from '../../assets/border_box.svg'
import { ReactComponent as Magic } from '../../assets/magic.svg'
import { ReactComponent as SelectArea } from '../../assets/select-area.svg'
import { ReactComponent as Save } from '../../assets/save.svg'
import { ReactComponent as Drag } from '../../assets/drag.svg'
import { ReactComponent as Back } from '../../assets/back.svg'
import { ReactComponent as Forward } from '../../assets/forward.svg'
import { ReactComponent as EyeClose } from '../../assets/eye-close.svg'
import { ReactComponent as EyeOpen } from '../../assets/eye-open.svg'
import { ReactComponent as Notification } from '../../assets/notification.svg'
import { ReactComponent as Desc } from '../../assets/desc.svg'
import { ReactComponent as Asc } from '../../assets/asc.svg'
import { ReactComponent as Filters } from '../../assets/filters.svg'
import { ReactComponent as Smiles } from '../../assets/smiles.svg'
import { ReactComponent as NotApplicable } from '../../assets/not-applicable.svg'
import { ReactComponent as Unselect } from '../../assets/unselect.svg'
import { ReactComponent as Link2 } from '../../assets/link-2.svg'
import { ReactComponent as MolecularEditor } from '../../assets/molecular_editor.svg'
import { ReactComponent as Spinner } from '../../assets/spinner.svg'
import { ReactComponent as Forecast } from '../../assets/forecast.svg'
import { ReactComponent as Equals } from '../../assets/equals.svg'
import { ReactComponent as Export } from '../../assets/export.svg'
import { ReactComponent as Import } from '../../assets/import.svg'
import { ReactComponent as Predict } from '../../assets/predict.svg'
import { ReactComponent as Datasets } from '../../assets/datasets.svg'
import { ReactComponent as Synmap } from '../../assets/synmap.svg'
import { ReactComponent as Reactions } from '../../assets/reactions.svg'
import { ReactComponent as Document } from '../../assets/document.svg'
import { ReactComponent as PredictSpectra } from '../../assets/predictSpectra.svg'
import { ReactComponent as Iupac } from '../../assets/iupac.svg'
import { ReactComponent as Statistics } from '../../assets/statistics.svg'
import { ReactComponent as Admin } from '../../assets/admin.svg'
import { ReactComponent as Language } from '../../assets/language.svg'
import { ReactComponent as User } from '../../assets/user.svg'
import { ReactComponent as Logout } from '../../assets/logout.svg'
import { ReactComponent as Reveal } from '../../assets/reveal.svg'
import { ReactComponent as Menu } from '../../assets/menu.svg'
import { ReactComponent as Menu2 } from '../../assets/menu_2.svg'
import { ReactComponent as Profile } from '../../assets/profile.svg'
import { ReactComponent as Telegram } from '../../assets/telegram.svg'
import { ReactComponent as Team } from '../../assets/team.svg'
import { ReactComponent as Feedback } from '../../assets/feedback.svg'
import { ReactComponent as Guide } from '../../assets/guide.svg'
import { ReactComponent as Merge } from '../../assets/merge.svg'
import { ReactComponent as OnMap } from '../../assets/on_map.svg'
import { ReactComponent as FolderOpened } from '../../assets/folderOpened.svg'
import { ReactComponent as FolderClosed } from '../../assets/folderClosed.svg'
import { ReactComponent as Sdf } from '../../assets/sdf.svg'
import { ReactComponent as Csv } from '../../assets/csv.svg'
import { ReactComponent as Smi } from '../../assets/smi.svg'
import { ReactComponent as Settings } from '../../assets/settings.svg'
import { ReactComponent as Loader } from '../../assets/loader.svg'
import { ReactComponent as Pdf2SmilesTag } from '../../assets/pdf2SmilesTag.svg'
import { ReactComponent as Settings_20 } from '../../assets/settings20.svg'
import { ReactComponent as Profile16 } from '../../assets/profile.svg'
import { ReactComponent as Email16 } from '../../assets/email16.svg'
import { ReactComponent as Deleted16 } from '../../assets/deleted16.svg'
import { ReactComponent as Pen16 } from '../../assets/pen16.svg'
import { ReactComponent as Calendar } from '../../assets/calendar.svg'
import { ReactComponent as Mail2 } from '../../assets/mail_2.svg'
import { ReactComponent as Mail3 } from '../../assets/mail_3.svg'
import { ReactComponent as Mail4 } from '../../assets/mail_4.svg'
import { ReactComponent as Success2 } from '../../assets/success_2.svg'
import { ReactComponent as FeedbackWhite } from '../../assets/feedback_white.svg'
import { ReactComponent as Translate } from '../../assets/translate.svg'
import { ReactComponent as Ru } from '../../assets/ru.svg'
import { ReactComponent as En } from '../../assets/en.svg'
import { ReactComponent as DotSeparator } from '../../assets/dot_separator.svg'
import { ReactComponent as Pen } from '../../assets/pen.svg'
import { ReactComponent as SmallBlocks } from '../../assets/smallBlocks.svg'
import { ReactComponent as MediumBlocks } from '../../assets/mediumBlocks.svg'
import { ReactComponent as LargeBlocks } from '../../assets/largeBlocks.svg'
import { ReactComponent as SingleBlocks } from '../../assets/singleBlocks.svg'
import { ReactComponent as SuccessLight } from '../../assets/success_light.svg'
import { ReactComponent as StructureImage } from '../../assets/structure-image.svg'
import { ReactComponent as LiteratureImage } from '../../assets/literature-image.svg'
import { ReactComponent as ReactionsImage } from '../../assets/reactions-image.svg'
import { ReactComponent as PlusReaction } from '../../assets/plus_reaction.svg'
import { ReactComponent as EyeBrow } from '../../assets/eye-brow.svg'
import { ReactComponent as Account } from '../../assets/account.svg'
import { ReactComponent as Hide } from '../../assets/hide.svg'
import { ReactComponent as Repeat } from '../../assets/repeat.svg'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as ActivationErrorIcon } from '../../assets/activation_error_icon.svg'
import { ReactComponent as TelegramLogo } from '../../assets/telegram_logo.svg'
import { ReactComponent as VkLogo } from '../../assets/vk_logo.svg'
import { ReactComponent as YoutubeLogo } from '../../assets/youtube_logo.svg'
import { ReactComponent as AdminSettings } from '../../assets/admin_settings.svg'
import { ReactComponent as Lock } from '../../assets/lock.svg'
import { ReactComponent as Unlock } from '../../assets/unlock.svg'
import { ReactComponent as SuccessImg } from '../../assets/successImg.svg'
import { ReactComponent as Expand } from '../../assets/expand.svg'
import { ReactComponent as Minus } from '../../assets/minus.svg'
import { ReactComponent as PDFDocument } from '../../assets/pdf_document.svg'
import { ReactComponent as ReactionResultIcon } from '../../assets/reaction_result_icon.svg'
import { ReactComponent as Note } from '../../assets/note.svg'
import { ReactComponent as Email } from '../../assets/email.svg'
import { ReactComponent as Briefcase } from '../../assets/briefcase.svg'
import { ReactComponent as Warning2 } from '../../assets/warning_2.svg'
import { ReactComponent as Transfer } from '../../assets/transfer.svg'
import { ReactComponent as WarningDark } from '../../assets/warning_dark.svg'
import { ReactComponent as Spinner2 } from '../../assets/spinner_2.svg'
import { ReactComponent as Phone } from '../../assets/phone.svg'
import { ReactComponent as Timer } from '../../assets/timer.svg'
import { ReactComponent as Calendar_2 } from '../../assets/calendar_2.svg'
import { ReactComponent as ImageIcon } from '../../assets/image_icon.svg'
import { ReactComponent as FileIcon } from '../../assets/file_icon.svg'
import { ReactComponent as Warning3 } from '../../assets/warning_orange.svg'
import { ReactComponent as Waiting } from '../../assets/waiting.svg'
import { ReactComponent as DeniedAccess } from '../../assets/denied_access.svg'
import { ReactComponent as ExactMatch } from '../../assets/exact_match.svg'
import { ReactComponent as StatisticsArrow } from '../../assets/statistics_arrow.svg'
import { ReactComponent as Dots } from '../../assets/dots.svg'

export const customIcons = {
  admin_settings: AdminSettings,
  search: Search,
  close: Close,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  edit: Edit,
  deleted: Deleted,
  threeDots: ThreeDots,
  arrowTop: ArrowTop,
  arrowDown: ArrowDown,
  warning: Warning,
  share: Share,
  description: Description,
  copy: Copy,
  link: Link,
  info: Info,
  tick: Tick,
  nut: Nut,
  error: Error,
  add: Add,
  add16: Add16,
  calculate: Calculate,
  upload: Upload,
  refresh: Refresh,
  success: Success,
  borderBox: BorderBox,
  magic: Magic,
  selectArea: SelectArea,
  save: Save,
  drag: Drag,
  back: Back,
  forward: Forward,
  eyeClose: EyeClose,
  eyeOpen: EyeOpen,
  notification: Notification,
  desc: Desc,
  asc: Asc,
  filters: Filters,
  smiles: Smiles,
  notApplicable: NotApplicable,
  unselect: Unselect,
  link2: Link2,
  molecularEditor: MolecularEditor,
  spinner: Spinner,
  forecast: Forecast,
  equals: Equals,
  export: Export,
  import: Import,
  predict: Predict,
  datasets: Datasets,
  synmap: Synmap,
  reactions: Reactions,
  document: Document,
  predictSpectra: PredictSpectra,
  iupac: Iupac,
  statistics: Statistics,
  admin: Admin,
  language: Language,
  user: User,
  logout: Logout,
  reveal: Reveal,
  menu: Menu,
  menu_2: Menu2,
  profile: Profile,
  telegram: Telegram,
  feedback: Feedback,
  guide: Guide,
  merge: Merge,
  onMap: OnMap,
  folderOpened: FolderOpened,
  folderClosed: FolderClosed,
  sdf: Sdf,
  csv: Csv,
  smi: Smi,
  settings: Settings,
  loader: Loader,
  pdf2SmilesTag: Pdf2SmilesTag,
  settings20: Settings_20,
  profile16: Profile16,
  email16: Email16,
  deleted16: Deleted16,
  pen16: Pen16,
  calendar: Calendar,
  mail2: Mail2,
  mail3: Mail3,
  mail4: Mail4,
  success2: Success2,
  FeedbackWhite: FeedbackWhite,
  translate: Translate,
  ru: Ru,
  en: En,
  dotSeparator: DotSeparator,
  pen: Pen,
  smallBlocks: SmallBlocks,
  mediumBlocks: MediumBlocks,
  largeBlocks: LargeBlocks,
  singleBlocks: SingleBlocks,
  successLight: SuccessLight,
  structureImage: StructureImage,
  literatureImage: LiteratureImage,
  reactionsImage: ReactionsImage,
  plusReaction: PlusReaction,
  eyeBrow: EyeBrow,
  account: Account,
  hide: Hide,
  team: Team,
  repeat: Repeat,
  logo: Logo,
  activationErrorIcon: ActivationErrorIcon,
  telegramLogo: TelegramLogo,
  vkLogo: VkLogo,
  youtubeLogo: YoutubeLogo,
  lock: Lock,
  unlock: Unlock,
  successImg: SuccessImg,
  expand: Expand,
  minus: Minus,
  pdfDocument: PDFDocument,
  download: Download,
  reactionResultIcon: ReactionResultIcon,
  note: Note,
  email: Email,
  briefcase: Briefcase,
  warning2: Warning2,
  transfer: Transfer,
  warningDark: WarningDark,
  spinner2: Spinner2,
  phone: Phone,
  timer: Timer,
  calendar_2: Calendar_2,
  imageIcon: ImageIcon,
  fileIcon: FileIcon,
  warning3: Warning3,
  waiting: Waiting,
  deniedAccess: DeniedAccess,
  exactMatch: ExactMatch,
  statisticsArrow: StatisticsArrow,
  dots: Dots,
}
