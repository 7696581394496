import styled from 'styled-components'
import { Caption } from 'components/common/text/index.style'

export const Container = styled.div`
  padding: 0.75rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
`

export const PluginsListWrapper = styled.div`
  display: grid;
  width: 350px;
  grid-template-columns: 150px 50px 50px 50px;
  padding: 8px 8px;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;
  border-radius: 1px;
`

export const HeaderBlock = styled.div`
  font-size: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const ColumnBlock = styled.div`
  display: grid;
  row-gap: 12px;
`

export const RPCBlock = styled.div`
  display: grid;
  grid-template-columns: 3.125rem repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 0.75rem;
`
export const DialogContent = styled.div`
  gap: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const BlockWithLabel = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
export const Label = styled(Caption)`
  color: ${({ theme }) => theme.colors.text.secondary};
  white-space: nowrap;
`
export const DialogActions = styled.div`
  gap: 0.5rem;
  display: flex;
  margin-left: auto;
`
