export const checkIfNumbers = (str, nonIntegral) => {
  if (!str?.length) return true
  var reg = nonIntegral ? /^(\d+(\.\d*)?|\.\d+)$/ : /^\d+$/
  return reg.test(str)
}

export const checkSymbCount = (str) => {
  if (!str?.length) return true
  var reg = /^\d+(\.\d{0,3})?$/
  return reg.test(str)
}
