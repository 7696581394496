import { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { resetMolecule, updatePersonalProperties } from 'store/actions/molecule'
import { PropertyItemWrapper, Wrapper, ButtonActions } from './index.style'
import { Label } from 'components/common/text/index.style'
import CustomScrollbar from 'components/CustomScrollbar'
import Icon from 'components/Icon'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { useTheme } from 'styled-components'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

export const PersonalProperties = ({ initData }) => {
  /** INIT - START */
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const elementTemplate = { name: '', value: '' }
  const [data, setData] = useState(
    initData?.length ? initData : [elementTemplate]
  )
  const noChanges = useMemo(
    () => JSON.stringify(initData) === JSON.stringify(data),
    [initData, data]
  )
  const equalToTemplate =
    data.length === 1 &&
    JSON.stringify(data?.[0]) === JSON.stringify(elementTemplate)
  /** INIT - END */

  /** METHODS - START */
  const handleDiscard = () =>
    setData(initData?.length > 0 ? initData : [elementTemplate])
  const handleSave = () => {
    dispatch(
      updatePersonalProperties({
        data: data.filter((el) => el?.name && el?.value),
      })
    )
  }
  const handleChange = (index, field, newValue) => {
    setData((prevData) =>
      prevData.map((item, idx) =>
        idx === index ? { ...item, [field]: newValue } : item
      )
    )
  }

  const handleDelete = (index) => {
    if (data.length === 1) {
      setData([elementTemplate])
      return
    }
    setData((prevData) => prevData.filter((_, idx) => idx !== index))
  }

  const handleAdd = () => {
    setData((prevData) => [...prevData, { name: '', value: '' }])
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const items = Array.from(data)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setData(items)
  }
  /** METHODS - END */

  return (
    <>
      <CustomScrollbar className="margin-4-right">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="mol-personal-properties">
            {(provided) => (
              <Wrapper ref={provided.innerRef} {...provided.droppableProps}>
                {data?.map((el, idx) => {
                  const { name, value } = el || {}
                  return (
                    <Draggable
                      key={idx}
                      draggableId={`personal-property-${idx}`}
                      index={idx}
                      isDragDisabled={data.length <= 1}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <PropertyItemWrapper key={idx}>
                            <CustomButton style={{ cursor: 'grab' }}>
                              <Icon iconType="dots" size="1rem" width="1rem" />
                            </CustomButton>

                            <Label style={{ width: '4rem' }}>{idx + 1}</Label>
                            <CustomInput
                              id={`personal-property-name-${el?.id}`}
                              placeholder={t('molviewer.prop_name')}
                              value={name}
                              onChange={(value) =>
                                handleChange(idx, 'name', value)
                              }
                              withClearButton
                            />
                            <CustomInput
                              id={`personal-property-value-${el?.id}`}
                              placeholder={t('molviewer.prop_value')}
                              value={value}
                              onChange={(value) =>
                                handleChange(idx, 'value', value)
                              }
                              withClearButton
                            />
                            <CustomButton
                              onClick={() => handleDelete(idx)}
                              bgColor={theme.colors.buttons.secondary}
                              disabled={equalToTemplate}
                            >
                              <Icon iconType="close" size="1rem" />
                            </CustomButton>
                          </PropertyItemWrapper>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
              </Wrapper>
            )}
          </Droppable>
        </DragDropContext>
        <CustomButton
          onClick={handleAdd}
          bgColor={theme.colors.buttons.secondary}
          withIcon={true}
          type="secondary"
          style={{ marginBottom: '1rem' }}
        >
          <Icon iconType="add" size="1rem" style={{ color: '#2D3745' }} />
          <Label style={{ minWidth: 'max-content' }}>
            {t('moleculeslist.topblock.add_more')}
          </Label>
        </CustomButton>
      </CustomScrollbar>
      <ButtonActions>
        <CustomButton
          onClick={handleDiscard}
          type="secondary"
          width="50%"
          disabled={noChanges || equalToTemplate}
        >
          {t('common.reset')}
        </CustomButton>
        <CustomButton
          width="50%"
          onClick={handleSave}
          type="accent"
          disabled={noChanges}
        >
          {t('common.save')}
        </CustomButton>
      </ButtonActions>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    categories: state.molecule.categories,
    services: state.molecule.services,
    properties: state.molecule.properties,
    noteConfig: state.molecule.noteConfig,
    noteDialogId: state.molecule.moleculeNoteDialogId,
  }
}

const mapDispatchToProps = {
  push,
  resetMolecule,
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalProperties)
