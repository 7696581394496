import { v4 as uuidv4 } from 'uuid'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  destroyV2,
  fetch,
  patchV2,
  store,
  storeV2,
  updateV2,
} from 'services/rest'

import {
  LOAD_APPLICABILITY_INIT,
  LOAD_APPLICABILITY_SUCCESS,
  LOAD_CATEGORIES_ERROR,
  LOAD_CATEGORIES_INIT,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_MOLECULE_PROPS_ERROR,
  LOAD_MOLECULE_PROPS_INIT,
  LOAD_MOLECULE_PROPS_SUCCESS,
  LOAD_RU_IUPAC_INIT,
  LOAD_RU_IUPAC_SUCCESS,
  LOAD_SERVICES_ERROR,
  LOAD_SERVICES_INIT,
  LOAD_SERVICES_SUCCESS,
  UPDATE_MOLECULE_PROPS_FINISHED,
  UPDATE_MOLECULE_PROPS_INIT,
  UPDATE_MOLECULE_PROPS_SUCCESS,
  LOAD_RU_IUPAC_ERROR,
  EDIT_MOLECULE_NOTE,
  UPDATE_MOLECULE_NOTE_CONFIG,
  SET_MOLECULE_NOTE_DIALOG_ID,
  LOAD_PERSONAL_PROPERTIES_INIT,
  LOAD_PERSONAL_PROPERTIES_SUCCESS,
  LOAD_PERSONAL_PROPERTIES_ERROR,
  UPDATE_PERSONAL_PROPERTIES_INIT,
  UPDATE_PERSONAL_PROPERTIES_SUCCESS,
  UPDATE_PERSONAL_PROPERTIES_ERROR,
} from 'store/constants/molecule'
import {
  getMoleculeBasketId,
  getMoleculeServices,
  getMoleculeStructuralData,
} from 'store/selectors'
import { addServiceResponceToData } from 'utils/molecule/addServiceResponceToData'
import { getSortedCategories } from 'utils/molecule/getSortedCategories'
import { handleCalculationsError } from 'utils/molecule/handleCalculationsError'
import {
  ADD_NOTIFICATION,
  ADD_SIMPLE_NOTIFICATION,
} from 'store/constants/notifications'
import {
  UPDATE_MOLECULES,
  UPDATE_SEARCH_V2_EXACT_RESULT,
  UPDATE_SEARCH_V2_SIMILAR_RESULTS,
} from 'store/constants/crud'

const EXCLUDED_SERVICES = ['nmr', 'reactions', 'user_params']

function* getCategories() {
  try {
    const { data } = yield fetch('/categories')
    yield put({
      type: LOAD_CATEGORIES_SUCCESS,
      data: getSortedCategories(data).filter(
        ({ category_name }) => !EXCLUDED_SERVICES.includes(category_name)
      ),
    })
  } catch (error) {
    yield put({
      type: LOAD_CATEGORIES_ERROR,
    })
  }
}

function* getServices() {
  try {
    const { data } = yield fetch('/services_list')
    yield put({
      type: LOAD_SERVICES_SUCCESS,
      data: data.filter((service) => !EXCLUDED_SERVICES.includes(service.name)),
    })
  } catch (error) {
    yield put({
      type: LOAD_SERVICES_ERROR,
    })
  }
}

function* getMoleculeProps({ mainId, basketId, isBestMatch }) {
  let dataToUse
  try {
    if (!Number.isInteger(+mainId)) {
      const { data } = yield storeV2('molecule/preview', {
        params: {
          smiles: mainId,
        },
      })
      const { result } = data || {}
      dataToUse = result
    }
    if (Number.isInteger(+mainId)) {
      const { data: fetchData } = yield fetch(`/molecule/${mainId}`, 2)
      const structural = fetchData?.result || {}
      dataToUse = structural
      // tlight now does not count itself when moving to a molecule, we need to send a request
      yield storeV2('traffic_light/calculate', {
        molecules_ids: [+mainId],
      })
    }

    const services = yield select(getMoleculeServices)

    let isNeedHidePred = false
    if (dataToUse.smiles) {
      const { data } = yield store('/check_inorganic', {
        smiles: dataToUse.smiles,
      })
      if (!data?.result) isNeedHidePred = true
    }

    const canUpdateProps = !!dataToUse.smiles && !!services?.length

    let molData = {
      ...dataToUse,
      structural: dataToUse,
    }

    yield put({
      type: LOAD_MOLECULE_PROPS_SUCCESS,
      mainId,
      basketId,
      data: molData,
      loading: canUpdateProps,
      isNeedHidePred,
    })

    if (canUpdateProps) {
      yield put({
        type: UPDATE_MOLECULE_PROPS_INIT,
        updateAllProps: !isBestMatch,
      })
    }
  } catch (error) {
    yield put({
      type: LOAD_MOLECULE_PROPS_ERROR,
    })
  }
}

function* getSynonyms() {
  try {
    const { baseID } = yield select(getMoleculeStructuralData)
    if (!baseID) return

    const { data } = yield fetch(`/synonym/structure_id/${baseID}`, 2)

    // резервный эндпоинт, если придется где-то искать чисто по синонимам:
    // POST /api/v2/synonym/query

    if (!data?.result) return

    yield put({
      type: UPDATE_MOLECULE_PROPS_SUCCESS,
      data: {
        synonyms: data.result,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

function* getCasNumbers() {
  try {
    const { baseID } = yield select(getMoleculeStructuralData)
    if (!baseID) return

    const { data } = yield fetch(`synonym/structure_id/${baseID}/cas`, 2)

    if (!data) return

    yield put({
      type: UPDATE_MOLECULE_PROPS_SUCCESS,
      data: {
        cas_number: data,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

function* getServiceData({ name, view_type, functions, method }) {
  try {
    const structural = yield select(getMoleculeStructuralData)
    const basketId = yield select(getMoleculeBasketId)

    const { data } = yield store('/calculations', {
      service: name,
      input: Number.isInteger(basketId)
        ? { ...structural, basketId }
        : structural,
      ...(name === 'rdkit' && {
        method,
        params: {
          smiles: structural.smiles,
        },
      }),
    })

    if (data.status === 'ok') {
      const { data: updatedData, sources } = addServiceResponceToData(
        view_type,
        name === 'rdkit'
          ? [{ index: `rdkit_${method}`, value: data.result }]
          : data.result,
        name
      )

      if (data || sources) {
        yield put({
          type: UPDATE_MOLECULE_PROPS_SUCCESS,
          data: updatedData,
          sources,
        })
      }
    } else if (data.status === 'error') {
      yield handleCalculationsError(name)
    }
  } catch (error) {
    yield handleCalculationsError(name)
    // todo: add notification
  }
}

function* updateMoleculeProps({ updateAllProps = true }) {
  const services = yield select(getMoleculeServices)

  const [rdkitFiltered] = services.filter((el) => el.name === 'rdkit')
  const rdkitList = rdkitFiltered.functions.map((el) =>
    call(getServiceData, { ...rdkitFiltered, method: el.name })
  )

  const promisesList = []

  if (updateAllProps) {
    for (let i = 0; i < services.length; i++) {
      if (services[i].name !== 'rdkit')
        promisesList.push(call(getServiceData, services[i]))
    }

    yield all([
      ...promisesList,
      ...rdkitList,
      call(getSynonyms),
      call(getCasNumbers),
    ])
  } else {
    promisesList.push(
      call(
        getServiceData,
        services.find((el) => el.name === 'iupacConverter')
      )
    )
    yield all([...promisesList, ...rdkitList, call(getCasNumbers)])
  }

  yield put({
    type: UPDATE_MOLECULE_PROPS_FINISHED,
  })
}

function* loadRuIupac({ iupac }) {
  const isErrorIupac = iupac === 'Unable to generate valid IUPAC name'
  if (isErrorIupac) {
    yield put({
      type: LOAD_RU_IUPAC_ERROR,
    })
    return
  }
  try {
    const res = yield store('/run-task', {
      service: 'iupac_en_to_ru',
      params: {
        iupac_en: iupac,
      },
    })
    if (res.status === 200) {
      yield put({
        type: LOAD_RU_IUPAC_SUCCESS,
        data: res.data.result,
      })
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* loadApplicability({ smiles }) {
  try {
    const res = yield store('/run-task', {
      service: 'applicability_domain',
      params: {
        smiles: [smiles],
      },
      method: '',
      type: 'instant',
    })
    if (res.status === 200) {
      yield put({
        type: LOAD_APPLICABILITY_SUCCESS,
        data: res.data.result[0],
      })
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* editMoleculeNote({
  molId,
  basketId,
  params,
  hasNote,
  isBestMatch,
  isSimilarResults,
  isSingleMolecule,
}) {
  const { alias, note } = params

  const needDelete = !(alias || note)

  const m = needDelete ? destroyV2 : hasNote ? patchV2 : storeV2
  try {
    const res = yield m(`molecule/molecular_notes/${basketId}/${molId}`, {
      params,
    })
    if (res?.status === 201 || res?.status === 200) {
      // лучшее совпадение
      if (isBestMatch) {
        const exactResult = yield select(
          (state) => state.crud.searchV2.result.exact_match
        )

        // заменяем на карточке заметку на актуальную
        yield put({
          type: UPDATE_SEARCH_V2_EXACT_RESULT,
          data: { ...exactResult, ...params },
        })
        // похожие результаты
      } else if (isSimilarResults) {
        const similarResults = yield select(
          (state) => state.crud.searchV2.result.similar_results
        )

        // заменяем на карточке заметку на актуальную
        yield put({
          type: UPDATE_SEARCH_V2_SIMILAR_RESULTS,
          data: similarResults.map((mol) =>
            mol.id === molId ? { ...mol, ...params } : mol
          ),
        })
      }
      const molecules = yield select((state) => state.crud.molecules)
      yield put({
        type: UPDATE_MOLECULES,
        data: molecules.map((mol) =>
          mol.id === molId ? { ...mol, ...params } : mol
        ),
      })

      if (isSingleMolecule) {
        yield put({
          type: UPDATE_MOLECULE_NOTE_CONFIG,
          config: { ...params, basketId },
        })
      }

      yield put({
        type: SET_MOLECULE_NOTE_DIALOG_ID,
        value: null,
      })

      const notify = {
        id: uuidv4(),
        name: needDelete
          ? 'molecule_viewer.molecule_note_deleted_success'
          : hasNote
          ? 'molecule_viewer.molecule_note_edited_success'
          : 'molecule_viewer.molecule_note_created_success',
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  } catch (e) {
    console.log('e', e)
  }
}

function* loadPersonalProperties({ basket_id, structure_id }) {
  try {
    const { data } = yield fetch(
      `/molecule/custom_mol_params/${basket_id}/${structure_id}`,
      2
    )
    yield put({
      type: LOAD_PERSONAL_PROPERTIES_SUCCESS,
      data: data?.custom_mol_params ?? [],
    })
  } catch (error) {
    yield put({
      type: LOAD_PERSONAL_PROPERTIES_ERROR,
    })
  }
}

function* updatePersonalProperties({ data }) {
  const { baseID: structure_id } = yield select(getMoleculeStructuralData)
  const { id: basket_id } = yield select((state) => state.basket.basketSelected)
  const personalProperties = yield select(
    (state) => state.molecule.personal_properties
  )
  const { data: stateData } = personalProperties ?? []

  try {
    const methodToUse = stateData?.length ? updateV2 : storeV2

    if (Array.isArray(data) && data?.length === 0) {
      yield destroyV2(
        `/molecule/custom_mol_params/${basket_id}/${structure_id}`
      )
      yield put({
        type: ADD_SIMPLE_NOTIFICATION,
        text: 'notification.changes_save_success',
      })
    } else {
      yield methodToUse(
        `/molecule/custom_mol_params/${basket_id}/${structure_id}`,
        {
          params: {
            custom_mol_params: data,
          },
        }
      )
      yield put({
        type: ADD_SIMPLE_NOTIFICATION,
        text: 'notification.changes_save_success',
      })
    }

    yield put({
      type: UPDATE_PERSONAL_PROPERTIES_SUCCESS,
      data,
    })
  } catch (error) {
    yield put({
      type: ADD_SIMPLE_NOTIFICATION,
      text: 'notification.changes_save_error',
    })
    yield put({
      type: UPDATE_PERSONAL_PROPERTIES_ERROR,
    })
  }
}

export function* getCategoriesWatcher() {
  yield takeEvery(LOAD_CATEGORIES_INIT, getCategories)
}

export function* getServicesWatcher() {
  yield takeEvery(LOAD_SERVICES_INIT, getServices)
}

export function* getMoleculePropsWatcher() {
  yield takeEvery(LOAD_MOLECULE_PROPS_INIT, getMoleculeProps)
}

export function* updateMoleculePropsWatcher() {
  yield takeEvery(UPDATE_MOLECULE_PROPS_INIT, updateMoleculeProps)
}

export function* loadRuIupacWatcher() {
  yield takeEvery(LOAD_RU_IUPAC_INIT, loadRuIupac)
}

export function* loadApplicabilityWatcher() {
  yield takeEvery(LOAD_APPLICABILITY_INIT, loadApplicability)
}

export function* editMoleculeNoteWatcher() {
  yield takeEvery(EDIT_MOLECULE_NOTE, editMoleculeNote)
}
export function* loadPersonalPropertiesWatcher() {
  yield takeEvery(LOAD_PERSONAL_PROPERTIES_INIT, loadPersonalProperties)
}

export function* updatePersonalPropertiesWatcher() {
  yield takeEvery(UPDATE_PERSONAL_PROPERTIES_INIT, updatePersonalProperties)
}

const watchers = [
  getCategoriesWatcher(),
  getServicesWatcher(),
  getMoleculePropsWatcher(),
  updateMoleculePropsWatcher(),
  loadRuIupacWatcher(),
  loadApplicabilityWatcher(),
  editMoleculeNoteWatcher(),
  loadPersonalPropertiesWatcher(),
  updatePersonalPropertiesWatcher(),
]
export default watchers
