import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { destroy, fetch, store } from '../../services/rest'
import { InputWithButton, ListBlock, WrapBlock } from './index.style'

import { confirm } from '../../store/actions/confirm'
import { useTranslation } from 'react-i18next'
import CustomButton from 'components/common/customButton'
import CustomInput from 'components/common/customInput'

const AdminBanListView = () => {
  const [list, setList] = useState([])
  const [ipadr, setIpadr] = useState('')

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const loadData = async () => {
    try {
      const { data } = await fetch(`/banlist`)
      setList(data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleAdd = async () => {
    try {
      const data = { ip: ipadr }

      await store(`/banlist`, data)
      setIpadr('')
      await loadData()
    } catch (e) {
      console.log(e)
    }
  }

  const handleDelete = (id, ip) => () => {
    try {
      dispatch(
        confirm(t('confirmation.deleteIp', { ip }), async () => {
          await destroy(`/banlist/${id}`)
          await loadData()
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <WrapBlock>
      <InputWithButton>
        <CustomInput
          placeholder={t('admin.enterIP')}
          value={ipadr}
          onChange={(value) => setIpadr(value)}
          withWhiteBackground={true}
        />
        <CustomButton
          type="accent"
          width="100%"
          onClick={handleAdd}
          disabled={!ipadr}
        >
          {t('admin.addIPToList')}
        </CustomButton>
      </InputWithButton>
      <span style={{ fontWeight: 800 }}>{t('admin.clickOnIp')}</span>
      <ListBlock>
        <div style={{ fontWeight: '800' }}>IPs</div>
        {list.map(({ id, ip }) => (
          <React.Fragment key={id}>
            <div onClick={handleDelete(id, ip)} style={{ cursor: 'pointer' }}>
              {ip}
            </div>
          </React.Fragment>
        ))}
      </ListBlock>
    </WrapBlock>
  )
}

export default AdminBanListView
