export const getRequestParams = ({ url, text, isQuick }) => {
  switch (url) {
    case 'molecule/global_search':
      return { quick: isQuick, query: text }
    case 'user/get':
      return { filter: { email: text } }
    case 'customer/get':
      return { filter: { name: text } }
    default:
      return { quick: isQuick, query: text }
  }
}

export const getEaxFilterKey = ({ url }) => {
  switch (url) {
    case 'molecule/global_search':
      return 'id'
    case 'user/get':
      return 'user_id'
    case 'customer/get':
      return 'customer_name'
    default:
      return 'id'
  }
}

export const onSelectPropsConfig = {
  'molecule/global_search': {
    handleChangeProps: 'smiles',
    onAddProps: 'smiles',
  },
  'user/get': {
    handleChangeProps: 'email',
    onAddProps: 'user_id',
  },
  'customer/get': {
    handleChangeProps: 'customer_name',
    onAddProps: 'customer_id',
  },
  default: {
    handleChangeProps: 'smiles',
    onAddProps: 'smiles',
  },
}
