import {
  GET_STATISTICS_ERROR,
  GET_STATISTICS_INIT,
  GET_STATISTICS_SUCCESS,
} from 'store/constants/statistics'
import { put, select, takeEvery } from 'redux-saga/effects'
import { storeV2 } from '../../services/rest'
import { NAME_LIST_BY_TYPE } from 'pages/AdminPanelPage/statistics/config'

function* getStatistics() {
  try {
    const filter_type = yield select((state) => state.statistics.filter_type)
    const selectedChart = yield select(
      (state) => state.statistics.selectedChartSection
    )
    const selectedSubSection = yield select(
      (state) => state.statistics.selected_sub_section
    )
    const name_list =
      selectedChart === 3
        ? NAME_LIST_BY_TYPE?.[selectedChart]?.[selectedSubSection]
        : NAME_LIST_BY_TYPE?.[selectedChart]

    const isUsersStats = filter_type === 'users'
    const selectedSection = yield select(
      (state) => state.statistics.selectedChartSection
    )
    const filter_by = yield select((state) => state.statistics.filter_by)
    const filter_by_cleaned = Object.entries(filter_by)?.reduce(
      (acc, [key, value]) => {
        if (
          value !== null &&
          value !== '' &&
          !Number.isNaN(value) &&
          value !== undefined
        ) {
          acc[key] = value
        }
        return acc
      },
      {}
    )

    const request_url = isUsersStats
      ? '/analytics/user/requests/get'
      : '/analytics/customer/requests/get'

    const request_body = {
      params: {
        filter_by: { ...filter_by_cleaned, name_list },
        ...(selectedSection === 2 ||
        selectedSection === 3 ||
        selectedSection === 4
          ? { group_by: ['name'] }
          : {}),
        sort_by: {
          created_at: 'asc',
        },
      },
    }
    const { data } = yield storeV2(request_url, request_body)

    yield put({
      type: GET_STATISTICS_SUCCESS,
      data,
    })
  } catch (e) {
    console.log(e)
    yield put({
      type: GET_STATISTICS_ERROR,
    })
  }
}

function* getStatisticsWatcher() {
  yield takeEvery(GET_STATISTICS_INIT, getStatistics)
}

const watchers = [getStatisticsWatcher()]
export default watchers
