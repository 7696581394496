import {
  GET_RU_IUPAC_INIT,
  GET_RU_IUPAC_SUCCESS,
  GET_RU_IUPAC_ERROR,
  GET_EN_IUPAC_INIT,
  GET_EN_IUPAC_SUCCESS,
  GET_EN_IUPAC_ERROR,
  RESET_IUPAC,
} from 'store/constants/smilesIupac'

export const initialState = {
  loading: false,
  error: false,
  error_text: '',
  results: {
    ru: [],
    en: [],
  },
}

const smilesIupacReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_RU_IUPAC_INIT:
      return {
        ...state,
        loading: true,
        error: false,
        results: {
          ...state.results,
          ru: [],
        },
      }

    case GET_RU_IUPAC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        results: {
          ...state.results,
          ru: payload.data,
        },
      }

    case GET_RU_IUPAC_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        error_text: payload.data,
        results: {
          ...state.results,
          ru: [],
        },
      }

    case GET_EN_IUPAC_INIT:
      return {
        ...state,
        loading: true,
        error: false,
        results: {
          ...state.results,
          en: [],
        },
      }

    case GET_EN_IUPAC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        results: {
          ...state.results,
          en: payload.data,
        },
      }

    case GET_EN_IUPAC_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        error_text: payload.data,
        results: {
          ...state.results,
          en: [],
        },
      }
    case RESET_IUPAC:
      return {
        ...state,
        loading: false,
        error: false,
        error_text: '',
        results: {
          en: [],
          ru: [],
        },
      }
    default:
      return state
  }
}

export default smilesIupacReducer
