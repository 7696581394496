import styled from 'styled-components'

export const PaginationBlock = styled.div`
  position: fixed;
  display: flex;
  column-gap: 0.75rem;
  bottom: 24px;
  z-index: 100;
`

export const IndicatorBtn = styled.button`
  outline: none;
  border: none;
  background: #ffffff;
  padding: 0.625rem;
  border-radius: 0.75rem;
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  & > svg {
    align-self: center;
    color: ${({ disabled }) => (disabled ? '#CBD2D9' : '#1F2933')};
  }

  &:hover {
    & > svg {
      color: ${({ disabled }) => !disabled && '#42C1BF'};
      transition: color 0.3s;
    }
  }
`

export const PaginationBtns = styled.div`
  background: #ffffff;
  display: flex;
  border-radius: 0.75rem;
`

export const PaginationBtn = styled.button`
  outline: none;
  border: none;
  background: ${({ selected }) => (selected ? '#42C1BF' : 'transparent')};
  color: ${({ selected }) => (selected ? '#ffffff' : '#1F2933')};
  padding: 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  min-width: 2.25rem;
  height: 2.25rem;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    color: ${({ selected }) => !selected && '#42C1BF'};
    transition: color 0.3s;
  }
`
