import styled from 'styled-components'

export const DataContainer = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  padding: ${({ isBestMatch }) => !isBestMatch && '1rem'};
`

export const ParamsWithNote = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
`
