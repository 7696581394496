import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { copyToClipboard, isValidSMILES } from 'utils/common/common'
import LoaderRestyling from 'components/LoaderRestyling'
import CustomButton from 'components/common/customButton'
import {
  Subheadline,
  TextBody,
  TitleSecondary,
} from 'components/common/text/index.style'

import {
  Status,
  LineBlock,
  Container,
  NameBlock,
  StyledIcon,
  ResultItem,
  ResultBlock,
  HeaderContainer,
  SwitcherWrapper,
} from './index.style'
import {
  addNotification,
  addSimpleNotification,
} from '../../store/actions/notifications'
import { getIupac, resetIupac } from '../../store/actions/smilesIupac'
import CustomSwitch from 'components/common/customSwitch'
import CustomInput from 'components/common/customInput'

export const LANGUAGE_SWITCHER = [
  { title: 'smilesIupac.inRussian', type: 'ru' },
  { title: 'smilesIupac.inEnglish', type: 'en' },
]

const SmilesIupacConverter = ({
  getIupac,
  resetIupac,
  smilesIupac,
  addNotification,
  addSimpleNotification,
}) => {
  const { t, i18n } = useTranslation()

  const userLang = i18n.language

  const { results, loading, error, error_text } = smilesIupac

  const [smiles, setSmiles] = useState('')
  const [currentSmiles, setCurrentSmiles] = useState('')
  const [renderLang, setRenderLang] = useState('en')

  const isResultExists = Object.values(results).some((res) => res.length)

  useEffect(() => {
    return () => resetIupac()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setRenderLang(userLang)
    if (isResultExists && !results?.[userLang].length) {
      getIupac({
        smiles: encodeURIComponent(currentSmiles),
        lang: userLang,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLang])

  const handleToggleLang = (lang) => {
    setRenderLang(lang)
    if (!results?.[lang].length && !loading)
      getIupac({ smiles: encodeURIComponent(currentSmiles), lang })
  }

  const handleReset = () => {
    if (isResultExists) resetIupac()
    setSmiles('')
    setRenderLang(userLang)
  }

  const handleSend = async () => {
    const validateSmiles = await isValidSMILES(smiles?.toString())
    if (!validateSmiles) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        notification_type: 'error',
        text: 'pdf2smiles.viewer.invalid_smiles',
        autoRemove: true,
        timeout: 5000,
      }
      addNotification(notify)
      return
    }
    setRenderLang(userLang)
    if (smiles) {
      setCurrentSmiles(smiles)
      getIupac({ smiles: encodeURIComponent(smiles), lang: userLang })
    }
  }

  const handleChange = (value) => {
    if (isResultExists) resetIupac()
    const reg = /[а-яА-ЯёЁ]/g

    if (reg.test(value)) {
      value = value.replace(reg, '')
    }
    setSmiles(value)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSend()
    }
  }

  useEffect(() => {
    if (error) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'smilesIupac.error',
        notification_type: 'error',
        text: error_text || 'smilesIupac.serverError',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: !error_text,
      }
      addNotification(notify)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <Container>
      <HeaderContainer>
        <TitleSecondary>{t('smilesIupac.header')}</TitleSecondary>
        <Subheadline color="#616E7C" textAlign="start">
          {t('smilesIupac.enterMolecule')}{' '}
          <a
            href="https://en.wikipedia.org/wiki/Simplified_molecular-input_line-entry_system"
            rel="noreferrer"
            target="_blank"
          >
            {t('smilesIupac.smiles')}
          </a>{' '}
          {t('smilesIupac.notationHere')}
          <br />
          {t('smilesIupac.youWillSeeResults')}
        </Subheadline>

        <LineBlock>
          <CustomInput
            value={smiles}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={t('smilesIupac.enterSmiles')}
            withWhiteBackground
            withClearButton
          />
          <CustomButton
            type="accent"
            onClick={handleSend}
            disabled={!smiles || loading}
            className="custom-convert"
          >
            {t('smilesIupac.convert')}
          </CustomButton>
        </LineBlock>
      </HeaderContainer>
      {isResultExists && (
        <ResultBlock fullHeight={loading}>
          <TextBody fontWeight="600">
            {t('smilesIupac.modelForecasts')}
          </TextBody>

          {userLang === 'ru' && (
            <SwitcherWrapper>
              <CustomSwitch
                active={renderLang}
                onClick={handleToggleLang}
                btnWidth="7rem"
                items={LANGUAGE_SWITCHER}
              />
            </SwitcherWrapper>
          )}
          {results?.[renderLang].map(({ name, score, status }) => {
            const id = uuidv4()
            return (
              <ResultItem key={`${id}-${name}`}>
                <Status isHighScore={status}>
                  {'Score'} {score}
                </Status>
                <NameBlock>
                  <StyledIcon
                    iconType="copy"
                    size="1rem"
                    onClick={() => {
                      addSimpleNotification('notification.iupac_copy')
                      copyToClipboard(name)
                    }}
                  />
                  {name}
                </NameBlock>
              </ResultItem>
            )
          })}
          {loading && <LoaderRestyling />}
        </ResultBlock>
      )}
      {!isResultExists && loading && <LoaderRestyling />}
    </Container>
  )
}
const mapStateToProps = (state) => {
  return { smilesIupac: state.smilesIupac }
}

const mapDispatchToProps = {
  addNotification,
  getIupac,
  resetIupac,
  addSimpleNotification,
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SmilesIupacConverter)
)
