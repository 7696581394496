import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomSelect from 'components/CustomSelect'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'

import {
  Label,
  RPCBlock,
  Container,
  ColumnBlock,
  HeaderBlock,
  DialogContent,
  DialogActions,
  BlockWithLabel,
  PluginsListWrapper,
} from './index.style'
import LoadingOverlay from '../LoadingOverlay'
import { confirm } from '../../store/actions/confirm'
import { fetch, store, update, destroy } from '../../services/rest'
import { addNotification } from '../../store/actions/notifications'
import { INPUT_OPTIONS, TYPE_OPTIONS, VIEW_TYPE_OPTIONS } from './config'

const AdminPluginsView = () => {
  const [loading, setLoading] = useState(false)
  const [opened, setOpened] = useState(-1)
  const [showModal, setShowModal] = useState(false)
  const [settings, setSettings] = useState({
    name: '',
    type: 'rpc',
    adr: '',
    functions: [{ name: '', input: 'smiles', timeout: 0, output: '' }],
    view_type: 'list',
  })

  const [pluginsList, setPluginsList] = useState([])

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const handleLoadPluginsList = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await fetch('/plugins/list')
      setPluginsList(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: e.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }
      dispatch(addNotification(notify))
    }
  }, [dispatch, t])

  const handleDelete = (id) => {
    dispatch(
      confirm(t('confirmation.areYouSure'), async () => {
        try {
          await destroy(`/plugins/list/${id}`)
          await handleLoadPluginsList()
        } catch (e) {
          const id = uuidv4()
          const notify = {
            id,
            name: 'notification.error',
            text: e.message,
            notification_type: 'error',
            autoRemove: true,
            timeout: 5000,
            needTranslateText: false,
          }
          dispatch(addNotification(notify))
        }
      })
    )
  }

  const handleEdit = async () => {
    try {
      await update(`/plugins/list/${opened}`, {
        name: settings.name,
        type: settings.type,
        functions: settings.functions[0].name ? settings.functions : [],
        adr: settings.adr,
        view_type: settings.view_type,
      })
      handleCloseSettings()
      handleLoadPluginsList()
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: e.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }
      dispatch(addNotification(notify))
    }
  }

  const handleAdd = async () => {
    try {
      await store(`/plugins/list`, {
        name: settings.name,
        type: settings.type,
        functions: settings.functions[0].name ? settings.functions : [],
        adr: settings.adr,
        view_type: settings.view_type,
      })
      handleCloseSettings()
      handleLoadPluginsList()
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: e.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }
      dispatch(addNotification(notify))
    }
  }

  const handleOpenSettings = (id) => {
    const set = pluginsList.find((el) => el.id === id)
    setSettings(set)
    setOpened(id)
    setShowModal(true)
  }

  const handleCloseSettings = () => {
    setSettings({
      name: '',
      type: 'rpc',
      adr: '',
      functions: [{ name: '', input: 'smiles', timeout: 0, output: '' }],
      view_type: 'list',
    })
    setOpened(-1)
    setShowModal(false)
  }

  const handleChangeFunctions = (line, header, value) => {
    setSettings((s) => {
      return {
        ...s,
        functions: s.functions.map((el, idx) => {
          if (idx !== line) return el
          return {
            ...el,
            [header]: value,
          }
        }),
      }
    })
  }

  const handleAddNewFunction = () => {
    setSettings((s) => {
      return {
        ...s,
        functions: [
          ...s.functions,
          { name: '', input: 'smiles', timeout: 0, output: '' },
        ],
      }
    })
  }

  const handleDeleteFunction = (line) => () => {
    setSettings((s) => {
      return {
        ...s,
        functions: s.functions.filter((el, idx) => idx !== line),
      }
    })
  }

  useEffect(() => {
    handleLoadPluginsList()
  }, [handleLoadPluginsList])

  const checkIsFullfilled = () => {
    if (settings.type === 'rest' && settings.adr) return true
    if (
      settings.type === 'rpc' &&
      settings.functions.every(({ name, output }) => name && output)
    )
      return true
  }

  return (
    <Container>
      <HeaderBlock>
        <CustomButton
          type="accent"
          gap="0.25rem"
          onClick={() => setShowModal(true)}
        >
          {t('admin.addNewPlugin')}
          <Icon iconType="add" size="1rem" />
        </CustomButton>
        <div>{t('admin.pluginsList')}</div>
      </HeaderBlock>
      <PluginsListWrapper>
        {pluginsList.map(({ id, name, type }) => {
          return (
            <Fragment key={id}>
              <div>{name}</div>
              <div>{type}</div>
              <CustomButton
                onClick={() => {
                  handleOpenSettings(id)
                }}
                type="text"
              >
                <Icon iconType="settings" />
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleDelete(id)
                }}
                color="text"
              >
                <Icon iconType="deleted" />
              </CustomButton>
            </Fragment>
          )
        })}
      </PluginsListWrapper>

      {showModal && (
        <CustomDialog onClose={handleCloseSettings} width="43.75rem">
          <DialogContent>
            <ColumnBlock>
              <CustomInput
                onChange={(value) => setSettings({ ...settings, name: value })}
                placeholder={t('admin.pluginName')}
                label={`${t('admin.pluginName')} *`}
                value={settings.name}
                withClearButton
              />
              <BlockWithLabel>
                <Label>{`${t('admin.type')} *`}</Label>
                <CustomSelect
                  selectedValue={{
                    value: settings.type,
                    label: TYPE_OPTIONS.find((el) => el.value === settings.type)
                      .label,
                  }}
                  onChange={(option) =>
                    setSettings({ ...settings, type: option.value })
                  }
                  options={TYPE_OPTIONS}
                  withTranslation={false}
                  width="100%"
                />
              </BlockWithLabel>

              <BlockWithLabel>
                <Label>{`${t('admin.viewType')} *`}</Label>
                <CustomSelect
                  selectedValue={{
                    value: settings.view_type,
                    label: VIEW_TYPE_OPTIONS.find(
                      (el) => el.value === settings.view_type
                    ).label,
                  }}
                  options={VIEW_TYPE_OPTIONS}
                  onChange={(option) =>
                    setSettings({ ...settings, view_type: option.value })
                  }
                  width="100%"
                />
              </BlockWithLabel>

              {settings.type === 'rest' && (
                <CustomInput
                  onChange={(value) => setSettings({ ...settings, adr: value })}
                  placeholder={t('admin.address')}
                  label={`${t('admin.address')} *`}
                  value={settings.adr}
                  withClearButton
                />
              )}
              {settings.type === 'rpc' && (
                <>
                  <h3>Methods</h3>
                  <RPCBlock>
                    {settings.functions.map((el, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <div style={{ alignSelf: 'center' }}>
                            <CustomButton
                              type="secondary"
                              onClick={handleDeleteFunction(idx)}
                              disabled={settings.functions.length === 1}
                            >
                              <Icon iconType="deleted" />
                            </CustomButton>
                          </div>
                          <CustomInput
                            onChange={(value) =>
                              handleChangeFunctions(idx, 'name', value)
                            }
                            label={`${t('admin.rpcMethodName')} *`}
                            value={el.name}
                            withClearButton
                          />
                          <BlockWithLabel>
                            <Label>{`${t('admin.input')} *`}</Label>
                            <CustomSelect
                              selectedValue={{
                                value: el.input,
                                label: INPUT_OPTIONS.find(
                                  (opt) => opt.value === el.input
                                )?.label,
                              }}
                              onChange={(option) =>
                                handleChangeFunctions(
                                  idx,
                                  'input',
                                  option.value
                                )
                              }
                              options={INPUT_OPTIONS}
                              withTranslation={false}
                            />
                          </BlockWithLabel>
                          <BlockWithLabel>
                            <CustomInput
                              onChange={(value) =>
                                handleChangeFunctions(idx, 'timeout', value)
                              }
                              placeholder={t('admin.default')}
                              label={t('admin.serviceTimeout')}
                              value={el.timeout}
                              type="number"
                            />
                            <Label>{t('admin.defaultValue')}</Label>
                          </BlockWithLabel>

                          <CustomInput
                            onChange={(value) =>
                              handleChangeFunctions(idx, 'output', value)
                            }
                            label={`${t('admin.outputName')} *`}
                            value={el.output}
                            withClearButton
                          />
                        </React.Fragment>
                      )
                    })}
                    <div>
                      <CustomButton
                        gap="4px"
                        type="accent"
                        onClick={handleAddNewFunction}
                      >
                        {t('admin.addNewFunc')}
                        <Icon iconType="add" size="1rem" />
                      </CustomButton>
                    </div>
                  </RPCBlock>
                </>
              )}
            </ColumnBlock>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleCloseSettings} type="secondary">
              {t('admin.exit')}
            </CustomButton>
            <CustomButton
              onClick={opened >= 0 ? handleEdit : handleAdd}
              type="accent"
              disabled={!checkIsFullfilled()}
            >
              {opened >= 0 ? t('admin.edit') : t('admin.create')}
            </CustomButton>
          </DialogActions>
        </CustomDialog>
      )}

      {loading && <LoadingOverlay />}
    </Container>
  )
}

export default AdminPluginsView
