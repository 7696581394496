import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SearchHistory from 'components/Search/SearchHistory'
import { Subheadline, TextBody } from 'components/common/text/index.style'
import LoaderRestyling from 'components/LoaderRestyling'
import { LoaderContainer, SearchList } from './index.style'
import SearchReactionsCard from 'components/SearchReactionsCard'
import { useEffect } from 'react'

const SearchReactionsList = ({
  reactionSearchStatus,
  reactionSearchResult,
  showPagination,
  listView,
  pagination,
  scrollRef,
}) => {
  const { t } = useTranslation()
  const { data: reactionsData = [] } = reactionSearchResult
  const twoRows = listView && listView === 'brick'

  useEffect(() => {
    reactionSearchStatus === 'done' &&
      scrollRef?.current &&
      scrollRef.current?.scrollTo(0, 1)
  }, [reactionSearchStatus, scrollRef])
  return (
    <>
      {reactionSearchStatus === 'loading' && (
        <LoaderContainer>
          <LoaderRestyling />
        </LoaderContainer>
      )}
      {reactionSearchStatus === 'hold' && <SearchHistory />}
      {reactionSearchStatus === 'done' && !reactionsData?.length && (
        <>
          <TextBody style={{ marginTop: '24px' }}>
            {t('filter.literature.requestNotFound')}
          </TextBody>
          <Subheadline
            fontWeight={500}
            color="#616E7C"
            style={{ marginTop: '8px' }}
          >
            {t('filter.literature.changeRequest')}
          </Subheadline>
        </>
      )}

      {reactionSearchStatus === 'done' && !!reactionsData?.length && (
        <SearchList showPagination={showPagination} twoRows={twoRows}>
          {reactionsData.map((el, index) => {
            return (
              <SearchReactionsCard
                key={`${el?.id}-${pagination.activePage}`}
                data={el}
                idx={index}
              />
            )
          })}
        </SearchList>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    reactionSearchStatus: state.crud.reactionsSearch.status,
    reactionSearchResult: state.crud.reactionsSearch.result,
    pagination: state.search.pagination,
  }
}

export default connect(mapStateToProps)(SearchReactionsList)
