import { useTheme } from 'styled-components'

import Icon from 'components/Icon'
import { TextMedium, TitleTertiary } from 'components/common/text/index.style'

import { Container, Descriptions, TitleWithDescription } from './index.style'

const InformationBlock = ({ iconType, title, description, description2 }) => {
  const theme = useTheme()
  return (
    <Container>
      <Icon iconType={iconType} size="3.75rem" />
      <TitleWithDescription>
        <TitleTertiary fontWeight={theme.fontWeight.semibold}>
          {title}
        </TitleTertiary>
        <Descriptions>
          <TextMedium
            color={theme.colors.text.secondary}
            fontWeight={theme.fontWeight.light}
          >
            {description}
          </TextMedium>
          <TextMedium
            color={theme.colors.text.secondary}
            fontWeight={theme.fontWeight.light}
          >
            {description2}
          </TextMedium>
        </Descriptions>
      </TitleWithDescription>
    </Container>
  )
}

export default InformationBlock
