import { Row, DeleteButton } from './index.style'
import Icon from 'components/Icon'
import CustomSelect from 'components/CustomSelect'
import CustomInput from 'components/common/customInput'
import { connect, useDispatch } from 'react-redux'
import { OPERATOR_OPTIONS, LANGUAGES_OPTIONS } from '../../constants'
import { setLitFilterConfig } from 'store/actions/literature'
import { checkIfNumbers } from 'components/Filter/utils/utils'

const FilterRow = ({ currentElement, config, handleDeleteRow }) => {
  const dispatch = useDispatch()

  const setInputValue = (e, index) => {
    if (currentElement === 'name') {
      dispatch(setLitFilterConfig({ ...config, name: e }))
    } else if (currentElement === 'published_date') {
      const value = e.split(' ')[1]

      if (!checkIfNumbers(value) || (value?.length && value.length > 4)) return
      const newObj = index === 0 ? { from_date: value } : { to_date: value }
      dispatch(
        setLitFilterConfig({
          ...config,
          published_date: { ...config.published_date, ...newObj },
        })
      )
    } else {
      const currentTypeObject = config[currentElement]

      const newValues = config[currentElement].values

      newValues[index] = e

      dispatch(
        setLitFilterConfig({
          ...config,
          [currentElement]: {
            ...currentTypeObject,
            values: newValues,
          },
        })
      )
    }
  }

  const handleChangeLanguage = (option, index) => {
    const currentTypeObject = config[currentElement]
    const newValues = config[currentElement].values
    newValues[index] = option.value
    dispatch(
      setLitFilterConfig({
        ...config,
        [currentElement]: { ...currentTypeObject, values: newValues },
      })
    )
  }

  const handleChangeOperator = (option) => {
    const currentTypeObject = config[currentElement]
    dispatch(
      setLitFilterConfig({
        ...config,
        [currentElement]: { ...currentTypeObject, operator: option.value },
      })
    )
  }

  if (currentElement === 'published_date') {
    return (
      <Row>
        <CustomInput
          value={config.published_date.from_date}
          onChange={(e) => setInputValue(e, 0)}
          withClearButton
          key={`${currentElement}-input-from`}
          additionalValue="filter.literature.from"
        />
        <CustomInput
          value={config.published_date.to_date}
          onChange={(e) => setInputValue(e, 1)}
          withClearButton
          key={`${currentElement}-input-to`}
          additionalValue="filter.literature.to"
        />

        <DeleteButton onClick={() => handleDeleteRow(0)}>
          <Icon iconType="close" />
        </DeleteButton>
      </Row>
    )
  }
  if (currentElement === 'language') {
    return (
      config.language.values &&
      config.language.values.map((_, index) => (
        <Row key={`${currentElement}-${index}-${_}`}>
          {index !== 0 && (
            <CustomSelect
              options={OPERATOR_OPTIONS}
              onChange={(option) => handleChangeOperator(option)}
              selectedValue={OPERATOR_OPTIONS.find(
                (option) => option.value === config.language.operator
              )}
            />
          )}
          <CustomSelect
            options={LANGUAGES_OPTIONS.filter(
              (option) => !config.language.values.includes(option.value)
            )}
            onChange={(option) => handleChangeLanguage(option, index)}
            selectedValue={LANGUAGES_OPTIONS.find(
              (option) => option.value === config[currentElement].values[index]
            )}
            disabledOpening={
              LANGUAGES_OPTIONS.length === config.language.values.length
            }
            width="100%"
          />

          <DeleteButton onClick={() => handleDeleteRow(index)}>
            <Icon iconType="close" />
          </DeleteButton>
        </Row>
      ))
    )
  }
  return config[currentElement].values ? (
    config[currentElement].values.map((_, index) => (
      <Row key={`${currentElement}-${index}`}>
        {index !== 0 && (
          <CustomSelect
            options={OPERATOR_OPTIONS}
            onChange={(option) => handleChangeOperator(option)}
            selectedValue={OPERATOR_OPTIONS.find(
              (option) => option.value === config[currentElement].operator
            )}
          />
        )}
        <CustomInput
          value={config[currentElement].values[index]}
          onChange={(e) => setInputValue(e, index)}
          withClearButton
          key={`${currentElement}-input-${index}`}
        />
        <DeleteButton
          onClick={() => {
            if (currentElement === 'title' && index === 0) return
            handleDeleteRow(index)
          }}
        >
          <Icon iconType="close" />
        </DeleteButton>
      </Row>
    ))
  ) : (
    <Row>
      {
        <CustomInput
          value={config[currentElement]}
          onChange={(e) => setInputValue(e, 0)}
          withClearButton
          key={`${currentElement}-input-0`}
        />
      }
      <DeleteButton onClick={() => handleDeleteRow(0)}>
        <Icon iconType="close" />
      </DeleteButton>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    config: state.literature.filter.config,
  }
}

FilterRow.displayName = 'FilterRow'

export default connect(mapStateToProps)(FilterRow)
