import styled from 'styled-components'

export const InputLabelContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  height: 1rem;
  width: fit-content;
  align-items: center;
  & > svg {
    transition: all 0.2s ease;
  }
  .icon-login-validating {
    color: ${({ theme }) => theme.colors.statuses.success};
  }
  .icon-login-validated {
    color: ${({ theme }) => theme.colors.statuses.success};
  }
  .icon-login-error {
    color: ${({ theme }) => theme.colors.statuses.error};
    cursor: pointer;
  }

  .icon-login-warning {
    color: ${({ theme }) => theme.colors.statuses.attention};
    cursor: pointer;
  }
`

export const CustomInputLabel = styled.label`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.text[11]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[12]};
`
