import styled from 'styled-components'

export const ListBlock = styled.div`
  display: grid;
  column-gap: 12px;
  row-gap: 20px;
  grid-template-columns: repeat(9, auto);
  padding: 24px 0;
  align-content: center;
  align-items: center;
`

export const UsersContainer = styled.div`
  width: 100%;
  padding: 0 0.75rem;
  margin: 0.75rem 0;
`
