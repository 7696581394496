import { useTranslation } from 'react-i18next'

import CustomInput from 'components/common/customInput'

import { Container, Row } from './index.style'

export const ContactFields = ({
  name,
  errors,
  contact,
  surname,
  onChange,
  emailWarning,
  isSubmitting,
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Row>
        <CustomInput
          id="name"
          label={`${t('registration.name')} *`}
          onChange={(value) => onChange('name', value)}
          value={name}
          placeholder={t('feedback.required_field')}
          autoComplete="name"
          withClearButton
          className={`feedback-item-input_error-${
            isSubmitting && !!errors.name
          }`}
          innerClassName="custom-input-wrapper"
        />
        <CustomInput
          id="surname"
          label={`${t('registration.surname')} *`}
          onChange={(value) => onChange('surname', value)}
          value={surname}
          placeholder={t('feedback.required_field')}
          autoComplete="surname"
          withClearButton
          className={`feedback-item-input_error-${
            isSubmitting && !!errors.surname
          }`}
          innerClassName="custom-input-wrapper"
        />
      </Row>

      <CustomInput
        id="email"
        onChange={(value) => onChange('contact', value)}
        value={contact}
        placeholder={t('feedback.required_field')}
        type="email"
        autoComplete="email"
        label="E-mail *"
        withClearButton
        warning={emailWarning}
        className={`feedback-item-input_error-${
          isSubmitting && !!errors.contact
        }`}
        innerClassName="custom-input-wrapper"
      />
    </Container>
  )
}

export default ContactFields
