import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Label } from 'components/common/text/index.style'
import { WARNING_PROPERTIES_VALUES } from 'components/Molecule/config/config'

const BlurableValue = ({ name, value, isBlurable, blurNonApplicable }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <>
      <ReactTooltip
        id={`blur-property-tooltip-${name}`}
        className="c-tooltip c-tooltip-base c-tooltip-category-blured c-tooltip-z-max"
        classNameArrow="c-tooltip-arrow"
        positionStrategy={'fixed'}
      />

      <Label
        data-tooltip-html={t('molviewer.blur_value')}
        data-tooltip-id={`blur-property-tooltip-${name}`}
        name={name}
        title={name === 'smiles' ? value : ''}
        as={'span'}
        className={`property-value_blured-${isBlurable && blurNonApplicable}`}
        color={
          WARNING_PROPERTIES_VALUES.includes(value)
            ? theme.colors.statuses.error
            : undefined
        }
      >
        {value}
      </Label>
    </>
  )
}

export default BlurableValue
