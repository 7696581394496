import { endOfWeek, format, startOfWeek } from 'date-fns'
import { NO_DATA_VALUE } from './config'
import { enUS, ru } from 'date-fns/locale'

export const prepareDataForChart = ({ data, selectedChart, language }) => {
  if (!data) return []
  const lang = language ?? 'en'
  const current_period_data = data?.result?.current_period?.items || []
  const truncate_by =
    data?.result?.current_period?.created_at_truncate_by ?? 'month'

  if (!current_period_data) return []

  if (selectedChart === 1) {
    return current_period_data?.map((el) => {
      const { truncated_date, sum } = el || {}
      return {
        date: formatDateForChart(truncated_date, lang, truncate_by),
        by_visits: sum === null ? NO_DATA_VALUE : sum, //NO_DATA_VALUE, если данных нет
      }
    })
  }
  if (selectedChart === 2 || selectedChart === 3 || selectedChart === 4) {
    let grouped_search_data = []
    current_period_data?.forEach((item) => {
      let existing = grouped_search_data?.find(
        (el) =>
          el?.date ===
          formatDateForChart(item?.truncated_date, lang, truncate_by)
      )
      const keyToUse =
        selectedChart === 3
          ? item?.name
          : getUnitedNamesKey(item?.name, selectedChart)
      if (existing) {
        existing[keyToUse] = existing[keyToUse]
          ? existing[keyToUse] + item?.sum
          : item?.sum
      } else {
        let newObj = {
          date: formatDateForChart(item?.truncated_date, lang, truncate_by),
        }
        newObj[keyToUse] = newObj[keyToUse]
          ? newObj[keyToUse] + item?.sum
          : item?.sum
        grouped_search_data.push(newObj)
      }
    })

    return grouped_search_data?.map((el) => {
      const elToUse = { ...el }
      for (const key in elToUse) {
        if (key !== 'date' && elToUse[key] === null) {
          elToUse[key] = NO_DATA_VALUE
        }
      }
      return elToUse
    })
  }
}

export const formatDateForChart = (dateString, lang, truncate_by) => {
  const date = new Date(dateString)
  const localeToUse = lang === 'ru' ? ru : enUS
  let formatToUse
  switch (truncate_by) {
    case 'day':
      formatToUse = 'd MMM yy'
      break
    case 'month':
      formatToUse = 'MMM yy'
      break
    case 'year':
      formatToUse = 'yyyy'
      break
    default:
      formatToUse = 'd MMM yy'
  }

  const weekStart = startOfWeek(date, { weekStartsOn: 1 })
  const weekEnd = endOfWeek(date, { weekStartsOn: 1 })
  const formattedWeekDate = `${format(weekStart, 'd MMM', {
    locale: localeToUse,
  })} - ${format(weekEnd, 'd MMM yy', { locale: localeToUse })}`

  const formattedDate =
    truncate_by === 'week'
      ? formattedWeekDate
      : format(date, formatToUse, {
          locale: localeToUse,
        })
  return formattedDate
}

const getUnitedNamesKey = (key, selectedChart) => {
  //объединяем данные, обслуживающие одинаковый раздел поиска
  if (selectedChart === 2) {
    if (key === 'advanced_text_search' || key === 'full_text_search')
      return 'by_literature'
    if (key === 'molecules_search' || key === 'bingo_search')
      return 'by_structures'
    if (key === 'reaction_compound_get') return 'by_reactions'
    return key
  }
  if (selectedChart === 4) {
    if (
      [
        'advanced_text_search',
        'full_text_search',
        'molecules_search',
        'bingo_search',
        'reaction_compound_get',
      ].includes(key)
    ) {
      return 'search'
    }
    if (key === 'calc_basket') return 'datasets'
    if (key === 'price_retro') return 'synth_cost'
    if (key === 'pdf2smiles_create_document_post') return 'pdf2smiles'
    if (key === 'iupacConverter') return 'smiles2iupac'
    if (['apply_transformer', 'reactions_tree'].includes(key)) return 'predict'
    if (['ir_spectr', 'nmr-hosegen', 'predict_cfmid'].includes(key))
      return 'spectra'

    return key
  }
}

export const getPeriodComparisonData = ({
  data = [],
  selectedChart,
  selectedSubSection = 0,
}) => {
  const result = []

  const { items: current_items, general_sum: current_sum } =
    data?.result?.current_period || {}
  const { items: prev_items, general_sum: prev_sum } =
    data?.result?.previous_period || {}

  if (selectedChart === 1) {
    result.push({ title: 'by_visits', current_sum, prev_sum })
    return result
  }
  if (selectedChart === 2 || selectedChart === 3 || selectedChart === 4) {
    let accum_data = {}
    const keysToUse =
      selectedChart === 3
        ? accumDataKeysByChartConfig[selectedChart]?.[selectedSubSection]
        : accumDataKeysByChartConfig[selectedChart]
    for (const key of keysToUse) {
      accum_data[key] = {
        current_sum: null,
        prev_sum: null,
      }
    }

    updateAccumData({
      items: current_items,
      accum_data,
      innerKey: 'current_sum',
      selectedChart,
    })
    updateAccumData({
      items: prev_items,
      accum_data,
      innerKey: 'prev_sum',
      selectedChart,
    })

    return Object.keys(accum_data)?.map((key) => {
      return { title: key, ...accum_data[key] }
    })
  }
}

const updateAccumData = ({ items, accum_data, innerKey, selectedChart }) => {
  items?.forEach((obj) => {
    if (selectedChart === 3) {
      if (
        obj?.sum &&
        obj?.name &&
        accum_data?.[obj.name]?.[innerKey] !== undefined
      ) {
        accum_data[obj.name][innerKey] += obj.sum
      }
    } else {
      const isStructures =
        obj?.name === 'molecules_search' || obj?.name === 'bingo_search'
      const isLiterature =
        obj?.name === 'advanced_text_search' || obj?.name === 'full_text_search'
      const isReactions = obj?.name === 'reaction_compound_get'
      const isSearch = isStructures || isLiterature || isReactions
      const isPredict =
        obj?.name === 'apply_transformer' || obj?.name === 'reactions_tree'
      const isDatasets = obj?.name === 'calc_basket'
      const isSpectra =
        obj?.name === 'ir_spectr' ||
        obj?.name === 'nmr-hosegen' ||
        obj?.name === 'predict_cfmid'
      const isSmiles2iupac = obj?.name === 'iupacConverter'
      const isPdf2smiles = obj?.name === 'pdf2smiles_create_document_post'
      const isSynth_cost = obj?.name === 'price_retro'
      const isSynmap = obj?.name === 'synmap'

      if (obj?.sum) {
        if (isStructures && selectedChart === 2)
          accum_data.by_structures[innerKey] += obj?.sum
        if (isLiterature && selectedChart === 2)
          accum_data.by_literature[innerKey] += obj?.sum
        if (isReactions && selectedChart === 2)
          accum_data.by_reactions[innerKey] += obj?.sum
        if (isSearch && selectedChart === 4)
          accum_data.search[innerKey] += obj?.sum
        if (isPredict) accum_data.predict[innerKey] += obj?.sum
        if (isDatasets) accum_data.datasets[innerKey] += obj?.sum
        if (isSpectra) accum_data.spectra[innerKey] += obj?.sum
        if (isSmiles2iupac) accum_data.smiles2iupac[innerKey] += obj?.sum
        if (isPdf2smiles) accum_data.pdf2smiles[innerKey] += obj?.sum
        if (isSynth_cost) accum_data.synth_cost[innerKey] += obj?.sum
        if (isSynmap) accum_data.synmap[innerKey] += obj?.sum
      }
    }
  })
}

const accumDataKeysByChartConfig = {
  1: ['by_visits'],
  2: ['by_structures', 'by_literature', 'by_reactions'],
  3: [
    ['synmap'],
    ['calc_basket'],
    ['apply_transformer', 'reactions_tree'],
    ['ir_spectr', 'nmr-hosegen', 'predict_cfmid'],
    ['price_retro'],
    ['pdf2smiles_create_document_post'],
    ['iupacConverter'],
    ['plugins_statistic'],
  ],
  4: [
    'search',
    'predict',
    'datasets',
    'spectra',
    'smiles2iupac',
    'pdf2smiles',
    'synth_cost',
    'synmap',
  ],
}
