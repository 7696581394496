import styled from 'styled-components'

export const CardsList = styled.div`
  width: 100%;
  row-gap: 0.75rem;
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(min(15rem, 100%), 1fr));
  justify-content: center;
  align-content: flex-start;

  padding-bottom: ${({ showPagination }) => (showPagination ? '80px' : '8px')};

  @media (max-width: 768px) {
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(min(15rem, 100%), 1fr));
    justify-items: center;
  }
`
