import { CloseIcon } from './index.style'
/**
 *  only 2 sizes 20px and 16px
 */
export const CustomCloseIcon = ({ onClick, size = '1.25rem', color }) => (
  <CloseIcon
    iconType={'close'}
    size={size}
    onClick={onClick}
    data-test="close-icon"
    color={color}
  />
)
