import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import StatisticsFilters from './StatisticsFilters'
import {
  ChartContainer,
  ChartHeader,
  ChartLegend,
  ChartWrapper,
  Container,
  PeriodComparison,
  Selectors,
} from './index.style'
import CustomButton from 'components/common/customButton'
import {
  COLORS_CONFIG,
  NAME_LIST_BY_TYPE,
  NO_DATA_VALUE,
  STATISTICS_SELECT_OPTIONS,
  subSectionsConfig,
} from './config'
import {
  getStatistics,
  selectChartSection,
  setStatisticsLoading,
  setStatisticsNameList,
  setStatisticsSubSection,
} from 'store/actions/statistics'
import LineChart from './LineChart'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Caption,
  Label,
  TitleSecondary,
} from 'components/common/text/index.style'
import { readStorage } from 'utils/storage/storage'
import { useTheme } from 'styled-components'
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend'
import { scaleOrdinal } from '@visx/scale'
import { prepareDataForChart } from './helpers'
import PrevPeriodStatistics from './PrevPeriodStatistics'
import Icon from 'components/Icon'
import ChartSkeleton from './ChartSkeleton'

const Statistics = () => {
  /** INIT - START */
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const chartContainerRef = useRef(null)

  const [showPeriodComparison, setShowComparison] = useState(false)
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  })
  const statistics_data_loading = useSelector(
    (state) => state.statistics.loading
  )
  const language = useSelector((store) => store.settings.lang)

  const statistics_data = useSelector((state) => state.statistics.data)
  const selectedChart = useSelector(
    (state) => state.statistics.selectedChartSection
  )
  const filter_type = useSelector((state) => state.statistics.filter_type)
  const selectedSubSection = useSelector(
    (state) => state.statistics.selected_sub_section
  )

  const name_list =
    selectedChart === 3
      ? NAME_LIST_BY_TYPE?.[selectedChart]?.[selectedSubSection]
      : NAME_LIST_BY_TYPE?.[selectedChart]
  const showAsBar =
    selectedChart === 2 ||
    (selectedChart === 3 && selectedSubSection === 2) ||
    (selectedChart === 3 && selectedSubSection === 3)

  const chartTitle = useMemo(() => {
    return STATISTICS_SELECT_OPTIONS.find((el) => el?.id === selectedChart)
      ?.label
  }, [selectedChart])

  const legendGlyphSize = 8
  /** INIT - END */

  /** EFFECTS - START */
  useEffect(() => {
    const adminRole = readStorage('role')
    if (adminRole === 'syn_admin') {
      dispatch(getStatistics())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name_list])

  useEffect(() => {
    if (chartContainerRef.current && !statistics_data_loading) {
      const updateChartDimensions = () => {
        setChartDimensions({
          width: chartContainerRef?.current?.offsetWidth,
          height: showPeriodComparison
            ? chartContainerRef?.current?.offsetHeight - 10
            : chartContainerRef?.current?.offsetHeight,
        })
      }

      const resizeObserver = new ResizeObserver(() => {
        updateChartDimensions()
      })

      resizeObserver.observe(chartContainerRef.current)
      updateChartDimensions()

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [
    chartContainerRef.current?.offsetWidth,
    statistics_data_loading,
    showPeriodComparison,
  ])
  /** EFFECTS - END */

  /** METHODS - START */
  const handleSelect = (id) => {
    dispatch(setStatisticsLoading({ value: true }))
    dispatch(selectChartSection({ value: id }))
    dispatch(
      setStatisticsNameList(
        id === 3
          ? NAME_LIST_BY_TYPE?.[id]?.[selectedSubSection]
          : NAME_LIST_BY_TYPE?.[id]
      )
    )
  }

  const handleSubSectionSelect = (id) => {
    dispatch(setStatisticsSubSection(id))
    dispatch(setStatisticsNameList(NAME_LIST_BY_TYPE?.[selectedChart]?.[id]))
  }
  /** METHODS - END */

  const prepared_data = useMemo(() => {
    return prepareDataForChart({
      data: statistics_data,
      selectedChart,
      language,
    })
  }, [language, selectedChart, statistics_data])

  const showGraph = useMemo(() => {
    if (prepared_data?.length === 0 || statistics_data_loading) return false
    const all_empty_values = prepared_data?.every((el) => {
      for (const key in el) {
        if (key !== 'date' && el?.[key] !== NO_DATA_VALUE) {
          return false
        }
      }
      return true
    })
    return !all_empty_values
  }, [prepared_data, statistics_data_loading])

  const ordinalColorScale = useMemo(() => {
    let keys
    if (
      (selectedChart === 2 || selectedChart === 3 || selectedChart === 4) &&
      !!prepared_data?.length
    ) {
      keys = Object.keys(prepared_data?.[0])
        ?.filter((key) => key !== 'date')
        .map((key) => `admin_statistics.tooltip.${key}`)
    }
    const domain_names = selectedChart === 1 ? [chartTitle] : keys ?? []

    const colors = domain_names?.map((_, index) => COLORS_CONFIG?.[index])

    return scaleOrdinal({
      domain: domain_names,
      range: colors,
    })
  }, [prepared_data, selectedChart, chartTitle])

  return (
    <Container id="statistics-page-container">
      <StatisticsFilters filter_type={filter_type} />
      <Selectors>
        {STATISTICS_SELECT_OPTIONS.map(({ id, label }) => {
          return (
            <CustomButton
              key={id}
              label={t(label)}
              type={selectedChart === id ? 'accent' : 'primary'}
              height="1.75rem"
              onClick={() => handleSelect(id)}
              disabled={statistics_data_loading}
            >
              <Label
                color={
                  selectedChart === id
                    ? theme.colors.text.white
                    : theme.colors.text.secondary
                }
                ellipsis={true}
              >
                {t(label)}
              </Label>
            </CustomButton>
          )
        })}
      </Selectors>
      {statistics_data_loading && (
        <ChartSkeleton
          selectedChart={selectedChart}
          showPeriodComparison={showPeriodComparison}
        />
      )}
      {!statistics_data_loading && (
        <ChartWrapper>
          <ChartHeader>
            <TitleSecondary>{t(chartTitle)}</TitleSecondary>
            {showGraph && (
              <PeriodComparison>
                <CustomButton
                  type="text"
                  size="medium"
                  onClick={() => setShowComparison(!showPeriodComparison)}
                  gap="0.25rem"
                  defaultColor={
                    showPeriodComparison
                      ? theme.colors.text.accentPrimary
                      : theme.colors.text.primary
                  }
                >
                  {t('admin_statistics.general_indicators')}
                  <Icon
                    iconType={showPeriodComparison ? 'eyeOpen' : 'eyeClose'}
                    size="1rem"
                  />
                </CustomButton>
              </PeriodComparison>
            )}
          </ChartHeader>
          {!showGraph && (
            <TitleSecondary>
              {t('admin_statistics.no_data_for_chosen_parameters')}
            </TitleSecondary>
          )}

          {showPeriodComparison && showGraph && (
            <PrevPeriodStatistics
              data={statistics_data}
              height={showPeriodComparison ? '3rem' : '0rem'}
            />
          )}
          {selectedChart === 3 && (
            <Selectors>
              {subSectionsConfig.map(({ id, label }) => {
                return (
                  <CustomButton
                    key={id}
                    label={t(label)}
                    type={selectedSubSection === id ? 'accent' : 'primary'}
                    height="1.75rem"
                    onClick={() => handleSubSectionSelect(id)}
                    bgColor={
                      selectedSubSection === id
                        ? theme.colors.text.accentPrimary
                        : theme.colors.buttons.secondary
                    }
                  >
                    <Label
                      color={
                        selectedSubSection === id
                          ? theme.colors.text.white
                          : theme.colors.text.secondary
                      }
                      ellipsis={true}
                    >
                      {t(label)}
                    </Label>
                  </CustomButton>
                )
              })}
            </Selectors>
          )}
          {showGraph && (
            <ChartContainer ref={chartContainerRef}>
              <LineChart
                width={chartDimensions.width}
                height={chartDimensions.height}
                data={prepared_data}
                showBar={showAsBar}
              />
            </ChartContainer>
          )}
          {showGraph && (
            <ChartLegend>
              <LegendOrdinal
                scale={ordinalColorScale}
                labelFormat={(label) => `${label.toUpperCase()}`}
              >
                {(labels) => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {labels?.map((label, i) => (
                        <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                          <svg width={legendGlyphSize} height={legendGlyphSize}>
                            <rect
                              fill={label.value}
                              width={legendGlyphSize}
                              height={legendGlyphSize}
                              rx="50%"
                            />
                          </svg>
                          <LegendLabel align="left" margin="0 0 0 0.25rem">
                            <Caption color={theme.colors.text.secondary}>
                              {t(label.text.toLowerCase())}
                            </Caption>
                          </LegendLabel>
                        </LegendItem>
                      ))}
                    </div>
                  )
                }}
              </LegendOrdinal>
            </ChartLegend>
          )}
        </ChartWrapper>
      )}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    section: state.adminPanel.section,
  }
}
Statistics.displayName = 'Statistics'
export default connect(mapStateToProps)(Statistics)
