import { put, takeEvery } from 'redux-saga/effects'
import { store } from 'services/rest'

import {
  GET_MARKUSH_MOLECULE_WEIGHT,
  SET_MARKUSH_INPUTS_DISABLED,
  SET_MARKUSH_MOLECULE_WEIGHT,
} from 'store/constants/filter'

function* getWeight({ smiles }) {
  try {
    const res = yield store('/run-task', {
      service: 'rdkit',
      params: {
        smiles: smiles.toString().split('|')[0].trim(),
      },
      method: 'count_molw',
    })
    if (res.status === 200 && !res.data.result?.error) {
      yield put({
        type: SET_MARKUSH_MOLECULE_WEIGHT,
        weight: Number(res.data.result).toFixed(3),
      })
      yield put({
        type: SET_MARKUSH_INPUTS_DISABLED,
        value: false,
      })
    }
  } catch (e) {
    console.log(e)
  }
}

function* getWeightWatcher() {
  yield takeEvery(GET_MARKUSH_MOLECULE_WEIGHT, getWeight)
}

const watchers = [getWeightWatcher()]
export default watchers
