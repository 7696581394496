import styled from 'styled-components'

export const Element = styled.a`
  padding: 8px;
  display: flex;
  justify-content: center;
  justify-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 200ms;

  & > svg {
    color: ${({ disabled }) => (disabled ? '#CED6E3' : '#2D3745')};
    :hover {
      color: ${({ disabled }) => (disabled ? '#CED6E3' : '#42C1BF')};
    }
  }
`
