import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { FooterButtons } from 'components/RegistrationPageNew/index.style'

import InfoWithProgress from '../../../InfoWithProgress'
import { FieldsBlock, NameWithSurname } from './index.style'

const PersonalData = ({
  role,
  surname,
  company,
  username,
  progress,
  onChange,
  onGoBack,
  onGoToNextStep,
  setProgress,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (username && surname && role && company) setProgress(100)
  }, [])

  return (
    <>
      <InfoWithProgress
        progress={progress}
        title={t('registration.personal_data')}
        description={t('registration.enter_personal_data')}
      />
      <FieldsBlock>
        <NameWithSurname>
          <CustomInput
            id="username"
            label={`${t('registration.name')} *`}
            onChange={(value) => onChange(value, 'username')}
            value={username}
            placeholder={t('registration.namePlaceholder')}
            autoComplete="username"
            withClearButton
          />
          <CustomInput
            id="surname"
            label={`${t('registration.surname')} *`}
            onChange={(value) => onChange(value, 'surname')}
            value={surname}
            placeholder={t('registration.surnamePlaceholder')}
            autoComplete="surname"
            withClearButton
          />
        </NameWithSurname>

        <CustomInput
          id="company"
          onChange={(value) => onChange(value, 'company')}
          value={company}
          label={`${t('registration.company')} *`}
          placeholder={t('registration.companyPlaceholder')}
          autoComplete="company"
          withClearButton
        />

        <CustomInput
          id="position"
          label={`${t('registration.position')} *`}
          onChange={(value) => onChange(value, 'role')}
          value={role}
          placeholder={t('registration.positionPlaceholder')}
          autoComplete="position-title"
          withClearButton
        />
      </FieldsBlock>
      <FooterButtons>
        <CustomButton
          type="text"
          gap="0.25rem"
          onClick={onGoBack}
          data-test="go-back-btn"
        >
          <Icon iconType="arrowLeft" size="1rem" />
          {t('registration.back')}
        </CustomButton>
        <CustomButton
          type="text_accent"
          data-tooltip-id="disabled-next-button"
          disabled={progress < 100}
          gap="0.25rem"
          onClick={onGoToNextStep}
          data-test="complete-registration-btn"
        >
          {t('registration.fill_the_questionnaire')}
          <Icon iconType="arrowRight" size="1rem" />
        </CustomButton>
      </FooterButtons>
      {progress < 100 && (
        <ReactTooltip
          id="disabled-next-button"
          className="c-tooltip c-tooltip-base"
          classNameArrow="c-tooltip-arrow"
          place={'top-end'}
        >
          {t('registration.fill_all_fields')}
        </ReactTooltip>
      )}
    </>
  )
}

export default PersonalData
