import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useCallback } from 'react'

import Icon from 'components/Icon'
import { confirm } from 'store/actions/confirm'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { addNotification } from 'store/actions/notifications'
import { CustomDialog } from 'components/common/customDialog'

import {
  Container,
  CategoryBlock,
  CategoryTitle,
  DialogActions,
  DialogContent,
  InnerCategoryTitle,
  InnerCategoriesBlock,
} from './index.style'
import LoadingOverlay from '../LoadingOverlay'
import { destroy, fetch, store } from '../../services/rest'
import CustomScrollbar from 'components/CustomScrollbar'

const AdminViewerView = () => {
  const [categories, setCategories] = useState([])
  const [innerCategories, setInnerCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState({ id: -1 })
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [categoryData, setCategoryData] = useState({ title: '' })
  const [innerCategoryData, setInnerCategoryData] = useState({
    title: '',
    name: '',
  })

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const { data: fetchedCategories } = await fetch('/plugins/categories')
      setCategories(fetchedCategories)

      const { data: fetchedInnerCategories } = await fetch(
        '/plugins/inner_categories'
      )
      setInnerCategories(fetchedInnerCategories)

      setLoading(false)
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notifications.error',
        text: e.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }
      dispatch(addNotification(notify))
      setLoading(false)
    }
  }, [dispatch, t])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleAddCategory = async () => {
    try {
      const newCategory = { title: categoryData.title }
      await store('/plugins/categories', newCategory)

      reloadData()
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: e.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }
      dispatch(addNotification(notify))
    }
  }

  const handleDeleteCategory = (id) => () => {
    dispatch(
      confirm(t('confirmation.areYouSure'), async () => {
        try {
          await destroy(`/plugins/categories/${id}`)
          reloadData()
        } catch (e) {
          const id = uuidv4()
          const notify = {
            id,
            name: 'notification.error',
            text: e.message,
            notification_type: 'error',
            autoRemove: true,
            timeout: 5000,
            needTranslateText: false,
          }
          dispatch(addNotification(notify))
        }
      })
    )
  }

  const handleAddInnerCategory = async () => {
    try {
      const newCategory = {
        name: innerCategoryData.name,
        title: innerCategoryData.title,
        parent: selected.parent,
      }
      await store('/plugins/inner_categories', newCategory)
      reloadData()
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: e.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }
      dispatch(addNotification(notify))
    }
  }

  const handleDeleteInnerCategory = (id) => () => {
    dispatch(
      confirm(t('confirmation.areYouSure'), async () => {
        try {
          await destroy(`/plugins/inner_categories/${id}`)
          reloadData()
        } catch (e) {
          const id = uuidv4()
          const notify = {
            id,
            name: 'notification.error',
            text: e.message,
            notification_type: 'error',
            autoRemove: true,
            timeout: 5000,
            needTranslateText: false,
          }
          dispatch(addNotification(notify))
        }
      })
    )
  }

  const resetState = () => {
    setShowCreateModal(false)
    setSelected({ id: -1 })
    setCategories([])
    setInnerCategories([])
    setCategoryData({ title: '' })
    setInnerCategoryData({ title: '', name: '' })
  }

  const reloadData = async () => {
    resetState()
    loadData()
  }

  const handleCloseModal = () => {
    setShowCreateModal(false)
    setSelected({ id: -1 })
  }

  const handleOpenEditModal = (type, id, parent) => () => {
    setSelected({ type, id, parent })
    setShowCreateModal(true)
  }

  const handleAdd = async () => {
    if (selected.id >= 0) return

    try {
      await (selected.type === 'category'
        ? handleAddCategory()
        : handleAddInnerCategory())
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const handleEdit = () => {}

  return (
    <CustomScrollbar className="margin-4-right">
      <Container>
        <div>
          <CustomButton
            type="accent"
            onClick={handleOpenEditModal('category', -1)}
            style={{ marginBottom: '0.75rem' }}
          >
            Create new category
          </CustomButton>
        </div>
        {categories.map(({ id: categoryId, title }, idx, arr) => {
          const inner = innerCategories.filter(
            ({ parent }) => parent === categoryId
          )

          return (
            <CategoryBlock key={categoryId}>
              <CategoryTitle>
                <div>
                  <CustomButton
                    type="text"
                    onClick={handleOpenEditModal('category', categoryId)}
                    disabled
                  >
                    <Icon iconType="settings" />
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    onClick={handleDeleteCategory(categoryId)}
                    type="text"
                  >
                    <Icon iconType="deleted" />
                  </CustomButton>
                </div>
                {idx !== 0 && (
                  <div>
                    <CustomButton onClick={() => {}} type="text" disabled>
                      <Icon iconType="arrowTop" />
                    </CustomButton>
                  </div>
                )}
                {idx === 0 && <div />}
                {idx !== arr.length - 1 && (
                  <div>
                    <CustomButton onClick={() => {}} type="text" disabled>
                      <Icon iconType="arrowDown" />
                    </CustomButton>
                  </div>
                )}
                {idx === arr.length - 1 && <div />}
                <div>{title}</div>
              </CategoryTitle>
              <InnerCategoriesBlock>
                {inner.map(
                  (
                    { id: innerCategoryId, title: innerCategoryTitle },
                    idx,
                    arr
                  ) => {
                    return (
                      <InnerCategoryTitle
                        key={`${innerCategoryId}-innerCategoryId`}
                      >
                        <div>
                          <CustomButton
                            onClick={handleOpenEditModal(
                              'inner',
                              innerCategoryId
                            )}
                            type="text"
                            disabled
                          >
                            <Icon iconType="settings" size="1.25rem" />
                          </CustomButton>
                        </div>
                        <div>
                          <CustomButton
                            onClick={handleDeleteInnerCategory(innerCategoryId)}
                            type="text"
                          >
                            <Icon iconType="deleted" size="1.25rem" />
                          </CustomButton>
                        </div>
                        {idx !== 0 && (
                          <div>
                            <CustomButton
                              onClick={() => {}}
                              type="text"
                              disabled
                            >
                              <Icon iconType="arrowTop" size="1.25rem" />
                            </CustomButton>
                          </div>
                        )}
                        {idx === 0 && <div />}
                        {idx !== arr.length - 1 && (
                          <div>
                            <CustomButton
                              onClick={() => {}}
                              type="text"
                              disabled
                            >
                              <Icon iconType="arrowDown" size="1.25rem" />
                            </CustomButton>
                          </div>
                        )}
                        {idx === arr.length - 1 && <div />}
                        <div>{innerCategoryTitle}</div>
                      </InnerCategoryTitle>
                    )
                  }
                )}
                <InnerCategoryTitle>
                  <div>
                    <CustomButton
                      onClick={handleOpenEditModal('inner', -1, categoryId)}
                      type="text"
                    >
                      <Icon iconType="add" size="1.25rem" />
                    </CustomButton>
                  </div>
                  <div />
                  <div />
                  <div />
                  <div />
                </InnerCategoryTitle>
              </InnerCategoriesBlock>
            </CategoryBlock>
          )
        })}
        {showCreateModal && (
          <CustomDialog onClose={handleCloseModal} width="43.75rem">
            <DialogContent>
              {selected.type === 'category' && (
                <div>
                  <CustomInput
                    onChange={(value) =>
                      setCategoryData({ ...categoryData, title: value })
                    }
                    value={categoryData.title}
                    label="Category title"
                    placeholder="Enter category title"
                    withClearButton
                  />
                </div>
              )}

              {selected.type === 'inner' && (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '1.25rem',
                  }}
                >
                  <CustomInput
                    onChange={(value) =>
                      setInnerCategoryData({
                        ...innerCategoryData,
                        title: value,
                      })
                    }
                    value={innerCategoryData.title}
                    label="Inner category title"
                    placeholder="Enter category title"
                    withClearButton
                  />

                  <CustomInput
                    onChange={(value) =>
                      setInnerCategoryData({
                        ...innerCategoryData,
                        name: value,
                      })
                    }
                    value={innerCategoryData.name}
                    label="Plugin output name"
                    placeholder="Enter plugin output name"
                    withClearButton
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <CustomButton type="secondary" onClick={handleCloseModal}>
                Exit
              </CustomButton>
              <CustomButton
                onClick={selected.id >= 0 ? handleEdit : handleAdd}
                type="accent"
              >
                {selected.id >= 0 ? 'Edit' : 'Create'}
              </CustomButton>
            </DialogActions>
          </CustomDialog>
        )}
        {loading && <LoadingOverlay />}
      </Container>
    </CustomScrollbar>
  )
}

export default AdminViewerView
