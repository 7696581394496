import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'

import { Buttons } from './index.style'

const NumberInputButtons = ({
  min,
  max,
  value,
  btnSize,
  iconSize,
  onIncrement,
  onDecrement,
  btnBorderRadius,
  withWhiteBackground,
}) => {
  return (
    <Buttons {...{ btnSize, btnBorderRadius, withWhiteBackground }}>
      <CustomButton
        type="icon_secondary"
        disabled={value >= max}
        onClick={onIncrement}
      >
        <Icon iconType="arrowTop" size={iconSize} />
      </CustomButton>
      <CustomButton
        type="icon_secondary"
        disabled={value <= min}
        onClick={onDecrement}
      >
        <Icon iconType="arrowDown" size={iconSize} />
      </CustomButton>
    </Buttons>
  )
}

export default NumberInputButtons
