import { t } from 'i18next'

export const getPlacesLeftTitle = (count) => {
  const lastDigit = count % 10
  const lastTwoDigits = count % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14)
    return t('admin.places_left_many')
  if (lastDigit === 1) return t('admin.places_left_one')
  if (lastDigit >= 2 && lastDigit <= 4) return t('admin.places_left_few')
  return t('admin.places_left_many')
}
