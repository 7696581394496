import { t } from 'i18next'

const getVendorName = (text) => {
  if (text === 'xgb' || text === 'tani') return 'predicted'
  if (text === 'past') return 'synthesized earlier'
  return text
}

export const getDataForRender = (data, type) => {
  if (data) {
    const map = new Map()

    data.forEach((el) => {
      const { way, stage } = el
      if (!map.has(way)) map.set(way, {})
      const wayObj = map.get(way)
      if (!wayObj?.[stage]) map.set(way, { ...wayObj, [stage]: [] })
      const obj = map.get(way)
      map.set(way, {
        ...obj,
        [stage]: [
          ...obj[stage],
          {
            ...el,
            id:
              el.id === 'xgb' || el.id === 'tani' || el.id === 'past'
                ? ''
                : el.id,
            vendor: getVendorName(el.vendor),
          },
        ],
      })
    })
    const result = Array.from(map.keys()).map((way) =>
      Object.keys(map.get(way)).map((stage) => map.get(way)[stage])
    )

    return result
  }
  return []
}

export const getSmirksForRender = (data, type) => {
  if (data) {
    const map = new Map()
    let id = 0

    data.forEach((el) => {
      id++
      const { way, stage } = el
      if (!map.has(way)) map.set(way, {})
      const wayObj = map.get(way)
      if (!wayObj?.[stage]) map.set(way, { ...wayObj, [stage]: { ...el, id } })
    })
    return Array.from(map.keys()).map((way) => Object.values(map.get(way)))
  }
  return []
}

export const getTableForDownload = (schemes) => {
  return schemes.map((scheme, index) =>
    scheme.map(({ stages }, ind) =>
      stages.map((reagent, i) => ({
        '№':
          ind > 0
            ? schemes[index].slice(0, ind).reduce((a, b) => a.concat(b), [])
                .length +
              i +
              1
            : i + 1,
        name: reagent.smiles,
        price: Number(reagent.price),
        weight: Number(reagent.weight),
        vendor: reagent.vendor,
        id: reagent.id,
      }))
    )
  )
}

export const getAllSchemesCSVData = (table, t, json2csv) =>
  table
    .map((scheme, ind) => {
      let stageNumber = 0
      const header = `Scheme ${ind + 1}\n`
      const table = scheme.reduce((a, b) => {
        stageNumber++
        return a.concat({ '№': `${t('synthCost.stage')} ${stageNumber}` }, b)
      }, [])
      const csv = json2csv.parse(table, { header: true })
      return header + csv
    })
    .join('\n\n')

export const getErrorText = (text) => {
  if (text?.toLowerCase()?.includes('is not a smiles'))
    return text.replace('is not a smiles', t('synthCost.is_not_smiles'))
  return 'synthCost.server_returned_error'
}

export const isLink = (str) => /^https?:\/\//.test(str)

export const getReagentsTitle = (count) => {
  const lastDigit = count % 10
  const lastTwoDigits = count % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14)
    return t('synthCost.reagents_many')
  if (lastDigit === 1) return t('synthCost.one_reagent')
  if (lastDigit >= 2 && lastDigit <= 4) return t('synthCost.reagents_few')
  return t('synthCost.reagents_many')
}
