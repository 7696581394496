import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Label } from 'components/common/text/index.style'
import RadioCheckbox from 'components/common/customRadioCheckbox'

import { Checkboxes, CheckboxWithTitle } from './index.style'

const RadioCheckboxes = ({ operator, onChangeOperator }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Checkboxes>
      <CheckboxWithTitle
        onClick={() =>
          operator === 'AND' ? onChangeOperator(null) : onChangeOperator('AND')
        }
      >
        <RadioCheckbox
          radio
          whiteBackground
          shouldPropagate
          value={operator === 'AND'}
        />
        <Label color={theme.colors.text.secondary}>
          {t('dataset_table.and')}
        </Label>
      </CheckboxWithTitle>
      <CheckboxWithTitle
        onClick={() =>
          operator === 'OR' ? onChangeOperator(null) : onChangeOperator('OR')
        }
      >
        <RadioCheckbox
          radio
          whiteBackground
          shouldPropagate
          value={operator === 'OR'}
        />
        <Label color={theme.colors.text.secondary}>
          {t('dataset_table.or')}
        </Label>
      </CheckboxWithTitle>
    </Checkboxes>
  )
}

export default RadioCheckboxes
