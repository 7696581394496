import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import {
  ListBlock,
  WrapBlock,
  WithDelBlock,
  InputWithButton,
} from './index.style'
import { destroy, fetch, store } from '../../services/rest'
import Icon from 'components/Icon'
import { confirm } from 'store/actions/confirm'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import CustomScrollbar from 'components/CustomScrollbar'

const AdminInviteView = () => {
  const [list, setList] = useState([])
  const [mails, setMails] = useState([])
  const [ips, setIps] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [ip, setIp] = useState('')
  const [mail, setMail] = useState('')

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const loadData = async () => {
    try {
      const { data: invites } = await fetch(`/profile/invite`)
      setList(invites)

      const { data: maillist } = await fetch(`/profile/whitelist_mail`)
      setMails(maillist)

      const { data: iplist } = await fetch(`/profile/whitelist_ip`)
      setIps(iplist)
    } catch (e) {
      console.log(e)
    }
  }

  const handleAdd = async () => {
    try {
      await store(`/profile/invite`, { quantity })
      await loadData()
    } catch (e) {
      console.log(e)
    }
  }

  const handleAddMail = async () => {
    try {
      await store(`/profile/whitelist_mail`, { mail })
      setMail('')
      await loadData()
    } catch (e) {
      console.log(e)
    }
  }

  const handleAddIp = async () => {
    try {
      await store(`/profile/whitelist_ip`, { ip })
      setIp('')
      await loadData()
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteMail = (mail) => {
    try {
      dispatch(
        confirm(t('confirmation.areYouSure'), async () => {
          await destroy(`/profile/whitelist_mail`, { mail })
          await loadData()
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteIP = (ip) => {
    try {
      dispatch(
        confirm(t('confirmation.areYouSure'), async () => {
          await destroy(`/profile/whitelist_ip`, { ip })
          await loadData()
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <CustomScrollbar className='margin-4-right'>
    <WrapBlock>
      <h4>{t('admin.mails')}</h4>
      <WithDelBlock>
        {mails.map((mail) => {
          return (
            <React.Fragment key={mail}>
              <CustomButton type="text" onClick={() => handleDeleteMail(mail)}>
                <Icon iconType="deleted" size="1rem" />
              </CustomButton>
              <div key={mail}>{mail}</div>
            </React.Fragment>
          )
        })}
      </WithDelBlock>
      <InputWithButton>
        <CustomInput
          label={t('admin.mail')}
          placeholder="@skoltech.ru"
          value={mail}
          onChange={(value) => setMail(value)}
          withWhiteBackground
        />
        <CustomButton
          onClick={handleAddMail}
          disabled={!mail}
          type="accent"
          width="9.375rem"
        >
          {t('admin.addMail')}
        </CustomButton>
      </InputWithButton>
      <h4>IPs</h4>
      <WithDelBlock>
        {ips.map((ip) => {
          return (
            <React.Fragment key={ip}>
              <CustomButton type="text" onClick={() => handleDeleteIP(ip)}>
                <Icon iconType="deleted" size="1rem" />
              </CustomButton>
              <div key={ip}>{ip}</div>
            </React.Fragment>
          )
        })}
      </WithDelBlock>
      <InputWithButton>
        <CustomInput
          label="IP"
          value={ip}
          placeholder="192.168.0.0/24"
          onChange={(value) => setIp(value)}
          withWhiteBackground
        />
        <CustomButton
          onClick={handleAddIp}
          disabled={!ip}
          type="accent"
          width="9.375rem"
        >
          {t('admin.addIP')}
        </CustomButton>
      </InputWithButton>
      {t('admin.invites')}{' '}
      <InputWithButton>
        <CustomInput
          variant="outlined"
          label={t('admin.num')}
          value={quantity}
          type="number"
          onChange={(value) => setQuantity(+value)}
          withWhiteBackground
        />
        <CustomButton
          onClick={handleAdd}
          type="accent"
          width="10rem"
          gap="0.25rem"
        >
          {t('admin.generate')} {quantity}
        </CustomButton>
      </InputWithButton>
      <ListBlock>
        <div style={{ fontWeight: 800 }}>{t('admin.token')}</div>
        <div style={{ fontWeight: 800 }}>{t('admin.created')}</div>
        <div style={{ fontWeight: 800 }}>{t('admin.regData')}</div>
        {list.map(({ id, created, date_register, email, is_used, token }) => (
          <React.Fragment key={id}>
            <div style={{ color: is_used ? 'green' : 'blueviolet' }}>
              {token}
            </div>
            <div>{format(new Date(created), 'dd.MM.yyyy HH:mm')}</div>
            <div>
              {is_used
                ? `${email} at ${format(new Date(date_register), 'dd.MM.yyyy')}`
                : t('admin.notUsed')}
            </div>
          </React.Fragment>
        ))}
      </ListBlock>
    </WrapBlock>
    </CustomScrollbar>
  )
}

export default AdminInviteView
