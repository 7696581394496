import {
  GET_SURVEYS_FAIL,
  GET_SURVEYS_INIT,
  GET_SURVEYS_SUCCESS,
  GET_USER_ANSWERS_FAIL,
  GET_USER_ANSWERS_INIT,
  GET_USER_ANSWERS_SUCCESS,
  RESET_USER_ANSWERS,
} from 'store/constants/survey'

export const initialState = {
  surveys: null,
  loading: false,
  error: '',
  user_answers: null,
}

const surveysReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_SURVEYS_INIT: {
      return { ...state, loading: true, surveys: null }
    }

    case GET_SURVEYS_SUCCESS: {
      return { ...state, loading: false, surveys: payload.items }
    }

    case GET_SURVEYS_FAIL: {
      return { ...state, loading: false, surveys: null, error: payload.error }
    }

    case GET_USER_ANSWERS_INIT: {
      return { ...state, loading: true, user_answers: null }
    }

    case GET_USER_ANSWERS_SUCCESS: {
      return { ...state, loading: false, user_answers: payload.items }
    }

    case GET_USER_ANSWERS_FAIL: {
      return {
        ...state,
        loading: false,
        user_answers: null,
        error: payload.error,
      }
    }

    case RESET_USER_ANSWERS: {
      return { ...state, user_answers: null }
    }

    default:
      return state
  }
}

export default surveysReducer
