import { useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import CustomTextArea from 'components/common/customTextArea'
import { Caption, TitleTertiary } from 'components/common/text/index.style'

import { NOTE_MAX_LETTERS_COUNT } from './config/config'
import { Inputs, NoteLabel, NoteAreaWithLabel, Buttons } from './index.style'

const MoleculeNoteDialog = ({ note, alias, onClose, onSaveNote }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [newName, setNewName] = useState(alias || '')
  const [newNote, setNewNote] = useState(note || '')

  const hasNote = alias !== null || note !== null

  return (
    <CustomDialog
      withPortal={true}
      gap="1.5rem"
      onClose={onClose}
      outboundClickEnabled={false}
      width="23.75rem"
    >
      <TitleTertiary
        fontWeight={theme.fontWeight.semibold}
        style={{ marginRight: '3rem' }}
      >
        {hasNote
          ? t('molecule_viewer.edit_name_or_note')
          : t('molecule_viewer.write_name_or_note')}
      </TitleTertiary>
      <Inputs>
        <CustomInput
          label={t('molecule_viewer.molecule_name')}
          value={newName}
          withClearButton
          onChange={(value) => setNewName(value)}
          placeholder={t('molecule_viewer.enter_name')}
        />
        <NoteAreaWithLabel>
          <NoteLabel>
            <Caption color={theme.colors.text.secondary}>
              {t('molecule_viewer.note')}
            </Caption>
            <Caption color={theme.colors.text.tertiary}>
              {`${newNote.length} / ${NOTE_MAX_LETTERS_COUNT}`}
            </Caption>
          </NoteLabel>
          <CustomTextArea
            value={newNote}
            withClearButton
            initHeight="6.5rem"
            onChange={(value) =>
              value.length <= NOTE_MAX_LETTERS_COUNT && setNewNote(value)
            }
            placeholder={t('molecule_viewer.write_note')}
          />
        </NoteAreaWithLabel>
      </Inputs>
      <Buttons>
        <CustomButton
          type="secondary"
          width="100%"
          onClick={hasNote ? () => onSaveNote(null, null) : onClose}
        >
          {hasNote
            ? t('molecule_viewer.delete_all')
            : t('molecule_viewer.cancel')}
        </CustomButton>
        <CustomButton
          type="accent"
          width="100%"
          onClick={() => onSaveNote(newName, newNote)}
          disabled={
            (alias === newName && note === newNote) || !(newName || newNote)
          }
        >
          {t('molecule_viewer.save')}
        </CustomButton>
      </Buttons>
    </CustomDialog>
  )
}

export default MoleculeNoteDialog
