import { memo } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTheme } from 'styled-components'

import Icon from 'components/Icon'

import {
  Container,
  ToggleBtn,
  ToggleLabel,
  ToggleBtnGroup,
  ToggleWithLabel,
} from './index.style'

const CustomToggle = memo(
  ({
    type = 'white',
    size = 'small',
    value,
    label,
    onClick,
    tooltipText,
    withTooltip,
    onMouseOver,
    onMouseLeave,
    tooltipPlace = 'top-start',
    noTooltipArrow = false,
    tooltipOffset = 14,
  }) => {
    const theme = useTheme()
    return (
      <ToggleWithLabel onClick={onClick}>
        {label && (
          <ToggleLabel
            active={value}
            onMouseOver={withTooltip && onMouseOver && onMouseOver}
            onMouseLeave={withTooltip && onMouseLeave && onMouseLeave}
          >
            <Container data-tooltip-id={tooltipText ? 'toggle-tooltip' : ''}>
              {label}
              {withTooltip && (
                <>
                  <Icon iconType="description" />

                  <ReactTooltip
                    id="toggle-tooltip"
                    className={`c-tooltip c-tooltip-base c-tooltip-toggle`}
                    classNameArrow="c-tooltip-arrow"
                    place={tooltipPlace}
                    noArrow={noTooltipArrow}
                    offset={tooltipOffset}
                  >
                    {tooltipText}
                  </ReactTooltip>
                </>
              )}
            </Container>
          </ToggleLabel>
        )}
        <ToggleBtnGroup type={type} size={size} active={value}>
          <ToggleBtn
            active={value}
            size={size}
            type={type}
            boxShadow={value ? theme.shadow.toggleOn : theme.shadow.toggleOff}
          />
        </ToggleBtnGroup>
      </ToggleWithLabel>
    )
  }
)

CustomToggle.displayName = 'CustomToggle'

export default CustomToggle
