import Icon from 'components/Icon'
import {
  TextArea,
  CustomTextAreaWrapper,
  CustomTextAreaClearButton,
} from './index.style'
import { useRef, useState } from 'react'
import CustomScrollbar from 'components/CustomScrollbar'

const CustomTextArea = ({
  onChange,
  value = '',
  placeholder = '',
  disabled = false,
  withClearButton = false,
  initHeight = '',
  className,
}) => {
  const textarea = useRef(null)
  const initContentHeight = initHeight || '7rem'
  const [contentHeight, setContentHeight] = useState(initContentHeight)

  const updateContentHeight = (reset) => {
    if (textarea.current) {
      const newHeight = textarea.current?.scrollHeight
      return setContentHeight(reset ? initContentHeight : newHeight)
    }
  }

  const showClearButton = withClearButton && value.length > 0

  const handleChange = (e) => onChange(e.target.value)
  const clear = (e) => {
    onChange('')
    updateContentHeight(true)
  }

  return (
    <CustomTextAreaWrapper
      style={{ height: initContentHeight }}
      className={className}
    >
      <CustomScrollbar
        noScrollY={!value}
        disableTrackYWidthCompensation={true}
        className="margin--0-right"
      >
        <TextArea
          ref={textarea}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            handleChange(e)
            updateContentHeight(e.target.value === '')
          }}
          placeholder={placeholder}
          showClearButton={showClearButton}
          style={{ height: contentHeight }}
        />
      </CustomScrollbar>
      {showClearButton && (
        <CustomTextAreaClearButton onClick={clear} id="clear">
          <Icon iconType="close" size="1rem" />
        </CustomTextAreaClearButton>
      )}
    </CustomTextAreaWrapper>
  )
}

export default CustomTextArea
