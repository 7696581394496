import styled from 'styled-components'

export const Menu = styled.div`
  padding: 0.175rem;
  border: ${({ theme }) => `1.2px solid ${theme.colors.icons.white}`};

  display: flex;
  border-radius: 0.75rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgrounds.contextMenu};
  box-shadow: ${({ theme }) => theme.shadow.blur};
  backdrop-filter: blur(30px);

  font-size: ${({ theme }) => theme.text[12]};
  line-height: ${({ theme }) => theme.text[16]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  min-width: 10rem;
  outline: none;
  cursor: default;
  text-align: left;
  transition: opacity 250ms ease !important;
  align-items: flex-start;
  z-index: 10000;

  position: absolute;
  top: ${({ type }) => (type === 'submenu' ? '50%' : '0.25rem')};
  left: ${({ type, currentLang }) => {
    if (type === 'submenu') {
      return currentLang === 'en' ? '185%' : '200%'
    }
    return 'calc(100% - 0.1875rem)'
  }};
`

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.625rem;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.text.tertiary : theme.colors.text.secondary};
  &:hover {
    ${({ theme, disabled }) => {
      if (disabled) return
      return `
         {
          color: ${theme.colors.text.accentPrimary};
          background-color: ${theme.colors.backgrounds.contextMenuHover};
        }
      `
    }}
  }
`
