import { useTheme } from 'styled-components'

import Icon from 'components/Icon'
import { Label } from 'components/common/text/index.style'

import { NotificationsCount } from './index.style'
import { NotificationsActionsBtn } from '../index.style'

const NotificationsBtnWithCount = ({
  onClick,
  itemsCompressed,
  notificationsCount,
}) => {
  const theme = useTheme()
  return (
    <NotificationsActionsBtn onClick={onClick}>
      <Icon iconType={itemsCompressed ? 'arrowDown' : 'arrowTop'} size="1rem" />
      <NotificationsCount>
        <Label color={theme.colors.text.accentPrimary}>
          {notificationsCount}
        </Label>
      </NotificationsCount>
    </NotificationsActionsBtn>
  )
}

export default NotificationsBtnWithCount
