import {
  SET_OPEN_FILTER,
  SET_FILTER_CONFIG,
  RESET_FILTER,
  GET_MARKUSH_MOLECULE_WEIGHT,
  RESET_MARKUSH_MOLECULE_WEIGHT,
  SET_LIT_DOC_TYPE,
} from '../constants/filter'

export const setOpenFilter = (open) => {
  return {
    type: SET_OPEN_FILTER,
    open,
  }
}

export const setFilterConfig = (config) => {
  return {
    type: SET_FILTER_CONFIG,
    config,
  }
}

export const resetFilter = () => {
  return {
    type: RESET_FILTER,
  }
}

export const getMarkushMoleculeWeight = (smiles) => {
  return {
    type: GET_MARKUSH_MOLECULE_WEIGHT,
    smiles,
  }
}

export const resetMarkushMoleculeWeight = () => {
  return {
    type: RESET_MARKUSH_MOLECULE_WEIGHT,
  }
}

export const setLeftFilterLitDocType = (docType) => {
  return { type: SET_LIT_DOC_TYPE, docType }
}
