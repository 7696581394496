import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Icon from 'components/Icon'

import {
  CardWrapper,
  CardBody,
  CardFooter,
  CardHeader,
  FeedbackType,
  CardFooterDateBlock,
  CardFooterDateItem,
  AttachmentWrapper,
  CardBodyText,
} from './index.style'

import {
  Caption,
  Subheadline,
  TextLarge,
} from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import { modules, themeOptions } from 'components/Feedback/FeedbackForm/config'
import { useDispatch, useSelector } from 'react-redux'
import { openFeedbackCard } from 'store/actions/feedback'
import { memo } from 'react'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'

const FeedbackCard = memo(({ card, setWindowVisible }) => {
  const {
    comment,
    ishandled,
    user_id,
    user_email,
    type_id,
    attach,
    created_at,
    module_ids,
    feedback_id,
  } = card || {}

  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isPDFAttach = attach?.[0]?.original_file_name?.includes('.pdf')
  const feedback_theme = themeOptions.find(
    (option) => option.id === type_id
  )?.label
  const first_module = modules.find((module) => module.id === module_ids?.[0])

  const handleOpenFeedbackCard = (e) => {
    e.stopPropagation()
    dispatch(openFeedbackCard(card))
    setWindowVisible(true)
  }

  return (
    <CardWrapper onClick={handleOpenFeedbackCard}>
      <CardHeader>
        {feedback_theme && (
          <>
            <FeedbackType
              withBorder={true}
              borderColor={theme.colors.backgrounds.accent}
              maxWidth="40%"
              data-tooltip-id={`feedback-theme-tooltip-${feedback_id}`}
            >
              <Caption color={theme.colors.text.accentPrimary} ellipsis={true}>
                {t(feedback_theme)}
              </Caption>
            </FeedbackType>
            <ReactTooltip
              id={`feedback-theme-tooltip-${feedback_id}`}
              className={`c-tooltip c-tooltip-base c-tooltip-toggle`}
              classNameArrow="c-tooltip-arrow"
              place="top"
            >
              {t(feedback_theme)}
            </ReactTooltip>
          </>
        )}

        {!!module_ids?.length && (
          <>
            <FeedbackType
              withBorder={true}
              color={theme.colors.text.primary}
              background={theme.colors.backgrounds.secondary}
              maxWidth={module_ids?.length > 1 ? '40%' : '50%'}
              data-tooltip-id={`feedback-module-tooltip-${feedback_id}`}
            >
              <Caption ellipsis={true}>{t(first_module?.label)}</Caption>
            </FeedbackType>
            <ReactTooltip
              id={`feedback-module-tooltip-${feedback_id}`}
              className={`c-tooltip c-tooltip-base c-tooltip-toggle`}
              classNameArrow="c-tooltip-arrow"
              place="top"
            >
              {t(first_module?.label)}
            </ReactTooltip>
          </>
        )}
        {module_ids?.length > 1 && (
          <FeedbackType
            withBorder={true}
            color={theme.colors.text.primary}
            background={theme.colors.backgrounds.secondary}
          >
            <Caption>+{module_ids.length - 1}</Caption>
          </FeedbackType>
        )}
      </CardHeader>
      <CardBody>
        <Subheadline color={theme.colors.text.secondary}>
          {user_email}
        </Subheadline>
        <CardBodyText>
          <TextLarge fontWeight={theme.fontWeight.medium}>{comment}</TextLarge>
        </CardBodyText>
      </CardBody>
      <CardFooter>
        {created_at && (
          <CardFooterDateBlock>
            <CardFooterDateItem>
              <Icon iconType="calendar_2" size="1rem" />
              <Caption
                color={theme.colors.text.secondary}
                fontWeight={theme.fontWeight.medium}
              >
                {formatUtcDigitDateByFormat(new Date(created_at), 'dd.MM.yyyy')}
              </Caption>
            </CardFooterDateItem>
            <CardFooterDateItem>
              <Icon iconType="timer" size="1rem" />
              <Caption
                color={theme.colors.text.secondary}
                fontWeight={theme.fontWeight.medium}
              >
                {formatUtcDigitDateByFormat(new Date(created_at), 'HH:mm')}
              </Caption>
            </CardFooterDateItem>
          </CardFooterDateBlock>
        )}

        {!!attach?.length && (
          <AttachmentWrapper>
            <Caption color={theme.colors.text.secondary}>
              {isPDFAttach
                ? `PDF (${attach?.length})`
                : `JPEG/PNG (${attach?.length})`}
            </Caption>
            <Icon
              iconType={isPDFAttach ? 'fileIcon' : 'imageIcon'}
              size="1rem"
            />
          </AttachmentWrapper>
        )}
      </CardFooter>
    </CardWrapper>
  )
})
FeedbackCard.displayName = 'FeedbackCard'
export default FeedbackCard
