import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { emailReg } from 'utils/common/common'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'

import { Container } from './index.style'
import TitleWithDescription from '../TitleWithDescription'

const EmailBlock = ({
  email,
  loading,
  setEmail,
  emailError,
  onSendMail,
  setEmailError,
  isResetPasswordDisabled,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (!email || emailReg.test(email)) {
      setEmailError('')
    } else {
      setEmailError('account.emailError')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, t])

  return (
    <Container>
      <TitleWithDescription
        title={t('recovery.password_reset')}
        description={t('recovery.enter_email')}
      />

      <CustomInput
        value={email}
        placeholder={t('recovery.email_placeholder')}
        onChange={(value) => {
          setEmail(value)
        }}
        type="email"
        withClearButton
        error={emailError}
        isValidated={!!email && !emailError}
      />

      <CustomButton
        width="100%"
        type="accent"
        onClick={onSendMail}
        disabled={isResetPasswordDisabled}
        data-test="restore-password-btn"
      >
        {loading ? (
          <Icon size="1rem" iconType="loader" />
        ) : (
          t('recovery.restore_password')
        )}
      </CustomButton>
    </Container>
  )
}

export default EmailBlock
