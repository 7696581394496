import styled from 'styled-components'

export const Content = styled.div`
  gap: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const ExtBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const PasswordChangeButtons = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
`

export const DialogButtons = styled.div`
  gap: 1.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const Footer = styled.div`
  gap: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TitleWithDescription = styled.div`
  display: flex;
  gap: 0.125rem;
  flex-direction: column;
`

export const ToggleWithLabel = styled.div`
  gap: 1rem;
  display: flex;
`
