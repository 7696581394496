import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Property from 'components/Property'
import MoleculeStructure from 'components/MoleculeStructure'
import { getDataByCategoryName } from 'config/molecule/molecule'
import MoleculeIdWithButtons from 'components/MoleculeIdWithButtons'

import {
  loadRuIupac,
  loadMolecule,
  loadServices,
  resetRuIupac,
  resetMolecule,
  loadCategories,
} from 'store/actions/molecule'

import {
  Data,
  Content,
  InfoContainer,
  BestSmilesViewContainer,
  Properties,
} from './index.style'
import Alias from '../MoleculeFooter/Alias'
import MoleculeFooter from '../MoleculeFooter'
import { EXCEPTIONS, SMILES_SIZE } from './config/config'

const BestMatch = ({
  num,
  note,
  text,
  params,
  noIdText,
  onOpenNote,
  withColors,
  preparedSmiles,
  referencesCount,
  noIdDescription,
  iconButtonsConfig,
  onLinkActionClick,
}) => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  const { categories, services, properties } = useSelector(
    (state) => state.molecule
  )
  const ruIupac = useSelector((state) => state.molecule.ruIupac)

  const isSidebarCollapsed = useSelector(
    (store) => store.settings.isSidebarCollapsed
  )
  const isFilterOpen = useSelector((state) => state.filter.open)

  const { data: categoriesData } = categories
  const { loading: servicesLoading, data: servicesData } = services
  const { sources } = properties

  const [data, setData] = useState(null)

  useEffect(() => {
    return () => {
      dispatch(resetRuIupac())
      dispatch(resetMolecule())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!categoriesData || categoriesData.length === 0) {
      dispatch(loadCategories())
    }

    if (!servicesData || servicesData.length === 0) {
      dispatch(loadServices())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (num && !data && !servicesLoading) {
      dispatch(loadMolecule({ mainId: num, basketId: null, isBestMatch: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [num, dispatch, servicesLoading])

  useEffect(() => {
    properties?.data?.smiles && setData(properties.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties?.data])

  useEffect(() => {
    if (data?.iupac && !ruIupac) dispatch(loadRuIupac({ iupac: data?.iupac }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.iupac, ruIupac])

  return (
    <>
      <BestSmilesViewContainer>
        <MoleculeStructure
          structure={preparedSmiles}
          width={SMILES_SIZE}
          height={SMILES_SIZE}
          fixedCanvasHeight={false}
        />
      </BestSmilesViewContainer>
      <InfoContainer isLeftPanelOpen={!isSidebarCollapsed || isFilterOpen}>
        <MoleculeIdWithButtons
          {...{
            num,
            noIdText,
            iconButtonsConfig,
            noIdDescription,
          }}
          isBestMatch={true}
          withTooltip={false}
        />
        <Data>
          <Content>
            {!!text && <Alias {...{ num }} alias={text} />}
            <Properties>
              {categoriesData
                ?.find((cat) => cat?.category_name === 'structural')
                ?.inner?.map(({ category_id, name, category_title }) => {
                  if (EXCEPTIONS.includes(name)) return
                  const d =
                    i18n.language === 'en' ? data : { ...data, iupac: ruIupac }
                  const value = getDataByCategoryName(name, d)
                  return (
                    <Property
                      key={category_id}
                      {...{
                        name,
                        value,
                        sources,
                        category_id,
                        category_title,
                      }}
                      data={d}
                    />
                  )
                })}
            </Properties>

            <MoleculeFooter
              {...{
                num,
                note,
                params,
                withColors,
                referencesCount,
                onLinkActionClick,
              }}
              isBestMatch={true}
              onOpenNote={onOpenNote}
            />
          </Content>
        </Data>
      </InfoContainer>
    </>
  )
}

export default BestMatch
