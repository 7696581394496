import Icon from 'components/Icon'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FilterSelectPointsWrapper,
  FilterSelectPointsTitle,
  PointsContainer,
  Point,
} from './index.style'
import { HideBtn } from '../../index.style'

const FilterSelectPoints = memo(({ setState, state, config }) => {
  const { values, title } = config
  const { t } = useTranslation()
  const [isFilerOpened, setFilterOpened] = useState(true)
  const handleOpen = () => setFilterOpened(!isFilerOpened)

  const [pointedSelected, setPointsSelected] = useState([])

  const handleClick = (value) => {
    if (pointedSelected.includes(value))
      return setPointsSelected(pointedSelected.filter((el) => el !== value))
    setPointsSelected([...pointedSelected, value])
  }

  return (
    <FilterSelectPointsWrapper opened={isFilerOpened}>
      <FilterSelectPointsTitle onClick={handleOpen}>
        <span>
          {t(title)}
          <Icon iconType="info" />
        </span>

        <HideBtn opened={isFilerOpened}>
          <Icon iconType="arrowDown" size='1rem' />
        </HideBtn>
      </FilterSelectPointsTitle>
      <PointsContainer opened={isFilerOpened}>
        {values.map(({ name, value }, key) => {
          const selected = pointedSelected.includes(value)
          return (
            <Point
              selected={selected}
              key={key}
              onClick={() => handleClick(value)}
            >
              {name}
            </Point>
          )
        })}
      </PointsContainer>
    </FilterSelectPointsWrapper>
  )
})

FilterSelectPoints.displayName = 'FilterSelectPoints'
export default FilterSelectPoints
