import styled from 'styled-components'

import Icon from 'components/Icon'

export const Condition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
`

export const ConditionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;

  width: 100%;
  color: #2d3745;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`

export const ControlButtons = styled.div`
  display: flex;
  gap: 12px;
`

export const TrashIcon = styled(Icon)`
  margin-left: ${({ marginleft }) => marginleft ?? '0'};
`
