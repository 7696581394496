import styled from 'styled-components'
import Icon from 'components/Icon'
import { scrollbarStyles } from 'components/common/scrollbar/scrollbar.style'

export const PlusIcon = styled(Icon)`
  margin-left: ${({ marginleft }) => marginleft ?? '0'};
  min-width: 16px;
`

export const FilterCondition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  border-radius: 0.75rem;
  margin-top: 0.25rem;
`

export const FilterLitConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const ConditionHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
  }
`

export const Condition = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.5rem;
  border-radius: 0.375rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  height: 1.5rem;
  min-width: 7.75rem;
  max-width: 12.75rem;
  width: fit-content;
  user-select: none;
`

export const ConditionWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

export const StyledTrashButton = styled.button`
  color: ${({ theme }) => theme.colors.icons.tertiary};
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  &:hover {
    color: ${({ theme }) => theme.colors.statuses.error};
  }
`
