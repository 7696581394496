import i18n from 'i18n'

export const getBasketsTopToolbarConfig = ({
  len,
  onAdd,
  onCopy,
  onDelete,
  onJoin,
  onAddToMap,
  isLogin,
  onExport,
  onCalculate,
  onRefresh,
  isEmptyBasketSelected,
  onTransfer,
  showCustomerDatasets,
  isPrivateDataset,
}) => [
  {
    onClick: onAdd,
    disabled: !isLogin,
    type: 'create',
    icon: 'add',
  },
  {
    onClick: onCopy,
    disabled: len === 0 || len > 1,
    type: 'copy',
    icon: 'copy',
  },
  {
    onClick: onDelete,
    disabled: len === 0,
    type: 'delete',
    icon: 'deleted',
  },
  {
    onClick: onJoin,
    disabled: len < 2,
    type: 'merge',
    icon: 'merge',
  },
  {
    onClick: onAddToMap,
    disabled: len === 0 || isEmptyBasketSelected,
    type: isEmptyBasketSelected ? 'mmap_empty' : 'mmap',
    icon: 'onMap',
  },
  {
    onClick: onExport,
    disabled: len !== 1 || isEmptyBasketSelected,
    type: isEmptyBasketSelected ? 'export_empty' : 'export',
    icon: 'export',
  },
  {
    onClick: onCalculate,
    disabled: len !== 1 || isEmptyBasketSelected,
    type: isEmptyBasketSelected ? 'calc_empty' : 'calc',
    icon: 'calculate',
  },
  {
    onClick: onTransfer,
    disabled: !len || !showCustomerDatasets || !isPrivateDataset,
    type: 'transfer',
    icon: 'transfer',
  },
  {
    onClick: onRefresh,
    disabled: !isLogin,
    type: 'refresh',
    icon: 'refresh',
  },
  // {
  //   onClick: onUndo,
  //   disabled: lastChange.length === 0,
  //   type: 'undo',
  //   icon: 'close',
  // },
]

export const BASKET_WIDTH = 257
export const BASKET_HEIGHT = 194

export const basketsSortConfig = (hidePrivate) => [
  {
    label: 'baskets.sortTypes.relevance',
    value: 'relevance',
    hidden: hidePrivate,
  },
  {
    label: 'baskets.sortTypes.date',
    value: 'date',
  },
  {
    label: 'baskets.sortTypes.molecules_count',
    value: 'molecules_count',
  },
  {
    label: 'baskets.sortTypes.name',
    value: i18n?.language === 'ru' && hidePrivate ? 'name_ru' : 'name',
  },
]

export const BASKET_TYPES = ['thematic', 'companies', 'private']
export const NON_PUBLIC_BASKET_TYPES = [/*'companies',*/ 'private'] //бэк в рамках 2738 еще не добавлен
//реализовал функционал переноса отдельных молекул в сет компании, можно перенести только весь сет разом
