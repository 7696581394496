import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactDom from 'react-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import {
  Buttons,
  Container,
  Image,
  Smiles,
  SmilesViewBar,
  SmilesViewWrapper,
  StyledSmilesView,
} from './index.style'
import { Label } from 'components/common/text/index.style'
import { copyToClipboard } from 'utils/common/common'
import { addSimpleNotification } from 'store/actions/notifications'
import {
  confirmDeleteHighlights,
  editHighlightSmiles,
  RELIABLE_SCORE,
} from '../config/config'
import Ketcher from 'components/Ketcher'
import '../../../components/common/tooltip/style.css'
import CustomButton from 'components/common/customButton'
import { setSelectedSmiles } from 'store/actions/pdf2smiles'
import CustomTag from 'components/common/customTag'
import { useTheme } from 'styled-components'
import MoleculeStructure from 'components/MoleculeStructure'

const SmilesViewer = ({ image, smiles, score, variant, id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showKetcher, setShowKetcher] = useState(false)
  const [showSmilesTooltip, setShowSmilesTooltip] = useState(false)
  const theme = useTheme()
  const smilesRef = useRef(null)

  useEffect(() => {
    if (smilesRef?.current?.clientWidth < smilesRef?.current?.scrollWidth)
      setShowSmilesTooltip(true)
    else setShowSmilesTooltip(false)
  }, [smilesRef])

  const copySmiles = () => {
    copyToClipboard(smiles)

    dispatch(addSimpleNotification('notification.smiles_copied'))
  }

  const buttonsConfig = [
    {
      icon: 'molecularEditor',
      text: 'pdf2smiles.viewer.edit',
      onClick: () => setShowKetcher(true),
    },
    {
      icon: 'add',
      text: 'pdf2smiles.viewer.to_dataset',
      onClick: () => {
        dispatch(setSelectedSmiles(smiles))
      },
    },
    {
      icon: 'deleted',
      text: 'pdf2smiles.viewer.delete',
      onClick: () => confirmDeleteHighlights([id]),
    },
  ]

  return (
    <Container variant={variant}>
      <SmilesViewWrapper>
        <SmilesView image={image} score={score} variant={variant} />
        <SmilesView smiles={smiles} variant={variant} />
      </SmilesViewWrapper>
      <Smiles>
        <CustomButton
          type="icon"
          onClick={copySmiles}
          defaultColor={theme.colors.icons.tertiary}
        >
          <Icon iconType="copy" size="1rem" />
        </CustomButton>
        <Label
          name="smiles"
          ref={smilesRef}
          data-tooltip-id={`${id}-${variant}`}
        >
          {smiles}
        </Label>
        {showSmilesTooltip && (
          <ReactTooltip
            id={`${id}-${variant}`}
            className="c-tooltip c-tooltip-base c-tooltip-pdf-smiles"
            classNameArrow="c-tooltip-arrow"
          >
            {smiles}
          </ReactTooltip>
        )}
      </Smiles>
      <Buttons>
        {buttonsConfig?.map(({ icon, text, onClick }, i) => (
          <CustomButton
            key={i}
            type={'text'}
            gap={'0.25rem'}
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
          >
            <Icon iconType={icon} size={'1rem'} />
            {t(text)}
          </CustomButton>
        ))}
      </Buttons>
      {showKetcher &&
        ReactDom.createPortal(
          <Ketcher
            handleData={(sm) => editHighlightSmiles(id, sm)}
            closeKetcher={() => setShowKetcher(false)}
            smiles={smiles}
          />,
          document.body
        )}
    </Container>
  )
}

export default SmilesViewer

const SmilesView = ({ image, smiles, score, variant }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const roundedScore = Math.round(score * 1000) / 1000

  return (
    <StyledSmilesView variant={variant}>
      <SmilesViewBar>
        {score && (
          <CustomTag
            type={'grey'}
            height={'1.5rem'}
            defaultColor={
              score < RELIABLE_SCORE
                ? theme.colors.statuses.error
                : theme.colors.statuses.success
            }
          >
            {t('pdf2smiles.viewer.score', {
              score: roundedScore,
            })}
          </CustomTag>
        )}
      </SmilesViewBar>
      {image ? (
        <Image
          src={image}
          alt={t('pdf2smiles.viewer.smiles_in_pdf')}
          variant={variant}
        />
      ) : (
        <MoleculeStructure structure={smiles} width={144} height={144} />
      )}
      <Icon
        iconType={image ? 'borderBox' : 'magic'}
        className={'smiles-type-icon'}
      />
    </StyledSmilesView>
  )
}
