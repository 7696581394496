import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`

export const CasWrapper = styled.span`
  display: flex;
  gap: 0.25rem;
  & > svg:not(.loader-icon) {
    color: ${({ theme }) => theme.colors.icons.tertiary};
    transition: 0.2s;
    cursor: pointer;
    vertical-align: middle;
    min-width: 1rem;

    &:hover {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }
  & span:last-child {
    padding-right: 0.25rem;
  }
`
export const LabelWithTooltip = styled.div`
  display: flex;
  gap: 0.25rem;
  cursor: ${({ isGeneratedMol }) => (isGeneratedMol ? 'pointer' : 'default')};
  & > svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
  }
  &:hover {
    & > svg {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }
`
