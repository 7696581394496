import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import CustomButton from 'components/common/customButton'

import { Container } from './index.style'

const AdminView = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const pages = [
    { name: 'billing', page: '/admin/billing' },
    { name: 'plugins', page: '/admin/plugins' },
    { name: 'molecule params viewer', page: '/admin/viewer' },
    { name: 'feedback viewer', page: '/admin/feedback' },
    { name: 'userLog', page: '/admin/log' },
    { name: 'users', page: '/admin/users' },
    { name: 'ban list', page: '/admin/banlist' },
    { name: 'invite', page: '/admin/invite' },
  ]

  return (
    <Container>
      <h3>Select category: </h3>
      {pages.map(({ name, page }) => {
        return (
          <CustomButton
            key={name}
            onClick={() => dispatch(push(page))}
            type="secondary"
            style={{ marginBottom: '0.75rem' }}
            width="17.5rem"
          >
            {t(`admin.${name}`)}
          </CustomButton>
        )
      })}
    </Container>
  )
}

export default AdminView
