import { useTheme } from 'styled-components'

import { TextMedium, TitleTertiary } from 'components/common/text/index.style'

import { Block } from './index.style'

const TitleWithDescription = ({ title, description }) => {
  const theme = useTheme()
  return (
    <Block>
      <TitleTertiary>{title}</TitleTertiary>
      <TextMedium
        color={theme.colors.text.secondary}
        fontWeight={theme.fontWeight.light}
      >
        {description}
      </TextMedium>
    </Block>
  )
}
export default TitleWithDescription
