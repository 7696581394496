import styled from 'styled-components'

import { TextMedium } from 'components/common/text/index.style'

export const DataElement = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
export const Block = styled.div`
  display: flex;
  gap: ${({ withValue }) => (withValue ? '0.5rem' : '0.25rem')};
  width: fit-content;
  padding: 0.125rem 0;
`

export const DataLabel = styled(TextMedium)`
  text-transform: capitalize;
  align-self: flex-start;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.text.white};
`

export const Point = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`
