import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { TextMedium, TitleTertiary } from 'components/common/text/index.style'

import { Container, TitleWithDescription } from '../../index.style'

const ActivationError = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Container>
      <TitleWithDescription>
        <TitleTertiary>
          {t('registration.activation_failed_title')}
        </TitleTertiary>
        <TextMedium
          color={theme.colors.text.secondary}
          fontWeight={theme.fontWeight.light}
        >
          {t('registration.activation_failed_description')}
        </TextMedium>
      </TitleWithDescription>
    </Container>
  )
}

export default ActivationError
