import {
  CardsHeader,
  HeaderItem,
  UsersSkeletonWrapper,
} from '../customers/index.style'
import { CompanyCardsWrapper, CompanyCards } from './index.style'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Caption } from 'components/common/text/index.style'
import UserCard from '../userCard'
import { connect } from 'react-redux'
import SkeletonComponent from 'components/common/skeleton'
import { ADMIN_HEADER_BLOCKS_TEXT, COMPANY_USERS_BLOCK_WIDTH } from '../config'

const CompanyUsers = ({
  currentCustomerUsers,
  role,
  pagesAmount,
  currentCustomerLoading,
}) => {
  const { items, loading, error } = currentCustomerUsers
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <CompanyCardsWrapper marginBottom={pagesAmount > 1 ? '4.25rem' : '0.75rem'}>
      {(loading || currentCustomerLoading) && (
        <UsersSkeletonWrapper>
          {Array.from({ length: 4 }).map((_, i) => (
            <SkeletonComponent
              height="5.5rem"
              width="100%"
              variant="dark"
              key={`skeleton-index-${i}`}
            />
          ))}
        </UsersSkeletonWrapper>
      )}
      {!!items.length && !loading && !currentCustomerLoading && !error && (
        <>
          <CardsHeader>
            {COMPANY_USERS_BLOCK_WIDTH.map(({ value, justify }, i) => (
              <HeaderItem width={value} key={uuidv4()} justifyContent={justify}>
                <Caption color={theme.colors.text.secondary}>
                  {t(ADMIN_HEADER_BLOCKS_TEXT[i])}
                </Caption>
              </HeaderItem>
            ))}
          </CardsHeader>
          <CompanyCards>
            {items.map((card) => (
              <UserCard
                key={uuidv4()}
                card={card}
                isCompany={true}
                role={role}
              />
            ))}
          </CompanyCards>
        </>
      )}
    </CompanyCardsWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    currentCustomerUsers: state.adminPanel.currentCustomerUsers,
    currentCustomerLoading: state.adminPanel.currentCustomer.loading,
    pagesAmount: state.adminPanel.currentCustomerUsers.pagination.pagesAmount,
  }
}
export default connect(mapStateToProps)(CompanyUsers)
