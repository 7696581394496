import {
  GET_HISTORY_INIT,
  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
  REPEAT_SEARCH,
  RESET_PAST_RESULT,
  SET_HISTORY_OPEN,
  SET_HISTORY_FILTER,
} from 'store/constants/searchHistory'

export const initialState = {
  loading: false,
  error: '',
  history: [],
  pastResult: null,
  showHistory: false,
  historyFilter: 'all',
}

const searchHistoryReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_HISTORY_INIT:
      return {
        ...state,
        loading: true,
      }

    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: payload.data,
      }

    case GET_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.data,
      }

    case REPEAT_SEARCH:
      return {
        ...state,
        loading: false,
        pastResult: payload.showPastResult ? payload.data : null,
      }

    case RESET_PAST_RESULT:
      return {
        ...state,
        loading: false,
        error: false,
        pastResult: null,
      }

    case SET_HISTORY_OPEN:
      return {
        ...state,
        showHistory: payload.isOpen,
      }

    case SET_HISTORY_FILTER:
      return {
        ...state,
        historyFilter: payload.filterType,
      }

    default:
      return state
  }
}

export default searchHistoryReducer
