import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 12px;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CategoryTitle = styled.div`
  display: grid;
  grid-template-columns: 20px 20px 15px 35px 1fr;
  font-size: 20px;
  font-weight: 800;
  align-items: center;
  column-gap: 8px;
`

export const CategoryBlock = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
`

export const InnerCategoriesBlock = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`

export const InnerCategoryTitle = styled(CategoryTitle)`
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
`
export const DialogContent = styled.div`
  gap: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const DialogActions = styled.div`
  gap: 0.5rem;
  margin-left: auto;
  display: flex;
`
