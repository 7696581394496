import styled from 'styled-components'

export const UserInfo = styled.div`
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`

export const Questionnaire = styled.div`
  width: 100%;
  gap: 0.5rem;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  max-height: 22.375rem;
`
export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const QuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  border-radius: 0.75rem;
  width: 100%;
  padding: 0.75rem;
`

export const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`
