import { useEffect, useState } from 'react'

const useResize = (ref) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref?.current?.offsetWidth)
      setHeight(ref?.current?.offsetHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWidth(ref?.current?.offsetWidth ?? 0)
      setHeight(ref?.current?.offsetHeight ?? 0)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  return { width, height }
}

export { useResize }
