import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import { TextMedium, TitleTertiary } from 'components/common/text/index.style'

import { Content, InfoBlock } from './index.style'

const UserAccountDialog = ({ onClose, type, title, text, btnText }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <CustomDialog
      width="25.5rem"
      showCloseIcon={false}
      onClose={onClose}
      outboundClickEnabled={false}
      withBackdrop={false}
    >
      <Content>
        <Icon
          iconType={type === 'success' ? 'successLight' : 'warning'}
          size="4rem"
        />
        <InfoBlock>
          <TitleTertiary fontWeight={theme.fontWeight.semibold}>
            {t(title)}
          </TitleTertiary>
          {text && (
            <TextMedium
              color={theme.colors.text.secondary}
              textAlign={'center'}
              fontWeight={theme.fontWeight.light}
            >
              {t(text)}
            </TextMedium>
          )}
        </InfoBlock>

        {btnText && (
          <CustomButton width={'100%'} type="secondary" onClick={onClose}>
            {t(btnText)}
          </CustomButton>
        )}
      </Content>
    </CustomDialog>
  )
}

export default UserAccountDialog
