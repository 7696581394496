import styled, { css } from 'styled-components'

const BluredBlock = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  backdrop-filter: blur(6px);
  background: ${({ theme }) => theme.colors.backgrounds.scroll};
  cursor: pointer;
  transition: all 0.15s;
  z-index: 15;

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`

export const TopBluredBlock = styled(BluredBlock)`
  ${({ extraStyles }) =>
    extraStyles &&
    css`
      ${extraStyles}
    `}
`
export const BottomBluredBlock = styled(BluredBlock)`
  ${({ extraStyles }) =>
    extraStyles &&
    css`
      ${extraStyles}
    `}
`
