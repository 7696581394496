export const SPECTRA_MS_INIT = 'SPECTRA_MS_INIT'
export const SPECTRA_MS_SUCCESS = 'SPECTRA_MS_SUCCESS'
export const SPECTRA_MS_FAIL = 'SPECTRA_MS_FAIL'

export const SPECTRA_NMR_INIT = 'SPECTRA_NMR_INIT'
export const SPECTRA_NMR_SUCCESS = 'SPECTRA_NMR_SUCCESS'
export const SPECTRA_NMR_FAIL = 'SPECTRA_NMR_FAIL'

export const SPECTRA_INFRARED_INIT = 'SPECTRA_INFRARED_INIT'
export const SPECTRA_INFRARED_SUCCESS = 'SPECTRA_INFRARED_SUCCESS'
export const SPECTRA_INFRARED_FAIL = 'SPECTRA_INFRARED_FAIL'

export const SPECTRA_RESET = 'SPECTRA_RESET'
export const SPECTRA_CLEAR_DATA = 'SPECTRA_CLEAR_DATA'

export const SET_SPECTRA_TYPE = 'SET_SPECTRA_TYPE'
export const SET_ION_MODE = 'SET_ION_MODE'
export const SET_ADDUCT_TYPE = 'SET_ADDUCT_TYPE'
export const SET_ROUNDING_RI = 'SET_ROUNDING_RI'
export const SET_ROUNDING_MZ = 'SET_ROUNDING_MZ'
export const SET_NMR_METHOD = 'SET_NMR_METHOD'

export const SET_CALCULATED_MS_TYPE = 'SET_CALCULATED_MS_TYPE'
export const SET_INFRARED_SHOOTING_METHOD = 'SET_INFRARED_SHOOTING_METHOD'
export const SET_NMR_CHART_ACTIVE_ATOM = 'SET_NMR_CHART_ACTIVE_ATOM'
export const SET_NMR_ADDINTION_ATOM = 'SET_NMR_ADDINTION_ATOM'
