import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'

import { Btn } from './index.style'

const NoteBtn = memo(({ num, note, onOpenNote }) => {
  const { t } = useTranslation()

  if (!note) return <></>

  return (
    <>
      <Btn
        type="secondary"
        gap="0.25rem"
        data-tooltip-id={`${num}-note`}
        onClick={(e) => {
          e.stopPropagation()
          onOpenNote()
        }}
      >
        {t('molecule_viewer.open_note')}
        <Icon iconType="note" size="1rem" />
      </Btn>

      <ReactTooltip
        id={`${num}-note`}
        className="c-tooltip c-tooltip-base c-tooltip-molecule-card-note"
        classNameArrow="c-tooltip-arrow"
        place={'bottom-start'}
        offset={14}
      >
        {note}
      </ReactTooltip>
    </>
  )
})

NoteBtn.displayName = 'NoteBtn'
export default NoteBtn
