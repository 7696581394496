import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 0.0625rem;
  height: 2.25rem;
  overflow: hidden;
  border-radius: 0.75rem;
  background-color: transparent;
`

export const Element = styled.a`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 200ms;
  transition: background-color 0.5s ease;
  background-color: ${({ theme }) => theme.colors.buttons.primary};
  position: relative;

  & > svg {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.icons.tertiary : theme.colors.icons.primary};
  }
  &:hover {
    & > svg {
      color: ${({ disabled, theme }) =>
        disabled
          ? theme.colors.icons.tertiary
          : theme.colors.icons.accentPrimary};
    }
  }
`
