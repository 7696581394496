import { default as styled } from 'styled-components'

export const Header = styled.div`
  // margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @media print {
    width: max-content;
  }
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: ${({ isFullMode }) => !isFullMode && 'column'};
`

export const ButtonContent = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`
export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > h2 {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }
`
