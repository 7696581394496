import { useSelector } from 'react-redux'
import { useState, useMemo, useEffect } from 'react'

import Icon from 'components/Icon'

import './index.css'
import { needInitSwitchersIds, selectorPositionConfig } from './config'
import { Switch, StyledLabel, TripleToggleContainer } from './index.style'

const TripleToggleSwitch = ({
  options,
  onChange,
  disabled,
  className,
  switcherID,
  position = 'absolute',
}) => {
  const literatureListView = useSelector((store) => store.literature.listView)
  const structuresListView = useSelector((store) => store.settings.viewSize)
  const litSearchDirection = useSelector(
    (store) => store.search.sorting.direction
  )
  const basketsDirection = useSelector(
    (store) => store.basket.sorting.direction
  )

  const feedbackSortDirection = useSelector(
    (store) => store.feedback.filter.sort.direction
  )

  const initSwitchPosition = useMemo(() => {
    if (switcherID === 'literature' && literatureListView) {
      return (
        selectorPositionConfig?.[switcherID]?.[literatureListView] ?? 'left'
      )
    }
    if (switcherID === 'structures' && structuresListView) {
      return (
        selectorPositionConfig?.[switcherID]?.[structuresListView] ?? 'left'
      )
    }
    if (switcherID === 'litSearchDirection' && litSearchDirection) {
      return (
        selectorPositionConfig?.sortDirection?.[litSearchDirection] ?? 'left'
      )
    }
    if (switcherID === 'basketsDirection' && basketsDirection) {
      return selectorPositionConfig?.sortDirection?.[basketsDirection] ?? 'left'
    }
    if (switcherID === 'feedbackSortDirection' && feedbackSortDirection) {
      return (
        selectorPositionConfig?.sortDirection?.[feedbackSortDirection] ?? 'left'
      )
    }
    return 'left'
  }, [
    switcherID,
    literatureListView,
    structuresListView,
    litSearchDirection,
    basketsDirection,
    feedbackSortDirection,
  ])

  const [switchPosition, setSwitchPosition] = useState(initSwitchPosition)
  const [animation, setAnimation] = useState(null)

  const optionsSize = Object.keys(options)?.length
  const isDoubleSwitcher = optionsSize === 2
  const isTripleSwitcher = optionsSize === 3

  const getSwitchAnimation = (value) => {
    if (options?.[value]?.disabled || disabled) return
    onChange && onChange(value)
    let animation = null
    if (value === 'center' && switchPosition === 'left') {
      animation = 'leftToCenter'
    } else if (value === 'right' && switchPosition === 'center') {
      animation = 'centerToRight'
    } else if (value === 'center' && switchPosition === 'right') {
      animation = 'rightToCenter'
    } else if (value === 'left' && switchPosition === 'center') {
      animation = 'centerToLeft'
    } else if (value === 'right' && switchPosition === 'left') {
      animation = 'leftToRight'
    } else if (value === 'left' && switchPosition === 'right') {
      animation = 'rightToLeft'
    }
    setSwitchPosition(value)
    setAnimation(animation)
  }

  useEffect(() => {
    if (
      needInitSwitchersIds.includes(switcherID) &&
      switchPosition !== initSwitchPosition
    ) {
      setSwitchPosition(initSwitchPosition)
      setAnimation('centerToLeft')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSwitchPosition])

  return (
    <TripleToggleContainer
      className={className}
      isDoubleSwitcher={isDoubleSwitcher}
      position={position}
      disabled={disabled}
    >
      <Switch animation={animation} switchPosition={switchPosition}></Switch>
      <input
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id={`${switcherID}-left`}
        type="radio"
        value="left"
      />
      <StyledLabel
        left="0.125rem"
        position="left"
        switchPosition={switchPosition}
        htmlFor={`${switcherID}-left`}
        disabled={options?.left?.disabled || disabled}
      >
        {options?.left?.icon && (
          <Icon iconType={options?.left?.icon} size="1rem" />
        )}
      </StyledLabel>

      <input
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id={`${switcherID}-center`}
        type="radio"
        value="center"
        checked={switchPosition === 'center'}
      />
      <StyledLabel
        left="2.125rem"
        position="center"
        switchPosition={switchPosition}
        htmlFor={`${switcherID}-center`}
        disabled={options?.center?.disabled || disabled}
      >
        {options?.center?.icon && (
          <Icon iconType={options?.center?.icon} size="1rem" />
        )}
      </StyledLabel>

      {isTripleSwitcher && (
        <>
          <input
            onChange={(e) => getSwitchAnimation(e.target.value)}
            name="map-switch"
            id={`${switcherID}-right`}
            type="radio"
            value="right"
          />
          <StyledLabel
            left="4.125rem"
            position="right"
            switchPosition={switchPosition}
            htmlFor={`${switcherID}-right`}
            disabled={options?.right?.disabled || disabled}
          >
            {options?.right?.icon && (
              <Icon iconType={options?.right?.icon} size="1rem" />
            )}
          </StyledLabel>{' '}
        </>
      )}
    </TripleToggleContainer>
  )
}

export default TripleToggleSwitch
