import { TitleSecondary } from 'components/common/text/index.style'
import { AdminHeaderWrapper, AdminTabsBlock } from './index.style'
import { useTranslation } from 'react-i18next'
import { ADMIN_TABS } from '../config'
import CustomTab from 'components/common/customTab'
import { connect, useDispatch } from 'react-redux'
import { setAdminPanelSection } from 'store/actions/adminPanel'

const AdminHeader = ({ section }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <AdminHeaderWrapper>
      <TitleSecondary>{t('admin.admin_panel')}</TitleSecondary>
      <AdminTabsBlock>
        {ADMIN_TABS.map((tab) => (
          <CustomTab
            isActive={section === tab}
            key={`admin-tab-${tab}`}
            id={`admin-tab-${tab}`}
            onClick={() => dispatch(setAdminPanelSection(tab))}
          >
            {t(`admin.tabs.${tab}`)}
          </CustomTab>
        ))}
      </AdminTabsBlock>
    </AdminHeaderWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    section: state.adminPanel.section,
  }
}

export default connect(mapStateToProps)(AdminHeader)
