import styled from 'styled-components'

export const Container = styled.div`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};
  overflow: hidden;

  & svg rect:first-of-type {
    fill: transparent !important;
  }
`
