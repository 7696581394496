import styled from 'styled-components'

export const FieldsBlock = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const NameWithSurname = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
`
export const InfoBlockWithProgress = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
