import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FilterRangeWrapper,
  FilterRangeTitle,
  Range,
  RangeSlider,
  RangeInputWrapper,
  RangeInput,
  RangeValues,
  RangeValuesInput,
  RangeSelected,
  RangeContainer,
} from './index.style'
import { HideBtn } from '../../index.style'
import Icon from 'components/Icon'
import { checkIfNumbers } from 'components/Filter/utils/utils'
import { useSelector } from 'react-redux'

const FilterRange = memo(({ setState, state, config }) => {
  const [isChanged, setIsChanged] = useState(false)
  const { step, title, values, numberOnly, key } = config
  const isSimilarity = config.key === 'similarity'
  const { t } = useTranslation()
  const { [key]: inputValues } = state
  const searchType = useSelector((store) => store.search.searchType)

  const [isFilterOpened, setFilterOpened] = useState(true)

  const isReactionsSearch = searchType === 'reaction'
  const isRangeDisabled =
    (state.match_type !== 'similar structures' && !isReactionsSearch) ||
    state.disabled

  const handleChange = (e, id, isRangeChange) => {
    const value = e.target.value

    const zeroIdCondition =
      id === 0 && (+value > +inputValues[1] || +value > 100 || +value < 0)
    const firstIdCondition =
      id === 1 && (+value < inputValues[0] || +value > 100 || +value < 0)

    if (
      (numberOnly && !checkIfNumbers(value)) ||
      (isRangeChange &&
        ((id === 1 && +value < +inputValues[0]) ||
          (id === 0 && +value > +inputValues[1]))) ||
      zeroIdCondition ||
      firstIdCondition
    )
      return
    setState({
      ...state,
      [config.key]: {
        ...(isSimilarity ? { ...state.similarity } : { ...state.yield }),
        [id]: value,
      },
    })
    if (!isChanged) setIsChanged(true)
  }

  const handleOpen = () => setFilterOpened(!isFilterOpened)

  const startValue = useMemo(
    () => (inputValues[0].length ? inputValues[0] : '0'),
    [inputValues]
  )
  const endValue = useMemo(
    () => (inputValues[1].length ? inputValues[1] : '100'),
    [inputValues]
  )

  return (
    <FilterRangeWrapper opened={isFilterOpened}>
      <FilterRangeTitle onClick={handleOpen}>
        {t(title)}
        <HideBtn opened={isFilterOpened}>
          <Icon iconType="arrowDown" size="1rem" />
        </HideBtn>
      </FilterRangeTitle>
      <RangeContainer opened={isFilterOpened}>
        <RangeValues>
          <RangeValuesInput
            value={inputValues[0]}
            placeholder={`${t(values[0].placeholder)} ${values[0].default}`}
            onChange={(e) => handleChange(e, 0)}
            disabled={isRangeDisabled}
            isChanged={isChanged}
          />
          <span>—</span>
          <RangeValuesInput
            value={inputValues[1]}
            placeholder={`${t(values[1].placeholder)} ${values[1].default}`}
            onChange={(e) => handleChange(e, 1)}
            disabled={isRangeDisabled}
            isChanged={isChanged}
          />
        </RangeValues>
        <Range className="range">
          <RangeSlider>
            <RangeSelected
              style={{
                left: `${(startValue / 100) * 100}%`,
                right: `${100 - (endValue / 100) * 100}%`,
              }}
              disabled={isRangeDisabled}
            />
          </RangeSlider>
          <RangeInputWrapper>
            {values.map(({ default: defaultValue }, idx) => {
              return (
                <RangeInput
                  type="range"
                  name={idx}
                  key={idx}
                  min={0}
                  max={100}
                  value={
                    !inputValues[idx].length ? defaultValue : inputValues[idx]
                  }
                  step={step}
                  onChange={(e) => handleChange(e, idx, true)}
                  disabled={isRangeDisabled}
                />
              )
            })}
          </RangeInputWrapper>
        </Range>
      </RangeContainer>
    </FilterRangeWrapper>
  )
})

FilterRange.displayName = 'FilterRange'
export default FilterRange
