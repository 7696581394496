const initRDKit = (() => {
  let rdkitLoadingPromise

  return () => {
    /**
     * Utility function ensuring there's only one call made to load RDKit
     * It returns a promise with the resolved RDKit API as value on success,
     * and a rejected promise with the error on failure.
     *
     * The RDKit API is also attached to the global object on successful load.
     */
    if (!rdkitLoadingPromise) {
      rdkitLoadingPromise = new Promise((resolve, reject) => {
        const RDKitScript = document.createElement('script')
        RDKitScript.src = `${process.env.PUBLIC_URL}/RDKit_minimal.js`
        RDKitScript.async = true

        RDKitScript.onload = async () => {
          window
            .initRDKitModule()
            .then((RDKit) => {
              window.RDKit = RDKit
              resolve(RDKit)
            })
            .catch((e) => {
              reject()
            })
          // try {
          //   // Wait for RDKit to initialize and load the WebAssembly module
          //   const RDKitModule = await window.initRDKitModule()
          //   window.RDKit = RDKitModule // Store globally for access
          //   resolve(RDKitModule)
          // } catch (error) {
          //   rdkitLoadingPromise = null // Reset promise in case of failure
          //   reject(error) // Let the caller handle the error
          // }
        }

        RDKitScript.onerror = () => {
          rdkitLoadingPromise = null // Reset promise in case of failure
          reject(new Error('Failed to load RDKit script'))
        }

        // Append the script to the document body
        document.body.appendChild(RDKitScript)

        /** OLD LOGIC, WORKING WITH <script> - START */
        /** uncomment  script (search for: <!-- <script src="https://unpkg.com/@rdkit/rdkit/dist/RDKit_minimal.js"></script> -->)
 before using */
        // window
        //   .initRDKitModule()
        //   .then((RDKit) => {
        //     window.RDKit = RDKit
        //     resolve(RDKit)
        //   })
        //   .catch((e) => {
        //     reject()
        //   })
        /** OLD LOGIC, WORKING WITH <script> - END */
      })
    }

    return rdkitLoadingPromise
  }
})()

export default initRDKit
