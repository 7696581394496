import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HistoryPreviewContainer,
  IconWrapper,
  HistoryPreviewTitle,
  HistoryPreviewText,
  HistoryPreviewBtn,
  HistoryTextWrapper,
} from './index.style'
import Icon from '../../../components/Icon/index'
import CustomButton from 'components/common/customButton'
import { useDispatch, useSelector } from 'react-redux'
import './index.css'
import { openSearchHistory } from 'store/actions/searchHistory'
import { setOpenFilter } from 'store/actions/filter'
import { settingsSidebarToggle } from 'store/actions/settings'

export const HistoryPreview = memo(() => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const filterOpen = useSelector((store) => store.filter.open)
  const history = useSelector((store) => store.searchHistory.history)
  const isSidebarCollapsed = useSelector(
    (store) => store.settings.isSidebarCollapsed
  )

  return (
    <HistoryPreviewContainer>
      <IconWrapper>
        <Icon iconType="folderClosed" />
      </IconWrapper>
      <HistoryTextWrapper>
        <HistoryPreviewTitle>
          {history?.length
            ? t('search.search_history.history')
            : t('search.search_history.history_empty_title')}
        </HistoryPreviewTitle>
        <HistoryPreviewText>
          {history?.length
            ? t('search.search_history.history_preview')
            : t('search.search_history.history_empty')}
        </HistoryPreviewText>
      </HistoryTextWrapper>
      {history?.length ? (
        <CustomButton
          type="primary"
          size="big"
          className="view-history-btn"
          onClick={() => {
            filterOpen && dispatch(setOpenFilter(false))
            isSidebarCollapsed && dispatch(settingsSidebarToggle())
            dispatch(openSearchHistory(true))
          }}
        >
          {t('search.search_history.view')}
        </CustomButton>
      ) : (
        <></>
      )}
    </HistoryPreviewContainer>
  )
})
HistoryPreview.displayName = 'HistoryPreview'

export default HistoryPreview
