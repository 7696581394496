import React, { Fragment } from 'react'

import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import '../../common/tooltip/style.css'
import { Element } from './index.style'
import Icon from 'components/Icon'

const BasketsTopIcon = ({ type, disabled, onClick, icon }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Element
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        data-tooltip-id={type}
        id={`toolbar-dataset-${type}`}
      >
        <Icon iconType={icon} size="1.25rem" />
      </Element>
      <ReactTooltip
        id={type}
        className="c-tooltip c-tooltip-base c-tooltip-baskets"
        classNameArrow="c-tooltip-arrow"
        place={'bottom'}
        offset={18}
      >
        <div>{t(`baskets.topblock.${type}`)}</div>
      </ReactTooltip>
    </Fragment>
  )
}

export default BasketsTopIcon
