import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { confirm } from 'store/actions/confirm'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'

import {
  DialogActions,
  ServicesListBlock,
  DialogContentWrapper,
} from './index.style'

const BillingServicesList = ({
  list,
  handleAdd,
  handleDelete,
  handleUpdate,
}) => {
  const [dialog, setDialog] = useState(false)
  const [opened, setOpened] = useState(-1)
  const [name, setName] = useState('')
  const [service, setService] = useState('')
  const [price, setPrice] = useState(0)
  const [description, setDescription] = useState('')

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const handleOpenAddModal = () => {
    setDialog(true)
  }

  const handleUpdateService = () => {
    handleUpdate({
      id: opened,
      name,
      service_name: service,
      price,
      description,
    })
    handleCloseModal()
  }
  const handleAddService = () => {
    handleAdd({ name, service_name: service, price, description })
    handleCloseModal()
  }

  const handleOpenEditModal = (id) => () => {
    const data = list.find((el) => el.id === id)

    setOpened(id)
    setName(data.name)
    setService(data.service_name)
    setDescription(data.description)
    setPrice(data.price)
    setDialog(true)
  }

  const handleCloseModal = () => {
    setDialog(false)
    setOpened(-1)
    setName('')
    setService('')
    setPrice(0)
    setDescription('')
  }

  const handleDeleteElement = (id) => async () => {
    dispatch(
      confirm(t('confirmation.deleteThatService'), () => {
        handleDelete({ id })
      })
    )
  }

  const handleClickModal = () => {
    if (opened === -1) {
      handleAddService()
    } else {
      handleUpdateService()
    }
  }

  return (
    <>
      <ServicesListBlock>
        <div>{t('billing.serviceName')}</div>
        <div>{t('billing.shortName')}</div>
        <div>{t('billing.desc')}</div>
        <div>{t('billing.price')}</div>
        <div></div>
        <div></div>

        {list.map(({ id, service_name, price, name, description }) => {
          return (
            <React.Fragment key={id}>
              <div>{service_name}</div>
              <div>{name}</div>
              <div>{description}</div>
              <div>{price}</div>
              <div>
                <CustomButton type="text" onClick={handleOpenEditModal(id)}>
                  <Icon iconType="edit" />
                </CustomButton>
              </div>
              <div>
                <CustomButton type="text" onClick={handleDeleteElement(id)}>
                  <Icon iconType="deleted" />
                </CustomButton>
              </div>
            </React.Fragment>
          )
        })}
      </ServicesListBlock>
      <CustomButton type="accent" onClick={handleOpenAddModal} width="100%">
        {t('billing.addNewService')}
      </CustomButton>

      {dialog && (
        <CustomDialog onClose={handleCloseModal} width="43.75rem">
          <>
            <DialogContentWrapper>
              <CustomInput
                placeholder={t('billing.serviceName')}
                label={t('billing.serviceName')}
                value={service}
                onChange={(value) => setService(value)}
                withClearButton
              />
              <CustomInput
                placeholder={t('billing.shortName')}
                label={t('billing.shortName')}
                value={name}
                onChange={(value) => setName(value)}
                withClearButton
              />
              <CustomInput
                placeholder={t('billing.desc')}
                label={t('billing.desc')}
                value={description}
                onChange={(value) => setDescription(value)}
                withClearButton
              />
              <CustomInput
                placeholder={t('billing.price')}
                label={t('billing.price')}
                value={price}
                type="number"
                onChange={(value) => setPrice(+value)}
              />
            </DialogContentWrapper>
          </>
          <DialogActions>
            <CustomButton onClick={handleCloseModal} type="secondary">
              {t('billing.close')}
            </CustomButton>
            <CustomButton
              onClick={handleClickModal}
              type="accent"
              disabled={!name || !description || !price || !service}
            >
              {opened !== -1 ? 'Save' : 'Add'}
            </CustomButton>
          </DialogActions>
        </CustomDialog>
      )}
    </>
  )
}

export default BillingServicesList
