import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background-color: #eef2f2;
  border-radius: 12px;
  padding: 1.5rem;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 0.75rem 0.5rem;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Value = styled.div`
  color: #58d5d3;
  font-size: 2rem;
  line-height: 40px;
`

export const Name = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
`

export const Delimeter = styled.div`
  color: #58d5d3;
  font-size: 2rem;
  line-height: 40px;
`
