import { put, takeEvery } from 'redux-saga/effects'
import { storeV2 } from 'services/rest'
import {
  GET_SURVEYS_FAIL,
  GET_SURVEYS_INIT,
  GET_SURVEYS_SUCCESS,
  GET_USER_ANSWERS_FAIL,
  GET_USER_ANSWERS_INIT,
  GET_USER_ANSWERS_SUCCESS,
} from 'store/constants/survey'

function* getSurveys({ onRegistration = true, limit = 1, offset = 0 }) {
  try {
    const { data } = yield storeV2('/survey/get', {
      params: {
        filter: {
          on_registration: onRegistration,
        },
        limit,
        offset,
      },
    })

    yield put({
      type: GET_SURVEYS_SUCCESS,
      items: data.items,
    })
  } catch (error) {
    yield put({
      type: GET_SURVEYS_FAIL,
      data: error?.message,
    })
  }
}

function* getUserAnswers({ user_id, survey_id }) {
  try {
    const { data } = yield storeV2('/survey/answer/get', {
      params: {
        filter_: {
          user_id,
          survey_id,
        },
      },
    })

    yield put({
      type: GET_USER_ANSWERS_SUCCESS,
      items: data.items[0]?.answers || [],
    })
  } catch (error) {
    yield put({
      type: GET_USER_ANSWERS_FAIL,
      data: error?.message,
    })
  }
}

export function* getSurveysWatcher() {
  yield takeEvery(GET_SURVEYS_INIT, getSurveys)
}

export function* getUserAnswersWatcher() {
  yield takeEvery(GET_USER_ANSWERS_INIT, getUserAnswers)
}

const watchers = [getSurveysWatcher(), getUserAnswersWatcher()]

export default watchers
