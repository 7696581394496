import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { setHistoryFilter } from 'store/actions/searchHistory'
import { TextMedium } from 'components/common/text/index.style'

import { tabs } from './utils/utils'
import { Row } from './index.style'
import CustomTab from 'components/common/customTab'

const FilterSection = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentFilter =
    useSelector((store) => store.searchHistory.historyFilter) || {}
  const history = useSelector((store) => store.searchHistory?.history) || []

  return (
    <Row>
      {tabs(history).map(({ title, disabled }, i) => {
        const active = title?.includes(currentFilter)
        return (
          <CustomTab
            key={i}
            type="white"
            onClick={() => {
              if (disabled) return
              const splitTagString = title?.split('.')
              const tagToUse = splitTagString[splitTagString?.length - 1]
              dispatch(setHistoryFilter(tagToUse))
            }}
            isActive={active}
            disabled={disabled}
          >
            <TextMedium color="inherit" fontWeight={theme.fontWeight.medium}>
              {t(title)}
            </TextMedium>
          </CustomTab>
        )
      })}
    </Row>
  )
}

export default FilterSection
