export const SMALL_ICON_SIZE = '0.75rem'

export const STANDART_ICON_SIZE = '1rem'

export const SMALL_BTN_BORDER_RADIUS = '0.375rem'
export const STANDART_BTN_BORDER_RADIUS = '0.5rem'

export const SMALL_INPUT_BORDER_RADIUS = '0.625rem'
export const STANDART_INPUT_BORDER_RADIUS = '0.75rem'

export const SMALL_BTN_PADDING_SIZE = '0.25rem'
export const STANDART_BTN_PADDING_SIZE = '0.375rem'

export const LARGE_BTN_SIZE = 2.25
export const STANDART_BTN_SIZE = 1.75
export const SMALL_BTN_SIZE = 1.25

export const SMALL_INPUT_LEFT_PADDING = '0.5rem'
export const STANDART_INPUT_LEFT_PADDING = '0.75rem'
