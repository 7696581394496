import styled from 'styled-components'

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -4px;
  padding: 4px 0px;
  overflow: hidden;
`

export const CarouselDiv = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  gap: 12px;
  padding: ${({ isDialog }) => isDialog && '0px 4px'};
  transform: ${({ transformValue }) => transformValue};
`
