import {
  SPECTRA_MS_INIT,
  SPECTRA_RESET,
  SET_SPECTRA_TYPE,
  SET_ION_MODE,
  SET_ADDUCT_TYPE,
  SET_ROUNDING_RI,
  SET_ROUNDING_MZ,
  SET_NMR_METHOD,
  SET_CALCULATED_MS_TYPE,
  SET_INFRARED_SHOOTING_METHOD,
  SPECTRA_NMR_INIT,
  SET_NMR_CHART_ACTIVE_ATOM,
  SET_NMR_ADDINTION_ATOM,
  SPECTRA_INFRARED_INIT,
  SPECTRA_CLEAR_DATA,
} from 'store/constants/spectra'

export const getMsData = (data) => {
  return {
    type: SPECTRA_MS_INIT,
    data,
  }
}

export const getNmrData = (data) => {
  return { type: SPECTRA_NMR_INIT, data }
}

export const getInfraredData = (smiles) => {
  return { type: SPECTRA_INFRARED_INIT, smiles }
}

export const resetSpectra = () => {
  return {
    type: SPECTRA_RESET,
  }
}

export const clearSpectraData = () => {
  return {
    type: SPECTRA_CLEAR_DATA,
  }
}

export const setSpectraType = (spectraType) => {
  return {
    type: SET_SPECTRA_TYPE,
    spectraType,
  }
}

export const setIonMode = (ionMode) => {
  return {
    type: SET_ION_MODE,
    ionMode,
  }
}

export const setAdductType = (adduct_type) => {
  return {
    type: SET_ADDUCT_TYPE,
    adduct_type,
  }
}

export const setRoundingMZ = (roundingMZ) => {
  return {
    type: SET_ROUNDING_MZ,
    roundingMZ,
  }
}

export const setRoundingRI = (roundingRI) => {
  return {
    type: SET_ROUNDING_RI,
    roundingRI,
  }
}

export const setNmrMethod = (nmrMethod) => {
  return {
    type: SET_NMR_METHOD,
    nmrMethod,
  }
}

export const setCalculatedMSType = (calculatedMSType) => {
  return {
    type: SET_CALCULATED_MS_TYPE,
    calculatedMSType,
  }
}

export const setInfraredShootingMethod = (infraredShootingMethod) => {
  return {
    type: SET_INFRARED_SHOOTING_METHOD,
    infraredShootingMethod,
  }
}

export const setNmrChartActiveAtom = (activeAtoms) => {
  return {
    type: SET_NMR_CHART_ACTIVE_ATOM,
    activeAtoms,
  }
}

export const setNmrAdditionalAtom = (additionalAtom) => {
  return {
    type: SET_NMR_ADDINTION_ATOM,
    additionalAtom,
  }
}
