export const SET_OPEN_LIT_FILTER = 'SET_OPEN_LIT_FILTER'
export const SET_LIT_FILTER_CONFIG = 'SET_LIT_FILTER_CONFIG'
export const SET_OPEN_LIT_DIALOG = 'SET_OPEN_LIT_DIALOG'
export const SET_LIT_DIALOG_CONFIG = 'SET_LIT_DIALOG_CONFIG'
export const SET_LIT_FILTER_DOC_TYPE = 'SET_LIT_FILTER_DOC_TYPE'
export const SET_PREV_SEARCH_DATA_ID = 'SET_PREV_SEARCH_DATA_ID'
export const SET_LITERATURE_LIST_VIEW = 'SET_LITERATURE_LIST_VIEW'
export const SET_LIT_PAGE_OPEN = 'SET_LIT_PAGE_OPEN'
export const SET_LIT_SYNTELLY_ID = 'SET_LIT_SYNTELLY_ID'

export const SET_LAST_SEARCHED_TEXT = 'SET_LAST_SEARCHED_TEXT'
