import CustomButton from 'components/common/customButton'
import styled from 'styled-components'

export const Btn = styled(CustomButton)`
  font-size: ${({ theme }) => theme.text[11]};
  line-height: ${({ theme }) => theme.text[14]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  height: 1.5rem;
  padding: 0.375rem 0.5rem;
  border-radius: 0.375rem;
`
