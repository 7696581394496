import { memo } from 'react'
import {
  NotificationAdmitContainer,
  NotificationHeader,
  NotificationAdmitButtons,
} from './index.style'
import { useTranslation } from 'react-i18next'
import CustomButton from '../../../common/customButton'

const NotificationAdmit = memo(({ item }) => {
  const { handler, cancel } = item
  const { t } = useTranslation()

  const buttonsConfig = [
    {
      text: t('notifications.admit.cancel'),
      type: 'secondary',
      onClick: cancel,
    },
    {
      text: t('notifications.admit.confirm'),
      type: 'accent',
      onClick: handler,
    },
  ]
  return (
    <NotificationAdmitContainer>
      <NotificationHeader>
        <span>{item.name}</span>
      </NotificationHeader>
      <NotificationAdmitButtons>
        {buttonsConfig.map(({ text, onClick, type }, i) => (
          <CustomButton
            type={type}
            onClick={() => {
              onClick()
            }}
            width={'50%'}
            key={i}
          >
            {text}
          </CustomButton>
        ))}
      </NotificationAdmitButtons>
    </NotificationAdmitContainer>
  )
})

NotificationAdmit.displayName = 'NotificationAdmit'
export default NotificationAdmit
