import { takeEvery, put, delay, select } from 'redux-saga/effects'
import { API_URL } from 'config/config'
import { fetch, store } from 'services/rest'
import {
  SYNTHCOST_GET_TASK_INIT,
  SYNTHCOST_GET_RESULT_FAIL,
  SYNTHCOST_GET_RESULT_INIT,
  SYNTHCOST_GET_TASK_SUCCESS,
  SYNTHCOST_GET_RESULT_SUCCESS,
  SYNTHCOST_GET_TASK_FAIL,
} from 'store/constants/synthcost'
import {
  DEFAULT_STAGE_END,
  DEFAULT_STAGE_START,
} from 'pages/SynthCost/config/config'

function* synthGetTask() {
  try {
    const { product, weight, stage_start, stage_end, reagent } = yield select(
      (state) => state.synthcost.settings
    )
    const response = yield store(`${API_URL}run-task`, {
      service: 'price_retro',
      params: {
        end_smiles: product,
        weight,
        max_deep: Number(stage_end) || DEFAULT_STAGE_END,
        min_deep: Number(stage_start) || DEFAULT_STAGE_START,
        start_smiles: reagent,
      },
      method: 'price_retro',
      type: 'delayed',
    })
    if (response.status === 200) {
      let status = 'running'
      while (status === 'running') {
        const id = response.data.task_uuid
        const tasks = yield fetch(`/userTasksStatus`)

        const task = tasks?.data?.find((task) => id === task.uuid)

        if (task) {
          yield put({
            type: SYNTHCOST_GET_TASK_SUCCESS,
            task,
          })
          if (task?.status === 'ok') {
            status = 'ok'
            yield put({ type: SYNTHCOST_GET_RESULT_INIT, task })
          }
        }
        yield delay(2000)
      }
    } else {
      yield put({
        type: SYNTHCOST_GET_TASK_FAIL,
        error: response.statusText,
      })
    }
  } catch (error) {
    yield put({
      type: SYNTHCOST_GET_TASK_FAIL,
      error: error.response.data.detail,
    })
  }
}

function* synthGetResult({ task }) {
  try {
    const response = yield fetch(`${API_URL}userTasksStatus/${task.uuid}`)
    if (response.status === 200) {
      yield put({
        type: SYNTHCOST_GET_RESULT_SUCCESS,
        data: response.data.result,
      })
    } else {
      yield put({
        type: SYNTHCOST_GET_RESULT_FAIL,
        error: response.statusText,
      })
    }
  } catch (error) {
    yield put({
      type: SYNTHCOST_GET_RESULT_FAIL,
      error: 'Error',
    })
  }
}

export function* synthGetTaskWatcher() {
  yield takeEvery(SYNTHCOST_GET_TASK_INIT, synthGetTask)
}

export function* synthGetDataWatcher() {
  yield takeEvery(SYNTHCOST_GET_RESULT_INIT, synthGetResult)
}

const watchers = [synthGetTaskWatcher(), synthGetDataWatcher()]
export default watchers
