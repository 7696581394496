import styled from 'styled-components'

import { TitleSecondary } from 'components/common/text/index.style'

export const DatasetTableWrapper = styled.div`
  gap: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  & .select-outbound {
    width: fit-content;
  }
`
export const Table = styled.div`
  height: 100%;
  width: 100%;
`

export const Header = styled(TitleSecondary)`
  width: calc(100% - 0.5rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.75rem;
`
