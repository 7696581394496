import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'
import Icon from 'components/Icon'
import { cancel } from 'store/actions/confirm'
import CustomButton from 'components/common/customButton'

import { Text, Buttons, Content, TextContent } from './index.style'

const ConfirmationDialog = () => {
  const dispatch = useDispatch()
  const confirmState = useSelector((store) => store.confirmation) || {}
  const {
    text,
    description,
    onConfirm,
    confirmFirst,
    buttonsTitle,
    hideTitle,
  } = confirmState
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <CustomDialog
      gap="24px"
      width="fit-content"
      onClose={() => {}}
      closeByEsc={false}
      showCloseIcon={false}
      outboundClickEnabled={false}
    >
      <Content>
        <Icon iconType="warning" />
        <TextContent>
          {onConfirm && (
            <CustomDialogHeader>
              {hideTitle ? text : t('confirmation.confirm_action')}
            </CustomDialogHeader>
          )}
          <TextContent gap="0.25rem">
            {!hideTitle && (
              <Text
                fontWeight={theme.fontWeight.light}
                color={theme.colors.text.secondary}
              >
                {text}
              </Text>
            )}
            {!!description && (
              <Text
                fontWeight={theme.fontWeight.light}
                color={theme.colors.text.secondary}
              >
                {description}
              </Text>
            )}
          </TextContent>
        </TextContent>
        <Buttons withConfirm={onConfirm} confirmFirst={confirmFirst}>
          {onConfirm && (
            <CustomButton type="accent" onClick={() => dispatch(cancel())}>
              {buttonsTitle?.[0] || t('confirmation.no')}
            </CustomButton>
          )}
          <CustomButton
            type="secondary"
            onClick={() => {
              onConfirm && onConfirm()
              dispatch(cancel())
            }}
          >
            {onConfirm ? buttonsTitle?.[1] || t('confirmation.yes') : 'OK'}
          </CustomButton>
        </Buttons>
      </Content>
    </CustomDialog>
  )
}

ConfirmationDialog.displayName = 'ConfirmationDialog'
export default ConfirmationDialog
