export const COMPANY_ACTIVITY_OPTIONS = [
  'organic_chemistry',
  'medicinal_chemistry_and_pharmaceuticals',
  'biochemistry',
  'environmental_chemistry',
  'cosmetic_chemistry',
  'petrochemistry',
  'polymer_chemistry',
  'agrochemistry',
  'chemical_technology',
  'education',
  'analytical_chemistry',
  'inorganic_chemistry',
  'materials_science',
  'geology_and_mineralogy',
]

export const COMPANY_SIZE_OPTIONS = [
  '0-100',
  '100-200',
  '300-500',
  '600-900',
  '>1000',
]

export const PARAMS_CONFIG = [
  {
    type: 'select',
    withSearch: true,
    placeholder: 'registration.select',
    options: COMPANY_ACTIVITY_OPTIONS,
  },
  {
    type: 'select',
    placeholder: '',
    withSearch: false,
    options: COMPANY_SIZE_OPTIONS,
  },
  {
    type: 'textarea',
    placeholder: 'registration.text_space',
  },
  {
    type: 'textarea',
    placeholder: 'registration.text_space',
  },
]

export const ANSWER_MAX_LETTERS_COUNT = 300
