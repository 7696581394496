import { useTranslation } from 'react-i18next'

import CustomButton from 'components/common/customButton'
import InformationBlock from 'components/LoginPage/InformationBlock'

const SuccessRegistration = ({ onLoginClick }) => {
  const { t } = useTranslation()
  return (
    <>
      <InformationBlock
        iconType="success2"
        title={t('registration.registered_success_title')}
        description={t('registration.registered_success_description')}
        description2={t('registration.registered_success_description_2')}
      />
      <CustomButton
        type="accent"
        width="100%"
        onClick={onLoginClick}
        data-test="success-registration-btn"
      >
        {t('registration.innovation_here')}
      </CustomButton>
    </>
  )
}

export default SuccessRegistration
