import styled from 'styled-components'

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  row-gap: 12px;
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.75rem;
`

export const RangeInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border-radius: 2px;
  height: 4px;
  background: #ffffff;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    width: 12px;
    background-color: #42c1bf;
    border-radius: 50%;
    transition: width 0.3s, height 0.3s;
    will-change: width, height;

    &:hover {
      height: 16px;
      width: 16px;
      transition: width 0.3s, height 0.3s;
      will-change: width, height;
    }
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    width: 12px;
    background-color: #42c1bf;
    border-radius: 50%;
    transition: width 0.3s, height 0.3s;
    will-change: width, height;

    &:hover {
      height: 16px;
      width: 16px;
      transition: width 0.3s, height 0.3s;
      will-change: width, height;
    }
  }
`
