import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { useCountdown } from './hooks/useCountdown'
import { Container, Delimeter, Item, Name, Value } from './index.style'

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  const { t } = useTranslation()

  const valuesConfig = [
    {
      value: days,
      name: t('timer.days'),
    },
    {
      value: hours,
      name: t('timer.hours'),
    },
    {
      value: minutes,
      name: t('timer.minutes'),
    },
    {
      value: seconds,
      name: t('timer.seconds'),
    },
  ]

  return (
    <Container>
      {valuesConfig.map(({ value, name }, i) => (
        <Fragment key={`item-${i}`}>
          <Item>
            <Value>{value}</Value>
            <Name>{name}</Name>
          </Item>
          {i < valuesConfig.length - 1 && <Delimeter>:</Delimeter>}
        </Fragment>
      ))}
    </Container>
  )
}

export default CountdownTimer
