export const getInfoConfig = (card) => {
  return {
    firstLine: [
      {
        icon: 'email',
        title: card.email,
        needCopy: true,
      },
      {
        icon: 'phone',
        title: card?.phone,
        needCopy: true,
      },
    ],
    secondLine: [
      {
        icon: 'briefcase',
        title: card?.company,
      },
      {
        icon: 'profile',
        title: card?.position,
      },
    ],
  }
}

export const getQuestionsConfig = (card) => [
  { question: 'company_activity', answer: '—' },
  { question: 'company_size', answer: '—' },
  { question: 'problems_to_solve', answer: '—' },
  { question: 'how_often_plan_to_use', answer: '—' },
]
