import styled from 'styled-components'

export const ReqWithIcon = styled.div`
  display: flex;
  gap: 0.25rem;
  color: ${({ isValid, theme }) =>
    isValid ? theme.colors.icons.accentPrimary : theme.colors.statuses.error};
`
export const TooltipContainer = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`
export const RequirementsBlock = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
