import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100vh;
  padding: 1.5rem 1.5rem 0.75rem 0.75rem;
`
