export const ADMIN_TABS = ['users_and_companies', 'statistics', 'user_messages']

export const FILTER_TYPES = ['users', 'companies']

export const SWITCH_FILTER_TYPES = [
  { title: 'admin.users', type: 'users' },
  { title: 'admin.companies', type: 'companies' },
  { title: 'admin.trial_applications', type: 'applications' },
]

export const ADD_COMPANY_SWITCH_TYPES = [
  { title: 'admin.with_license', type: 'license' },
  { title: 'admin.test_period', type: 'free_trial' },
]

export const gregorian_ru = {
  name: 'gregorian_ru',
  months: [
    ['Январь', 'Янв'],
    ['Февраль', 'Фев'],
    ['Март', 'Мар'],
    ['Апрель', 'Апр'],
    ['Май', 'Май'],
    ['Июнь', 'Июн'],
    ['Июль', 'Июл'],
    ['Август', 'Авг'],
    ['Сентябрь', 'Сен'],
    ['Октябрь', 'Окт'],
    ['Ноябрь', 'Ноя'],
    ['Декабрь', 'Дек'],
  ],
  weekDays: [
    ['Суббота', 'Сб'],
    ['Воскресенье', 'Вс'],
    ['Понедельник', 'Пн'],
    ['Вторник', 'Вт'],
    ['Среда', 'Ср'],
    ['Четверг', 'Чт'],
    ['Пятницу', 'Пт'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'аm'],
    ['PM', 'pm'],
  ],
}

export const SYN_ADMIN_HEADER_BLOCKS_TEXT = [
  'admin.name_position_company',
  'admin.email_phone',
  'admin.status',
  'admin.activation',
  'admin.ending',
  'admin.last_activity',
]

export const USERS_BLOCKS_WIDTH = [
  { value: '22%', justify: 'flex-start' },
  { value: '22%', justify: 'flex-start' },
  { value: '14%', justify: 'center' },
  { value: '14%', justify: 'center' },
  { value: '14%', justify: 'center' },
  { value: '14%', justify: 'center' },
]

export const APPLICATIONS_HEADER_BLOCKS_TEXT = [
  'admin.application_data',
  'admin.email_phone',
  'admin.status',
  'admin.submission_date',
]

export const APPLICATIONS_BLOCKS_WIDTH = [
  { value: '22%', justify: 'flex-start' },
  { value: '22%', justify: 'flex-start' },
  { value: '14%', justify: 'center' },
  { value: '14%', justify: 'center' },
]

export const SCROLL_OFFSET = 100

export const ADMIN_COMPANY_TYPE_OPTIONS = [
  { value: 'all', label: 'admin.filter.options.all' },
  { value: 'license', label: 'admin.license' },
  { value: 'free_trial', label: 'admin.test_period' },
  { value: 'not_active', label: 'admin.not_active' },
]

export const ADMIN_STATUS_OPTIONS = [
  { value: 'all', label: 'admin.filter.options.all' },
]

export const ADMIN_HEADER_BLOCKS_TEXT = [
  'admin.name_position',
  'admin.email_phone',
  'admin.activation',
  'admin.last_activity',
]

export const COMPANY_USERS_BLOCK_WIDTH = [
  { value: '25%', justify: 'flex-start' },
  { value: '25%', justify: 'flex-start' },
  { value: '25%', justify: 'center' },
  { value: '25%', justify: 'center' },
]
