import { useState } from 'react'
import { scaleLinear } from '@visx/scale'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { GridRows, GridColumns } from '@visx/grid'
import { withResizeDetector } from 'react-resize-detector'
import { max } from 'd3-array'
import { useTooltip, defaultStyles, useTooltipInPortal } from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { Rect, ChartSvg, TooltipData } from '../index.style'
import MoleculeViewer from 'components/MoleculeViewer'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Zoom } from '@visx/zoom'
import { getScrollScale } from '../helpers'
import { useTheme } from 'styled-components'
import { Label } from 'components/common/text/index.style'

const initialTransform = {
  scaleX: 1,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  skewX: 0,
  skewY: 0,
}

const Y_ADDED_OFFSET = 10

const BarChart = ({ data, width = 80, calculatedMSType }) => {
  const [activeId, setActiveId] = useState('')
  const theme = useTheme()
  const { t } = useTranslation()
  const ICONS_SEC = theme.colors.icons.secondary
  const TEXT_PRIMARY = theme.colors.text.primary
  const TEXT_ACCENT = theme.colors.text.accentPrimary
  const BACK_SEC = theme.colors.backgrounds.secondary

  const {
    tooltipOpen,
    tooltipTop,
    tooltipLeft,
    tooltipData,
    showTooltip,
    hideTooltip,
  } = useTooltip()

  const { containerRef, TooltipInPortal, containerBounds } = useTooltipInPortal(
    {
      detectBounds: true,
      scroll: true,
    }
  )
  const isIsotopic = calculatedMSType === 'isotopic_distribution'
  const mzValues = data.map((d) => d[0])
  const intensityValues = isIsotopic
    ? data.map((d) => d[2])
    : data.map((d) => d[1])

  const height = 356
  const margin = { top: 24, right: 20, bottom: 40, left: 60 }

  const minMz = Math.min(...mzValues) - 5
  const maxMz = Math.max(...mzValues) + 5

  const handleMouseOver = (event, d, index) => {
    const coords = localPoint(event)

    if (isIsotopic) {
      setActiveId(`${index}-${d[2]}`)
      showTooltip({
        tooltipData: { x: d[0], y: d[1], z: d[2] },
        tooltipTop: coords.y - 100,
        tooltipLeft:
          coords.x + 150 > window.innerWidth ? coords.x - 50 : coords.x + 20,
      })
    } else {
      setActiveId(`${index}-${d[3]}`)
      showTooltip({
        tooltipData: { x: d[0], y: d[1], smiles: d[2] },
        tooltipTop:
          coords.y + 100 > window.innerHeight ? coords.y + 100 : coords.y - 320,
        tooltipLeft:
          coords.x + 280 > window.innerWidth ? coords.x - 270 : coords.x + 20,
      })
    }
  }

  const handleMouseOut = () => {
    hideTooltip()
    setActiveId('')
  }

  const yScale = scaleLinear({
    domain: [0, max(intensityValues) + Y_ADDED_OFFSET || 1],
    range: [height - margin.bottom, margin.top],
  })

  const clipPathXF = (zoomCoords) => {
    return (
      zoomCoords -
      (zoomCoords > margin.left + 30 ? 30 : zoomCoords - margin.left)
    )
  }

  return (
    <div ref={containerRef}>
      <Zoom
        width={width}
        height={height}
        scaleXMin={1}
        scaleXMax={100}
        scaleYMin={1}
        scaleYMax={1}
        transformMatrix={initialTransform}
      >
        {(zoom) => {
          const xScale = scaleLinear({
            domain: [minMz, maxMz],
            range: [margin.left, width - margin.right],
          })

          const rescaleXAxis = (scale, zoom) => {
            let newDomain = scale.range().map((r) => {
              return scale.invert(
                (r - zoom.transformMatrix.translateX) /
                  zoom.transformMatrix.scaleX
              )
            })
            return scale.copy().domain(newDomain)
          }

          const zoomedScaleX = rescaleXAxis(xScale, zoom)

          const currentDomain = zoomedScaleX.domain()

          return (
            <>
              <ChartSvg
                width={width}
                height={height}
                ref={zoom.containerRef}
                onWheel={(e) => {
                  getScrollScale(e, zoom)
                }}
                onTouchStart={zoom.dragStart}
                onTouchMove={zoom.dragMove}
                onTouchEnd={zoom.dragEnd}
                onMouseDown={(e) => {
                  zoom.dragStart(e)
                }}
                onMouseMove={zoom.dragMove}
                onMouseUp={zoom.dragEnd}
                onMouseLeave={() => {
                  if (zoom.isDragging) zoom.dragEnd()
                }}
                id="bar-spectra-chart"
              >
                <GridRows
                  scale={yScale}
                  left={margin.left}
                  width={width - margin.left - margin.right}
                  numTicks={10}
                  stroke={BACK_SEC}
                  strokeWidth={1}
                  strokeOpacity={1}
                />

                <GridColumns
                  scale={zoomedScaleX}
                  top={height - margin.bottom}
                  height={-height + margin.top + margin.bottom}
                  width={width - margin.left - margin.right}
                  numTicks={5}
                  stroke={BACK_SEC}
                  strokeWidth={1}
                  strokeOpacity={1}
                  tickValues={zoomedScaleX.ticks()}
                />
                <rect
                  x={width - margin.right}
                  y={margin.top}
                  width={1}
                  height={height - margin.top - margin.bottom}
                  fill={BACK_SEC}
                />

                {data.map((d, index) => {
                  const zoomedX = zoomedScaleX(d[0])

                  // return ONLY BARS
                  if (d[0] < currentDomain[1] && d[0] > currentDomain[0]) {
                    return (
                      <g key={index}>
                        <Rect
                          id={`${index}-${isIsotopic ? d[2] : d[3]}`}
                          x={zoomedX}
                          y={yScale(isIsotopic ? d[2] : d[1])}
                          active={
                            `${index}-${isIsotopic ? d[2] : d[3]}` === activeId
                          }
                          width={1}
                          height={
                            height -
                            margin.bottom -
                            yScale(isIsotopic ? d[2] : d[1])
                          }
                          fill={ICONS_SEC}
                        />
                        <Rect
                          id={`${index}-${isIsotopic ? d[2] : d[3]}_1`}
                          x={zoomedX - 4}
                          y={yScale(isIsotopic ? d[2] : d[1])}
                          width={8}
                          height={height - margin.bottom - margin.top}
                          fill="transparent"
                          onMouseOver={(event) =>
                            handleMouseOver(event, d, index)
                          }
                          onMouseOut={handleMouseOut}
                        />
                      </g>
                    )
                  }
                })}

                {data.map((d, index) => {
                  const zoomedX = zoomedScaleX(d[0])
                  const clipPathX = clipPathXF(zoomedX)

                  // return bars info
                  if (d[0] < currentDomain[1] && d[0] > currentDomain[0]) {
                    return (
                      <>
                        {
                          <defs>
                            <clipPath id={`${index}-${d[0]}-clip`}>
                              <rect
                                x={clipPathX}
                                y={yScale(isIsotopic ? d[2] : d[1]) - 32}
                                width="60"
                                height="38"
                              />
                            </clipPath>
                          </defs>
                        }

                        <g
                          x={zoomedX}
                          y={yScale(isIsotopic ? d[2] : d[1])}
                          clipPath={`url(#${index}-${d[0]}-clip`}
                          onMouseOver={(event) =>
                            handleMouseOver(event, d, index)
                          }
                          onMouseOut={handleMouseOut}
                        >
                          <filter
                            id="filter"
                            x="-20%"
                            y="-20%"
                            width="140%"
                            height="140%"
                            filterUnits="objectBoundingBox"
                            primitiveUnits="userSpaceOnUse"
                            colorInterpolationFilters="linearRGB"
                          >
                            <feDropShadow
                              stdDeviation="4 4"
                              in="SourceGraphic"
                              dx="0"
                              dy="0"
                              floodColor={TEXT_PRIMARY}
                              floodOpacity="0.06"
                              x="0%"
                              y="0%"
                              width="100%"
                              height="100%"
                              result="dropShadow1"
                            />
                            <feDropShadow
                              stdDeviation="8 8"
                              in="dropShadow1"
                              dx="0"
                              dy="-4"
                              floodColor={TEXT_PRIMARY}
                              floodOpacity="0.02"
                              x="0%"
                              y="0%"
                              width="100%"
                              height="100%"
                              result="dropShadow"
                            />
                            <feDropShadow
                              stdDeviation="8 8"
                              in="dropShadow1"
                              dx="0"
                              dy="-4"
                              floodColor={TEXT_PRIMARY}
                              floodOpacity="0.02"
                              x="0%"
                              y="0%"
                              width="100%"
                              height="100%"
                              result="dropShadow3"
                            />
                          </filter>

                          <Rect
                            filter="url(#filter)"
                            x={zoomedX}
                            y={yScale(isIsotopic ? d[2] : d[1]) - 24}
                            rx={8}
                            width="44"
                            height="22"
                            fill="#ffffff"
                            transform={'translate(-22)'}
                          />
                          <text
                            x={zoomedX}
                            y={yScale(isIsotopic ? d[2] : d[1]) - 12}
                            fontSize="11"
                            fontWeight={400}
                            fill={
                              `${index}-${isIsotopic ? d[2] : d[3]}` ===
                              activeId
                                ? TEXT_ACCENT
                                : ICONS_SEC
                            }
                            textAnchor="middle"
                            alignmentBaseline="middle"
                          >
                            {d[0].toFixed(2)}
                          </text>
                        </g>
                      </>
                    )
                  }
                })}
                <AxisBottom
                  scale={zoomedScaleX}
                  top={height - margin.bottom}
                  tickFormat={(value) => value}
                  stroke={ICONS_SEC}
                  hideTicks={true}
                  tickLabelProps={() => ({
                    fill: ICONS_SEC,
                    fontSize: 11,
                    textAnchor: 'end',
                    fontWeight: 400,
                    fontFamily: 'Geologica Cursive',
                    lineHeight: 14,
                  })}
                  label={t('spectra.chart.m/z')}
                  labelProps={{
                    fontSize: 11,
                    lineHeight: 14,
                    fontWeight: 400,
                    fill: TEXT_PRIMARY,
                    fontFamily: 'Geologica Cursive',
                    textAnchor: 'middle',
                    x: width / 2,
                  }}
                />

                <AxisLeft
                  scale={yScale}
                  left={margin.left}
                  numTicks={5}
                  hideZero={true}
                  tickFormat={(value) => value.toFixed()}
                  stroke={ICONS_SEC}
                  hideTicks={true}
                  tickLabelProps={() => ({
                    fill: ICONS_SEC,
                    fontSize: 11,
                    textAnchor: 'end',
                    fontWeight: 400,
                    fontFamily: 'Geologica Cursive',
                    lineHeight: 14,
                    angle: -90,
                  })}
                  label={t('spectra.chart.relative_intensity')}
                  labelOffset={20}
                  labelProps={{
                    fontSize: 11,
                    lineHeight: 14,
                    fontWeight: 400,
                    fill: TEXT_PRIMARY,
                    textAnchor: 'middle',
                    x: -height / 2 + 10,
                  }}
                />
              </ChartSvg>
            </>
          )
        }}
      </Zoom>
      {tooltipOpen && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          key={Math.random()}
          style={{
            ...defaultStyles,
            zIndex: '10',
            padding: '12px 12px 0px 12px',
            maxWidth: '224px',
            borderRadius: '12px',
            background: 'rgba(245, 247, 247, 0.60)',
            backdropFilter: 'blur(40px)',
            boxShadow:
              '0px 0px 3px 0px rgba(31, 41, 51, 0.04), 0px 0px 4px 0px rgba(31, 41, 51, 0.06), 0px 0px 10px 0px rgba(31, 41, 51, 0.14)',
          }}
        >
          {!isIsotopic && (
            <div
              style={{
                width: '200px',
                height: '200px',
                minWidth: '200px',
                minHeight: '200x',
              }}
            >
              <MoleculeViewer
                smiles={tooltipData.smiles}
                onShowProps={() => {}}
                noDelete={true}
                noEdit={true}
                noMenu={true}
                width={200}
                height={200}
                noPadding={true}
                cursor="default"
                structureClassName="rounded-8-mol-structure"
              ></MoleculeViewer>
            </div>
          )}

          <TooltipData marginTop={isIsotopic ? '-12px' : '12px'}>
            <div>
              <p>{t('spectra.chart.m/z')}:</p>
              <Label
                color={theme.colors.text.primary}
                style={{ marginTop: '0.75rem' }}
              >
                {tooltipData.x}
              </Label>
            </div>
            <div>
              <p>{t('spectra.chart.intensity')}:</p>
              <Label
                color={theme.colors.text.primary}
                style={{ marginTop: '0.75rem' }}
              >
                {tooltipData.y}
              </Label>
            </div>
            <div>
              <p>
                {' '}
                {isIsotopic
                  ? t('spectra.chart.RI')
                  : t('spectra.chart.formula')}
                :
              </p>
              <Label
                name="ellipsis"
                color={theme.colors.text.primary}
                style={{ marginTop: '0.75rem' }}
                textAlign="end"
              >
                {isIsotopic ? tooltipData.z : tooltipData.smiles ?? 'Undefined'}
              </Label>
            </div>
          </TooltipData>
        </TooltipInPortal>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    calculatedMSType: state.spectra.calculatedMSType,
  }
}

export default connect(mapStateToProps)(withResizeDetector(BarChart))
