import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  gap: 1.25rem;
  display: flex;
  position: relative;
  flex-direction: column;
`
export const Inputs = styled.div`
  gap: 1rem;
  display: flex;
`
