import { t } from 'i18next'

export const getInfoConfig = (error_data) => {
  const isApplicationPending = error_data.license_request_is_handled === null

  return {
    title: isApplicationPending
      ? t('account.wait_verification_title')
      : t('account.denied_test_access_title'),

    description: isApplicationPending
      ? t('account.wait_verification_description')
      : t('account.denied_test_access_description'),

    description2: isApplicationPending
      ? t('account.wait_verification_description_2')
      : t('account.denied_test_access_description_2'),

    iconType: isApplicationPending ? 'waiting' : 'deniedAccess',
  }
}
