import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { PasswordTooltip } from './passwordTooltip'

const Tooltips = ({
  id,
  type,
  email,
  value,
  error,
  warning,
  showTooltip,
  tooltipPlace,
  tooltipOffset,
  withPasswordTooltip,
  errorTranslationParams,
}) => {
  const { t } = useTranslation()

  if (!showTooltip) return <></>

  if (withPasswordTooltip && showTooltip)
    return (
      <ReactTooltip
        id="password-req-tooltip"
        className="c-tooltip c-tooltip-base c-tooltip-password-check"
        place={tooltipPlace}
        isOpen={showTooltip}
        offset={tooltipOffset}
      >
        <PasswordTooltip password={value} email={email} />
      </ReactTooltip>
    )

  return (
    <ReactTooltip
      id={warning ? `${type}-${id}-warning` : `${type}-${id}-error`}
      className="c-tooltip c-tooltip-base"
      classNameArrow="c-tooltip-arrow"
      place={tooltipPlace}
      offset={tooltipOffset}
      isOpen={showTooltip}
    >
      {t(`${warning || error}`, errorTranslationParams)}
    </ReactTooltip>
  )
}

export default Tooltips
