import { useRef, useState } from 'react'
import { useTheme } from 'styled-components'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  Label,
  TextLarge,
  TextMedium,
} from 'components/common/text/index.style'
import {
  closeApplicationDialog,
  licenseUserToggleAccess,
  openApplicationDialog,
  removeUserFromCompany,
  setCurrentCustomerId,
} from 'store/actions/adminPanel'
import Icon from 'components/Icon'
import { useOverflow } from 'hooks/useOverflow'
import CustomButton from 'components/common/customButton'
import { resetUsersAnswers } from 'store/actions/answers'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'
import { confirm } from 'store/actions/confirm'
import { readStorage } from 'utils/storage/storage'

import {
  Info,
  UserRow,
  CardBlock,
  RestBlock,
  CardButtons,
  ToggleButton,
  UserCardWrapper,
  CompanyOverflowBlock,
} from './index.style'
import PhoneNumber from '../PhoneNumber'
import ApplicationDialog from '../applicationDialog'
import { getApplicationStatus, getName, getUserStatus } from './utils/utils'

const UserCard = ({ card, isCompany = false, isApplication = false, role }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const containerRef = useRef(null)
  const userTextRef = useRef(null)
  const companyTextRef = useRef(null)

  const isUserOverflow = useOverflow(userTextRef, containerRef)
  const isCompanyOverflow = useOverflow(companyTextRef, containerRef)

  const { openedApplicationDialog } = useSelector(
    (state) => state.adminPanel.applications
  )

  const getName = (name, surname) => {
    if (name && surname) {
      return name.concat(' ', surname)
    }
    if (!name && surname) return surname
    if (name && !surname) return name
    return t('admin.no_name')
  }

  const userName = getName(card.name, card.surname)

  const handleToggleAccess = () => {
    dispatch(
      licenseUserToggleAccess({
        isCurrentCustomer: isCompany,
        user_id: card.user_id,
        license_id: card?.license_id,
        customer_id: card?.customer_id,
      })
    )
  }

  const handleDeleteUserFromCompany = () => {
    const adminRole = readStorage('role')
    dispatch(
      confirm(
        t('admin.delete_user', {
          user: userName === t('admin.no_name') ? '' : userName,
        }),
        () =>
          dispatch(
            removeUserFromCompany({
              isCurrentCustomer: isCompany,
              user_id: card.user_id,
              isSynAdmin: adminRole === 'syn_admin',
            })
          ),
        t('admin.user_data_will_be_deleted'),
        true,
        [t('admin.keep'), t('admin.delete')],
        true
      )
    )
  }

  const handleOpenCompany = () => {
    if (card.customer_id && card.license_id) {
      dispatch(
        setCurrentCustomerId({
          customerId: card.customer_id,
          licenseId: card.license_id,
        })
      )
      dispatch(push(`/admin-panel/company`))
    }
  }

  const handleCardClick = () => {
    if (isApplication) dispatch(openApplicationDialog(card.user_id))
  }

  const handleCloseDialog = () => {
    dispatch(resetUsersAnswers())
    dispatch(closeApplicationDialog())
  }

  return (
    <>
      <UserCardWrapper onClick={handleCardClick} isApplication={isApplication}>
        <RestBlock>
          <CardBlock
            width={isCompany ? '25%' : '22%'}
            paddingLeft="0rem"
            paddingRight="0.375rem"
            ref={containerRef}
          >
            <Info gap={isCompany ? '0.625rem' : '0.5rem'}>
              {/* имя и фамилия юзера на всех карточках */}
              <TextLarge
                ref={userTextRef}
                id={`user-label-${card.user_id}`}
                ellipsis
                data-tooltip-id={`user-hint-${card.user_id}`}
                fontWeight={theme.fontWeight.semibold}
              >
                {userName}
              </TextLarge>

              <UserRow>
                <Icon iconType="profile16" size="1rem" />
                <Label
                  name="ellipsis"
                  color="inherit"
                  id={`textref-${card.user_id}`}
                >
                  {card.position || '—'}
                </Label>
              </UserRow>

              {/* компанию отображаем только для заявки */}
              {isApplication && (
                <>
                  <UserRow>
                    <Icon iconType="briefcase" size="1rem" />
                    <Label name="ellipsis" color="inherit">
                      {card.company ? card.company : t('admin.no_company')}
                    </Label>
                  </UserRow>
                </>
              )}
            </Info>
            {/* добавила хинт для имени юзера, если имя не помещается */}
            {isUserOverflow && (
              <ReactTooltip
                id={`user-hint-${card.user_id}`}
                place={'top'}
                className="c-tooltip c-tooltip-base"
                classNameArrow="c-tooltip-arrow"
              >
                <TextMedium
                  color={theme.colors.text.white}
                  fontWeight={theme.fontWeight.light}
                >
                  {userName}
                </TextMedium>
              </ReactTooltip>
            )}
            {/* кнопка на карточке: для юзеров это кнопка с названием компании, для заявки - кнопка "Данные анкеты"  */}
            {!isCompany && (
              <CompanyOverflowBlock
                onClick={isApplication ? () => {} : handleOpenCompany}
              >
                <Label
                  name="smiles"
                  ref={companyTextRef}
                  id={`textref-${card.user_id}`}
                  color="inherit"
                  data-tooltip-id={`company-hint-${card.user_id}`}
                  className={isApplication ? 'questionnaire-data-label' : ''}
                >
                  {isApplication
                    ? t('admin.questionnaire_data')
                    : card.company || t('admin.no_company')}
                </Label>{' '}
                {(card.company || isApplication) && (
                  <CustomButton
                    type="icon"
                    defaultColor="inherit"
                    noAnimation={true}
                  >
                    <Icon iconType="arrowRight" size="1rem" />
                  </CustomButton>
                )}
                {isCompanyOverflow && !isApplication && (
                  <ReactTooltip
                    id={`company-hint-${card.user_id}`}
                    place={'top'}
                    className="c-tooltip c-tooltip-base"
                    classNameArrow="c-tooltip-arrow"
                  >
                    <TextMedium
                      color={theme.colors.text.white}
                      fontWeight={theme.fontWeight.light}
                    >
                      {card.company}
                    </TextMedium>
                  </ReactTooltip>
                )}
              </CompanyOverflowBlock>
            )}
          </CardBlock>

          {/* теперь второй столбец у всех юзеров это почта и телефон */}
          <CardBlock
            width={isCompany ? '25%' : '22%'}
            paddingLeft="0.375rem"
            paddingRight="0.375rem"
          >
            <UserRow>
              <Icon iconType="email" size="1rem" />
              <Label name="ellipsis" color="inherit">
                {card.email || '—'}
              </Label>
            </UserRow>

            <UserRow>
              <Icon iconType="phone" size="1rem" />
              {card?.phone ? (
                <PhoneNumber value={card.phone} />
              ) : (
                <Label color="inherit" name="ellipsis">
                  —
                </Label>
              )}
            </UserRow>
          </CardBlock>

          {!isCompany && (
            <CardBlock
              width="14%"
              paddingLeft="0.375rem"
              paddingRight="0.375rem"
              alignItems="center"
              textAlign="center"
            >
              <Label>
                {t(
                  isApplication
                    ? getApplicationStatus(card.isApproved)
                    : getUserStatus(card)
                )}
              </Label>
            </CardBlock>
          )}

          <CardBlock
            width={isCompany ? '25%' : '14%'}
            paddingLeft="0.375rem"
            paddingRight="0.375rem"
            alignItems="center"
            gap="0.25rem"
          >
            <Label>
              {card?.date_register
                ? formatUtcDigitDateByFormat(
                    new Date(card?.date_register),
                    'dd.MM.yyyy'
                  )
                : card?.created_date || '—'}
            </Label>
            {isApplication && (
              <Label color={theme.colors.text.secondary}>
                {card.created_time}
              </Label>
            )}
          </CardBlock>
          {!isApplication && (
            <>
              {!isCompany && (
                <CardBlock
                  width="14%"
                  paddingLeft="0.375rem"
                  paddingRight="0.375rem"
                  alignItems="center"
                >
                  <Label>
                    {card.license_expired_at
                      ? formatUtcDigitDateByFormat(
                          new Date(card.license_expired_at),
                          'dd.MM.yyyy'
                        )
                      : '—'}
                  </Label>
                </CardBlock>
              )}
              <CardBlock
                alignItems="center"
                width={isCompany ? '25%' : '14%'}
                paddingLeft="0.375rem"
                paddingRight="0rem"
                gap="0.25rem"
              >
                <Label>
                  {card.date_last_enter
                    ? `${formatUtcDigitDateByFormat(
                        new Date(card.date_last_enter),
                        'dd.MM.yyyy'
                      )}`
                    : '—'}
                </Label>
                {card.date_last_enter && (
                  <Label color={theme.colors.text.secondary}>
                    {formatUtcDigitDateByFormat(
                      new Date(card.date_last_enter),
                      'HH:mm'
                    )}
                  </Label>
                )}
              </CardBlock>
            </>
          )}
        </RestBlock>
        {!isApplication && (
          <CardButtons width="4rem" gap="0.5rem" row>
            {role === 'syn_admin' && card?.company && card?.customer_id && (
              <>
                <ToggleButton
                  type="icon_tertiary"
                  defaultPadding="0.375rem"
                  borderRadius="0.5rem"
                  height="1.75rem"
                  id={`card-button-2-${card.id}`}
                  isGreen={card?.license_id}
                  onClick={handleToggleAccess}
                >
                  <Icon
                    iconType={card?.license_id ? 'unlock' : 'lock'}
                    size="1rem"
                  />
                </ToggleButton>
                <ToggleButton
                  type="icon_tertiary"
                  defaultPadding="0.375rem"
                  borderRadius="0.5rem"
                  height="1.75rem"
                  id={`card-button-2-${card.id}`}
                  isRemoveBtn
                  onClick={handleDeleteUserFromCompany}
                >
                  <Icon iconType="deleted" size="1rem" />
                </ToggleButton>
              </>
            )}
          </CardButtons>
        )}
      </UserCardWrapper>
      {openedApplicationDialog === card.user_id && (
        <ApplicationDialog onClose={handleCloseDialog} {...{ card }} />
      )}
    </>
  )
}

export default connect()(UserCard)
