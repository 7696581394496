import styled from 'styled-components'

import CustomButton from 'components/common/customButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SearchBlock = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  width: 100%;
  flex: 1;
  border-radius: 0.75rem;
  align-items: center;
  position: relative;
  padding-right: 0.25rem;

  & > input {
    margin-right: ${({ focused }) => (focused ? '1rem' : '0')};
  }
`

export const InputBlockBtns = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ visible }) => (visible ? '0.25rem' : '0rem')};
  margin-left: ${({ visible }) => (visible ? '0.5rem' : '0rem')};
`

export const InputBlockBtn = styled(CustomButton)`
  color: ${({ theme }) => theme.colors.icons.tertiary};
  border-radius: 0.5rem;
  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  width: ${({ visible }) => (visible ? '2.25rem' : '0')};
  padding: ${({ visible }) => (visible ? '0.375rem' : '0.375rem 0')};
  transition: all 0.3s;
`

export const InputBlock = styled.input`
  display: flex;
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  line-height: 1rem;

  &::placeholder {
    color: #cbd2d9;
  }
`

export const VariantBlock = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadow.default};
`

export const VariantWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 8px 20px;

  &:first-child {
    padding-top: 20px;
  }

  &:last-child {
    padding-bottom: 20px;
  }

  &:hover {
    background: rgba(196, 196, 196, 0.2);
  }
`
export const VariantElement = styled.div``

export const FlexBlock = styled.div`
  display: flex;
  width: 100%;
  height: 2.75rem;
`
