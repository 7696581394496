import { useTheme } from 'styled-components'
import { useTransition } from 'react-spring'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'

import Icon from 'components/Icon'
import { Label } from 'components/common/text/index.style'

import {
  PseudoLabel,
  AnimatedNumber,
  CustomInputField,
  CustomInputValue,
  CustomInputWrapper,
  CustomInputFieldWrapper,
} from './index.style'
import Tooltips from './Tooltips'
import { getStyleParams } from './utils'
import RightButtons from './RightButtons'
import CustomInputLabelBlock from './LabelBlock'

const CustomInput = ({
  onChange,
  value = '',
  label = '',
  placeholder = '',
  withClearButton = false,
  onKeyUp,
  onKeyDown,
  autoFocus,
  className,
  innerClassName,
  onBlur,
  onFocus = () => '',
  additionalValue,
  type = 'text',
  showSearchIcon = false,
  focused = false,
  disabled = false,
  withWhiteBackground = false,
  autoComplete = 'off',
  error = '',
  warning = '',
  isValidating = false,
  isValidated = false,
  id = 'inputField',
  withPasswordTooltip = false,
  email = '',
  dataTest = 'inputField',
  step = 1,
  min = 1,
  max = 100,
  width = '100%',
  padding = '',
  gap = '0.5rem',
  afterLabelPadding = '0.75rem',
  pseudoBeforeText = '',
  pseudoAfterText = '',
  tooltipPlace = 'top-start',
  tooltipOffset = 14,
  errorTranslationParams,
  readOnly = false,
  clearBtnProps = {},
  size = 'standart',
  withLargeText = false,
  withAddButton = false,
  onAddItem = () => console.log('add'),
  withHideButton = false,
}) => {
  const showClearButton = withClearButton && value.toString().length > 0
  const inputRef = useRef(null)

  const theme = useTheme()
  const { t } = useTranslation()

  const [showTooltip, setShowTooltip] = useState(false)

  const [animDirection, setAnimDirection] = useState(null)
  const [isValueHidden, setIsValueHidden] = useState(withHideButton)

  const showAddButton = withAddButton && value.length > 0
  const showHideButton = withHideButton && value.length > 0

  const needTooltip = withPasswordTooltip || error || warning

  const {
    iconSize,
    btnSize,
    btnPaddingSize,
    btnBorderRadius,
    inputBorderRadius,
  } = getStyleParams(size, withLargeText)

  useEffect(() => {
    let timerId
    if (needTooltip) {
      setShowTooltip(true)
      timerId = setTimeout(() => {
        setShowTooltip(false)
      }, 3000)
    } else setShowTooltip(false)

    return () => clearTimeout(timerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needTooltip])

  const handleChange = (e) => {
    if (type === 'number') {
      animDirection && setAnimDirection(null)
    }
    onChange(e.target.value)
  }
  const getInputBorder = () => {
    if (label) return 'none'
    if (warning || withPasswordTooltip)
      return `1.2px solid ${theme.colors.statuses.attention}`
    if (error) return `1.2px solid ${theme.colors.statuses.attention}`
    if (isValidated) return `1.2px solid ${theme.colors.statuses.success}`
    return 'none'
  }

  const getTooltipId = () => {
    if (label) return ''
    if (withPasswordTooltip) return 'password-req-tooltip'
    return warning ? `${type}-${id}-warning` : `${type}-${id}-error`
  }

  const getTransitions = useTransition(value, {
    from: {
      transform: `translateY(${animDirection === 'up' ? '-100%' : '100%'})`,
      opacity: 0,
    },
    enter: {
      transform: 'translateY(0%)',
      opacity: 1,
    },
    leave: {
      transform: `translateY(${animDirection === 'up' ? '100%' : '-100%'})`,
      opacity: 0,
    },
    config: { tension: 250 },
  })

  const handleIncrement = () => {
    setAnimDirection('up')
    onChange((Number(value) + 1).toString())

    setTimeout(() => {
      setAnimDirection(null)
    }, 200)
  }

  const handleDecrement = () => {
    setAnimDirection('down')
    onChange((Number(value) - 1).toString())

    setTimeout(() => {
      setAnimDirection(null)
    }, 200)
  }

  return (
    <CustomInputWrapper className={className} width={width} gap={gap}>
      {label && (
        <CustomInputLabelBlock
          {...{
            id,
            type,
            label,
            error,
            onFocus,
            warning,
            isValidated,
            showTooltip,
            needTooltip,
            isValidating,
            setShowTooltip,
            withPasswordTooltip,
          }}
        />
      )}
      <Tooltips
        {...{
          id,
          type,
          error,
          email,
          value,
          warning,
          showTooltip,
          tooltipPlace,
          tooltipOffset,
          withPasswordTooltip,
          errorTranslationParams,
        }}
      />
      <CustomInputFieldWrapper
        style={{ border: getInputBorder() }}
        {...{
          size,
          padding,
          focused,
          iconSize,
          showClearButton,
          inputBorderRadius,
          withWhiteBackground,
        }}
        className={innerClassName}
        withBeforeLabel={!!pseudoBeforeText}
        data-tooltip-id={getTooltipId()}
        onMouseEnter={() =>
          label || !needTooltip
            ? () => {}
            : !showTooltip && setShowTooltip(true)
        }
        onMouseLeave={() =>
          label ? () => {} : showTooltip && setShowTooltip(false)
        }
      >
        {showSearchIcon && <Icon iconType="search" size={iconSize} />}

        {type === 'number' &&
          !!animDirection &&
          getTransitions((style, item) => (
            <AnimatedNumber {...{ size, style, animDirection }}>
              <Label>{item}</Label>
            </AnimatedNumber>
          ))}

        {!!pseudoBeforeText.length && value.length !== 0 && (
          <PseudoLabel
            onClick={() => {
              inputRef.current.focus()
            }}
          >
            {pseudoBeforeText}
          </PseudoLabel>
        )}
        <CustomInputField
          {...{
            id,
            min,
            max,
            step,
            size,
            type: withHideButton ? (isValueHidden ? 'password' : 'text') : type,
            onBlur,
            onKeyUp,
            onFocus,
            padding,
            disabled,
            onKeyDown,
            autoFocus,

            autoComplete,
            withLargeText,
            showSearchIcon,
            withWhiteBackground,
          }}
          name={id}
          ref={inputRef}
          spellCheck={false}
          data-test={dataTest}
          onChange={handleChange}
          showClearButton={showClearButton && !disabled}
          value={
            animDirection
              ? ''
              : additionalValue
              ? `${t(additionalValue)} ${value}`
              : value
          }
          readOnly={!!animDirection || readOnly}
          placeholder={animDirection ? '' : placeholder}
          withBeforeLabel={!!pseudoBeforeText.length && value.length !== 0}
          padding={padding}
        />
        {pseudoAfterText &&
          pseudoBeforeText.length === 0 &&
          value.length !== 0 && (
            <CustomInputValue afterLabelPadding={afterLabelPadding}>
              <span style={{ opacity: 0, marginRight: '2px' }}>{value}</span>

              <span style={{ position: 'relative' }}>{pseudoAfterText}</span>
            </CustomInputValue>
          )}
        <RightButtons
          {...{
            min,
            max,
            type,
            value,
            btnSize,
            onChange,
            disabled,
            iconSize,
            onAddItem,
            clearBtnProps,
            showAddButton,
            btnPaddingSize,
            showClearButton,
            btnBorderRadius,
            withWhiteBackground,
            showHideButton,
            isValueHidden,
            onHideBtnClick: () => setIsValueHidden(!isValueHidden),
          }}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
        />
      </CustomInputFieldWrapper>
    </CustomInputWrapper>
  )
}

export default CustomInput
