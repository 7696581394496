import {
  UPDATE_TASKS,
  REMOVE_TASK,
  REMOVE_COMPLETED_TASKS,
} from '../constants/tasks'

export const updateTasks = (tasks) => ({
  type: UPDATE_TASKS,
  tasks,
})

export const removeTask = (id) => ({
  type: REMOVE_TASK,
  id,
})

export const removeCompletedTasks = () => ({
  type: REMOVE_COMPLETED_TASKS,
})
