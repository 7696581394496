import { Container } from './index.style'
import LoaderRestyling from 'components/LoaderRestyling'

const LoadingOverlay = () => {
  return (
    <Container>
      <LoaderRestyling />
    </Container>
  )
}

export default LoadingOverlay
