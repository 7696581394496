import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo, useEffect, useState } from 'react'

import {
  setSearchV2State,
  setLitSearchState,
  setBingoSearchState,
} from 'store/actions/crud'
import {
  setDrawnSmiles,
  setSearchText,
  setPaginationConfig,
  setSearchSortingConfig,
} from 'store/actions/search'
import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { setLitFilterConfig } from 'store/actions/literature'
import { removeNotification } from 'store/actions/notifications'
import { setFilterConfig, setOpenFilter } from 'store/actions/filter'

import NotificationText from '../NotificationText'
import { Body, Header, Bottom, Container } from './index.style'
import { useUndo } from 'hooks/useUndo'

const NotificationCancel = memo(
  ({
    item,
    setOpenFilter,
    setSearchText,
    setDrawnSmiles,
    setFilterConfig,
    setSearchV2State,
    setLitSearchState,
    removeNotification,
    setLitFilterConfig,
    setBingoSearchState,
    setPaginationConfig,
    setSearchSortingConfig,
  }) => {
    const { t } = useTranslation()
    const { handleUndo } = useUndo()

    const [time, setTime] = useState(5)

    let timer1 = setTimeout(() => setTime((prev) => prev - 1), 1000)

    const { id, name, text: itemText, params } = item || {}

    const { text, actionType, searchType } = params || {}

    useEffect(() => {
      return () => {
        clearTimeout(timer1)
      }
    }, [timer1])

    const handleCancelAction = () => {
      if (actionType === 'searchQuery') {
        const {
          text,
          searchV2,
          litSearch,
          pagination,
          searchType,
          bingoSearch,
          drawnSmiles,
          filterConfig,
          litFilterConfig,
          sortingConfig,
        } = params

        setSearchText(text)
        setDrawnSmiles(drawnSmiles)
        setPaginationConfig(pagination)
        setFilterConfig(filterConfig)
        setOpenFilter(true)
        if (searchType === 'structure') {
          setSearchV2State(searchV2)
          setBingoSearchState(bingoSearch)
        }
        if (searchType === 'literature') {
          setLitFilterConfig(litFilterConfig)
          setSearchSortingConfig(sortingConfig)
          setLitSearchState(litSearch)
        }
      }
      if (actionType === 'baskets-join' || actionType === 'baskets-delete') {
        handleUndo && handleUndo()
      }
      removeNotification(id)
    }

    return (
      <Container>
        <Body>
          <Header>
            <Icon iconType="warning" />
            <span>
              {t(
                name,
                name === 'notification.search_query_removed'
                  ? { type: t(`search.${searchType}`).toLowerCase(), text }
                  : {}
              )}
            </span>
          </Header>
          {!!itemText && <NotificationText {...{ item, t }} />}
        </Body>
        <Bottom>
          <CustomButton width="100%" type="accent" onClick={handleCancelAction}>
            {t('notification.cancel', {
              time,
            })}
          </CustomButton>
        </Bottom>
      </Container>
    )
  }
)

NotificationCancel.displayName = 'NotificationCancel'

const mapDispatchToProps = {
  setSearchText,
  setOpenFilter,
  setDrawnSmiles,
  setFilterConfig,
  setSearchV2State,
  setLitSearchState,
  removeNotification,
  setLitFilterConfig,
  setPaginationConfig,
  setBingoSearchState,
  setSearchSortingConfig,
}

export default connect(null, mapDispatchToProps)(NotificationCancel)
