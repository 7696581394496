import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { fetch, storeV2 } from 'services/rest'
import { useDebounce } from 'hooks/useDebounce'
import CustomInput from 'components/common/customInput'
import { setSearch, setSearchString } from 'store/actions/crud'
import {
  Container,
  FlexBlock,
  SearchBlock,
  VariantBlock,
  InputBlockBtn,
  VariantElement,
  VariantWrapper,
  InputBlockBtns,
} from './index.style'
import Ketcher from '../Ketcher'

const MoleculeSearchPanelRest = ({ setSearch, setSearchString }) => {
  const [options, setOptions] = useState([])
  const [text, setTextHook] = useState('')
  const [ketcher, setKetcher] = useState(false)

  const { t } = useTranslation()
  const debouncedInputValue = useDebounce(text, 300)
  const synonymSelectedValueRef = useRef('')

  useEffect(() => {
    if (text === synonymSelectedValueRef.current) return
    text && requestSearch(text, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue])

  const requestSearch = async (text, isQuick = false) => {
    const { data } = await storeV2('molecule/global_search', {
      params: {
        quick: isQuick,
        query: text,
      },
    })
    const search_data = data?.result?.items || []
    if (Array.isArray(search_data)) {
      const eax = search_data.filter((e) => e.id)
      setOptions(eax)
    }
  }

  const handleChange = (msg) => {
    setText(msg)
    if (!msg) {
      setSearch([])
      setOptions([])
    }
  }

  const setText = (t) => {
    setTextHook(t)
    setSearchString(t)
  }

  const handleSelectFromList = (val) => {
    setText(val.label)
    synonymSelectedValueRef.current = val.label
    setSearch([val.id])
    setOptions([])
  }

  const fetchInchi = async (smiles) => {
    const sm = encodeURIComponent(smiles)
    try {
      const r1 = await fetch(`/convert_smiles_to_inchi?smiles=${sm}`)
      if (!r1.data.error) {
        return r1.data.inchiKey
      }
    } catch (err) {
      console.log(err)
    }
    return ''
  }

  const handleSearch = async () => {
    let opts = options.map((el) => el.id)
    if (text.length === 27 && text[14] && text[25]) {
      opts = [...opts, `z${text}`]
    }
    const inchiKey = await fetchInchi(text)
    if (inchiKey) {
      opts = [...opts, `z${inchiKey}`]
    }

    setSearch(opts)
    setOptions([])
  }

  const handleDraw = async (smiles) => {
    const inchiKey = await fetchInchi(smiles)
    if (inchiKey) {
      setText(smiles)
      setSearch([`z${inchiKey}`])
    }
    setOptions([])
  }

  const handleReset = () => {
    setText('')
    setSearch([])
    setOptions([])
  }

  return (
    <Container>
      <FlexBlock>
        <SearchBlock focused={options.length > 0 || text}>
          <CustomInput
            value={text}
            onChange={handleChange}
            withWhiteBackground
            placeholder={t('moleditor.search')}
            withLargeText
          />
          <InputBlockBtns visible={text || options.length > 0 || text}>
            <InputBlockBtn
              visible={text}
              onClick={handleReset}
              id="moleditor-close-btn"
              type="secondary"
            >
              <Icon iconType="close" size="1rem" />
            </InputBlockBtn>
            <InputBlockBtn
              visible={options.length > 0 || text}
              onClick={handleSearch}
              id="moleditor-search-btn"
              type="secondary"
            >
              <Icon iconType="search" size="1rem" />
            </InputBlockBtn>
          </InputBlockBtns>
          {options.length > 0 && (
            <VariantBlock>
              {options.map((el, idx) => {
                return (
                  <VariantWrapper
                    key={idx}
                    onClick={() => handleSelectFromList(el)}
                  >
                    <VariantElement>{el.label}</VariantElement>
                  </VariantWrapper>
                )
              })}
            </VariantBlock>
          )}
        </SearchBlock>
      </FlexBlock>

      {ketcher && (
        <Ketcher
          handleData={handleDraw}
          closeKetcher={() => setKetcher(false)}
          smiles=""
        />
      )}
    </Container>
  )
}

const mapDispatchToProps = { setSearch, setSearchString }

export default connect(null, mapDispatchToProps)(MoleculeSearchPanelRest)
