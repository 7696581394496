import { t } from 'i18next'

export const checkTextCondition = (type, value = '', filter = '') => {
  if (type === 'contains') return value.includes(filter)
  if (type === 'doesNotContain') return !value.includes(filter)
  if (type === 'equals') return value === filter
  if (type === 'doesNotEqual') return value !== filter
  if (type === 'startsWith') return value.startsWith(filter)
  if (type === 'endsWith') return value.endsWith(filter)
  if (type === 'blank') return !value
  if (type === 'notBlank') return !!value
}

export const checkNumberCondition = (
  type,
  value = '',
  filter = '',
  filter2 = ''
) => {
  if (type === 'contains') return value.includes(filter)
  if (type === 'equals') return value === filter
  if (type === 'doesNotEqual') return value !== filter

  if (type === 'greaterThan') return Number(value) > Number(filter)
  if (type === 'greaterThanOrEqual') return Number(value) >= Number(filter)

  if (type === 'lessThan') return Number(value) < Number(filter)
  if (type === 'lessThanOrEqual') return Number(value) <= Number(filter)
  if (type === 'inRange')
    return Number(value) >= Number(filter) && Number(value) <= Number(filter2)

  if (type === 'blank') return !value
  if (type === 'notBlank') return !!value
}

export const checkObjDisablity = (el) => {
  if (el.type === 'inRange') return !el.filter || !el?.filter2
  if (
    el.type &&
    el.type !== 'blank' &&
    el.type !== 'notBlank' &&
    el.type !== 'set'
  )
    return !el.filter
}

export const checkFilterConditions = (value, filter, columnType, operator) => {
  if (!filter) return true
  let firstCondition = true
  let secondCondition = true
  if (filter?.conditions) {
    firstCondition =
      columnType === 'string'
        ? checkTextCondition(
            filter.conditions[0].type,
            value?.toLowerCase(),
            filter.conditions[0].filter?.toLowerCase()
          )
        : checkNumberCondition(
            filter.conditions[0].type,
            value,
            filter.conditions[0].filter,
            filter.conditions[0]?.filter2
          )
    secondCondition =
      columnType === 'string'
        ? checkTextCondition(
            filter.conditions[1].type,
            value?.toLowerCase(),
            filter.conditions[1].filter?.toLowerCase()
          )
        : checkNumberCondition(
            filter.conditions[1].type,
            value,
            filter.conditions[1].filter,
            filter.conditions[1]?.filter2
          )
  } else {
    firstCondition =
      columnType === 'string'
        ? checkTextCondition(
            filter.type,
            value?.toLowerCase(),
            filter.filter?.toLowerCase()
          )
        : checkNumberCondition(
            filter.type,
            value,
            filter.filter,
            filter?.filter2
          )
    return firstCondition
  }

  return operator === 'AND'
    ? firstCondition && secondCondition
    : firstCondition || secondCondition
}

export const getTypeValue = (type) => {
  if (type === 'blank') return t('dataset_table.blank').toLowerCase()
  if (type === 'notBlank') return t('dataset_table.notBlank').toLowerCase()
  else return ''
}

export const onChangeFilters = (ind, key, value, setFiltersState) => {
  if (key === 'type' && (!value || value === 'blank' || value === 'notBlank'))
    setFiltersState((prev) =>
      prev.map((el, i) =>
        ind === i
          ? {
              ...el,
              [key]: value,
              filter: '',
              filter2: '',
            }
          : el
      )
    )
  else
    setFiltersState((prev) =>
      prev.map((el, i) =>
        ind === i
          ? {
              ...el,
              filter2: el.type === 'inRange' ? el.filter2 : '',
              [key]: value,
            }
          : el
      )
    )
}
