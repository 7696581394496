import styled from 'styled-components'

export const KetcherBox = styled.div`
  border-radius: 0.75rem;
  margin: 0 1.5rem 0 0.75rem;
  background-color: ${({ theme }) => theme?.colors?.backgrounds?.primary};
`
export const KetcherWrapper = styled.div`
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
  width: ${({ width }) => width && `${width - 36}px`};
  height: ${({ height }) => height && `${height - 102}px`};
`

export const KetcherDialogWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
  height: ${({ height }) => height};
`

export const DialogActions = styled.div`
  gap: 0.5rem;
  width: 100%;
  display: flex;
`
