import styled from 'styled-components'

export const ButtonContent = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`

export const LeftBlock = styled.div`
  padding-right: 0.125rem;
  min-width: 0;
  display: flex;
  flex-direction: column;

  em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    background: ${({ theme }) => theme.colors.backgrounds.accentSecondary}40;
    border-radius: 0.375rem;
  }
`
export const ButtonWrapper = styled.div`
  margin-bottom: 8px;
`

export const TabsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
  margin-top: 24px;
  margin-bottom: 12px;

  & div {
    display: flex;
    gap: 8px;
  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 16px;
  & div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`

export const AbstractBlock = styled.div`
  margin-top: 16px;
`
