import { memo, useCallback, useEffect, useRef, useState } from 'react'
import isEmpty from 'lodash.isempty'

import initRDKit from 'utils/rdkit/initRDKit'
import { MOL_DETAILS } from './config/config'
import { Container } from './index.style'
import './index.css'

const MoleculeStructure = memo(
  ({
    structure,
    width = 250,
    height = 200,
    svgMode = false,
    extraDetails = {},
    drawingDelay,
    showTitle,
    className,
    hideEmpty = false,
    dataTest,
    onRender = () => {},
    onClear = () => {},
    fixedContainerSize = true,
    highlight_smiles = '',
    fixedCanvas = false,
    fixedCanvasHeight = true,
  }) => {
    const [rdKit, setRdKit] = useState()
    const [invalidMol, setInvalidMol] = useState(false)
    const [svg, setSvg] = useState()
    const [rdKitLoaded, setRdKitLoaded] = useState(false)
    const [rdKitError, setRdKitError] = useState(false)
    const isDrawnOnceRef = useRef()
    const canvasRef = useRef()

    const getMolDetails = useCallback((mol, qmol) => {
      if (!!mol && mol?.is_valid() && !!qmol && qmol?.is_valid()) {
        const subStructHighlightDetails = JSON.parse(
          mol.get_substruct_matches(qmol)
        )
        const subStructHighlightDetailsMerged = !isEmpty(
          subStructHighlightDetails
        )
          ? subStructHighlightDetails.reduce(
              (acc, { atoms, bonds }) => ({
                atoms: [...acc.atoms, ...atoms],
                bonds: [...acc.bonds, ...bonds],
              }),
              { bonds: [], atoms: [] }
            )
          : subStructHighlightDetails

        return JSON.stringify({
          width,
          height,
          ...MOL_DETAILS,
          ...(extraDetails || {}),
          ...subStructHighlightDetailsMerged,
        })
      } else {
        return JSON.stringify({
          width,
          height,
          ...MOL_DETAILS,
          ...(extraDetails || {}),
        })
      }
    }, [extraDetails, height, width])

    const drawSVGorCanvas = useCallback(() => {
      const mol = rdKit?.get_mol(structure || 'invalid')
      const qmol = rdKit?.get_qmol(highlight_smiles || 'invalid')

      if (!!mol && mol?.is_valid()) {
        setInvalidMol(false)

        if (svgMode) {
          setSvg(mol?.get_svg_with_highlights(getMolDetails(mol, qmol)))
        } else {
          const canvas = canvasRef?.current
          if (!canvas) return

          const scale = 2
          canvas.width = width * 2
          canvas.height = height * 2
          const context = canvas.getContext('2d')

          context.scale(scale, scale)

          mol?.draw_to_canvas_with_highlights(canvas, getMolDetails(mol, qmol))
        }
      } else {
        setInvalidMol(true)
      }

      /**
       * Delete C++ mol objects manually
       * https://emscripten.org/docs/porting/connecting_cpp_and_javascript/embind.html#memory-management
       */
      mol?.delete()
      qmol?.delete()
    }, [getMolDetails, height, highlight_smiles, rdKit, structure, svgMode, width])

    const draw = useCallback(() => {
      if (!rdKit) return

      if (drawingDelay) {
        setTimeout(() => {
          drawSVGorCanvas()
        }, drawingDelay)
      } else {
        drawSVGorCanvas()
      }
    }, [drawSVGorCanvas, drawingDelay, rdKit])

    const drawOnce = () => {
      if (isDrawnOnceRef?.current) return

      isDrawnOnceRef.current = true
      draw()
    }

    useEffect(() => {
      initRDKit()
        .then((rdKit) => {
          setRdKit(rdKit)
          setRdKitLoaded(true)
          try {
            draw()
          } catch (err) {
            console.log(err)
          }
        })
        .catch((err) => {
          console.log(err)
          setRdKitError(true)
        })
    }, [draw])

    useEffect(() => {
      if (!rdKitError && rdKitLoaded && !svgMode) drawOnce()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rdKitError, rdKitLoaded, svgMode])

    useEffect(() => {
      if (!structure) {
        setInvalidMol(true)
        return
      }
      draw()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [structure, svgMode, highlight_smiles, width, height, extraDetails])

    useEffect(() => {
      if (rdKitError || !rdKitLoaded || !window.RDKit || invalidMol) {
        onClear()
        return
      }
      onRender()
    })

    if (rdKitError || !rdKitLoaded || !window.RDKit) {
      if (hideEmpty) return null
      return (
        <Container
          width={fixedContainerSize ? width : ''}
          height={fixedContainerSize ? height : ''}
        />
      )
    }

    if (invalidMol) {
      if (hideEmpty) return null
      return (
        <Container
          width={fixedContainerSize ? width : ''}
          height={fixedContainerSize ? height : ''}
          data-test={dataTest}
        />
      )
    }

    if (svgMode) {
      return (
        <Container
          title={showTitle && structure}
          dangerouslySetInnerHTML={{ __html: svg }}
          width={fixedContainerSize ? width : ''}
          height={fixedContainerSize ? height : ''}
          className={`${className} svg-molecule-structure`}
          data-test={dataTest}
        />
      )
    }

    return (
      <div
        className={`${className} canvas-molecule-structure`}
        data-test={dataTest}
      >
        <canvas
          title={showTitle && structure}
          width={width * 2}
          height={height * 2}
          ref={canvasRef}
          style={
            fixedCanvas
              ? { width, height }
              : fixedCanvasHeight
              ? { height }
              : {}
          }
          data-canvas="mol-viewer-canvas"
        />
      </div>
    )
  }
)

MoleculeStructure.displayName = 'MoleculeStructure'
export default MoleculeStructure
