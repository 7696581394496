import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: grid;
  column-gap: 0.5rem;
  row-gap: 0.75rem;
  grid-template-columns: repeat(auto-fill, minmax(min(19rem, 100%), 1fr));
  justify-content: start;
  margin-top: 1.5rem;
  padding-bottom: 4.25rem;
`

export const CommentsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FeedbackLabel = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 20px;
`

export const CommentElement = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 150px 200px 1fr;
  background-color: ${({ handled }) => (handled ? '#fff;' : '#ddd;')};
  font-weight: ${({ handled }) => (handled ? '400;' : '600;')};
  padding: 8px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const ImageBlock = styled.img`
  max-width: 100%;
  z-index: 1;
  position: relative;
  user-select: none;
`
