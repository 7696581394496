import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  gap: 0.75rem;
  width: 100%;
`
export const DeleteButton = styled.div`
  width: 36px;
  height: 36px;
  padding: 10px;

  svg {
    width: 16px;
    height: 16px;
  }

  background: #f5f7f7;
  border-radius: 12px;

  color: #cbd2d9;
  cursor: pointer;
  transition: all 200ms;

  &:hover {
    color: #42c1bf;
  }
`
