export const themeOptions = [
  {
    id: 1,
    label: 'feedback.themes.license',
    value: 1,
  },
  { id: 2, label: 'feedback.themes.error', value: 2 },
  {
    id: 3,
    label: 'feedback.themes.improve',
    value: 3,
  },
  {
    id: 4,
    label: 'feedback.themes.experimental_data',
    value: 4,
  },
  // { Эта опция пока скрыта от пользователей, см задачу SYN- 2730
  //   id: 5,
  //   label: 'feedback.themes.literature_source',
  //   /*value: 'предоставить литературный источник',*/
  //   value: 5,
  // },
  { id: 6, label: 'feedback.themes.feedback', value: 6 },
]

export const modules = [
  {
    id: 1,
    label: 'feedback.modules.props',
    value: 1,
    themes: [],
  },
  {
    id: 2,
    label: 'feedback.modules.spectra',
    value: 2,
    themes: [],
  },
  {
    id: 3,
    label: 'feedback.modules.synth_cost',
    value: 3,
    themes: [],
  },
  {
    id: 4,
    label: 'feedback.modules.search',
    themes: [],
    value: 4,
  },
  {
    id: 5,
    label: 'feedback.modules.moleditor',
    themes: [],
    value: 5,
  },
  {
    id: 6,
    label: 'feedback.modules.datasets',
    value: 6,
  },
  {
    id: 7,
    label: 'feedback.modules.reactions',
    themes: [],
    value: 7,
  },
  {
    id: 8,
    label: 'feedback.modules.pdf2smi',
    themes: [],
    value: 8,
  },
  {
    id: 9,
    label: 'feedback.modules.smi2iupac',
    themes: [],
    value: 9,
  },
  {
    id: 10,
    label: 'feedback.modules.statistics',
    themes: [],
    value: 10,
  },
  {
    id: 11,
    label: 'feedback.modules.common',
    themes: [],
    value: 11,
  },
  {
    id: 12,
    label: 'feedback.modules.synmap',
    value: 12,
  },
]

export const FEEDBACK_SORT_TYPE_CONFIG = [
  {
    label: 'search.sortTypes.created_at',
    value: 'created_at',
  },
]
