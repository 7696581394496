import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  validatePasswordSymb,
  validatePasswordLength,
  validatePasswordLetters,
} from 'utils/common/common'
import Icon from 'components/Icon'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'

import { Container, Inputs } from './index.style'
import TitleWithDescription from '../TitleWithDescription'

const PasswordsBlock = ({ loading, onChangePassword, email }) => {
  const { t } = useTranslation()

  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [password2Error, setPassword2Error] = useState('')
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(false)

  const isPasswordValidationError =
    !!password &&
    (!validatePasswordLength(password) ||
      !validatePasswordLetters(password) ||
      !validatePasswordSymb(password) ||
      (!!email && password.includes(email)))

  const isChangePasswordDisabled =
    !password ||
    !password2 ||
    isPasswordValidationError ||
    password2Error ||
    !isPasswordsMatch

  useEffect(() => {
    if (!password2 && password2Error) setPassword2Error('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password2])

  const handleBlur = () => {
    if (password && password2) {
      if (password !== password2) {
        setPassword2Error('account.password2Error')
        setIsPasswordsMatch(false)
      } else {
        if (!isPasswordsMatch) setIsPasswordsMatch(true)
        setPassword2Error('')
      }
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
  }

  return (
    <>
      <Container>
        <TitleWithDescription
          title={t('recovery.new_password')}
          description={t('recovery.enter_new_password')}
        />
        <Inputs>
          <CustomInput
            id="pass1"
            label={t('registration.password')}
            type="password"
            onChange={(value) => {
              setIsPasswordsMatch(false)
              setPassword(value)
            }}
            value={password}
            placeholder={t('registration.password_placeholder')}
            warning={isPasswordValidationError}
            isValidated={!!password && !isPasswordValidationError}
            withClearButton
            withPasswordTooltip={isPasswordValidationError}
            email={email}
          />
          <CustomInput
            id="pass2"
            label={t('registration.password2_placeholder')}
            type="password"
            onChange={(value) => {
              setIsPasswordsMatch(false)
              setPassword2(value)
            }}
            value={password2}
            warning={password2Error}
            isValidated={!!password2 && !password2Error && isPasswordsMatch}
            placeholder={t('registration.password2_placeholder')}
            withClearButton
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
          />
        </Inputs>
      </Container>

      <CustomButton
        width={'100%'}
        type="accent"
        onClick={() => onChangePassword(password)}
        disabled={isChangePasswordDisabled}
        data-test="change-password-btn"
      >
        {loading ? (
          <Icon size="1rem" iconType="loader" />
        ) : (
          t('recovery.confirm')
        )}
      </CustomButton>
    </>
  )
}
export default PasswordsBlock
