import { AdminPanelWrapper } from './index.style'
import AdminHeader from './adminHeader/index'
import { memo, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { SCROLL_OFFSET } from './config'
import CustomScrollbar from 'components/CustomScrollbar'
import { readStorage } from 'utils/storage/storage'
import { push } from 'connected-react-router'
import FeedbackViewer from 'components/Feedback/FeedbackViewer'
import UsersAndCompanies from './usersAndCompanies'
import Statistics from './statistics'

const AdminPanelPage = ({
  usersPagination,
  customersPagination,
  section,
  applicationsPagination,
  filterType,
  feedbackPagination,
}) => {
  const dispatch = useDispatch()
  const scrollRef = useRef(null)
  const [state, setState] = useState({
    scrollTopEnable: false,
    isBottom: false,
  })

  const { scrollTopEnable, isBottom } = state || {}

  const checkIsBottom = () => {
    const d = document.getElementById('adminContainer')
    if (!scrollRef.current?.scrollValues?.clientHeight) return

    const isNotTop = scrollRef.current?.scrollValues?.scrollTop > SCROLL_OFFSET
    let isBottomCurrent

    // нужно чтобы при первом переключении на фидбэк не появлялась пагинация
    if (
      section === 'user_messages' &&
      scrollRef.current?.scrollValues?.clientHeight ===
        scrollRef.current?.scrollValues?.scrollHeight &&
      feedbackPagination?.activePage === 1
    )
      isBottomCurrent = false
    else {
      isBottomCurrent =
        scrollRef.current?.scrollValues?.scrollTop +
          d?.getBoundingClientRect()?.height +
          36 >=
          scrollRef.current?.scrollValues?.scrollHeight ||
        !scrollRef.current?.scrollValues?.scrollYPossible
    }

    if (isNotTop === scrollTopEnable && isBottomCurrent === isBottom) {
      return
    }

    setState((prev) => ({
      ...prev,
      scrollTopEnable: isNotTop && !isBottomCurrent,
      isBottom: isBottomCurrent,
    }))
  }

  const handleScroll = () => {
    checkIsBottom()
  }

  const handleScrollTop = () => {
    if (scrollRef?.current?.scrollValues?.scrollTop)
      scrollRef?.current?.scrollTo(0, 1)
    else if (scrollRef?.current?.scrollValues) {
      scrollRef.current.scrollValues.scrollTop = 1
      setTimeout(() => {
        checkIsBottom()
      })
    }
  }

  useEffect(() => {
    handleScrollTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    section,
    filterType,
    feedbackPagination?.activePage,
    usersPagination?.activePage,
    customersPagination?.activePage,
    applicationsPagination?.activePage,
  ])

  useEffect(() => {
    const adminRole = readStorage('role')
    adminRole !== 'syn_admin' && dispatch(push('/admin-panel/company'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPanelWrapper id="adminContainer" flexCol={section === 'statistics'}>
      {section === 'statistics' ? (
        <>
          <AdminHeader />
          <Statistics />
        </>
      ) : (
        <CustomScrollbar innerRef={scrollRef} onScroll={handleScroll}>
          <AdminHeader />
          {section === 'users_and_companies' && (
            <UsersAndCompanies
              handleScroll={handleScroll}
              isBottom={isBottom}
              scrollTopEnable={scrollTopEnable}
              handleScrollTop={handleScrollTop}
            />
          )}
          {section === 'user_messages' && (
            <FeedbackViewer
              isBottom={isBottom}
              scrollTopEnable={scrollTopEnable}
              handleScrollTop={handleScrollTop}
            />
          )}
        </CustomScrollbar>
      )}
    </AdminPanelWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
    filterType: state.adminPanel.filter.type,
    tasks: state.tasks,
    usersPagination: state.adminPanel.users.pagination,
    customersPagination: state.adminPanel.customers.pagination,
    applicationsPagination: state.adminPanel.applications.pagination,
    addCompanyDialogOpen: state.adminPanel.addCompanyDialogOpen,
    section: state.adminPanel.section,
    feedbackPagination: state.feedback.pagination,
  }
}

AdminPanelPage.displayName = 'AdminPanelPage'
export default connect(mapStateToProps)(AdminPanelPage)
