import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FilterSingleInputWrapper,
  FilterSingleInputTitle,
  SingleInputContainer,
} from './index.style'
import { HideBtn } from '../../index.style'
import Icon from 'components/Icon'
import { checkIfNumbers } from 'components/Filter/utils/utils'
import CustomInput from 'components/common/customInput'

const FilterSingleInput = memo(({ setState, state, config }) => {
  const { value, title, numberOnly, key } = config
  const { t } = useTranslation()
  const [isFilerOpened, setFilterOpened] = useState(true)

  const handleOpen = () => setFilterOpened(!isFilerOpened)

  const handleChange = (value) => {
    if (numberOnly && !checkIfNumbers(value)) return
    setState({ [key]: value })
  }

  return (
    <FilterSingleInputWrapper opened={isFilerOpened}>
      <FilterSingleInputTitle onClick={handleOpen}>
        {t(title)}
        <HideBtn opened={isFilerOpened}>
          <Icon iconType="arrowDown" size='1rem' />
        </HideBtn>
      </FilterSingleInputTitle>
      <SingleInputContainer opened={isFilerOpened}>
        <CustomInput
          placeholder={t(value.placeholder)}
          value={state[key] ?? ''}
          onChange={(e) => handleChange(e)}
          key={`singleInput-${key}`}
        />
      </SingleInputContainer>
    </FilterSingleInputWrapper>
  )
})

FilterSingleInput.displayName = 'FilterSingleInput'
export default FilterSingleInput
