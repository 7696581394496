export const refLinksConfig = [
  {
    name: 'molviewer.literature',
    disabled: false,
    handler: 'literature',
  },
  {
    name: 'molviewer.to_reactions', //убрано до появления реакций
    disabled: true,
    handler: 'reactions',
  },
]
