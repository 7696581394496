import styled from 'styled-components'
import CustomButton from 'components/common/customButton'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #f5f7f7;
`

export const KetcherBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 4.375rem);
  padding-top: 1.5rem;
`

export const TitleBlock = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.text[20]};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: ${({ theme }) => theme.text[24]};
  padding: 0 1.5rem 0 0.75rem;
`

export const SearchBlock = styled.div`
  padding: 1rem 1.5rem 1.5rem 0.75rem;
  display: flex;
  gap: 0.5rem;
`

export const ButtonsBlock = styled.div`
  display: flex;
  column-gap: 0.5rem;
`

export const ButtonNext = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  background: ${({ disabled }) =>
    disabled ? 'rgba(158, 158, 158 ,0.3)' : '#42C1BF'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  border-radius: 0.75rem;
  white-space: nowrap;
  align-items: center;
  column-gap: 0.5rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.168px;
  color: #ffffff;
  transition: all 0.3s;

  &:hover {
    background: ${({ disabled }) =>
      disabled ? 'rgba(158, 158, 158 ,0.3)' : '#3AA9A7'};
  }
`

export const MainBlock = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 400px;
  transition: all 0.5s ease-in;
`

export const TableBlock = styled.div`
  font-size: 14px;
  line-height: 36px;

  display: block;
  max-width: 100%;
  margin-bottom: 12px;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    width: 100%;

    border-spacing: 0;
    border: 0.5px solid #dadee0;
    border-left: none;
    thead {
      background-color: lightblue;
      white-space: nowrap;
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0 12px;
      border-bottom: 1px solid #edeff0;
      border-right: 1px solid #edeff0;

      :last-child {
        border-right: 0;
      }
    }
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const ShowButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

export const Warning = styled.div`
  font-weight: bold;
  font-size: 24px;
  padding: 22px;
`
export const LoadButton = styled(CustomButton)`
  width: 100%;
`
