import React, { Fragment } from 'react'
import { Container, Divider } from './index.style'

import BasketsTopIcon from './BasketsTopIcon'

const BasketsTopToolbar = ({ config }) => {
  return (
    <Container>
      {config.map(({ onClick, disabled, type, icon }, i) => (
        <Fragment key={i}>
          <BasketsTopIcon
            onClick={onClick}
            disabled={disabled}
            type={type}
            icon={icon}
          />
          <Divider />
        </Fragment>
      ))}
    </Container>
  )
}

export default BasketsTopToolbar
