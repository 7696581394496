import { TitleTertiary } from 'components/common/text/index.style'
import HistoryList from '../HistoryList'
import { Column } from '../index.style'
import FilterSection from '../FilterSection'
import { useTranslation } from 'react-i18next'
import './index.css'

const History = ({ history, onRepeatSearch, onShowPastResult }) => {
  const { t } = useTranslation()

  return (
    <>
      <Column className="history-column">
        <TitleTertiary fontWeight={600} color="#1F2933">
          {t('search.search_history.history')}
        </TitleTertiary>
        <FilterSection />
      </Column>
      <HistoryList {...{ history, onRepeatSearch, onShowPastResult }} />
    </>
  )
}
export default History
