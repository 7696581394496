import { INIT_SETTINGS } from 'pages/SynthCost/config/config'
import {
  SYNTHCOST_GET_TASK_INIT,
  SYNTHCOST_GET_TASK_FAIL,
  SYNTHCOST_GET_TASK_SUCCESS,
  SYNTHCOST_GET_RESULT_INIT,
  SYNTHCOST_GET_RESULT_FAIL,
  SYNTHCOST_GET_RESULT_SUCCESS,
  SYNTHCOST_RESET_PREDICTION,
  SYNTHCOST_RESET_SETTINGS,
  SYNTHCOST_SAVE_SETTINGS,
  SYNTHCOST_RESET_TASK,
  SYNTHCOST_RESET_ERROR,
} from 'store/constants/synthcost'

const initialState = {
  task: null,
  data: {},
  loading: false,
  error: '',
  settings: INIT_SETTINGS,
}

const synthCostReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SYNTHCOST_GET_TASK_INIT:
      return {
        ...state,
        task: null,
        data: {},
        error: '',
        loading: true,
      }

    case SYNTHCOST_GET_TASK_SUCCESS:
      return {
        ...state,
        task: payload.task,
      }

    case SYNTHCOST_GET_TASK_FAIL:
      return {
        ...state,
        id: null,
        error: payload.error,
        loading: false,
      }

    case SYNTHCOST_GET_RESULT_INIT:
      return {
        ...state,
        data: {},
      }
    case SYNTHCOST_GET_RESULT_SUCCESS:
      return {
        ...state,
        task: null,
        data: payload.data,
        error: '',
        loading: false,
      }
    case SYNTHCOST_GET_RESULT_FAIL:
      return {
        ...state,
        data: {},
        error: payload.error,
        loading: false,
      }

    case SYNTHCOST_RESET_PREDICTION:
      return {
        ...state,
        data: {},
        error: '',
        task: null,
        loading: false,
      }

    case SYNTHCOST_SAVE_SETTINGS:
      return { ...state, settings: payload.data }

    case SYNTHCOST_RESET_SETTINGS:
      return { ...state, settings: initialState.settings }

    case SYNTHCOST_RESET_TASK:
      return { ...state, task: null, loading: false }

    case SYNTHCOST_RESET_ERROR:
      return { ...state, error: '' }

    default:
      return state
  }
}

export default synthCostReducer
