import React, { useCallback, useState } from 'react'
import Icon from 'components/Icon'
import { CustomDialog } from '../../common/customDialog'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  CardWrapper,
  CardHeader,
  TypeRow,
  SourcesWrapper,
  Source,
  ShowMoreBlock,
} from '../index.style'
import {
  Label,
  TextLarge,
  TitleSecondary,
} from 'components/common/text/index.style'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { setOpenReactionsDialog } from 'store/actions/reactionSearch'
import { useTheme } from 'styled-components'
import { addSimpleNotification } from 'store/actions/notifications'
import CustomButton from 'components/common/customButton'
import { ArrowDownSvg } from 'components/CustomSelect/index.style'

const ReactionSearchDialog = ({ data }) => {
  const { protocol_text, source, is_open_reaction_database } = data?.data || {}
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [showMore, setShowMore] = useState(false)
  const [canBeHidden, setCanBeHidden] = useState(false)

  const litListWrapper = useCallback((node) => {
    if (node !== null) {
      const shouldHide =
        node.getBoundingClientRect().height + node.scrollTop > 56
      setCanBeHidden(shouldHide)
    }
  }, [])

  return (
    <CustomDialog
      width={'50rem'}
      onClose={() => dispatch(setOpenReactionsDialog(false))}
      maxHeight={'38rem'}
      gap={'1rem'}
      overflowY={'scroll'}
      height="none"
      withPortal={true}
    >
      <CardWrapper
        padding="0rem"
        gap="1.5rem"
        isFullMode={true}
        isDialog={true}
      >
        <CardHeader width="max-content">
          <TitleSecondary id={`reactions-card-title-${data.idx}`}>
            {`${t('filter.reaction.method')} ${data?.idx}`}
          </TitleSecondary>
          <SourcesWrapper data-tooltip-id="card-method-sources-tip-modal">
            <Label color={theme.colors.text.tertiary}>
              {t('filter.reaction.sources')}
            </Label>
            <Icon iconType="info" size="1rem" />
          </SourcesWrapper>
          <ReactTooltip
            id="card-method-sources-tip-modal"
            className="c-tooltip c-tooltip-base"
            place="top"
            positionStrategy="fixed"
          >
            {`${t('filter.reaction.material_taken_from_open_sources')}`}
          </ReactTooltip>
        </CardHeader>

        {protocol_text && <TextLarge>{protocol_text}</TextLarge>}
        <TypeRow
          ref={litListWrapper}
          flexWrap="wrap"
          overflow="hidden"
          height={canBeHidden ? (showMore ? 'auto' : '3.5rem') : 'auto'}
        >
          <Source>
            <Label>{t('filter.reaction.lit_link')}:</Label>
            <Label color={!source && theme.colors.statuses.attention}>
              {source ? source : t('filter.reaction.conditions.no_information')}
            </Label>
            {source && (
              <Icon
                className="copy-name-icon"
                iconType="copy"
                size="1rem"
                dataTest="reaction-card-copy-btn"
                onClick={(e) => {
                  e.stopPropagation()
                  navigator.clipboard.writeText(source)
                  dispatch(addSimpleNotification(`notification.DOI_copied`))
                }}
              />
            )}
          </Source>
        </TypeRow>
        {canBeHidden && (
          <ShowMoreBlock left="0">
            <CustomButton
              type="text"
              width="100%"
              gap="0.25rem"
              onClick={() => {
                setShowMore((prev) => !prev)
              }}
            >
              {t(
                `filter.reaction.${
                  showMore ? 'show_less_lit_links' : 'show_more_lit_links'
                }`
              )}

              <ArrowDownSvg iconType="arrowDown" open={showMore} size="1rem" />
            </CustomButton>
          </ShowMoreBlock>
        )}
      </CardWrapper>
    </CustomDialog>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.reactionsSearch.dialog.config,
  }
}

export default connect(mapStateToProps)(ReactionSearchDialog)
