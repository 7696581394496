import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { Headline, TextMedium } from 'components/common/text/index.style'

import {
  Info,
  Wrapper,
  Container,
  ImageContainer,
  NavigationButtons,
  TitleWithDescription,
  ImageContainerWithNavigation,
} from './index.style'
import Indicator from './components/Indicator'
import { DEFAULT_TIME, NAVIGATION_BUTTONS, PROMO_CONFIG } from './config'

const Promo = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  let [active, setActive] = useState(0)
  const [transition, setTransition] = useState(true)

  let timeoutId

  const imagesCount = PROMO_CONFIG.length

  const handleChangeImg = (nextIndex) => {
    if (nextIndex === imagesCount) {
      setTransition(false)
      setActive(0)
    } else if (nextIndex < 0) {
      setTransition(false)
      setActive(imagesCount - 1)
    } else {
      if (!transition) setTransition(true)
      setActive(nextIndex)
    }
  }

  const autoChangeImg = () => {
    handleChangeImg(active + 1)
  }

  const resetTimeout = () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(autoChangeImg, DEFAULT_TIME)
  }

  useEffect(() => {
    resetTimeout()
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <Wrapper>
      <Container>
        <ImageContainerWithNavigation>
          <NavigationButtons>
            {NAVIGATION_BUTTONS.map((el) => (
              <CustomButton
                key={el}
                type="secondary"
                onClick={() => {
                  handleChangeImg(el === 'left' ? active - 1 : active + 1)
                }}
              >
                <Icon
                  size="1rem"
                  iconType={el === 'left' ? 'arrowLeft' : 'arrowRight'}
                />
              </CustomButton>
            ))}
          </NavigationButtons>
          <ImageContainer
            transition={transition}
            transformValue={`translate(-${active * 100}%)`}
          >
            {PROMO_CONFIG.map(({ img_ru, img_en, title }) => (
              <img
                id="promo-image"
                key={img_en}
                src={i18n?.language === 'ru' ? img_ru : img_en}
                alt={title}
              />
            ))}
          </ImageContainer>
        </ImageContainerWithNavigation>
        <Info>
          <Indicator {...{ active }} />
          <TitleWithDescription>
            <Headline
              fontWeight={theme.fontWeight.semibold}
              style={{ margin: 0 }}
              textAlign="center"
            >
              {t(PROMO_CONFIG[active]?.title)}
            </Headline>
            <TextMedium
              fontWeight={theme.fontWeight.light}
              color={theme.colors.text.secondary}
              textAlign="center"
              style={{ margin: 0 }}
            >
              {t(PROMO_CONFIG[active]?.description)}
            </TextMedium>
          </TitleWithDescription>
        </Info>
      </Container>
    </Wrapper>
  )
}

export default Promo
