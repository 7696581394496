import styled from 'styled-components'

export const RangeContainer = styled.div`
  height: ${({ opened }) => (opened ? '2.5rem' : '0px')};
  overflow: ${({ opened }) => (opened ? 'unset' : 'hidden')};
  min-height: ${({ opened }) => (opened ? '2.5rem' : '0px')};
  opacity: ${({ opened }) => (opened ? '1' : '0')};

  transition: all 0.3s;
`

export const FilterRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterRangeTitle = styled.span`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;
`

export const Range = styled.div`
  width: 90%;
  margin: auto;
`

export const RangeSlider = styled.div`
  height: 0.125rem;
  position: relative;
  // background: transparent;
  border-radius: 2px;
`

export const RangeSelected = styled.span`
  height: 100%;
  left: 30%;
  right: 30%;
  position: absolute;
  border-radius: 5px;
  background: ${({ disabled }) => (disabled ? '#B3E6E5' : '#42c1bf')};
`

export const RangeInputWrapper = styled.div`
  position: relative;
`
export const RangeInput = styled.input`
  position: absolute;
  width: 100%;
  height: 0.125rem;
  top: -0.125rem;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:first-child {
    &::-webkit-slider-thumb {
      transform: translateX(-5px);
    }
  }

  &:disabled {
    &::-webkit-slider-thumb {
      background: #b3e6e5;
      cursor: not-allowed;
    }
  }

  &::-webkit-slider-thumb {
    cursor: pointer;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    background: #42c1bf;
    pointer-events: auto;
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    cursor: pointer;
    height: 0.75rem;
    width: 0.75rem;
    padding-right: 0.25rem;
    border-radius: 50%;
    background: #42c1bf;
    pointer-events: auto;
    -moz-appearance: none;
  }
`
export const RangeValues = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f5f7f9;
  border-radius: 0.75rem;
  display: flex;
  justify-content: space-between;
  padding: 0.625rem;

  & > span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
`

export const RangeValuesInput = styled.input`
  width: 30%;
  padding: 0.125rem;
  border: none;
  outline: none;
  background: transparent;
  color: ${({ isChanged }) => (isChanged ? '#2D3745' : '#CED6E3')};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &:last-child {
    text-align: right;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${({theme})=> theme.colors.text.tertiary};
  }
`
