import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import styled, { css, keyframes } from 'styled-components'

const hideAnimation = keyframes`
from {
	opacity: 1;
	right: 1.5rem;
	scale: 1
}
to {
	right: -1.5rem;
	opacity: 0;
	scale: 0.7
}
`

const openAnimation = keyframes`
from {
	right: -1.5rem;
	opacity: 0;
	scale: 0.7
}
to {
	opacity: 1;
	right: 1.5rem;
	scale: 1;
}
`

const hideSimpleAnimation = keyframes`
from {
	opacity: 1;
	top: 0.75rem;
}
to {
	top: -3rem;
	opacity: 0.2;
}
`

const openSimpleAnimation = keyframes`
from {
	top: -3rem;
	opacity: 0.2;
}
to {
	top: 0.75rem;
	opacity: 1
}
`

const openElementAnimation = keyframes`
0% {
	opacity: 0;
     display: none;
      width: 0;
}
50% {	
	opacity: 0;
    display: none;
    width: 0;
}
100% {
    opacity: 1;
    diplay: block;
    width: fit-content;
}
`

const hideElementAnimation = keyframes`
0% {
	opacity: 1;
    diplay: block;
    width: fit-content;
}
20% {
	opacity: 0;
    display: none;
     width: 0;
    }
100% {
    opacity: 0;
    display: none;
    width: 0;
}`

export const SidebarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 100vh;
  padding: 12px;
  transition: width 0.5s ease;

  background-color: #f5f7f7;
`

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  width: ${({ isCollapsed }) => (isCollapsed ? '3.75rem' : '20.5rem')};
  padding: 1.25rem 0.75rem;

  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadow.default};

  background: white;
  z-index: 20;

  transition: width 0.5s;
  position: relative;
`

export const SidebarLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const SidebarBottomLinks = styled(SidebarLinks)`
  gap: 1rem;
  padding: ${({ isCollapsed }) =>
    isCollapsed ? '0rem 0rem' : '0rem 0.375rem'};
  align-items: ${({ isCollapsed }) => (isCollapsed ? 'center' : 'start')};
  transition: all 0.5s;
`

export const LogoWrapper = styled.div`
  padding: ${({ isCollapsed }) =>
    isCollapsed ? '0rem 0rem 1.25rem 0rem' : '0rem 0rem 1.25rem 0.5rem'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ mr }) => (mr ? mr : '0px')};

  width: ${({ width }) => (width ? width : '36px')};
  height: ${({ height }) => (height ? height : '36px')};
  padding: 0.25rem;

  border-radius: 8px;
  z-index: 10;
  background-color: ${({ isHovered, theme, transparent }) => {
    if (transparent) return 'transparent'
    if (isHovered) return `${theme.colors.icons.accentTertiary}`
    return `${theme.colors.backgrounds.secondary}`
  }};
  color: ${({ isHovered, theme, color }) => {
    if (color) return color
    if (isHovered) return theme.colors.icons.accentHover
    if (isHovered === false) return theme.colors.backgrounds.secondary
    return 'inherit'
  }};
  transition: all 0.15s;
  cursor: pointer;

  &:hover {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};s
  }
`

export const RevealWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.25rem;
  height: 2.25rem;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  box-shadow: ${({ theme }) => theme.shadow.default};

  color: ${({ theme }) => theme.colors.icons.tertiary};
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #42c1bf;
  }
`

export const AccentIcon = styled(Icon)`
  color: #42c1bf;
`

export const Element = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${({ gap }) => (gap ? gap : '8px')};

  width: ${({ width }) => (width ? width : '')};
  border-radius: 8px;
  position: relative;
  transition: all 0.5s;
`

export const SidebarElement = styled.a`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  padding: 0.5rem;
  height: 2.25rem;
  width: ${({ isCollapsed }) => (isCollapsed ? '2.25rem' : '100%')};
  border-radius: 8px;
  position: relative;
  color: ${({ selected }) => (selected ? '#42C1BF' : '#6E809D')};
  font-weight: 300;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.backgrounds.secondary : 'none'};
  text-decoration: none;
  min-width: 2.25rem;
  transition: all 0.4s;

  &:hover {
    color: #42c1bf;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  }
`

export const SidebarBottomElement = styled(Element)`
  width: ${({ width }) => (width === 'fit' ? 'fit-content' : '100%')};
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'start')};
  gap: 0.75rem;
  user-select: none;
  transition: all 0.5s;
`

export const UserElement = styled.div`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : '0rem')};
  width: 100%;

  position: relative;
`

export const Label = styled.div`
  width: ${({ width }) => (width ? width : '')};
  font-size: 12px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  line-height: 16px;
  overflow: hidden;
  white-space: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  text-overflow: ellipsis;
  color: ${({ color, isHovered, theme }) => {
    if (color) return color
    if (isHovered) return `${theme.colors.text.accentPrimary}`
    if (isHovered === false) return `${theme.colors.text.primary}`
    return 'inherit'
  }};

  cursor: pointer;
`

export const UserLabel = styled(Label)`
  font-size: 11px;
  font-weight: 300;
  line-height: 12px;
  color: ${({ isHovered, theme }) => {
    if (isHovered) return `${theme.colors.text.accentPrimary}`
    if (isHovered === false) return `${theme.colors.text.secondary}`
    return 'inherit'
  }};
  transition: all 0.15s;
  cursor: pointer;
`

export const StyledHr = styled.hr`
  width: 100%;

  border: none;
  border-top: 1px solid #f1f1f1;
`

export const FeedbackButton = styled.button`
  width: 44px;
  height: 44px;
  padding: 8px;

  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;

  background: #42c1bf;

  border: none;
  border-radius: 12px;
  color: white;

  box-shadow: ${({ theme }) => theme.shadow.accent};
  cursor: pointer;
  z-index: ${({ zIndex }) => zIndex};

  transition: all 0.2s ease-in-out;

  opacity: ${({ visible }) => (visible ? '1' : '0')};
  pointer-events: ${({ visible }) => !visible && 'none'};

  &:hover {
    background: #3aa9a7;
  }
`

export const Notifications = styled.div`
  transition: all 0.5s ease;
  display: flex;
  z-index: 11;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 80px;
  right: ${({ visible }) => (visible ? '1.5rem' : '-1.5rem')};
  width: 44px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  height: 44px;
  padding: 8px;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadow.default};
  background: #fff;
  svg {
    width: 28px;
    height: 28px;
    color: #cbd2d9;
  }

  &:hover {
    svg {
      color: #42c1bf;
    }
  }

  animation: ${({ visible }) => (visible ? openAnimation : hideAnimation)} 0.5s
    forwards;
`
export const SimpleNotification = styled.div`
  transition: all 0.5s ease;
  top: ${({ visible }) => (visible ? '0.75rem' : '-0.75rem')};
  gap: 0.25rem;
  padding: 0.75rem;
  position: absolute;
  left: 50%;
  z-index: 10000;
  transform: translateX(-50%);
  border-radius: 0.75rem;
  backdrop-filter: blur(40px);
  box-shadow: ${({ theme }) => theme.shadow.default};
  pointer-events: none;
  color: ${({ theme }) => theme.colors.text.white};
  background: ${({ theme }) => theme.colors.backgrounds.hint};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  animation: ${({ visible }) =>
      visible ? openSimpleAnimation : hideSimpleAnimation}
    0.5s forwards;
`

export const NotificationsCount = styled.div`
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 28px;
  left: 28px;
  border-radius: 50%;
  padding: 4px;
  background: #fff;
  min-width: 24px;
  box-shadow: ${({ theme }) => theme.shadow.default};
`

export const ItemLinkWrapper = styled.a`
  width: 100%;
  text-decoration: none;
`

export const IconButton = styled(CustomButton)`
  padding: 0.25rem;
  border-radius: 0.25rem;
  position: absolute;
  right: 0.25rem;
  will-change: auto;
  background: ${({ theme }) => theme.colors.buttons.accent};
  color: ${({ theme }) => theme.colors.icons.white};
  ${({ isSidebarCollapsed }) =>
    isSidebarCollapsed &&
    css`
      position: absolute;
      top: 0.375rem;
      right: -1.125rem;
    `};
`

export const SidebarCloseButton = styled.button`
  padding: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.icons.tertiary};
  transition: all 0.1s ease-in-out;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const AnimWrapper = styled.div`
  padding: 0;
  width: ${({ opened }) => (opened ? 'fit-content' : '0')};
  flex: ${({ flex }) => (flex ? flex : '0')};
  animation: ${({ opened }) =>
      opened ? openElementAnimation : hideElementAnimation}
    0.5s;
  will-change: auto;
`

export const SidebarIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
`

export const UserIconButtons = styled.div`
  display: flex;
`
