import { useTranslation } from 'react-i18next'
import { Subheadline } from 'components/common/text/index.style'

import { Wrapper } from './index.style'
import SynthCostTable from '../SynthCostTable'

const AllSchemes = ({ data, allowAnimate }) => {
  const { t } = useTranslation()
  return (
    <Wrapper id="all_schemes">
      {data.map((_, i) => (
        <div key={i}>
          <Subheadline fontWeight="600">
            {t('synthCost.Scheme')} {i + 1}
          </Subheadline>
          <SynthCostTable
            selectedScheme={i}
            savedSchemes={data}
            downloadAllInPdfClicked={true}
            allowAnimate={allowAnimate}
          />
        </div>
      ))}
    </Wrapper>
  )
}

export default AllSchemes
