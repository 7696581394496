import styled from 'styled-components'

export const ListBlock = styled.div`
  display: grid;
  column-gap: 24px;
  row-gap: 8px;
  grid-template-columns: auto auto 1fr;
  padding: 8px;
  align-content: center;
  align-items: center;
`

export const WrapBlock = styled.div`
  display: grid;
  gap: 12px;
  padding: 0 12px;
  margin: 12px 0;
  width: 100%;
`

export const WithDelBlock = styled.div`
  display: grid;
  column-gap: 8px;
  grid-template-columns: 32px auto;
  align-content: center;
  align-items: center;
  margin-top: -24px;
`
export const InputWithButton = styled.div`
  gap: 0.75rem;
  display: flex;
  align-items: flex-end;
`
