import { v4 as uuidv4 } from 'uuid'
import Latex from 'react-latex-next'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CustomDialog } from 'components/common/customDialog'
import { Subheadline } from 'components/common/text/index.style'

import { AuthorsDialogHeader } from './index.style'

const AuthorsDialog = ({ setDialogOpen, docData }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <CustomDialog
      onClose={() => setDialogOpen(false)}
      width="24rem"
      maxHeight="22.25rem"
      padding="1.5rem"
      gap="0.75rem"
      overflowY="scroll"
      withPortal={true}
    >
      <AuthorsDialogHeader
        color={theme.colors.text.primary}
        fontWeight={theme.fontWeight.semibold}
      >
        {t(`literatureCard.${docData.type}_authors`)} {'"'}
        <Latex>
          {docData?.title
            ? String.raw({ raw: docData?.title }).replaceAll(/\\/g, '\\')
            : ''}
        </Latex>
        {'"'}
      </AuthorsDialogHeader>

      <Subheadline
        key={uuidv4()}
        color={theme.colors.text.primary}
        fontWeight={theme.fontWeight.light}
      >
        {docData.authors.join(', ')}
      </Subheadline>
    </CustomDialog>
  )
}

export default AuthorsDialog
