import styled from 'styled-components'

export const Content = styled.div`
  gap: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const InfoBlock = styled.div`
  gap: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
