import {
  PDF2SMILES_GET_DOCUMENT_ERROR,
  PDF2SMILES_GET_DOCUMENT_INIT,
  PDF2SMILES_GET_DOCUMENT_SUCCESS,
  PDF2SMILES_IS_DOCUMENT_HEADER_COLLAPSED,
  PDF2SMILES_SET_CURRENT_HIGHLIGHT_ID,
  PDF2SMILES_SET_CURRENT_PAGE,
  PDF2SMILES_SET_NEED_UPDATE_DOCUMENTS,
  PDF2SMILES_SET_SELECTED_HIGHLIGHTS,
  PDF2SMILES_SET_SELECTED_SMILES,
  PDF2SMILES_UPDATE_MARKUP,
  TOGGLE_SHOW_DIALOG,
} from 'store/constants/pdf2smiles'

export const initialState = {
  currentPage: 1,
  currentHighlightId: '',
  isDocumentHeaderCollapsed: false,
  loading: false,
  error: false,
  filename: '',
  file_url: '',
  markup: [],
  needUpdateDocuments: true,
  selectedHighlights: [],
  showDialog: false,
  selectedSmiles: '',
}

const pdf2SmilesReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case PDF2SMILES_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload.currentPage,
      }

    case PDF2SMILES_SET_CURRENT_HIGHLIGHT_ID:
      return {
        ...state,
        currentHighlightId: payload.currentHighlightId,
      }

    case PDF2SMILES_IS_DOCUMENT_HEADER_COLLAPSED: {
      return {
        ...state,
        isDocumentHeaderCollapsed: payload.isDocumentHeaderCollapsed,
      }
    }

    case PDF2SMILES_UPDATE_MARKUP:
      return {
        ...state,
        markup: payload.markup,
      }

    case PDF2SMILES_GET_DOCUMENT_INIT: {
      return {
        ...initialState,
        loading: true,
      }
    }

    case PDF2SMILES_GET_DOCUMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        filename: payload.filename,
        file_url: payload.file_url,
        markup: payload.markup.filter(
          ({ structure }) =>
            Array.isArray(structure?.smiles) && structure.smiles.length > 0
        ),
      }
    }

    case PDF2SMILES_GET_DOCUMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case PDF2SMILES_SET_NEED_UPDATE_DOCUMENTS: {
      return {
        ...state,
        needUpdateDocuments: payload.needUpdateDocuments,
      }
    }
    case TOGGLE_SHOW_DIALOG: {
      const id = payload.id ?? null
      return {
        ...state,
        showDialog: id,
      }
    }

    case PDF2SMILES_SET_SELECTED_HIGHLIGHTS: {
      return {
        ...state,
        selectedHighlights: payload.selectedHighlights,
      }
    }

    case PDF2SMILES_SET_SELECTED_SMILES: {
      return {
        ...state,
        selectedSmiles: payload.selectedSmiles,
      }
    }

    default:
      return state
  }
}

export default pdf2SmilesReducer
