import { UPDATE_TASKS, REMOVE_TASK } from '../constants/tasks'

const initialState = []

const tasksReducer = (state = initialState, action) => {
  let newState
  switch (action.type) {
    case REMOVE_TASK:
      newState = state.filter((task) => task.id !== action.id)
      return newState

    case UPDATE_TASKS:
      return action.tasks

    default:
      return state
  }
}

export default tasksReducer
