import { default as styled } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0 1.5rem 0;
`

export const PropertyItemWrapper = styled.div`
  width: 100%;
  height: 2.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const ButtonActions = styled.div`
  width: 100%;
  gap: 0.75rem;
  display: flex;
`
