import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import SkeletonComponent from 'components/common/skeleton'
import { Caption } from 'components/common/text/index.style'
import { formatUtcDateWithTimeZone } from 'utils/dateTime/formatUtcDate'

import {
  APPLICATIONS_BLOCKS_WIDTH,
  APPLICATIONS_HEADER_BLOCKS_TEXT,
} from '../config'
import {
  HeaderItem,
  CardsHeader,
  UsersSkeletonWrapper,
} from '../customers/index.style'
import UserCard from '../userCard'
import { UsersBlock, ApplicationsWrapper } from './index.style'

const Applications = ({
  pagination,
  applications,
  getApplicationsError,
  getApplicationsLoading,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <ApplicationsWrapper id="applicationsContainer">
      {getApplicationsLoading && (
        <UsersSkeletonWrapper>
          {Array.from({ length: 12 }).map((_, i) => (
            <SkeletonComponent
              height="5.5rem"
              width="100%"
              variant="dark"
              key={`skeleton-index-${i}`}
            />
          ))}
        </UsersSkeletonWrapper>
      )}
      {!getApplicationsLoading &&
        !getApplicationsError &&
        (applications.length > 0 ? (
          <>
            <CardsHeader>
              {APPLICATIONS_BLOCKS_WIDTH.map((el, i) => (
                <HeaderItem
                  width={el.value}
                  key={uuidv4()}
                  justifyContent={el.justify}
                >
                  <Caption
                    color={theme.colors.text.secondary}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {t(APPLICATIONS_HEADER_BLOCKS_TEXT[i])}
                  </Caption>
                </HeaderItem>
              ))}
            </CardsHeader>
            <UsersBlock
              marginBottom={pagination.pagesAmount > 1 ? '4.25rem' : '0.75rem'}
            >
              {applications.map(
                ({
                  id,
                  created_at,
                  created_by_name: name,
                  created_by_role: role,
                  created_by_id: user_id,
                  is_handled: isApproved,
                  created_by_email: email,
                  created_by_phone: phone,
                  created_by_company: company,
                  created_by_surname: surname,
                  created_by_position: position,
                }) => {
                  const modifiedDate = formatUtcDateWithTimeZone(created_at)
                  const date = format(modifiedDate, 'dd.MM.yyyy')
                  const time = format(modifiedDate, 'HH:mm')

                  return (
                    <UserCard
                      card={{
                        id,
                        name,
                        role,
                        email,
                        phone,
                        surname,
                        company,
                        user_id,
                        position,
                        isApproved,
                        created_date: date,
                        created_time: time,
                      }}
                      key={id}
                      isApplication
                      role="syn_admin"
                    />
                  )
                }
              )}
            </UsersBlock>
          </>
        ) : (
          <div style={{ marginTop: '1rem' }}>{t('admin.no_applications')}</div>
        ))}
    </ApplicationsWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    applications: state.adminPanel.applications.items,
    getApplicationsLoading: state.adminPanel.applications.loading,
    getApplicationsError: state.adminPanel.applications.error,
    pagination: state.adminPanel.applications.pagination,
  }
}

export default connect(mapStateToProps)(Applications)
