import { useTranslation } from 'react-i18next'

import ruLogoImg from '../assets/logo_ru.svg'
import enLogoImg from '../assets/logo_en.svg'

export const useGetLogoImage = () => {
  const { i18n } = useTranslation()

  return i18n.language === 'en' ? enLogoImg : ruLogoImg
}
