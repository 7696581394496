import { connect } from 'react-redux'

import {
  resetCalculations,
  addMoleculeToReaction,
  editMoleculeInReaction,
} from 'store/actions/reactions'
import { setSearchText } from 'store/actions/search'
import RetrosynthViewer from 'components/RetrosynthViewer'
import { removeAllMoleculesFromReaction } from 'store/actions/reactions'
import MainSearchBar from 'components/main-search-bar/main-search-bar'

const Retrosynthesis = ({
  list,
  reaction,
  onCalcReaction,
  resetCalculations,
  editMoleculeInReaction,
  removeAllMoleculesFromReaction,
}) => {
  const { calculation } = reaction

  const handleChange = (smiles) => {
    calculation && resetCalculations()
    smiles
      ? editMoleculeInReaction(0, smiles || '')
      : removeAllMoleculesFromReaction()
  }

  return (
    <>
      <MainSearchBar
        inputValue={list[0] || ''}
        onResetSearch={handleChange}
        onSearchClick={onCalcReaction}
        onInputChange={handleChange}
        onSelectOption={handleChange}
      />
      {calculation?.length > 0 && <RetrosynthViewer {...{ calculation }} />}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    list: state.reactions.list,
    text: state.search.searchText,
    reaction: state.reactions,
  }
}

const mapDispatchToProps = {
  setSearchText,
  resetCalculations,
  addMoleculeToReaction,
  editMoleculeInReaction,
  removeAllMoleculesFromReaction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Retrosynthesis)
