export const LOAD_CATEGORIES_INIT = 'LOAD_CATEGORIES_INIT'
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS'
export const LOAD_CATEGORIES_ERROR = 'LOAD_CATEGORIES_ERROR'
export const SET_BLUR_NON_APPLICABLE = 'SET_BLUR_NON_APPLICABLE'

export const LOAD_SERVICES_INIT = 'LOAD_SERVICES_INIT'
export const LOAD_SERVICES_SUCCESS = 'LOAD_SERVICES_SUCCESS'
export const LOAD_SERVICES_ERROR = 'LOAD_SERVICES_ERROR'

export const LOAD_MOLECULE_PROPS_INIT = 'LOAD_MOLECULE_PROPS_INIT'
export const LOAD_MOLECULE_PROPS_SUCCESS = 'LOAD_MOLECULE_PROPS_SUCCESS'
export const LOAD_MOLECULE_PROPS_ERROR = 'LOAD_MOLECULE_PROPS_ERROR'
export const RESET_MOLECULE_PROPS = 'RESET_MOLECULE_PROPS'

export const UPDATE_MOLECULE_PROPS_INIT = 'UPDATE_MOLECULE_PROPS_INIT'
export const UPDATE_MOLECULE_PROPS_SUCCESS = 'UPDATE_MOLECULE_PROPS_SUCCESS'
export const UPDATE_MOLECULE_PROPS_ERROR = 'UPDATE_MOLECULE_PROPS_ERROR'
export const UPDATE_MOLECULE_PROPS_FINISHED = 'UPDATE_MOLECULE_PROPS_FINISHED'

export const LOAD_RU_IUPAC_INIT = 'LOAD_RU_IUPAC_INIT'
export const LOAD_RU_IUPAC_SUCCESS = 'LOAD_RU_IUPAC_SUCCESS'
export const LOAD_RU_IUPAC_ERROR = 'LOAD_RU_IUPAC_ERROR'

export const RESET_RU_IUPAC = 'RESET_RU_IUPAC'
export const TOGGLE_APPLICABILITY = 'TOGGLE_APPLICABILITY'

export const LOAD_APPLICABILITY_INIT = 'LOAD_APPLICABILITY_INIT'
export const LOAD_APPLICABILITY_SUCCESS = 'LOAD_APPLICABILITY_SUCCESS'

export const OPEN_ADD_MOLECULE_DIALOG = 'OPEN_ADD_MOLECULE_DIALOG'
export const CLOSE_ADD_MOLECULE_DIALOG = 'CLOSE_ADD_MOLECULE_DIALOG'
export const SET_EDITOR_INDIVIDUAL_ID = 'SET_EDITOR_INDIVIDUAL_ID'

export const EDIT_MOLECULE_NOTE = 'EDIT_MOLECULE_NOTE'
export const SET_MOLECULE_NOTE_DIALOG_ID = 'SET_MOLECULE_NOTE_DIALOG_ID'
export const UPDATE_MOLECULE_NOTE_CONFIG = 'UPDATE_MOLECULE_NOTE_CONFIG'
export const RESET_NOTE_CONFIG = 'RESET_NOTE_CONFIG'

export const LOAD_PERSONAL_PROPERTIES_ERROR = 'LOAD_PERSONAL_PROPERTIES_ERROR'
export const LOAD_PERSONAL_PROPERTIES_INIT = 'LOAD_PERSONAL_PROPERTIES_INIT'
export const LOAD_PERSONAL_PROPERTIES_SUCCESS =
  'LOAD_PERSONAL_PROPERTIES_SUCCESS'
export const UPDATE_PERSONAL_PROPERTIES_ERROR =
  'UPDATE_PERSONAL_PROPERTIES_ERROR'
export const UPDATE_PERSONAL_PROPERTIES_INIT = 'UPDATE_PERSONAL_PROPERTIES_INIT'
export const UPDATE_PERSONAL_PROPERTIES_SUCCESS =
  'UPDATE_PERSONAL_PROPERTIES_SUCCESS'
export const TOGGLE_PERSONAL_PROPERTIES_WINDOW =
  'TOGGLE_PERSONAL_PROPERTIES_WINDOW'
