import { GET_SURVEYS_INIT } from 'store/constants/survey'

export const getSurvey = (onRegistration, offset, limit) => {
  return {
    type: GET_SURVEYS_INIT,
    onRegistration,
    offset,
    limit,
  }
}
