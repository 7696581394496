import styled from 'styled-components'

export const Buttons = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  div {
    height: ${({ btnSize }) => btnSize / 2}rem;
    border-radius: ${({ btnBorderRadius }) =>
      `${btnBorderRadius} ${btnBorderRadius}  0 0`};

    background: ${({ theme, withWhiteBackground }) =>
      withWhiteBackground
        ? theme.colors.backgrounds.secondary
        : theme.colors.backgrounds.primary};
  }

  div:nth-child(2) {
    border-radius: ${({ btnBorderRadius }) =>
      `0 0 ${btnBorderRadius}  ${btnBorderRadius} `};
  }
`
