import { connect } from 'react-redux'
import { Container, Content, SideBar, BackgroundBlur } from './index.style'
import SideBarView from '../SideBarView'
import RoutesList from '../../routers'
import ConfirmationDialog from '../ConfirmationDialog'
import MobileInDev from '../MobileInDev'
import Notifications from 'components/Notifications'
import { useAuth } from 'utils/auth/auth'
import { useLocation } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { setStorage } from 'utils/storage/storage'

const ViewContainer = ({ showConfirmation, showBlur }) => {
  const { isAuthenticated } = useAuth()

  const useQuery = () => {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()

  useEffect(() => {
    const shouldOpenFeedback = query.get('feedback')
    if (shouldOpenFeedback) {
      setStorage('shouldOpenFeedback', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container>
        <SideBar>
          <SideBarView />
        </SideBar>
        <Content>
          <RoutesList />
        </Content>
      </Container>
      {showConfirmation && <ConfirmationDialog />}
      {showBlur && <BackgroundBlur />}
      <Notifications />
      <MobileInDev />
    </>
  )
}

const mapStateToProps = (state) => ({
  showConfirmation: state.confirmation.show,
  showBlur: state.blur.showBlur,
})

export default connect(mapStateToProps, null)(ViewContainer)
