import { memo } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import { Caption } from 'components/common/text/index.style'
import CustomTag from 'components/common/customTag'

const IdBlock = memo(
  ({
    num,
    noIdText,
    noIdDescription,
    type = 'grey',
    withTooltip = true,
    isMolIndividualPage,
    isDialog,
    isBestMatch,
  }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const text = noIdText || t('molecule_viewer.molecule_generated')
    const desc =
      noIdDescription || t('molecule_viewer.molecule_generated_description')

    return (
      <>
        {!isDialog && (
          <CustomTag
            data-tooltip-id={num ? '' : 'id'}
            num={num}
            type={num ? type : 'accent'}
            data-test="molecule-card-btn-id-div"
            withTooltip={withTooltip}
          >
            {!isMolIndividualPage && (
              <Caption
                color={
                  num ? theme.colors.text.primary : theme.colors.text.white
                }
                lineHeight={theme.text[14]}
              >
                {num ? `ID: ${num}` : text}
              </Caption>
            )}
            {!num && !isBestMatch && <Icon iconType="magic" size={'1rem'} />}
          </CustomTag>
        )}
        {withTooltip && (
          <ReactTooltip
            id="id"
            className="c-tooltip c-tooltip-base c-tooltip-molviewer"
            offset={10}
            place="bottom-start"
            classNameArrow="c-tooltip-arrow"
          >
            {desc}
          </ReactTooltip>
        )}
      </>
    )
  }
)

IdBlock.displayName = 'IdBlock'
export default IdBlock
