export const listViewOptions = {
  left: {
    title: 'left',
    value: 'left',
    icon: 'smallBlocks',
  },
  center: {
    title: 'center',
    value: 'center',
    icon: 'mediumBlocks',
  },
  right: {
    title: 'right',
    value: 'right',
    icon: 'largeBlocks',
  },
}

export const valueSelector = {
  left: 1,
  center: 3,
  right: 6,
}
