import styled from 'styled-components'

export const Wrapper = styled.div`
  gap: 1.25rem;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1.25rem 0;
  margin-top: 0.75rem;
  border-radius: 0.75rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
`
export const TopBlock = styled.div`
  gap: 0.75rem;
  display: flex;
  padding: 0 1.25rem;
  flex-direction: column;
`

export const TabsWithRightPanel = styled.div`
  gap: 0.75rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`
export const Tabs = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
`
export const Tab = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0.375rem 0.625rem;
  color: ${({ theme, active }) =>
    active ? theme.colors.text.white : theme.colors.text.secondary};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.buttons.accent : theme.colors.buttons.secondary};
`

export const Tag = styled.div`
  gap: 0.25rem;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.375rem 0.5rem;
  font-size: ${({ theme }) => theme.text[11]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[14]};
  background-color: ${({ theme }) => theme.colors.buttons.secondary};
  color: ${({ theme, color }) => (color ? color : theme.colors.text.primary)};
  cursor: ${({ withInfo }) => (withInfo ? 'pointer' : 'default')};
  & > svg {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`

export const RightPanel = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
