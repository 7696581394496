import styled from 'styled-components'

export const SearchList = styled.div`
  gap: 16px;
  display: ${({ twoRows }) => (twoRows ? 'grid' : 'flex')};
  grid-template-columns: ${({ twoRows }) => twoRows && '1fr 1fr'};
  flex-wrap: ${({ twoRows }) => !twoRows && 'wrap'};
  flex-direction: ${({ twoRows }) => !twoRows && 'column'};
  padding-bottom: ${({ showPagination }) => (showPagination ? '80px' : '12px')};
`

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
