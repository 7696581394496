import {
  SET_REACTIONS_DIALOG_CONFIG,
  SET_OPEN_REACTIONS_DIALOG,
  SET_REACTIONS_COMPOUND_ID,
} from 'store/constants/searchReactions'

const initialState = {
  dialog: { open: false, config: {} },
  compound_id: null,
}

const reactionsSearchReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SET_OPEN_REACTIONS_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: payload.isOpen,
        },
      }
    case SET_REACTIONS_DIALOG_CONFIG:
      return {
        ...state,
        dialog: { ...state.dialog, config: { ...payload.config } },
      }
    case SET_REACTIONS_COMPOUND_ID:
      return {
        ...state,
        compound_id: payload.compound_id,
      }
    default:
      return state
  }
}

export default reactionsSearchReducer
