import styled from 'styled-components'

const tablet = 769

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;

  @media (max-width: ${tablet}px) {
    display: none;
  }

  @media print {
    display: block;
  }
`

export const SideBar = styled.div`
  display: flex;
  grid-area: sidebar;
  @media print {
    display: none;
  }
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-height: 100vh;
  background-color: #f5f7f7;
  overflow-y: hidden;
  grid-area: content;
`

export const BackgroundBlur = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.blur};
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
`
