import styled from 'styled-components'

export const ServicesListBlock = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto 70px 70px;
  padding: 8px 8px;
  column-gap: 15px;
  row-gap: 8px;
  align-items: center;
  border: 1px solid lightblue;
  border-radius: 5px;
  margin-bottom: 12px;
`

export const DialogContentWrapper = styled.div`
  display: grid;
  gap: 1.25rem;
  width: 100%;
`
export const DialogActions = styled.div`
  gap: 0.5rem;
  display: flex;
  margin-left: auto;
`
