import React, { useCallback, useEffect, useState } from 'react'
import { fetch } from '../../services/rest'
import {
  ListBlock,
  ReactionItem,
  ListContainer,
  ListBlockItem,
} from './index.style'
import MoleculeViewer from '../MoleculeViewer'
import LoadingOverlay from '../LoadingOverlay'
import { formatUtcDigitDate } from 'utils/dateTime/formatUtcDate'
import CustomScrollbar from 'components/CustomScrollbar'

const AdminLogView = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)

  const [paginationStep, setPaginationStep] = useState(1)

  const loadData = useCallback(
    async (offset = 0, limit = 100) => {
      try {
        const response = await fetch(
          `/admin/log?offset=${offset}&limit=${limit}`
        )
        setList([...list, ...response.data])
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    [list]
  )

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop
    const scrollHeight = e.target.scrollHeight
    const clientHeight = e.target.clientHeight
    const isInTheBottom = scrollHeight - scrollTop - clientHeight === 0

    if (isInTheBottom) {
      setLoading(true)
      loadData(paginationStep * 100)
      setPaginationStep(paginationStep + 1)
    }
  }

  useEffect(() => {
    if (!list.length) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ListContainer>
      <CustomScrollbar className='margin-4-right'>
        <ListBlock onScroll={handleScroll}>
          {loading && <LoadingOverlay />}
          {list.map(({ id, source, query, date, ip }) => {
            return (
              <ListBlockItem key={id} id={id}>
                <div>{formatUtcDigitDate(new Date(date))}</div>
                <div>{source}</div>
                <div>{ip}</div>

                {source === 'reactions' ? (
                  <ReactionItem>{query}</ReactionItem>
                ) : (
                  <MoleculeViewer
                    num={id}
                    size={-1}
                    smiles={query}
                    handleClick={() => {}}
                    onShowProps={() => {}}
                  />
                )}
              </ListBlockItem>
            )
          })}
        </ListBlock>
      </CustomScrollbar>
    </ListContainer>
  )
}

export default AdminLogView
