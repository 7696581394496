import React, { memo } from 'react'
import {
  ChartContainer,
  ChartHeader,
  ChartLegend,
  ChartWrapper,
  Selectors,
} from '../index.style'
import SkeletonComponent from 'components/common/skeleton'
import { Separator, Wrapper } from '../PrevPeriodStatistics/index.style'

const ChartSkeleton = memo(({ selectedChart, showPeriodComparison }) => {
  return (
    <ChartWrapper justify="center">
      <ChartHeader>
        <SkeletonComponent height="100%" width="15rem" borderRadius="0.5rem" />
        <SkeletonComponent height="100%" width="8.5rem" borderRadius="0.5rem" />
      </ChartHeader>
      {showPeriodComparison && (
        <Wrapper>
          {[...new Array(3)].map((_, index) => (
            <>
              <SkeletonComponent
                height="100%"
                width="6.5rem"
                borderRadius="0.5rem"
                key={index}
              />
              {index < 2 && <Separator />}
            </>
          ))}
        </Wrapper>
      )}
      {selectedChart === 3 && (
        <Selectors>
          {[...new Array(4)].map((_, index) => (
            <SkeletonComponent
              height="1.75rem"
              width="10rem"
              borderRadius="0.5rem"
              key={index}
            />
          ))}
        </Selectors>
      )}
      <ChartContainer>
        <SkeletonComponent height="100%" width="100%" borderRadius="0.5rem" />
      </ChartContainer>
      <ChartLegend>
        {[...new Array(2)].map((_, index) => (
          <SkeletonComponent
            key={index}
            height="0.75rem"
            width="10rem"
            borderRadius="0.5rem"
          />
        ))}
      </ChartLegend>
    </ChartWrapper>
  )
})

ChartSkeleton.displayName = 'ChartSkeleton'

export default ChartSkeleton
