export const getMoleculeEditErrorText = (message) => {
  switch (message) {
    case 'Given smiles already exists at selected basket composition id':
      return 'molecule_viewer.molecule_edit.smiles_already_exists'
    case 'Given structure already exists at selected place':
      return 'molecule_viewer.molecule_edit.structure_already_exists'
    case 'User is not basket owner':
      return 'molecule_viewer.molecule_edit.user_is_not_basket_owner'
    case 'Molecule with same smiles already exists in basket':
      return 'molecule_viewer.molecule_edit.molecule_already_exists'
    case `Could not process structure's smiles`:
      return 'pdf2smiles.viewer.invalid_smiles'
    default:
      return 'molecule_viewer.molecule_edit.failed_to_edit_molecule'
  }
}
