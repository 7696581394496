import debounce from 'lodash.debounce'
import { useTheme } from 'styled-components'
import React, { memo, useEffect, useState } from 'react'

import Icon from 'components/Icon'

import { getTitle } from './utils/utils'
import { ExpandButton, NotificationWarningText } from './index.style'

const NotificationText = memo(({ item, t }) => {
  const { text, params, needTranslateText = true, translationTextParams } = item

  const textRef = React.createRef()
  const theme = useTheme()

  const [isTextExpanded, setIsTextExpanded] = useState(null)
  const [showExpandButton, setShowExpandButton] = useState(false)

  useEffect(() => {
    const container = textRef?.current

    const getHeight = () => {
      if (container && container.scrollHeight > container.clientHeight) {
        setShowExpandButton(true)
      }
    }
    const debouncedLoadHeight = debounce(getHeight, 100)
    debouncedLoadHeight()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textRef])

  const { start_text, finish_text } = getTitle(params?.smiles?.length, text)

  return (
    <>
      {item?.params?.type === 'createMolecule' ? (
        <NotificationWarningText ref={textRef} isTextExpanded={isTextExpanded}>
          {start_text}{' '}
          {params.smiles.map((el, ind, arr) => (
            <React.Fragment key={ind}>
              <span style={{ color: theme.colors.statuses.link }}>
                {el.smiles}
              </span>{' '}
              {ind === arr.length - 1 ? ' ' : ', '}
            </React.Fragment>
          ))}
          {finish_text}
        </NotificationWarningText>
      ) : (
        <NotificationWarningText>
          {needTranslateText ? t(text, translationTextParams) : text}
        </NotificationWarningText>
      )}
      {showExpandButton && (
        <ExpandButton
          type="text"
          onClick={() => setIsTextExpanded((prev) => !prev)}
        >
          {isTextExpanded
            ? t('notification.less_information')
            : t('notification.more_information')}
          <Icon
            iconType={isTextExpanded ? 'arrowTop' : 'arrowDown'}
            size="1rem"
          />
        </ExpandButton>
      )}
    </>
  )
})

NotificationText.displayName = 'NotificationText'
export default NotificationText
