import { useTheme } from 'styled-components'

import Icon from 'components/Icon'
import { Caption } from 'components/common/text/index.style'

import PhoneNumber from '../../PhoneNumber'
import { InfoRow, InfoSection } from './index.style'

const InfoLine = ({ info, onCopy }) => {
  const theme = useTheme()

  return (
    <InfoRow>
      {info.map(({ icon, title, needCopy }) => (
        <InfoSection key={icon}>
          <Icon iconType={icon} size="1rem" />
          {icon === 'phone' && title ? (
            <PhoneNumber value={title} withCaption={true} />
          ) : (
            <Caption
              ellipsis
              lineHeight={theme.text[14]}
              fontWeight={theme.fontWeight.medium}
            >
              {title || '—'}
            </Caption>
          )}

          {needCopy && !!title && (
            <Icon
              iconType="copy"
              size="1rem"
              onClick={() => onCopy(icon, title)}
            />
          )}
        </InfoSection>
      ))}
    </InfoRow>
  )
}

export default InfoLine
