export const TextComparator = (a, b) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export const NumberComparator = (a, b) => {
  if (Number(a) < Number(b)) {
    return -1
  }
  if (Number(a) > Number(b)) {
    return 1
  }
  return 0
}
