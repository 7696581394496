import { INITIAL_OUTPUT } from 'pages/SynthCost'

export const getOutputValue = (value) => {
  if (!value || value.startsWith('0')) return ''
  return !/^(100|([1-9]?\d(\.\d{0,2})?))$/.test(value)
    ? value.slice(0, -1)
    : value
}

export const getFinalNum = (initial, outputRatio) => {
  if (!initial && !outputRatio) return '0.00'

  if (initial / outputRatio === Infinity) return '0.00'

  if (isNaN((Number(initial) / outputRatio).toString())) return '0.00'
  else return (Number(initial) / outputRatio).toString()
}

export const getInitial = (stages, initial, newOutput, idx) => {
  let res = initial

  stages.forEach((el, elIdx) => {
    if (!+newOutput) {
      if (elIdx === idx) res = '0.00'
      return
    } else if (elIdx === idx) {
      res = res / (newOutput / INITIAL_OUTPUT)
    } else res = res / (el.output / INITIAL_OUTPUT)
  })
  return res
}

export const getUpdatedTable = (
  currentTableSchemes,
  selectedScheme,
  defaultValues,
  newOutput,
  idx
) => {
  const updated = currentTableSchemes.map((scheme, schemeIdx) => {
    if (schemeIdx !== selectedScheme) return scheme
    else {
      let outputRatio = Number(newOutput) / INITIAL_OUTPUT

      let updatedScheme = []
      let i = scheme.length - 1

      while (i >= 0) {
        let stage = scheme[i]
        const currentRatio = Number(stage.output) / INITIAL_OUTPUT
        if (i !== idx) outputRatio = currentRatio * outputRatio
        if (i > idx) updatedScheme.push(scheme[i])
        else {
          let newData = {
            output: i === idx ? newOutput : stage.output,
            // eslint-disable-next-line no-loop-func
            stages: stage.stages.map((reagent, stageIdx) => {
              const initialWeight =
                defaultValues[selectedScheme][i].stages[stageIdx]?.weight
              const initialPrice =
                defaultValues[selectedScheme][i].stages[stageIdx]?.price

              return {
                ...reagent,
                price: getFinalNum(initialPrice, outputRatio),
                weight: getFinalNum(initialWeight, outputRatio),
              }
            }),
          }

          updatedScheme.push(newData)
        }
        i--
      }
      const schemesForRender = updatedScheme.reverse()
      return schemesForRender
    }
  })

  return updated
}

export const calculateDefaultValues = (savedSchemes, selectedScheme) => {
  const updated = savedSchemes.map((scheme, schemeIdx) => {
    if (schemeIdx !== selectedScheme) return scheme
    else {
      let updatedScheme = []
      let i = scheme.length - 1
      let outputRatio = Number(scheme[i].output) / INITIAL_OUTPUT

      while (i >= 0) {
        let stage = scheme[i]
        const currentRatio = Number(stage.output) / INITIAL_OUTPUT
        if (i !== scheme.length - 1) outputRatio = currentRatio * outputRatio
        let newData = {
          output: '100',
          // eslint-disable-next-line no-loop-func
          stages: stage.stages.map((reagent) => ({
            ...reagent,
            price: Number(reagent.price) * outputRatio,
            weight: Number(reagent?.weight) * outputRatio,
          })),
        }

        updatedScheme.push(newData)
        i--
      }
      const schemesForRender = updatedScheme.reverse()
      return schemesForRender
    }
  })
  return updated
}
