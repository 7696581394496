import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { TextBody, TitleTertiary } from 'components/common/text/index.style'

import { NotFoundBlock, NotFoundButton } from './index.style'

const NotFoundInfo = ({ searchV2, openIndividual }) => {
  const { t } = useTranslation()
  return (
    <NotFoundBlock>
      <TitleTertiary color="#2D3745" fontWeight="600">
        {t('moleculeslist.request_not_found')} {searchV2.result.label}{' '}
      </TitleTertiary>
      <TextBody color="#6E809D" fontWeight="300">
        {t('moleculeslist.see_molecule_props')}
      </TextBody>
      <NotFoundButton onClick={openIndividual}>
        <TextBody color="#2D3745">{t('moleculeslist.open_editor')}</TextBody>
        <Icon iconType="arrowRight" size={'1.25rem'} />
      </NotFoundButton>
    </NotFoundBlock>
  )
}

const mapStateToProps = (state) => {
  return {
    searchV2: state.crud.searchV2,
  }
}

export default connect(mapStateToProps)(NotFoundInfo)
