import {
  REPEAT_SEARCH,
  GET_HISTORY_INIT,
  SHOW_PAST_RESULT,
  RESET_PAST_RESULT,
  SET_HISTORY_OPEN,
  SET_HISTORY_FILTER,
} from 'store/constants/searchHistory'

export const getSearchHistory = ({ offset, limit }) => {
  return {
    type: GET_HISTORY_INIT,
    offset,
    limit,
  }
}

export const repeatSearch = (data, showPastResult) => {
  return {
    data,
    showPastResult,
    type: REPEAT_SEARCH,
  }
}

export const showPastResult = (data) => {
  return {
    data,
    type: SHOW_PAST_RESULT,
  }
}

export const resetPastResult = () => {
  return {
    type: RESET_PAST_RESULT,
  }
}

export const openSearchHistory = (isOpen) => {
  return {
    isOpen,
    type: SET_HISTORY_OPEN,
  }
}

export const setHistoryFilter = (filterType) => {
  return {
    filterType,
    type: SET_HISTORY_FILTER,
  }
}
