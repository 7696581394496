import {
  ADD_NOTIFICATION,
  HIDE_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  ADD_SIMPLE_NOTIFICATION,
  HIDE_SIMPLE_NOTIFICATION,
  REMOVE_SIMPLE_NOTIFICATION,
} from '../constants/notifications'

export const addNotification = (task) => ({
  type: ADD_NOTIFICATION,
  task,
})

export const removeNotification = (id) => ({
  type: REMOVE_NOTIFICATION,
  id,
})

export const hideNotifications = () => ({
  type: HIDE_NOTIFICATIONS,
})

export const addSimpleNotification = (text) => ({
  type: ADD_SIMPLE_NOTIFICATION,
  text,
})

export const hideSimpleNotification = () => ({
  type: HIDE_SIMPLE_NOTIFICATION,
})

export const removeSimpleNotification = () => ({
  type: REMOVE_SIMPLE_NOTIFICATION,
})
