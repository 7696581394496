import reactReplace from 'react-string-replace'

export const getMainId = (id, match, smiles) => {
  if (smiles) return smiles
  if (match?.params?.smiles) return decodeURIComponent(match.params.smiles)
  return id
}

export const refButtonsConfig = [
  {
    name: 'molviewer.literature',
    disabled: false,
    handler: 'literature',
  },
  {
    name: 'molviewer.to_reactions',
    disabled: true,
    handler: 'reactions',
  },
]

export const filterCategories = (categories, data) => {
  if (!categories?.length || !data) return []

  return categories
    .filter(({ category_title }) => {
      switch (category_title) {
        case 'Synonyms':
          return !!data?.synonyms

        case 'Drug-likeness':
          return !!data?.drug

        case 'External DBs':
          return !!data?.external?.length

        default:
          return true
      }
    })
}

export const WARNING_PROPERTIES_VALUES = ['Positive ', 'Inhibitor ']

export const DEFAULT_EXPANDED_CATEGORY_ID = 1


export const DIALOG_MODE_SIZE = 400

export const CATEGORIES_ID = 'categories'

export const scrollToCategory = (id) => {
  const currentCategory = document.getElementById(getCategoryId(id))

  if (currentCategory) {
    currentCategory.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
}

export const getCategoryId = (id) => `category-${id}`
