import styled from 'styled-components'

export const InfoBlockWithProgress = styled.div`
  gap: 0.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  span {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }
`
export const TitleWithDescriptions = styled.div`
  display: flex;
  gap: ${({ gap }) => gap};
  flex-direction: column;
`

export const ProgressContainer = styled.div`
  gap: 0.25rem;
  display: flex;
`

export const ProgressItem = styled.div`
  height: 0.25rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  border-radius: 0.75rem;

  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  position: relative;

  & > span {
    display: block;
    height: 100%;
    background: ${({ theme }) => theme.colors.backgrounds.accent};
    position: relative;
    border-radius: 0.75rem;
    transition: all 0.5s ease;
    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
  }
`
export const DescriptionBlock = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`
