import { t } from 'i18next'
import { getRandomColor } from 'utils/common/common'
import { INNER_LANG_OPERATOR, KEYS_WITH_LANG } from './config'
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns'

export const getSmilesBySuccessType = (res) => {
  const items = res?.data?.result?.items ?? []
  const invalidSmiles = []
  const repeatedSmiles = []

  items.forEach((item) => {
    if (item?.is_success === false) {
      if (item.error_message === 'Structure is not valid') {
        invalidSmiles.push(item)
      } else if (item.error_message === 'Structure already exists in basket') {
        repeatedSmiles.push(item)
      }
    }
  })

  return { invalidSmiles, repeatedSmiles }
}

export const countAddedMolItems = (res) => {
  const items = res?.data?.result?.items ?? []
  const succeedCount = []
  const failedCount = []

  items.forEach((item) => {
    if (item?.is_success === true) {
      succeedCount.push(item)
    } else {
      failedCount.push(item)
    }
  })

  return { succeedCount, failedCount }
}

export const checkMarkushFieldsError = (
  molecular_weight,
  molecular_weight_default,
  weightDiff
) =>
  (!molecular_weight?.[0] && !molecular_weight?.[1]) ||
  !molecular_weight?.[0] ||
  molecular_weight?.[0] === molecular_weight?.[1] ||
  Number(molecular_weight[0]) < Number(molecular_weight_default[0]) ||
  weightDiff > 100

export const getMarkushNotification = (
  text,
  isSubstructure,
  isMarkush,
  molecular_weight,
  molecular_weight_default,
  weightDiff
) => {
  let notificationName = ''
  let translationNameParams

  if (isSubstructure.length > 2) notificationName = 'filter.notify_sub'
  else if (isMarkush && !text.includes('|'))
    notificationName = 'filter.notify_not_ext_smiles'
  else if (!molecular_weight?.[0] && !molecular_weight?.[1])
    notificationName = 'filter.notify_not_both_weights'
  else if (!molecular_weight?.[0])
    notificationName = 'filter.notify_not_min_weight'
  else if (
    molecular_weight?.[0] &&
    Number(molecular_weight[0]) < Number(molecular_weight_default[0])
  ) {
    notificationName = 'filter.notify_min_weight_not_less'
    translationNameParams = {
      value: molecular_weight_default[0],
    }
  } else if (molecular_weight?.[0] === molecular_weight?.[1])
    notificationName = 'filter.notify_min_and_max_equal'
  else if (weightDiff > 100) notificationName = 'filter.notify_mol_weight_error'

  return { notificationName, translationNameParams }
}

export const generatorCreateLayer = (id, name) => {
  const color1 = 'FAFAFA'
  const color2 = getRandomColor({ withHash: false })

  const options = {
    id,
    name,
    opacity: 1,
    visible: true,
    isGenerator: true,
    color: [color1, color2],
    size: 'sm',
    status: 'loading',
    score: 0.4,
  }
  return options
}

export const getBasketsQueryParams = (sortingConfig, name, basketType = '') => {
  const sortQuery = `order_by=${sortingConfig.type}&descending=${
    sortingConfig.direction === 'desc'
  }`
  const basketTypeQuery =
    basketType === 'thematic'
      ? 'public=true'
      : basketType === 'companies'
      ? 'filter_by_customer=true'
      : basketType === 'private'
      ? 'public=false'
      : ''
  const nameQuery = name ? `name=${name}` : ''
  const queries = [sortQuery, basketTypeQuery, nameQuery]
  return queries.filter((el) => el).join('&')
}

export const convertLitSearchConfig = (obj) => {
  const resObj = {}
  const resParams = []

  // язык добавляем, есть только в фильтрах выбран язык
  // и нет полей, у которых должно быть окончание '.ru'/'.en'
  if (
    obj?.language?.values?.length &&
    !Object.keys(obj).some((key) => KEYS_WITH_LANG.includes(key))
  ) {
    const conditions = []
    obj.language.values.forEach((el) => {
      conditions.push({
        match_type: 'match',
        field: 'language',
        value: el,
      })
    })

    resParams.push({ or_operator: conditions })
  }

  for (let key in obj) {
    if (key === 'language') continue
    else if (key === 'sort') {
      resObj.sort = obj.sort
    } else if (key === 'type') {
      let types = obj.type.length ? obj.type : ['patent', 'article']
      const typeCondition = []
      types.forEach((el) => {
        typeCondition.push({
          match_type: 'match',
          field: 'type',
          value: el,
        })
      })

      resParams.push({ or_operator: typeCondition })
    } else if (key === 'published_date') {
      const dates = obj.published_date
      let newDateValue

      if (Object.keys(dates).length === 2) {
        newDateValue = {
          gte: dates.from_date,
          lte: dates.to_date,
        }
      } else if (Object.keys(dates).includes('from_date')) {
        newDateValue = {
          gte: dates.from_date,
        }
      } else {
        newDateValue = {
          lte: dates.to_date,
        }
      }

      resParams.push({
        or_operator: [
          { match_type: 'range', field: 'published_date', value: newDateValue },
        ],
      })
    } else if (key === 'name') {
      resParams.push({
        or_operator: [
          { match_type: 'match_phrase', field: 'name', value: obj.name },
        ],
      })
    }
    // ключи, у которых должно быть окончание '.ru'/'.en'
    else if (KEYS_WITH_LANG.includes(key)) {
      const ruConditions = []
      const enConditions = []
      const langConditions = []
      const languages = obj?.language?.values?.length
        ? obj.language.values
        : ['en', 'ru']

      const operator = convertOldOperatorToNew(obj[key].operator)

      // если в значении есть пробел, то используем match_phrase, иначе match
      if (languages.includes('ru')) {
        obj[key].values.forEach((el) => {
          ruConditions.push({
            match_type: el?.includes(' ') ? 'match_phrase' : 'match',
            field: `${key}.ru`,
            value: el,
          })
        })
      }

      if (languages.includes('en')) {
        obj[key].values.forEach((el) => {
          enConditions.push({
            match_type: el?.includes(' ') ? 'match_phrase' : 'match',
            field: `${key}.en`,
            value: el,
          })
        })
      }

      const isRu = ruConditions.length
      const isEn = enConditions.length

      const length = ruConditions.length || enConditions.length

      for (let i = 0; i < length; i++) {
        langConditions.push({
          [INNER_LANG_OPERATOR]:
            isRu && isEn
              ? [enConditions[i], ruConditions[i]]
              : isRu
              ? [ruConditions[i]]
              : [enConditions[i]],
        })
      }
      resParams.push({ [operator]: langConditions })
    }

    // остальные ключи
    else {
      const conditions = []
      const operator = convertOldOperatorToNew(obj[key].operator)

      obj[key].values.forEach((el) => {
        conditions.push({
          match_type:
            key === 'structures_ids' || el?.includes(' ')
              ? 'match_phrase'
              : 'match',
          field: key,
          value: el,
        })
      })

      resParams.push({ [operator]: conditions })
    }
  }

  resObj.query = {
    and_operator: resParams,
  }

  return resObj
}

export const convertOldOperatorToNew = (oldOperator) =>
  oldOperator === 'should' ? 'or_operator' : 'and_operator'

export const convertNewOperatorToOld = (newOperator) =>
  newOperator === 'or_operator' ? 'should' : 'must'

export const getISOTimeZoneDate = (value) => {
  const curDate = new Date(value)
  const curTZ = -new Date().getTimezoneOffset()
  curDate.setMinutes(curDate.getMinutes() + curTZ)
  return curDate.toISOString().slice(0, -1)
}

export const getStartOfPeriod = (value, period = 'day') => {
  const date = new Date(value)
  const startOfDate = period === 'month' ? startOfMonth(date) : startOfDay(date)
  return getISOTimeZoneDate(startOfDate)
}
export const getEndOfPeriod = (value, period = 'day') => {
  const date = new Date(value)
  const startOfDate = period === 'month' ? endOfMonth(date) : endOfDay(date)
  return getISOTimeZoneDate(startOfDate)
}
