import {
  Caption,
  Label,
  Subheadline,
  TextLarge,
  TitleTertiary,
} from 'components/common/text/index.style'
import { Fragment, useCallback, useRef, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  CardWrapper,
  CardReaction,
  CardMethodsWrapper,
  CardMethod,
  MethodYield,
  Container,
  ReactBlock,
  CardContent,
  SvgWrapper,
  Source,
  ReactionCardHeader,
  ReactionCardTitle,
  MethodInfo,
  Conditions,
  ConditionsBlock,
  ConditionsList,
  Condition,
  Divider,
  CardMethodsContainer,
  More,
  MoreTooltipList,
} from './index.style'
import Icon from '../Icon'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addSimpleNotification } from '../../store/actions/notifications'
import { useTheme } from 'styled-components'
import { ReactionOperatorIcon } from 'components/ReactionViewer/index.style'
import MoleculeViewer from 'components/MoleculeViewer'
import {
  setOpenReactionsDialog,
  setReactionsDialogConfig,
} from 'store/actions/reactionSearch'
import CustomButton from 'components/common/customButton'
import CustomScrollbar from 'components/CustomScrollbar'
import { REACTIONS_PAGINATION_LIMIT } from 'store/sagas/utils/config'
import { Row } from 'components/Applicability/index.style'
import CustomTag from 'components/common/customTag'
import { getCountNoun } from 'pages/Pdf2Smiles/config/config'
import {
  ARRAY_REACTION_CONDITIONS,
  REACTION_CONDITIONS_1,
  REACTION_CONDITIONS_2,
} from './config/config'

const SearchReactionsCard = ({ data, idx }) => {
  const { id, reactants, products, conditions } = data || {}

  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const paginationState = useSelector((state) => state.search.pagination)
  const highlighted_molecule_id = useSelector(
    (state) => state.reactionsSearch.compound_id
  )
  const { activePage } = paginationState || {}
  const reactionRef = useRef(null)
  const [showMore, setShowMore] = useState(false)
  const conditionsQty = conditions?.length || 0

  const handleOpenDialog = useCallback(
    ({ e, condition, idx }) => {
      e.preventDefault()
      dispatch(setOpenReactionsDialog(true))
      dispatch(
        setReactionsDialogConfig({
          data: condition,
          idx,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  return (
    <>
      <CardWrapper
        isFullMode={false}
        id="reaction-search-wrapper"
        padding="1rem"
        showMore={showMore}
        isHighlightTitle={true}
        minHeight={'34.5rem'}
      >
        <CardContent ref={reactionRef}>
          <ReactionCardHeader id="search-reactions-card-header">
            <ReactionCardTitle>
              <TitleTertiary
                className="header-title"
                fontWeight={500}
                style={{ maxWidth: 'calc(100% - 4.5rem)' }}
              >
                {`${t('filter.reaction.reaction')} ${
                  !activePage || activePage === 1
                    ? idx + 1
                    : (activePage - 1) * REACTIONS_PAGINATION_LIMIT + idx + 1
                }`}
              </TitleTertiary>
              <CustomTag type="grey">
                {t(
                  `filter.reaction.method_quantity.${getCountNoun(
                    conditionsQty
                  )}`,
                  {
                    count: conditionsQty,
                  }
                )}
              </CustomTag>
            </ReactionCardTitle>
          </ReactionCardHeader>
          {/* НАЧАЛО РЕАКЦИИ */}
          <CustomScrollbar
            noScrollY={true}
            noScrollX={false}
            style={{ height: '10.25rem' }}
            disableTrackXWidthCompensation={true}
          >
            <CardReaction id="search-reactions-card-reaction">
              <Container>
                <ReactBlock>
                  {reactants?.map((el, idx) => {
                    return (
                      <Fragment key={`l-${idx}`}>
                        <MoleculeViewer
                          fixedSize={true}
                          width={230}
                          height={130}
                          smiles={el?.smiles}
                          noDelete
                          noEdit
                          noMenu
                          noHover={true}
                          noContainerHover={true}
                          noClick={true}
                          type={'no-border-carousel-dialog'}
                          highlight_smiles={
                            el?.id === highlighted_molecule_id
                              ? el?.smiles
                              : null
                          }
                        />
                        {idx !== reactants.length - 1 && (
                          <SvgWrapper size="1.75rem">
                            <ReactionOperatorIcon
                              iconType="add"
                              size="1.75rem"
                              color={theme.colors.icons.accentPrimary}
                            />
                          </SvgWrapper>
                        )}
                      </Fragment>
                    )
                  })}

                  <SvgWrapper size="1.75rem">
                    <ReactionOperatorIcon
                      iconType="reactionResultIcon"
                      size="1.75rem"
                      color={theme.colors.icons.accentPrimary}
                    />
                  </SvgWrapper>

                  {products?.map((el, idx) => {
                    return (
                      <Fragment key={el.id ?? `r-${idx}`}>
                        <MoleculeViewer
                          fixedSize={true}
                          width={230}
                          height={130}
                          smiles={el?.smiles}
                          noDelete
                          noEdit
                          noMenu
                          noHover
                          noContainerHover={true}
                          noClick={true}
                          type={'no-border-carousel-dialog'}
                          highlight_smiles={
                            el?.id === highlighted_molecule_id
                              ? el?.smiles
                              : null
                          }
                        />
                        {idx !== products.length - 1 && (
                          <SvgWrapper size="1.75rem">
                            <ReactionOperatorIcon
                              iconType="add"
                              size="1.75rem"
                              color={theme.colors.icons.accentPrimary}
                            />
                          </SvgWrapper>
                        )}
                      </Fragment>
                    )
                  })}
                </ReactBlock>
              </Container>
            </CardReaction>
          </CustomScrollbar>
          {/* КОНЕЦ РЕАКЦИИ */}
          <CardMethodsContainer>
            <CustomScrollbar
              noScrollY={true}
              noScrollX={false}
              style={{ height: '19.375rem' }}
              disableTrackXWidthCompensation={true}
            >
              <CardMethodsWrapper id="search-reactions-card-conditions">
                {conditions?.map((condition, index) => {
                  const { yield_percents, is_open_reaction_database } =
                    condition || {}

                  return (
                    <CardMethod key={id}>
                      <Row justifyContent="space-between">
                        <TextLarge fontWeight={theme.fontWeight.semibold}>{`${t(
                          'filter.reaction.method'
                        )} ${index + 1}`}</TextLarge>
                        <MethodInfo>
                          <MethodYield>
                            <Caption
                              lineHeight={theme.text[14]}
                              fontWeight={theme.fontWeight.medium}
                            >{`${t('filter.reaction.yield')}: ${
                              yield_percents
                                ? `${yield_percents}%`
                                : `${t('common.unknown')}`
                            }`}</Caption>
                          </MethodYield>
                          <MethodYield
                            data-tooltip-id={`card-method-sources-tip-${idx}`}
                            pointer
                          >
                            <Caption
                              lineHeight={theme.text[14]}
                              fontWeight={theme.fontWeight.medium}
                            >
                              {t('filter.reaction.sources')}
                            </Caption>
                            <Icon
                              iconType="info"
                              size="1rem"
                              color={theme.colors.text.tertiary}
                            />
                          </MethodYield>
                          <ReactTooltip
                            id={`card-method-sources-tip-${idx}`}
                            className="c-tooltip c-tooltip-base"
                            place="top"
                            positionStrategy="fixed"
                          >
                            {`${t(
                              'filter.reaction.material_taken_from_open_sources'
                            )}`}
                          </ReactTooltip>
                        </MethodInfo>
                      </Row>
                      <Conditions>
                        <Subheadline fontWeight={500}>
                          {t('filter.reaction.reaction_conditions')}:
                        </Subheadline>
                        <ConditionsBlock>
                          {[REACTION_CONDITIONS_1, REACTION_CONDITIONS_2].map(
                            (list, i) => (
                              <ConditionsList key={i} order={i && 2}>
                                {list.map((cond) => {
                                  const isEmpty =
                                    !condition?.[cond] ||
                                    condition?.[cond]?.length === 0
                                  const isArray =
                                    ARRAY_REACTION_CONDITIONS.includes(cond) &&
                                    !isEmpty
                                  return (
                                    <Condition key={cond}>
                                      <Caption
                                        lineHeight={theme.text[14]}
                                        fontWeight={theme.fontWeight.medium}
                                        color={theme.colors.text.secondary}
                                      >
                                        {t(
                                          `filter.reaction.conditions.${cond}`
                                        )}
                                        :
                                      </Caption>
                                      {isArray && (
                                        <>
                                          <Source
                                            onClick={() => {
                                              window.open(
                                                `/molecule/${condition[cond][0].id}`
                                              )
                                            }}
                                          >
                                            <Caption
                                              lineHeight={theme.text[14]}
                                              fontWeight={
                                                theme.fontWeight.medium
                                              }
                                              color={theme.colors.statuses.link}
                                              pointer
                                              ellipsis
                                              minWidth={'0'}
                                            >
                                              {condition[cond][0].name}
                                            </Caption>
                                            <Icon
                                              iconType="link"
                                              size="0.75rem"
                                              color={theme.colors.statuses.link}
                                            />
                                          </Source>
                                          {condition[cond].length > 1 && (
                                            <>
                                              <More
                                                data-tooltip-id={`${id}-${cond}`}
                                              >
                                                <Caption
                                                  lineHeight={theme.text[14]}
                                                  fontWeight={
                                                    theme.fontWeight.medium
                                                  }
                                                  pointer
                                                >
                                                  {`${t(
                                                    'filter.reaction.more'
                                                  )} ${
                                                    condition[cond].length - 1
                                                  }`}
                                                </Caption>
                                                <Icon
                                                  iconType="arrowRight"
                                                  size="0.75rem"
                                                />
                                              </More>
                                              <ReactTooltip
                                                id={`${id}-${cond}`}
                                                className="c-tooltip c-tooltip-base c-tooltip-reaction-condition"
                                                classNameArrow="c-tooltip-arrow c-tooltip-arrow-white"
                                                place={'bottom-start'}
                                                offset={18}
                                                clickable
                                                positionStrategy="fixed"
                                              >
                                                <MoreTooltipList>
                                                  {condition[cond].map(
                                                    ({ name, id }, i) => (
                                                      <Source
                                                        onClick={() => {
                                                          window.open(
                                                            `/molecule/${id}`
                                                          )
                                                        }}
                                                        key={i}
                                                      >
                                                        <Caption
                                                          lineHeight={
                                                            theme.text[14]
                                                          }
                                                          fontWeight={
                                                            theme.fontWeight
                                                              .medium
                                                          }
                                                          color={
                                                            theme.colors
                                                              .statuses.link
                                                          }
                                                          pointer={'pointer'}
                                                          ellipsis
                                                          minWidth={'0'}
                                                        >
                                                          {name}
                                                        </Caption>
                                                        <Icon
                                                          iconType="link"
                                                          size="0.75rem"
                                                          color={
                                                            theme.colors
                                                              .statuses.link
                                                          }
                                                        />
                                                      </Source>
                                                    )
                                                  )}
                                                </MoreTooltipList>
                                              </ReactTooltip>
                                            </>
                                          )}
                                        </>
                                      )}
                                      {!isArray && (
                                        <Caption
                                          lineHeight={theme.text[14]}
                                          fontWeight={theme.fontWeight.medium}
                                          color={
                                            isEmpty
                                              ? theme.colors.statuses.attention
                                              : undefined
                                          }
                                        >
                                          {isEmpty
                                            ? t(
                                                'filter.reaction.conditions.no_information'
                                              )
                                            : condition?.[cond]}
                                        </Caption>
                                      )}
                                    </Condition>
                                  )
                                })}
                              </ConditionsList>
                            )
                          )}
                          <Divider />
                        </ConditionsBlock>
                      </Conditions>
                      <Source>
                        <Label>{t('filter.reaction.lit_link')}:</Label>
                        <Label
                          color={
                            condition.source
                              ? undefined
                              : theme.colors.statuses.attention
                          }
                          ellipsis
                          minWidth={'0'}
                        >
                          {condition.source
                            ? condition.source
                            : t('filter.reaction.conditions.no_information')}
                        </Label>
                        {condition.source && (
                          <Icon
                            className="copy-name-icon"
                            iconType="copy"
                            size="1rem"
                            dataTest="reaction-card-copy-btn"
                            onClick={(e) => {
                              e.stopPropagation()
                              navigator.clipboard.writeText(condition.source)
                              dispatch(
                                addSimpleNotification(
                                  `notification.successfully_copied`
                                )
                              )
                            }}
                          />
                        )}
                      </Source>
                      <CustomButton
                        gap={'0.25rem'}
                        type={'text_accent'}
                        onClick={(e) =>
                          handleOpenDialog({
                            e,
                            condition,
                            idx: index + 1,
                          })
                        }
                      >
                        <div>{t('filter.reaction.reaction_protocol')}</div>
                        <Icon iconType={'arrowRight'} size={'1rem'} />
                      </CustomButton>
                    </CardMethod>
                  )
                })}
              </CardMethodsWrapper>
            </CustomScrollbar>
          </CardMethodsContainer>
        </CardContent>
      </CardWrapper>
    </>
  )
}

export default SearchReactionsCard
