import styled, { css } from 'styled-components'

import CustomButton from 'components/common/customButton'
import { Subheadline, TitleSecondary } from 'components/common/text/index.style'

export const SearchBlock = styled.div`
  ${({ pastResult }) =>
    pastResult &&
    css`
      display: none;
    `}
  ${({ isSearchPage }) =>
    isSearchPage
      ? css`
          padding-bottom: 16px;
        `
      : css`
          flex-grow: 1;
        `}

  @media print {
    display: none;
  }
`
export const Tools = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
`
export const BackButtonContent = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`

export const BasketHeader = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`
export const BasketInfoAndToolbar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 0.5rem;
`
export const BasketInfoAndEditBtn = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const BasketInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
`

export const Header = styled(TitleSecondary)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  max-width: 47.5rem;

  @media print {
    display: none;
  }
`

export const BasketDescription = styled(Subheadline)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  max-width: 47.5rem;

  @media print {
    display: none;
  }
`
export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

export const Container = styled.div`
  padding-right: 0.75rem;
  display: flex;
  flex-direction: column;
`

export const TopBlockContainer = styled.div`
  position: relative;
  top: 0;
  z-index: 1;
  padding: 1.5rem 0.25rem;
  background: linear-gradient(
    180deg,
    #f5f7f7 91.89%,
    rgba(245, 247, 249, 0) 100%
  );
  display: ${({ hidden }) => hidden && 'none'};
`
export const ListBlockContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`

export const ToggleWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 2.25rem;
  align-items: center;
  justify-content: end;
  gap: 24px;
`
