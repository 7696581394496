import styled, { css } from 'styled-components'

import CustomButton from 'components/common/customButton'

export const NotificationWarningText = styled.span`
  line-height: 1rem;
  font-size: ${({ theme }) => theme.text[12]};
  font-weight: ${({ theme }) => theme.fontWeight.light};

  color: ${({ theme }) => theme.colors.text.secondary};
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ isTextExpanded }) => (isTextExpanded ? 11 : 3)};
  -webkit-box-orient: vertical;
  max-height: ${({ isTextExpanded }) => (isTextExpanded ? '11rem' : '3rem')};
  text-overflow: ellipsis;
  ${({ isTextExpanded }) =>
    isTextExpanded &&
    css`
      word-break: break-all;
    `}
`
export const ExpandButton = styled(CustomButton)`
  gap: 0.25rem;
`
