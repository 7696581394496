import {
  HANDLE_SHOW_CONTEXT_MENU,
  HIDE_CONTEXT_MENU,
  UPDATE_DISABLED_ACTIONS,
} from 'store/constants/contextMenu'

export const handleShowContextMenu = (payload) => ({
  type: HANDLE_SHOW_CONTEXT_MENU,
  payload,
})

export const hideContextMenu = () => ({
  type: HIDE_CONTEXT_MENU,
})

export const updateDisabledActions = (value) => ({
  type: UPDATE_DISABLED_ACTIONS,
  payload: value,
})
