import styled from 'styled-components'

export const Container = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const FieldsBlock = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const PasswordBlock = styled.div`
  gap: 1rem;
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
`
export const Agreement = styled.div`
  width: 100%;
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`
