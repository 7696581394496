import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Container } from './index.style'
import LoadingOverlay from '../../LoadingOverlay'
import FeedbackCard from 'components/Feedback/FeedbackCard'
import { closeFeedbackCard, getFeedback } from 'store/actions/feedback'
import Pagination from 'pages/MoleculesList/Pagination'
import { ScrollTopBtn } from 'pages/AdminPanelPage/index.style'
import Icon from 'components/Icon'
import FeedbackFilters from 'components/Feedback/FeedbackFilters'

const FeedbackViewer = ({ handleScrollTop, isBottom, scrollTopEnable }) => {
  /** INIT - START */
  const [isWindowVisible, setWindowVisible] = useState(false)

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.feedback.loading)
  const isFeedbackCardOpen = useSelector(
    (state) => state.feedback.show_feedback_card
  )
  const data = useSelector((state) => state.feedback.data)
  const offset = useSelector((state) => state.feedback.offset)
  const notifications = useSelector((state) => state.notifications)
  const tasks = useSelector((state) => state.tasks)

  const pagination = useSelector((state) => state.feedback.pagination)
  const { perPage: limit } = pagination || {}

  const { notificationsList, isHidden } = notifications

  const isNotificationsButtonShow =
    isHidden && notificationsList.length + tasks.length > 0
  /** INIT - END */

  useEffect(() => {
    dispatch(getFeedback({ limit, offset }))
    return () => dispatch(closeFeedbackCard())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /** EFFECTS - END */

  // const setHandled = (id, ishandled) => async () => {
  //   try {
  //     await update(`/feedback/${id}`, { handled: !ishandled })
  //     setLoaded({})
  //     loadFeedbackList()
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }
  return (
    <>
      <FeedbackFilters scrollTop={handleScrollTop} />
      <Container
        id="feedback-viewer-container"
        showPagination={pagination?.pagesAmount > 1 && isBottom}
      >
        {data?.map((card) => {
          return (
            <FeedbackCard
              card={card}
              isWindowVisible={isWindowVisible}
              setWindowVisible={setWindowVisible}
              isFeedbackCardOpen={isFeedbackCardOpen}
              key={card.feedback_id}
            />
          )
        })}

        {loading && <LoadingOverlay />}
        {isBottom && !loading && pagination?.pagesAmount > 1 && (
          <Pagination pageType="feedback" />
        )}
      </Container>
      <ScrollTopBtn
        visible={scrollTopEnable}
        onClick={handleScrollTop}
        isNotificationsButtonShow={isNotificationsButtonShow}
      >
        <Icon iconType="arrowTop" size="1.25rem" />
      </ScrollTopBtn>
    </>
  )
}

export default FeedbackViewer
