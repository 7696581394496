import { useTranslation } from 'react-i18next'

import CustomButton from 'components/common/customButton'

import { ActionButtons } from './index.style'

export const ApplyResetBtns = ({
  onReset,
  onApply,
  isResetBtnDisabled,
  isApplyBtnDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <ActionButtons>
      <CustomButton
        type="primary"
        onClick={onReset}
        disabled={isResetBtnDisabled}
        width="50%"
      >
        {t('dataset_table.clear')}
      </CustomButton>
      <CustomButton
        type="primary"
        onClick={onApply}
        disabled={isApplyBtnDisabled}
        width="50%"
      >
        {t('dataset_table.apply')}
      </CustomButton>
    </ActionButtons>
  )
}

export default ApplyResetBtns
