import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'
import { Content } from 'components/AddEditDialog/index.style'

const BasketJoinAndCopyDialog = ({
  type,
  handleJoin,
  handleCopy,
  handleClose,
  usedNames,
}) => {
  const [newName, setName] = useState('')
  const [newDesc, setDesc] = useState('')
  const { t } = useTranslation()

  const namesConfig = {
    join: {
      title: 'baskets.join_copy_dialog.joined_dataset_creation',
      name_placeholder: 'baskets.join_copy_dialog.joined_dataset_name',
      desc_placeholder: 'baskets.join_copy_dialog.joined_dataset_desc',
    },
    copy: {
      title: 'baskets.join_copy_dialog.copied_dataset_creation',
      name_placeholder: 'baskets.join_copy_dialog.copied_dataset_name',
      desc_placeholder: 'baskets.join_copy_dialog.copied_dataset_desc',
    },
  }

  const save = () => {
    if (type === 'join' && handleJoin) {
      handleJoin({ new_basket_name: newName, new_basket_desc: newDesc })
    }
    if (type === 'copy' && handleCopy) {
      handleCopy({ new_basket_name: newName, new_basket_desc: newDesc })
    }
  }

  const saveByEnter = (e) => {
    if (e.key === 'Enter' && newName?.length) {
      save()
    }
  }

  const nameError = useMemo(() => {
    return usedNames.some((name) => name === newName)
  }, [usedNames, newName])

  return (
    <CustomDialog width={'372px'} onClose={handleClose}>
      <CustomDialogHeader>{t(namesConfig?.[type]?.title)}</CustomDialogHeader>
      <Content>
        <CustomInput
          onChange={setName}
          value={newName}
          label={t('baskets.add_create_dialog.name')}
          placeholder={t(namesConfig?.[type]?.name_placeholder)}
          withClearButton={true}
          onKeyUp={saveByEnter}
          autoFocus={true}
          error={nameError ? 'baskets.name_taken' : ''}
        />
        <CustomInput
          onChange={setDesc}
          value={newDesc}
          label={t('baskets.add_create_dialog.description')}
          placeholder={t(namesConfig?.[type]?.desc_placeholder)}
          onKeyUp={saveByEnter}
        />
      </Content>
      <CustomButton
        width={'100%'}
        type="accent"
        disabled={!newName || nameError}
        onClick={!newName ? () => {} : save}
      >
        {t('baskets.add_create_dialog.create')}
      </CustomButton>
    </CustomDialog>
  )
}

export default BasketJoinAndCopyDialog
