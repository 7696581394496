import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomInput from 'components/common/customInput'

import { getTypeValue } from '../CustomFilter/utils/utils'
import { FilterBtn, FloatingFilterWrapper } from './index.style'

const FloatingFilter = ({ model, api, column, paintingModel }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [isInputDisabled, setIsInputDisabled] = useState(false)

  const filter = model?.filterModels?.find(
    (el) => el?.conditions || el?.type !== 'set'
  )
  const filterException = model?.filterModels?.find((el) => el?.type === 'set')

  const isFilterActive =
    !!model?.filterModels?.length || paintingModel?.[column.colId]

  useEffect(() => {
    getInputValue()
  }, [model])

  const getInputValue = () => {
    setIsInputDisabled(false)
    if (!filter) setValue('')
    else if (filter?.conditions) {
      const operator = t(
        `dataset_table.${filter.operator.toLowerCase()}`
      ).toLowerCase()
      setIsInputDisabled(true)
      if (filter.conditions.every((el) => el.type === 'inRange'))
        setValue(
          `${filter.conditions[0].filter}-${filter.conditions[0].filter2} ${operator} ${filter.conditions[1].filter}-${filter.conditions[1].filter2}`
        )
      else if (filter.conditions[0].type === 'inRange')
        setValue(
          `${filter.conditions[0].filter}-${
            filter.conditions[0].filter2
          } ${operator} ${
            filter.conditions[1].filter ||
            getTypeValue(filter.conditions[1].type)
          }`
        )
      else if (filter.conditions[1].type === 'inRange')
        setValue(
          `${
            filter.conditions[0].filter ||
            getTypeValue(filter.conditions[0].type)
          } ${operator} ${filter.conditions[1].filter}-${
            filter.conditions[1].filter2
          }`
        )
      else
        setValue(
          `${
            filter.conditions[0].filter ||
            getTypeValue(filter.conditions[0].type)
          } ${operator} ${
            filter.conditions[1].filter ||
            getTypeValue(filter.conditions[1].type)
          }`
        )
    } else {
      if (
        filter.type === 'blank' ||
        filter.type === 'notBlank' ||
        filter.type === 'inRange'
      )
        setIsInputDisabled(true)
      filter.type === 'inRange'
        ? setValue(`${filter.filter}-${filter.filter2}`)
        : setValue(filter.filter || getTypeValue(filter.type))
    }
  }

  const handleChange = async (newValue) => {
    let filterModels = []
    if (!model || !isFilterActive) {
      filterModels = [
        {
          type: 'contains',
          filter: newValue,
        },
      ]
    } else {
      if (!newValue) filterModels = []
      else {
        if (filter?.type || !filter) {
          filterModels = [
            {
              type: 'contains',
              filter: newValue,
            },
          ]
        } else {
          const currentType = filter.conditions[0].type

          filterModels = [
            {
              type: currentType,
              filter: newValue,
            },
          ]
        }
      }

      if (filterException) filterModels.push(filterException)
    }

    await api.setColumnFilterModel(column, {
      filterModels,
    })

    await api.onFilterChanged()
  }

  const onClick = () => {
    api.showColumnFilter(column)
  }

  return (
    <FloatingFilterWrapper>
      <CustomInput
        value={value}
        onChange={handleChange}
        withWhiteBackground
        withClearButton
        placeholder={t('dataset_table.search')}
        disabled={isInputDisabled}
      />
      <FilterBtn
        onClick={onClick}
        type="primary"
        isFilterActive={isFilterActive}
      >
        <Icon iconType="filters" size="1rem" />
      </FilterBtn>
    </FloatingFilterWrapper>
  )
}

export default FloatingFilter
