export const REACTION_CONDITIONS_1 = [
  'temperature',
  'pressure',
  'catalyst',
  'agent',
  'solvent',
]

export const REACTION_CONDITIONS_2 = [
  'pH',
  'equipment',
  'atmosphere',
  'radiation',
  'time',
]

export const ARRAY_REACTION_CONDITIONS = ['catalyst', 'agent', 'solvent']
