import CustomButton from 'components/common/customButton'
import CustomScrollbar from 'components/CustomScrollbar'
import Icon from 'components/Icon'
import { push } from 'connected-react-router'
import { SCROLL_OFFSET } from 'pages/MoleculesList/config/config'
import Pagination from 'pages/MoleculesList/Pagination'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import {
  getCurrentCustomer,
  getCurrentCustomerUsers,
} from 'store/actions/adminPanel'
import { readStorage } from 'utils/storage/storage'
import CompanyHeader from '../companyHeader'
import CompanyUsers from '../companyUsers'
import { CompanyPageWrapper } from './index.style'
import { hideFeedbackBtn, showFeedbackBtn } from 'store/actions/feedback'
import { ScrollTopBtn } from '../index.style'

const AdminCompany = ({
  currentCustomerId,
  pagination,
  isFeedbackBtnshow,
  notifications,
  tasks,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const scrollRef = useRef(null)
  const role = useRef()

  const [state, setState] = useState({
    scrollTopEnable: false,
    isBottom: false,
  })

  const { scrollTopEnable, isBottom } = state || {}
  const { pagesAmount } = pagination
  const { notificationsList, isHidden } = notifications

  const isNotificationsButtonShow =
    isHidden && notificationsList.length + tasks.length > 0

  const handleScroll = () => {
    const d = document.getElementById('companyContainer')
    const isNotTop = scrollRef.current?.scrollValues?.scrollTop > SCROLL_OFFSET
    const isTop = scrollRef.current?.scrollValues?.scrollTop === 0

    if (isTop) {
      setState((prev) => ({
        ...prev,
        scrollTopEnable: false,
        isBottom: false,
      }))
      return
    }

    const isBottomCurrent =
      scrollRef.current?.scrollValues?.scrollTop +
        d?.getBoundingClientRect()?.height +
        36 >=
      scrollRef.current?.scrollValues?.scrollHeight

    if (isNotTop === scrollTopEnable && isBottomCurrent === isBottom) {
      return
    }

    setState((prev) => ({
      ...prev,
      scrollTopEnable: isNotTop && !isBottomCurrent,
      isBottom: isBottomCurrent,
    }))
  }

  const handleScrollTop = () => {
    scrollRef?.current?.scrollTo(0, 0)
  }

  useEffect(() => {
    handleScrollTop()
    handleScroll()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.activePage])

  const handleReturn = () => {
    dispatch(push('/admin-panel'))
  }

  useEffect(() => {
    const adminRole = readStorage('role')
    role.current = adminRole

    // setState((prev) => ({
    //   ...prev,
    //   scrollTopEnable: false,
    //   isBottom: false,
    // }))
    if (!currentCustomerId && adminRole === 'syn_admin') handleReturn()
    else {
      dispatch(getCurrentCustomer({ isSynAdmin: adminRole === 'syn_admin' }))
      dispatch(
        getCurrentCustomerUsers({
          isSynAdmin: adminRole === 'syn_admin',
          limit: 24,
          offset: 0,
        })
      )
    }
  }, [])

  // feedback btn should be hidden if isBottom and there is no pagination
  useEffect(() => {
    if (isBottom && pagesAmount < 2 && isFeedbackBtnshow)
      dispatch(hideFeedbackBtn())
    else if (!isFeedbackBtnshow) dispatch(showFeedbackBtn())

    return () => dispatch(showFeedbackBtn())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom, pagesAmount])

  return (
    <CompanyPageWrapper id="companyContainer">
      <CustomScrollbar innerRef={scrollRef} onScroll={handleScroll}>
        {role?.current && role.current === 'syn_admin' && (
          <CustomButton
            gap="4px"
            type="text_accent"
            onClick={handleReturn}
            style={{ marginTop: '1.5rem' }}
          >
            <Icon iconType="arrowLeft" size="1rem" />
            {t('common.back')}
          </CustomButton>
        )}
        <CompanyHeader role={role?.current} />
        <CompanyUsers role={role?.current} />
        {isBottom && pagination?.pagesAmount > 1 && (
          <Pagination pageType="adminCurrentCompany" role={role?.current} />
        )}
        <ScrollTopBtn
          visible={scrollTopEnable}
          onClick={handleScrollTop}
          isNotificationsButtonShow={isNotificationsButtonShow}
        >
          <Icon iconType="arrowTop" size="1.25rem" />
        </ScrollTopBtn>
      </CustomScrollbar>
    </CompanyPageWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    pagination: state.adminPanel.currentCustomerUsers.pagination,
    currentCustomerId: state.adminPanel.currentCustomerId,
    isFeedbackBtnshow: state.feedback.show_btn,
    notifications: state.notifications,
    tasks: state.tasks,
  }
}

export default connect(mapStateToProps)(AdminCompany)
