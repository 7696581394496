import styled from 'styled-components'
import Icon from 'components/Icon'

export const WindowContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background: ${({ isOpen }) =>
    isOpen ? 'rgba(97, 110, 124, 0.08)' : 'transparent'};
  backdrop-filter: ${({ isOpen }) => (isOpen ? 'blur(6px)' : 'none')};

  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1000)};
  transition: all 0.5s;
  overflow: hidden;
  color: #2d3745;
`

export const Window = styled.div`
  display: flex;
  flex-direction: column;

  width: ${({ width }) => (width ? width : '680px')};
  height: calc(100% - 24px);
  background: white;

  position: absolute;
  right: 12px;
  top: 12px;
  bottom: 12px;

  border-radius: 12px;
  padding: 24px;

  transform: ${({ isOpen }) => (isOpen ? 'none' : 'translateX(100%)')};
  opacity: 1;
  transition: all 0.5s;
`
export const CloseButton = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;

  color: #cbd2d9;
  cursor: pointer;
  transition: all 200ms;
  &:hover {
    color: #42c1bf;
  }

  &:active {
    outline: none;
  }
`

export const Header = styled.div`
  color: #2d3745;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: ${({ padding }) => padding && padding};
`
