import { v4 as uuidv4 } from 'uuid'
import { Fragment, useEffect, useRef, useState } from 'react'

import Icon from 'components/Icon'
import MoleculeViewer from 'components/MoleculeViewer'
import { Subheadline } from 'components/common/text/index.style'

import {
  Row,
  StyledButton,
  DrawnMolecule,
  MoleculeWrapper,
} from '../../index.style'
import { ReagentNumber } from './index.style'

const Reaction = ({
  smirks,
  product,
  reagents,
  initialId,
  selectedStage,
  selectedScheme,
  setSelectedStage,
  downloadAllInPdfClicked,
}) => {
  const divRef = useRef(null)
  const [width, setWidth] = useState(0)

  const updateWidth = () => {
    if (divRef.current) setWidth(divRef.current.offsetWidth)
  }

  useEffect(() => {
    setWidth(0)
    updateWidth()
    const handleResize = () => {
      updateWidth()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [selectedStage, selectedScheme])

  return (
    <Row
      gap="16px"
      margin="1.25rem"
      className={downloadAllInPdfClicked ? 'hidden-for-print' : ''}
    >
      {reagents?.map((smiles, index, arr) => {
        const id = uuidv4()
        return (
          <Fragment key={id}>
            <MoleculeWrapper width={width} height={'172px'}>
              <DrawnMolecule>
                <MoleculeViewer
                  smiles={smiles}
                  onShowProps={() => {}}
                  noDelete={true}
                  noEdit={true}
                  noMenu={true}
                  width={width}
                  height={172}
                  noContainerHover={true}
                  noPadding={true}
                  cursor="default"
                  showId={false}
                  fixedSize
                />
              </DrawnMolecule>
              <ReagentNumber>
                <Subheadline fontWeight={500}>
                  {initialId + index + 1}
                </Subheadline>
              </ReagentNumber>
            </MoleculeWrapper>
            {index < arr.length - 1 && <Icon iconType="add" size="1.25rem" />}
          </Fragment>
        )
      })}
      <Icon iconType="arrowRight" size="1.25rem" />
      <MoleculeWrapper ref={divRef} width={width} height={'172px'}>
        <DrawnMolecule>
          <MoleculeViewer
            smiles={product}
            onShowProps={() => {}}
            noDelete={true}
            noEdit={true}
            noMenu={true}
            width={width}
            height={172}
            noPadding={true}
            noContainerHover={true}
            cursor="default"
            showId={false}
            fixedSize
          />
        </DrawnMolecule>
      </MoleculeWrapper>
    </Row>
  )
}
export default Reaction
