import { useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { resetMolecule } from 'store/actions/molecule'
import LoadingOverlay from 'components/LoadingOverlay'
import { CustomDialog } from 'components/common/customDialog'
import Molecule from '../molecule'
import { Header, Content, HeaderBlock } from './index.style'
import { useLocation } from 'react-router-dom'
import { TitleSecondary } from 'components/common/text/index.style'

import { useOverflow } from 'hooks/useOverflow'

export const MoleculeDialog = ({
  id,
  molId,
  onClose,
  services,
  basketId,
  properties,
  categories,
  resetMolecule,
  noteConfig,
  noteDialogId,
}) => {
  const { alias } = noteConfig

  const { t } = useTranslation()
  const location = useLocation()
  const { loading: categoriesLoading } = categories
  const { loading: servicesLoading } = services
  const { loading } = properties
  const isReactionPage = location.pathname.includes('reactions')

  const textRef = useRef(null)
  const containerRef = useRef(null)
  const isOverflow = useOverflow(textRef, containerRef)

  useEffect(() => {
    return () => resetMolecule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const customWindowOpen = useSelector(
    (store) => store.molecule.personal_properties.show_properties_window
  )
  if (categoriesLoading || servicesLoading || loading) return <LoadingOverlay />
  // if (customWindowOpen) return null
  return (
    <CustomDialog
      outboundExceptions={['context-menu', 'context-menu-item']}
      exceptionalParentId="personal-props-window-personal_properties"
      width={'68vw'}
      onClose={onClose}
      maxHeight={'88vh'}
      gap={'0.75rem'}
      withPortal={!!isReactionPage}
      outboundClickEnabled={!noteDialogId || !customWindowOpen}
    >
      <Header>
        <HeaderBlock
          ref={containerRef}
          data-test="molecule-dialog-header-block"
        >
          <TitleSecondary ref={textRef} data-tooltip-id="molecule-alias">
            {alias || t('molviewer.molecule_fast_view')}
          </TitleSecondary>
          {isOverflow && (
            <ReactTooltip
              id="molecule-alias"
              place={'bottom'}
              className="c-tooltip c-tooltip-base c-tooltip-z-max c-tooltip-molecule-dialog-note"
              classNameArrow="c-tooltip-arrow"
            >
              {alias}
            </ReactTooltip>
          )}
          {/* <Applicability /> */}
        </HeaderBlock>
      </Header>
      <Content isFullMode={false}>
        <Molecule
          id={id}
          onClose={onClose}
          molId={molId}
          basketId={basketId}
          isFullMode={false}
          isDialog={true}
        />
      </Content>
    </CustomDialog>
  )
}

const mapStateToProps = (state) => {
  return {
    categories: state.molecule.categories,
    services: state.molecule.services,
    properties: state.molecule.properties,
    noteConfig: state.molecule.noteConfig,
    noteDialogId: state.molecule.moleculeNoteDialogId,
  }
}

const mapDispatchToProps = {
  push,
  resetMolecule,
}

export default connect(mapStateToProps, mapDispatchToProps)(MoleculeDialog)
