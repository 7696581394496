import styled from 'styled-components'

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 13.75rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  overflow: hidden;
  padding: 1rem;
  gap: 1.5rem;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.default};
    cursor: pointer;
  }
`
export const CardHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: start;
  align-items: center;
  height: 1.5rem;
`
export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.25rem;
`
export const CardBodyText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 4;
  -moz-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardFooterDateBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.text.secondary};
`
export const CardFooterDateItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
export const FeedbackType = styled.div`
  display: flex;
  border-radius: 0.375rem;
  border: ${({ theme, withBorder, borderColor }) =>
    withBorder &&
    `1.2px solid ${
      borderColor ? borderColor : theme.colors.backgrounds.secondary
    }`};
  color: ${({ theme, color }) =>
    color ? color : theme.colors.text.accentPrimary};
  background-color: ${({ theme, background }) =>
    background ? background : theme.colors.backgrounds.accentTertiary};
  padding: 5px 0.5rem;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
`
export const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
