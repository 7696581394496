import Icon from 'components/Icon'

import NumberInputButtons from '../NumberInputButtons'
import {
  CustomInputAddButton,
  CustomInputClearButton,
  CustomInputHideButton,
} from './index.style'

const RightButtons = ({
  min,
  max,
  type,
  value,
  btnSize,
  disabled,
  onChange,
  iconSize,
  onAddItem,
  onIncrement,
  onDecrement,
  clearBtnProps,
  showAddButton,
  btnPaddingSize,
  btnBorderRadius,
  showClearButton,
  withWhiteBackground,
  showHideButton,
  isValueHidden,
  onHideBtnClick,
}) => {
  const { onClear, id: clearBtnId } = clearBtnProps

  const clear = () => {
    onClear ? onClear() : onChange('')
  }

  return (
    <>
      <CustomInputHideButton
        onClick={onHideBtnClick}
        visible={showHideButton}
        {...{
          iconSize,
          btnSize,
          btnPaddingSize,
          btnBorderRadius,
          withWhiteBackground,
        }}
      >
        <Icon
          iconType={isValueHidden ? 'eyeClose' : 'eyeOpen'}
          size={iconSize}
        />
      </CustomInputHideButton>
      <CustomInputClearButton
        onClick={clear}
        id={clearBtnId || 'clear'}
        visible={showClearButton && !disabled}
        {...{
          iconSize,
          btnSize,
          clearBtnProps,
          showAddButton,
          showHideButton,
          btnPaddingSize,
          btnBorderRadius,
          withWhiteBackground,
        }}
      >
        <Icon iconType="close" size={iconSize} />
      </CustomInputClearButton>
      <CustomInputAddButton
        onClick={onAddItem}
        visible={showAddButton}
        {...{
          type,
          btnSize,
          iconSize,
          btnPaddingSize,
          btnBorderRadius,
          withWhiteBackground,
        }}
      >
        <Icon iconType="add" size={iconSize} />
      </CustomInputAddButton>

      {type === 'number' && (
        <NumberInputButtons
          {...{
            min,
            max,
            value,
            btnSize,
            iconSize,
            onIncrement,
            onDecrement,
            btnBorderRadius,
            withWhiteBackground,
          }}
        />
      )}
    </>
  )
}

export default RightButtons
