import SearchRu from 'assets/promo/search_ru.png'
import SearchEn from 'assets/promo/search_en.png'
import MolecularEditorRu from 'assets/promo/molecularEditor_ru.png'
import MolecularEditorEn from 'assets/promo/molecularEditor_en.png'
import DatasetsRu from 'assets/promo/datasets_ru.png'
import DatasetsEn from 'assets/promo/datasets_en.png'
import SynMap from 'assets/promo/synmap.png'
import PredictingReactionsRu from 'assets/promo/predictingReactions_ru.png'
import PredictingReactionsEn from 'assets/promo/predictingReactions_en.png'
import SpectraRu from 'assets/promo/spectra_ru.png'
import SpectraEn from 'assets/promo/spectra_en.png'
import SynthCostRu from 'assets/promo/synthcost_ru.png'
import SynthCostEn from 'assets/promo/synthcost_en.png'
import Pdf2SmilesRu from 'assets/promo/pdf2smiles_ru.png'
import Pdf2SmilesEn from 'assets/promo/pdf2smiles_en.png'
import Smiles2IupacRu from 'assets/promo/smiles2iupac_ru.png'
import Smiles2IupacEn from 'assets/promo/smiles2iupac_en.png'
import StatisticsRu from 'assets/promo/statistics_ru.png'
import StatisticsEn from 'assets/promo/statistics_en.png'

export const DEFAULT_TIME = 4000

export const PROMO_CONFIG = [
  {
    img_ru: SearchRu,
    img_en: SearchEn,
    title: 'promo.search_title',
    description: 'promo.search_description',
  },
  {
    img_ru: MolecularEditorRu,
    img_en: MolecularEditorEn,
    title: 'promo.molecular_editor_title',
    description: 'promo.molecular_editor_description',
  },
  {
    img_ru: DatasetsRu,
    img_en: DatasetsEn,
    title: 'promo.datasets_title',
    description: 'promo.datasets_description',
  },
  {
    img_ru: SynMap,
    img_en: SynMap,
    title: 'promo.synmap_title',
    description: 'promo.synmap_description',
  },
  {
    img_ru: PredictingReactionsRu,
    img_en: PredictingReactionsEn,
    title: 'promo.predicting_reactions_title',
    description: 'promo.predicting_reactions_description',
  },
  {
    img_ru: SpectraRu,
    img_en: SpectraEn,
    title: 'promo.spectra_title',
    description: 'promo.spectra_description',
  },
  {
    img_ru: SynthCostRu,
    img_en: SynthCostEn,
    title: 'promo.synthcost_title',
    description: 'promo.synthcost_description',
  },
  {
    img_ru: Pdf2SmilesRu,
    img_en: Pdf2SmilesEn,
    title: 'promo.pdf2smiles_title',
    description: 'promo.pdf2smiles_description',
  },
  {
    img_ru: Smiles2IupacRu,
    img_en: Smiles2IupacEn,
    title: 'promo.smiles2iupac_title',
    description: 'promo.smiles2iupac_description',
  },
  {
    img_ru: StatisticsRu,
    img_en: StatisticsEn,
    title: 'promo.statistics_title',
    description: 'promo.statistics_description',
  },
]
export const NAVIGATION_BUTTONS = ['left', 'right']
