import styled from 'styled-components'

import { CustomInputFieldWrapper } from 'components/common/customInput/index.style'
import { CustomInputClearButton } from 'components/common/customInput/RightButtons/index.style'

export const InputWithTitle = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  ${CustomInputFieldWrapper} {
    display: none;
  }

  ${CustomInputClearButton} {
    position: absolute;
    right: 0.25rem;
    bottom: 0.25rem;
  }
`
