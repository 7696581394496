import { v4 as uuidv4 } from 'uuid'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useRef } from 'react'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { Subheadline, TextBody } from 'components/common/text/index.style'
import { TextNumber } from 'components/LiteratureCard/dialog/index.style'

import {
  FixedBlock,
  RightBlock,
  ColoredSpan,
  AuthorsBlock,
} from './index.style'

const RightPanel = ({ docData, setDialogOpen }) => {
  const [showMore, setShowMore] = useState(true)

  const authorsRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    const container = authorsRef.current
    if (container && container.scrollHeight > container.clientHeight) {
      setShowMore(true)
    } else setShowMore(false)
  }, [])

  return (
    <>
      <RightBlock>
        <div>
          <TextBody fontWeight={500} color="#2D3745">
            {t(`literatureCard.${docData.type}`)}
          </TextBody>
        </div>
        <div>
          <Subheadline color="#6E809D">
            {t('literatureCard.source')}
          </Subheadline>
          {!!docData?.published_date && (
            <Subheadline
              color="#6E809D"
              style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
            >
              {t('filter.structure.published_date')}
              {': '}
              <ColoredSpan>
                {format(parseISO(docData.published_date), 'dd.MM.yyyy')}
              </ColoredSpan>
            </Subheadline>
          )}
          <Subheadline
            color="#6E809D"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              flexWrap: 'wrap',
            }}
          >
            {' '}
            {docData.type === 'article'
              ? t('literatureCard.doi')
              : t('literatureCard.patentNumber')}
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <TextNumber
                fontWeight={600}
                style={{
                  maxWidth: '284px',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                {docData.type === 'article' ? (
                  <a
                    target="_blank"
                    href={`https://doi.org/${docData.doi}`}
                    rel="noreferrer"
                  >
                    {docData.doi}
                  </a>
                ) : (
                  docData.patent_number
                )}
              </TextNumber>
            </div>
          </Subheadline>
        </div>
        {docData?.authors && !!docData.authors.length && (
          <FixedBlock>
            <Subheadline color="#6E809D">
              {t(
                `literatureCard.${
                  docData.authors.length > 1 ? 'authorsNotColon' : 'author'
                }`
              )}
            </Subheadline>
            <AuthorsBlock height={showMore ? '48px' : '72px'} ref={authorsRef}>
              {
                <Subheadline key={uuidv4()} color="#2D3745" fontWeight="300">
                  {docData.authors.join(', ')}
                </Subheadline>
              }
            </AuthorsBlock>
            {showMore && (
              <CustomButton
                type="text"
                gap="4px"
                onClick={() => setDialogOpen(true)}
              >
                <div>{t('literatureCard.more')}</div>
                <Icon iconType="arrowRight" size="1rem" />
              </CustomButton>
            )}
          </FixedBlock>
        )}
        {docData.type === 'article'
          ? docData?.journal && (
              <div>
                <Subheadline color="#6E809D">
                  {t('literatureCard.journal')}
                </Subheadline>
                {docData.journal.map((el) => (
                  <Subheadline key={uuidv4()} color="#2D3745">
                    {el}
                  </Subheadline>
                ))}
              </div>
            )
          : docData?.company &&
            !!docData.company.length && (
              <div>
                <Subheadline color="#6E809D">
                  {t('literatureCard.owners')}
                </Subheadline>
                <Subheadline key={uuidv4()} color="#2D3745">
                  {docData.company.join(', ')}
                </Subheadline>
              </div>
            )}
      </RightBlock>
    </>
  )
}

export default RightPanel
