import CustomTag from 'components/common/customTag'
import { Caption, Headline, Label } from 'components/common/text/index.style'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import {
  CompanyCardWrapper,
  SegmentsWrapper,
  Segment,
  DotsButtonWrapper,
} from './index.style'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import { connect, useDispatch } from 'react-redux'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import EditCompanyDialog from '../editCompanyDialog'
import { useState } from 'react'
import { push } from 'connected-react-router'
import { setCurrentCustomerId } from 'store/actions/adminPanel'
import { getLicenseDate } from '../utils'
import { getIsLicenseActive } from 'utils/licenses/getLicenseNotificationText'

const CompanyCard = ({ company }) => {
  const [editOpen, setEditOpen] = useState(false)
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const isLicenseActive = getIsLicenseActive(company)

  const handleClickMenu = () => {
    setEditOpen(true)
  }

  const handleOpenMenu = (e) => {
    e.stopPropagation()
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'companyCardMenu',
        item: { handler: handleClickMenu, company },
      })
    )
  }

  const handleOpenCompany = () => {
    dispatch(
      setCurrentCustomerId({
        customerId: company.customer_id,
        licenseId: company.license_id,
      })
    )
    dispatch(push(`/admin-panel/company`))
  }

  return (
    <CompanyCardWrapper onClick={handleOpenCompany}>
      <CustomTag
        width="fit-content"
        type={
          !isLicenseActive
            ? 'score_minus'
            : company.license_is_free_trial
            ? 'accent_secondary'
            : 'accent'
        }
      >
        <Caption color="inherit">
          {t(
            `admin.${
              !isLicenseActive
                ? 'not_active'
                : company.license_is_free_trial
                ? 'test_period'
                : 'license'
            }`
          )}
        </Caption>
      </CustomTag>
      <Headline color="inherit" id={`company-label-${company.license_id}`}>
        {company.customer_name}
      </Headline>
      <SegmentsWrapper>
        <Segment>
          <Caption color={theme.colors.text.secondary}>
            {t('admin.admin')}
          </Caption>
          <Label name="smiles" color="inherit">
            {company?.customer_admin ? company.customer_admin : '—'}
          </Label>
        </Segment>
        {/* <Segment>
          <Caption color={theme.colors.text.secondary}>
            {t('admin.domain')}
          </Caption>
          <Label color="inherit">{card.domain}</Label>
        </Segment> */}
        <Segment>
          <Caption color={theme.colors.text.secondary}>
            {t('admin.numberInLicense')}
          </Caption>
          <Label color="inherit">
            {company.license_max_users_count === null
              ? '—'
              : company.license_max_users_count}
          </Label>
        </Segment>
        <Segment>
          <Caption color={theme.colors.text.secondary}>
            {t('admin.registered')}
          </Caption>
          <Label color="inherit">{company.license_users_count}</Label>
        </Segment>
        <Segment>
          <Caption color={theme.colors.text.secondary}>
            {t('admin.periodOfValidity')}
          </Caption>
          <Label color="inherit">
            {getLicenseDate(
              company.license_started_at,
              company.license_expired_at
            )}
          </Label>
        </Segment>
      </SegmentsWrapper>
      <DotsButtonWrapper>
        <CustomButton type="icon_primary" onClick={handleOpenMenu}>
          <Icon iconType="menu_2" />
        </CustomButton>
      </DotsButtonWrapper>
      {editOpen && (
        <EditCompanyDialog
          onClose={() => {
            setEditOpen(false)
          }}
          company={company}
          setEditOpen={setEditOpen}
        />
      )}
    </CompanyCardWrapper>
  )
}

export default connect()(CompanyCard)
