export const REACTION_MOLECULE_ADD = 'REACTION_MOLECULE_ADD'
export const REACTION_MOLECULE_REMOVE = 'REACTION_MOLECULE_REMOVE'
export const REACTION_MOLECULE_EDIT = 'REACTION_MOLECULE_EDIT'
export const REACTION_MOLECULE_REMOVE_ALL = 'REACTION_MOLECULE_REMOVE_ALL'

export const REACTION_CALCULATION_RESET = 'REACTION_CALCULATION_RESET'

export const REACTION_CALC_INIT = 'REACTION_CALC_INIT'
export const REACTION_CALC_SUCCESS = 'REACTION_CALC_SUCCESS'
export const REACTION_CALC_FAIL = 'REACTION_CALC_FAIL'

export const REACTION_SYNTH_CALC_INIT = 'REACTION_SYNTH_CALC_INIT'
export const REACTION_RETRO_CALC_INIT = 'REACTION_RETRO_CALC_INIT'

export const REACTION_SET_MODE = 'REACTION_SET_MODE'
