import CustomSelect from 'components/CustomSelect'

import ValuesSelector from '../ValuesSelector'
import RadioCheckboxes from '../RadioCheckboxes'

const ConditionsBlock = ({
  values,
  operator,
  typeOptions,
  filtersState,
  onChangeFilter,
  onChangeOperator,
}) => {
  return (
    <>
      <CustomSelect
        width="304px"
        options={typeOptions}
        onChange={(option) => onChangeFilter(0, 'type', option.value)}
        selectedValue={{
          value: filtersState?.[0]?.type || '',
          label: filtersState?.[0]?.type
            ? `dataset_table.${filtersState[0].type}`
            : 'dataset_table.not_selected',
        }}
        emptyLabel={'dataset_table.type'}
        withWhiteBackground={true}
        withTranslation
      />
      <ValuesSelector
        values={values}
        type={filtersState?.[0]?.type}
        filterValue={filtersState?.[0]?.filter}
        filterValue2={filtersState?.[0]?.filter2}
        onChange={(type, value) => onChangeFilter(0, type, value)}
      />
      <RadioCheckboxes
        {...{ operator, onChangeOperator }}
        onChangeOperator={(value) => onChangeOperator(value)}
      />

      <CustomSelect
        width="304px"
        options={typeOptions}
        onChange={(option) => onChangeFilter(1, 'type', option.value)}
        selectedValue={{
          value: filtersState?.[1]?.type || '',
          label: filtersState?.[1]?.type
            ? `dataset_table.${filtersState[1].type}`
            : 'dataset_table.not_selected',
        }}
        emptyLabel={'dataset_table.type'}
        withWhiteBackground={true}
      />

      <ValuesSelector
        values={values}
        type={filtersState?.[1]?.type}
        filterValue={filtersState?.[1]?.filter}
        filterValue2={filtersState?.[1]?.filter2}
        onChange={(type, value) => onChangeFilter(1, type, value)}
        disabled={!operator}
      />
    </>
  )
}

export default ConditionsBlock
