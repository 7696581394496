import {
  OPEN_FEEDBACK_FORM,
  CLOSE_FEEDBACK_FORM,
  OPEN_FEEDBACK_CARD,
  CLOSE_FEEDBACK_CARD,
  SET_FEEDBACK_PAGINATION_CONFIG,
  GET_FEEDBACK_INIT,
  SET_FEEDBACK_FILTER,
  CLEAR_FEEDBACK_FILTER,
  APPLY_FEEDBACK_FILTER,
  SHOW_FEEDBACK_BTN,
  HIDE_FEEDBACK_BTN,
} from 'store/constants/feedbackForm'

export const openFeedbackForm = (defaultTheme = null) => ({
  type: OPEN_FEEDBACK_FORM,
  defaultTheme,
})

export const closeFeedbackForm = () => ({
  type: CLOSE_FEEDBACK_FORM,
})

export const openFeedbackCard = (data) => ({
  type: OPEN_FEEDBACK_CARD,
  data,
})
export const closeFeedbackCard = () => ({
  type: CLOSE_FEEDBACK_CARD,
})

export const setFeedbackPaginationConfig = (config) => {
  return {
    type: SET_FEEDBACK_PAGINATION_CONFIG,
    config,
  }
}
export const getFeedback = ({ limit = 24, offset = 0, filter = {} }) => {
  return {
    type: GET_FEEDBACK_INIT,
    limit,
    offset,
    filter,
  }
}

export const setFeedbackFilter = ({ key, value }) => {
  return {
    type: SET_FEEDBACK_FILTER,
    key,
    value,
  }
}

export const clearFeedbackFilter = () => {
  return {
    type: CLEAR_FEEDBACK_FILTER,
  }
}
export const applyFeedbackFilter = () => {
  return {
    type: APPLY_FEEDBACK_FILTER,
  }
}

export const showFeedbackBtn = () => {
  return {
    type: SHOW_FEEDBACK_BTN,
  }
}

export const hideFeedbackBtn = () => {
  return {
    type: HIDE_FEEDBACK_BTN,
  }
}
