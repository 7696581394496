import styled, { css, keyframes } from 'styled-components'

import { TitleTertiary } from 'components/common/text/index.style'

export const Header = styled.div`
  padding: 1.75rem 1.5rem 0.75rem;
  width: 100%;
  height: 3.5rem;
`

export const Title = styled(TitleTertiary)`
  position: absolute;
  top: 1.5rem;
  left: 6.6875rem;
  width: 18.75rem;
`

export const Footer = styled.div`
  padding: 0.75rem 1.5rem 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const Content = styled.div`
  padding: 0.75rem 1.5rem;
  width: 100%;
  height: 100%;
`

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.44);
  backdrop-filter: blur(4px);
  border-radius: 1.25rem;
`

const spin = keyframes`
  to{transform: rotate(1turn)}
`

const spinnerAnimation = css`
  animation: ${spin} 1s infinite linear;
`

export const Loader = styled.div`
  z-index: 1;
  position: absolute;
  top: 3.5rem;
  left: 0;
  width: 30.75rem;
  height: 31rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};

  .spinner-icon {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};

    ${spinnerAnimation}
  }
`
