import { SHOW_BLUR, HIDE_BLUR } from '../constants/blur'

const initialState = {
  showBlur: false,
}

const blurReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SHOW_BLUR:
      return {
        ...state,
        showBlur: true,
      }

    case HIDE_BLUR:
      return initialState

    default:
      return state
  }
}

export default blurReducer
