/* eslint-disable no-case-declarations */
export const addServiceResponceToData = (view_type, responce, name = '') => {
  switch (view_type) {
    case 'table':
      if (responce === 'OK') return
      return {
        data: {
          [name]: responce,
        },
      }

    case 'list': {
      const res = responce.reduce(
        (acc, el) => {
          const { index, value, units, datasource } = el
          acc.data[index] = `${value} ${units || ''}`
          acc.sources[index] = datasource
          return acc
        },
        { data: {}, sources: {} }
      )
      return res
    }

    case 'one':
      const { index, value } = responce[0]
      return {
        data: {
          [index]: value,
        },
      }

    case 'qsar':
      const res = responce.reduce(
        (acc, el) => {
          const { index, datasource, ...values } = el
          acc.data[index] = values
          acc.sources[index] = datasource
          return acc
        },
        { data: {}, sources: {} }
      )
      return res

    default:
      return {}
  }
}
