import { useEffect } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  Label,
  TextLarge,
  TitleTertiary,
} from 'components/common/text/index.style'
import Icon from 'components/Icon'
import { getSurvey } from 'store/actions/survey'
import { copyToClipboard } from 'utils/common/common'
import CustomScrollbar from 'components/CustomScrollbar'
import LoaderRestyling from 'components/LoaderRestyling'
import { getUsersAnswers } from 'store/actions/answers'
import CustomButton from 'components/common/customButton'
import { updateApplication } from 'store/actions/adminPanel'
import { CustomDialog } from 'components/common/customDialog'
import { addSimpleNotification } from 'store/actions/notifications'
import {
  COMPANY_ACTIVITY_OPTIONS,
  COMPANY_SIZE_OPTIONS,
} from 'components/RegistrationPageNew/components/Questionnaire/config/config'

import {
  Buttons,
  UserInfo,
  Questions,
  Questionnaire,
  QuestionBlock,
} from './index.style'
import InfoLine from './InfoLine'
import { getInfoConfig } from './utils/utils'
import { getName } from '../userCard/utils/utils'

const ApplicationDialog = ({ card, onClose }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const infoConfig = getInfoConfig(card)
  const userName = getName(card?.name, card?.surname)

  const { surveys, loading, user_answers } = useSelector(
    (state) => state.survey
  )
  const survey = surveys?.find((el) => el.on_registration) || {}

  const { updating } = useSelector((state) => state.adminPanel.applications)

  useEffect(() => {
    if (!surveys) dispatch(getSurvey())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (survey?.id) {
      dispatch(getUsersAnswers({ user_id: card.user_id, survey_id: survey.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey?.id])

  const handleCopy = (type, value) => {
    copyToClipboard(value)
    dispatch(addSimpleNotification(`admin.${type}_copied`))
  }

  const handleUpdateApplication = (type) =>
    dispatch(
      updateApplication(
        card.id,
        type === 'accept',
        card.user_id,
        card.isApproved
      )
    )

  const getAnswerForRender = (question, answer) => {
    if (question === 'company_size') {
      return answer && COMPANY_SIZE_OPTIONS.includes(answer)
        ? t(`registration.${question}_options.${answer}`)
        : answer
    }
    if (question === 'company_activity')
      return answer && COMPANY_ACTIVITY_OPTIONS.includes(answer)
        ? t(`registration.${question}_options.${answer}`)
        : answer
    return answer
  }

  return (
    <CustomDialog
      withPortal
      gap="1rem"
      width="37rem"
      outboundClickEnabled={false}
      maxHeight="38.5625"
      onClose={updating ? () => {} : onClose}
    >
      {loading || !user_answers ? (
        <LoaderRestyling />
      ) : (
        <>
          <TitleTertiary
            fontWeight={theme.fontWeight.semibold}
            style={{ marginRight: '1.5rem' }}
          >
            {t('admin.test_period_application')}
          </TitleTertiary>
          <UserInfo>
            <TextLarge ellipsis fontWeight={theme.fontWeight.semibold}>
              {userName}
            </TextLarge>
            {Object.values(infoConfig).map((info, i) => (
              <InfoLine key={i} {...{ info }} onCopy={handleCopy} />
            ))}
          </UserInfo>{' '}
          <Questionnaire>
            <TextLarge ellipsis fontWeight={theme.fontWeight.semibold}>
              {t('admin.questionnaire')}
            </TextLarge>
            <CustomScrollbar
              style={{
                height: '16.5rem',
                maxHeigh: '22.375rem',
              }}
              disableTrackYWidthCompensation={true}
              className="margin--8-right"
            >
              <Questions>
                {survey?.questions?.map(({ id, text }) => {
                  const answer = user_answers.find(
                    (el) => el.question_id === id
                  )?.answer_text

                  return (
                    <QuestionBlock key={id}>
                      <Label fontWeight={theme.fontWeight.semibold}>
                        {t(`registration.${text}`)}
                      </Label>
                      <Label
                        fontWeight={theme.fontWeight.light}
                        color={theme.colors.text.secondary}
                      >
                        {getAnswerForRender(text, answer) || '—'}
                      </Label>
                    </QuestionBlock>
                  )
                })}
              </Questions>{' '}
            </CustomScrollbar>
          </Questionnaire>
          <Buttons>
            <CustomButton
              type="secondary"
              onClick={() => handleUpdateApplication('reject')}
              width="50%"
              disabled={updating || card.isApproved === false}
            >
              {t(
                card.isApproved === false
                  ? 'admin.application_rejected'
                  : 'admin.reject'
              )}
            </CustomButton>

            <CustomButton
              type="accent"
              onClick={() => handleUpdateApplication('accept')}
              width="50%"
              disabled={updating || card.isApproved}
            >
              {updating ? (
                <Icon iconType="loader" />
              ) : (
                t(
                  card.isApproved
                    ? 'admin.application_approved'
                    : 'admin.accept'
                )
              )}
            </CustomButton>
          </Buttons>
        </>
      )}
    </CustomDialog>
  )
}

export default ApplicationDialog
