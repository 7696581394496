import { memo } from 'react'

import '../../../styles/toggle.css'
import '../../../styles/pdf2smiles.css'

const RadioCheckbox = memo(
  ({
    id,
    value,
    wrapperProps,
    handler,
    title,
    disabled,
    checkbox,
    radio,
    className,
    shouldPropagate,
    inputAttributes,
    labelAttributes,
    whiteBackground = false,
  }) => {
    return (
      <div
        {...wrapperProps}
        className={`toggle toggle_is-disabled-${!!disabled} toggle_is-checkbox-${!!checkbox} toggle_is-radio-${!!radio} ${className}`}
      >
        <input
          {...inputAttributes}
          type="checkbox"
          className="toggle-input"
          id={id}
          checked={value}
          onChange={(ev) => (disabled || !handler ? () => {} : handler(ev))}
        />
        <label
          {...labelAttributes}
          htmlFor={id}
          className={`toggle-label ${
            whiteBackground ? 'toggle-label-white' : ''
          } toggle-label_is-checkbox-${!!checkbox}`}
          title={title || ''}
          onClick={(ev) => {
            if (!shouldPropagate) ev.stopPropagation()
          }}
        >
          <small className="toggle-label-slider" />
        </label>
      </div>
    )
  }
)

RadioCheckbox.displayName = 'RadioCheckbox'

export default RadioCheckbox
