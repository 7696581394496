import { Label } from 'components/common/text/index.style'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`

export const UserInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconWrapper = styled.div`
  padding: 0.75rem;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 0.75rem;
  background-color: #f3f5f8;
  margin-bottom: 1.25rem;
`

export const UserInfoField = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  max-width: ${({ maxWidth }) => maxWidth};
`

export const JobInfo = styled.div`
  margin-top: 0.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`

export const Sections = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

export const SectionItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.icons.primary};
  cursor: pointer;
  transition: all 0.2s ease;

  ${Label} {
    transition: all 0.2s ease;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};

    ${Label} {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }
`

export const SectionDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.25rem;
`

export const BottomBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
`
