import { t } from 'i18next'

export const TYPE_OPTIONS = [
  { value: 'rest', label: 'REST' },
  { value: 'rpc', label: 'RPS' },
]

export const VIEW_TYPE_OPTIONS = [
  { value: 'table', label: 'admin.table' },
  { value: 'list', label: 'admin.list' },
  { value: 'one', label: 'admin.pluginsView' },
]
export const INPUT_OPTIONS = [
  { value: 'smiles', label: 'SMILES' },
  { value: 'inchi', label: 'InChi' },
  { value: 'inchiKey', label: t('admin.inchiKey') },
]
