import { useTranslation } from 'react-i18next'

import CustomSelect from 'components/CustomSelect'
import CustomInput from 'components/common/customInput'

import { RangeInputs } from './index.style'

const ValuesSelector = ({
  type,
  values,
  onChange,
  filterValue,
  filterValue2,
}) => {
  const { t } = useTranslation()
  if (type === 'blank' || type === 'notBlank') return <></>

  if (type === 'equals')
    return (
      <CustomSelect
        width="19rem"
        options={[...new Set(values)].map((el) => ({
          value: el,
          label: el,
        }))}
        onChange={(option) => onChange('filter', option.value)}
        selectedValue={{ value: filterValue, label: filterValue }}
        emptyLabel={t('dataset_table.value')}
        withWhiteBackground
        withTranslation={false}
        withSearchByValue
        inputPlaceholder={t('dataset_table.select_value')}
      />
    )

  if (type === 'inRange')
    return (
      <RangeInputs>
        <CustomInput
          withWhiteBackground
          placeholder={t('dataset_table.from')}
          value={filterValue || ''}
          onChange={(value) => onChange('filter', value)}
          withClearButton
        />
        <CustomInput
          withWhiteBackground
          placeholder={t('dataset_table.to')}
          value={filterValue2 || ''}
          onChange={(value) => onChange('filter2', value)}
          withClearButton
        />
      </RangeInputs>
    )

  return (
    <CustomInput
      withWhiteBackground
      placeholder={t('dataset_table.search')}
      disabled={!type}
      value={filterValue || ''}
      onChange={(value) => onChange('filter', value)}
      withClearButton
    />
  )
}

export default ValuesSelector
