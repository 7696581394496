import {
  RESET_IUPAC,
  GET_EN_IUPAC_INIT,
  GET_RU_IUPAC_INIT,
} from 'store/constants/smilesIupac'

export const getIupac = ({ smiles, lang }) => {
  const type = lang === 'ru' ? GET_RU_IUPAC_INIT : GET_EN_IUPAC_INIT
  return {
    type,
    smiles,
    lang,
  }
}

export const resetIupac = () => {
  return {
    type: RESET_IUPAC,
  }
}
